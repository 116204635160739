import { WhiteTip } from "@components";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import useStyles from "./styles";

const LabelTooltip = ({ title }) => {
  const classes = useStyles();
  return (
    <WhiteTip title={title}>
      <div className={classes.labelInfo}>
        <InfoIcon />
      </div>
    </WhiteTip>
  );
};

export default LabelTooltip;
