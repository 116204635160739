import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& span": {
      color: theme.palette.common.white,
      border: "none !important",
      padding: "initial !important",
    },
    "&.MuiButton-contained": {
      background: "linear-gradient(90deg, #585ce9 0%, #7b37f9 91%)",
      color: theme.palette.common.white,
      whiteSpace: "nowrap",
      lineHeight: "initial",
    },
    "&.MuiButton-containedSizeLarge": {
      paddingTop: "8px",
      paddingBottom: "8px",
      fontSize: ".875rem",
    },
    "&.MuiButton-containedSizeSmall": {
      padding: "2.5px 8px",
    },
    "&.MuiButton-containedPrimary": {
      background: "linear-gradient(90deg, #585ce9 0%, #7b37f9 91%)",
    },
    "&.MuiButton-containedSecondary": {
      background: "linear-gradient(90deg,#f7c129,#fc8031 0,#fd2d2d 88%)",
    },
    "&.MuiButton-containedSuccess": {
      background:
        "linear-gradient(97deg, rgba(16,187,53,1) 0%, rgba(16,187,76,1) 48%, rgba(16,187,116,0.9836309523809523) 100%)",
    },
    "&.MuiButton-colorInherit": {
      "& span": {
        color: theme.palette.text.main,
      },
      boxShadow: "0 2px 5px 1px rgb(179, 179, 179, .14)",
      border: "1px solid transparent",
      borderRadius: "2px",
      "&.active": {
        border: "1px solid #15c70045",
        background: "#a9e8b91f",
      },

      background:
        "linear-gradient(97deg, rgba(236,236,236,1) 0%, rgba(233,233,233,1) 48%, rgba(231,231,231,1) 100%)",
    },
  },
}));

const CustomLoadingBtn = ({ label, setClass, ...rest }) => {
  const classes = useStyles();
  return (
    <LoadingButton
      className={`${classes.root} ${
        setClass !== undefined && setClass !== false ? setClass : ""
      }`}
      {...rest}
      variant="contained"
    >
      {label}
    </LoadingButton>
  );
};

export default CustomLoadingBtn;
