import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserAccountData } from "../../appRedux/action/subscriptionActions";

function UserAlert() {
  // For Translator
  const { t } = useTranslation();
  // For Translator

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAccountData());
  }, []);

  const useProfileData = useSelector(
    (userData) => userData.subscription.useAccountDetails?.data
  );

  const noteFinalData = {
    name: useProfileData?.subscription_name
      ? useProfileData.subscription_name
      : "",
    expire: useProfileData?.expired_at ? useProfileData.expired_at : "",
  };

  return (
    <div className="note-box note_box-2">
      {noteFinalData.name.toLowerCase() === "free trial" ? (
        <div className="note note__free">
          <div className="note-icon">
            <i className="fas fa-exclamation-triangle" />
          </div>
          <div className="note-text">
            <p>
              {t("common.noteFree.1")}
              <span>
                {noteFinalData.name} {t("common.noteFree.2")}
              </span>
              {t("common.noteFree.3")}
              <span>{noteFinalData.expire}</span> {t("common.noteFree.4")}{" "}
              &nbsp;
              <Link to="/pricing"> {t("common.noteFree.5")}</Link>
            </p>
          </div>
        </div>
      ) : noteFinalData.name.toLowerCase() === "free" ? (
        <div className="note note__free">
          <div className="note-icon">
            <i className="fas fa-exclamation-triangle" />
          </div>
          <div className="note-text">
            <p>
              {t("common.noteFree.1")}
              <span>
                {noteFinalData.name} {t("common.noteFree.2")}
              </span>
              {t("common.noteFree.7")} {t("common.noteFree.6")} &nbsp;
              {t("common.noteFree.4")} &nbsp;
              <Link to="/pricing"> {t("common.noteFree.5")}</Link>
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UserAlert;
