import serverCall from "../../modules/serverCall";

export const getAllCountry = () => (dispatch) => {
  dispatch({
    type: GET_ALL_COUNTRY_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/country/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_COUNTRY_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_COUNTRY_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const GET_ALL_COUNTRY_BEGIN = "GET_ALL_COUNTRY_BEGIN";
export const GET_ALL_COUNTRY_SUCCESS = "GET_ALL_COUNTRY_SUCCESS";
export const GET_ALL_COUNTRY_FAIL = "GET_ALL_COUNTRY_FAIL";
