import { createTheme } from "@mui/material/styles";

const primaryColor = "#585CE9";
const secondaryColor = "#10bb35";
const textColor = "#384760";
const redColor = "#ff1515";
const grayColor = "#f0f2f4";
const borderColor = "#eaeaea";
const yellowColor = "#ee8322";
const grayLight = "#f8f8f8";
const grayDark = "#f9f9f9";
const whiteColor = "#ffffff";
const blackColor = "#000918";
const greenColor = "#10bb35";
const gray = "#bfbfbf";

export default createTheme({
  palette: {
    background: "#f9f9f9",
    primary: {
      main: primaryColor,
      gray
    },

    secondary: {
      main: secondaryColor,
      yellow: yellowColor
    },

    text: {
      main: textColor,
      light: whiteColor,
      gray: "#7b7b7b"
    },

    error: {
      main: redColor
    },

    warning: {
      main: yellowColor
    },

    gray: {
      main: grayColor,
      light: grayLight,
      dark: grayDark
    },

    border: {
      main: borderColor
    },

    common: {
      white: whiteColor,
      red: redColor,
      yellow: yellowColor,
      black: blackColor,
      green: greenColor,
      solidGray: "#d1d4d8"
    },
    series: {
      success: "#69db5c",
      improve: "#28bfed",
      info: "#09a4d3",
      warning: "#f9a147",
      danger: "#ff5a26",
      error: "#d31052"
    }
  },
  typography: {
    fontFamily: ["Heebo", "sans-serif"].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "1.6rem",
      lineHeight: "56px"
    },
    h2: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "32px"
    },
    h3: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px"
    },
    h4: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px"
    },
    h5: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px"
    },
    h6: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px"
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "25px"
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "16px"
    },
    body1: {
      fontSize: "14px"
    },
    body2: {
      fontSize: "13px"
    },
    button: {
      fontSize: "14px"
    },
    caption: {
      fontSize: "12px"
    },
    overline: {
      fontSize: "12px",
      fontWeight: 600,
      textTransform: "uppercase"
    }
  },

  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          paddingBottom: "25px",
          marginBottom: "25px",

          "& .MuiTableHead-root": {
            "& tr > th": {
              border: "none"
            },
            "& .MuiTableRow-root": {
              "& .MuiTableCell-root": {
                "&:nth-of-type(1)": {
                  borderRadius: "4px 0 0 4px"
                },
                "&:nth-last-of-type(1)": {
                  borderRadius: "0px 4px 4px 0px"
                },

                "& strong": {
                  fontSize: ".875rem",
                  fontWeight: "600"
                },

                whiteSpace: "nowrap",
                padding: "10px 15px",
                backgroundColor: grayColor,
                textTransform: "uppercase"
              }
            }
          },

          "& .MuiTableBody-root": {
            "& .MuiTableRow-root": {
              "& .MuiTableCell-root": {
                "&:nth-of-type(1)": {
                  maxWidth: "400px"
                },

                minWidth: "150px",

                "&:nth-last-of-type(4)": {
                  "& span": {
                    border: "1px solid transparent",
                    borderColor: "#ddd",
                    padding: "0px 4px"
                  }
                },

                borderBottom: "1px solid transparent",
                borderBottomColor: borderColor,
                padding: "10px 10px"
              }
            },

            "& .MuiTableCell-root": {
              "& .MuiButtonBase-root": {
                marginLeft: "10px",
                "&:focus": {
                  outline: "none"
                }
              },

              "& i": {
                marginRight: "8px"
              },
              padding: "8px 15px",
              border: "none"
            }
          },

          " &::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundolor: "#eee",
            height: "8px"
          },

          "&::-webkit-scrollbar": {
            height: "8px",
            backgroundColor: "#eee",
            borderadius: "10px"
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            backgroundColor: " #585ce9",
            border: " 1px solid #eee"
          }
        }
      }
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: primaryColor,
          "&.Mui-checked": {
            color: primaryColor
          }
        },
        colorSecondary: {}
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: ".2rem",
          padding: "1.4rem 1.5rem",
          background: "#fff",
          "&.MuiPaper-elevation1": {
            boxShadow: "0px 0px 1px 1px rgba(150, 150, 150, .18)"
          },
          "&.MuiPaper-elevation4": {
            boxShadow: "0px 0px 10px 2px rgba(150, 150, 150, .10)"
          },
          "&.MuiPaper-elevation6": {
            boxShadow: "0px 0px 30px 4px rgba(150, 150, 150, .20)"
          },
          "&.MuiPaper-elevation8": {
            boxShadow: "0px 0px 30px 4px rgba(150, 150, 150, .20)",
            "&.MuiPopover-paper": {
              paddingTop: "5px",
              paddingBottom: "5px",
              marginTop: "1rem",
              "& li": {
                margin: "0 -25px",
                padding: "5px 30px"
              }
            }
          }
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          color: textColor,
          "& .MuiOutlinedInput-root": {
            color: textColor,
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "none"
              }
            }
          },
          "& .MuiFormLabel-root": {
            color: textColor,
            fontSize: ".874rem",
            "&.Mui-focused": {
              fontSize: ".875rem"
            }
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 40,
          color: primaryColor,
          borderRadius: 5,
          "& $notchedOutline": {
            borderColor: primaryColor
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: primaryColor,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderColor: primaryColor
            }
          },
          "&$focused $notchedOutline": {
            borderColor: primaryColor,
            borderWidth: 1
          }
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: primaryColor,
          "&$focused": {
            color: primaryColor
          },
          "&$active": {
            color: primaryColor
          }
        }
      }
    }
  }
});
