import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    backgroundColor: theme.palette.common.white,
    padding: "2px .5rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    "&.small__inline": {
      display: "inline-block",
      marginLeft: "3rem",
      "& p": {
        fontSize: ".8rem",
        fontWeight: "500",
        textTransform: "initial",
        "& span": {
          fontSize: ".8rem",
          fontWeight: "500",
        },
      },
    },
    "& p": {
      fontSize: ".897rem",
      marginTop: "0",
      textTransform: "initial",
      "& span": {
        fontSize: ".897rem",
        fontWeight: "500",
        color: theme.palette.secondary.yellow,
        margin: "0 .5rem",
      },
    },
  },
}));

const UsesStatus = ({ data, ...rest }) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.root} ${
        data?.type !== undefined && data?.type
      } generated_field2`}
      {...rest}
    >
      {data.title && (
        <Typography variant="body1">
          {data.title}
          <Typography component="span">
            {data.use} / {data.total}
          </Typography>
          {data.lastTitle}
        </Typography>
      )}
      {data.total === 0 && (
        <Typography variant="body1"> {data.upgrade} </Typography>
      )}
    </Box>
  );
};

export default UsesStatus;
