import {
  STEP_ONE_DATA_POST_BEGIN,
  STEP_ONE_DATA_POST_SUCCESS,
  STEP_ONE_DATA_POST_FAIL,
  STEP_ONE_DATA_GET_BEGIN,
  STEP_ONE_DATA_GET_SUCCESS,
  STEP_ONE_DATA_GET_FAIL,
  STEP_ONE_DATA_UPDATE_BEGIN,
  STEP_ONE_DATA_UPDATE_SUCCESS,
  STEP_ONE_DATA_UPDATE_FAIL,
} from "../action/stepOneAction";

const initialState = {
  title: null,
  group: null,
  url: null,
  domain: null,
  step_loading: false,
  project_id: null,
  project_details: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STEP_ONE_DATA_POST_BEGIN:
      return {
        ...state,
        step_loading: true,
      };

    case STEP_ONE_DATA_POST_SUCCESS:
      return {
        ...state,
        step_loading: false,
        project_id: action.payload.data.data.project_id,
      };

    case STEP_ONE_DATA_POST_FAIL:
      return {
        ...state,
        step_loading: false,
      };

    case STEP_ONE_DATA_GET_BEGIN:
      return {
        ...state,
        step_loading: true,
      };

    case STEP_ONE_DATA_GET_SUCCESS:
      return {
        ...state,
        step_loading: false,
        project_details: action.payload.data.data.project_details,
      };

    case STEP_ONE_DATA_GET_FAIL:
      return {
        ...state,
        step_loading: false,
      };

    case STEP_ONE_DATA_UPDATE_BEGIN:
      return {
        ...state,
        step_loading: true,
      };

    case STEP_ONE_DATA_UPDATE_SUCCESS:
      return {
        ...state,
        step_loading: false,
      };

    case STEP_ONE_DATA_UPDATE_FAIL:
      return {
        ...state,
        step_loading: false,
      };
    default:
      return state;
  }
};
