import {
  CustomButton,
  Drawers,
  PageLoader,
  UsesStatus,
  YellowNote,
} from "@components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addWidget,
  addWidgetUpdate,
  getWidgetDefaultAction,
} from "../../appRedux/action/addWidgetAction";
import {
  getWidgetbyId,
  resetWidget,
} from "../../appRedux/action/widgetListAction";
import FormField from "./FormField";
import FormStyle from "./FormStyle";
import ResetModal from "./ResetModal";
import useStyles from "./styles";
import WidgetType from "./WidgetType";

const CreateWidget = () => {
  const [tabValue, setValue] = useState("2");

  const handleChangesTab = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(getWidgetDefaultAction());
  }, [dispatch]);

  const widgetDefaultValue = useSelector((data) => data.widgetDefaultValue);

  const [btnLoader, setBtnLoader] = useState(false);

  const updateWidget = useSelector((state) => state.widget.widgetdata);

  // Selected Theme for widget type
  const [selectedWidgetTheme, setSelectedWidgetTheme] = useState({
    theme: {},
    active_theme_type: "",
    active_theme: "",
  });
  /// On changing value the themes and widget
  const [changingWidgetTheme, setChangingWidgetTheme] = useState({
    active_theme_type: "FIXED",
    active_theme: "",
  });

  const [values, setValues] = useState({
    name: "",
    smtp_verified:false,
    widget_title: "",
    widget_lang: "",
    available_lang: [],
    display_title: "on",
    widget_type: 1,
    widget_settings_extra: {
      widget_auto_popup: "",
      widget_popup_icon: "",
      show_form_view_as: 1,
      event_type: {
        on_exit: 0,
        on_timeout: -1,
        on_scroll: 200,
      },
    },

    widget_position: "right",
    widget_theme_code: "",
    widget_form_extra: "",
    widget_description: "",
    widget_button_label: "",
    widget_button_color: "",
    action: "widget-setting",
    close_button_action: "default",
    close_button_text: "Close this report",
    close_button_extra: {
      default: {
        title: "",
      },
      redirect: {
        title: "",
        redirect_url: "",
      },
    },
    report_action: "default",
    report_action_extra: {
      default: {

      },
      redirect: {
        redirect_url: "",
      },
      popup: {
        text: "",
      },
    },
    

    active_theme: "",
    active_theme_type: "",
    show_field_text_as: "",

    font_family: "",
    form_style: {
      background: "#dddddd",
      border_radius: "",
      margin_top: "",
      margin_right: "",
      margin_bottom: "",
      margin_left: "",
      padding_top: "",
      padding_right: "",
      padding_bottom: "",
      padding_left: "",
      border_top: "",
      border_bottom: "",
      border_left: "",
      border_right: "",
      border_color: "#ffffff",
      border_type: "",
      full_width: "",
      max_width: "",
    },

    form_body_style: {
      background: "#dddddd",
      border_radius: "",
      margin_top: "",
      margin_right: "",
      margin_bottom: "",
      margin_left: "",
      padding_top: "",
      padding_right: "",
      padding_bottom: "",
      padding_left: "",
      border_bottom: "",
      border_top: "",
      border_left: "",
      border_right: "",
      border_color: "#ddd",
      border_type: "",
    },

    cta_button: {
      color: "#ffffff",
      background: "#ffffff",
      text_size: "",
      text_transform: "",
      font_weight: "",
      border_top: "",
      border_bottom: "",
      border_left: "",
      border_right: "",
      border_color: "#ffffff",
      border_type: "",
      border_radius: "",
      position: "",
    },

    // Form Title Style
    form_title: {
      color: "#ffffff",
      text_size: "",
      font_weight: "",
      text_transform: "",
    },

    // Form Terms and Condition Style
    tnc_style: {
      color: "#000000",
      text_size: "",
      font_weight: "",
      text_transform: "",
      check_background: "#ffffff",
      check_active_background: "#ffffff",
      check_mark_color: "#ffffff",
      check_width: "",
      check_height: "",
      check_border_radius: "",
    },

    // Form Des Style
    form_des: {
      color: "#000000",
      text_size: "",
      font_weight: "",
      text_transform: "",
    },
    // Form Extra HTML Content
    form_extra_html: {
      color: "#000000",
      text_size: "",
      font_weight: "",
      text_transform: "",
    },
    // Input field style
    input_field: {
      background: "#ffffff",
      margin_top: "",
      margin_right: "",
      margin_bottom: "",
      margin_left: "",
      padding_top: "",
      padding_right: "",
      padding_bottom: "",
      padding_left: "",
      border_top: "",
      border_bottom: "",
      border_left: "",
      border_right: "",
      border_radius: "",
      border_color: "#ffffff",
      border_type: "solid",
      color: "#ffffff",
      text_size: "",
      font_weight: "",
      text_transform: "normal",
    },

    // Placeholder style
    input_placeholder: {
      text_size: "",
      font_weight: "",
      text_transform: "normal",
      color: "#ffffff",
    },

    // Input label style
    input_label: {
      text_size: "",
      font_weight: "",
      text_transform: "normal",
      color: "#ffffff",
    },

    // Input label style
    input_error_message: {
      text_size: "",
      font_weight: "",
      text_transform: "normal",
      color: "#ffffff",
    },

    // Popup Icon Style
    popup_icon: {
      width: "",
      height: "",
      color: "#ffffff",
      border_radius: "",
    },

    // Close button style
    close_btn: {
      background: "#ffffff",
      icon_color: "#ffffff",
      margin_top: "",
      margin_right: "",
      margin_bottom: "",
      margin_left: "",
      padding_top: "",
      padding_right: "",
      padding_bottom: "",
      padding_left: "",
      border_top: "",
      border_bottom: "",
      border_left: "",
      border_right: "",
      border_color: "#dddddd",
      border_type: "",
      border_radius: "",
      display: "",
      text_size: "",
      font_weight: "",
    },

    // Report Close button style
    report_close_btn: {
      background: "#ffffff",
      color: "#ffffff",
      text_transform: "",
      font_weight: "",
      margin_top: "",
      margin_right: "",
      margin_bottom: "",
      margin_left: "",
      padding_top: "",
      padding_right: "",
      padding_bottom: "",
      padding_left: "",
      border_top: "",
      border_bottom: "",
      border_left: "",
      border_right: "",
      border_color: "#ffffff",
      border_type: "",
      border_radius: "",
    },
    can_advance_audit: 0,
    wform_url: {
      label: "",
      placeholder: "",
      advance_audit: 0,
    },

    wform_keyword: {
      label: "",
      placeholder: "",
      show_field: 1,
    },

    wform_name: {
      label: "",
      show_field: 0,
      placeholder: "",
    },

    wform_phone: {
      label: "",
      show_field: 0,
      placeholder: "",
    },

    wform_email: {
      label: "",
      show_field: 2,
      placeholder: "",
    },

    wform_tnc: {
      label: "",
      show_field: 0,
      placeholder: "",
    },
  });
  const [someEditedValue, setSomeEditedValue] = useState({});

  const [errors, setErrors] = useState({
    name_error: "",
    title_error: "",
    widget_type_error: "",
    redirect_url_error: "",
    close_button_label_error: "",
    url_label_error: "",
    url_placeholder_error: "",
    key_placeholder_error: "",
    key_label_error: "",
    email_placeholder_error: "",
    email_label_error: "",
    phone_placeholder_error: "",
    phone_label_error: "",
    name_placeholder_error: "",
    name_label_error: "",
    terms_condition_text_error: "",
    lead_generation_btn_error: "",
  });

  const { id } = useParams();

  useEffect(() => {
    if (id === undefined) {
      setValues((prevValues) => ({
        ...prevValues,
        ...changingWidgetTheme,
        widget_settings_extra: {
          ...prevValues.widget_settings_extra,
          ...changingWidgetTheme.widget_settings_extra,
          event_type: {
            ...prevValues.widget_settings_extra.event_type,
            ...changingWidgetTheme.widget_settings_extra?.event_type,
          },
        },
        widget_type:
          changingWidgetTheme.active_theme_type === "POPUP"
            ? 2
            : changingWidgetTheme.active_theme_type === "FIXED"
            ? 1
            : changingWidgetTheme.active_theme_type === "TAKEOVER"
            ? 4
            : changingWidgetTheme.active_theme_type === "TWO_STEP"
            ? 5
            : 3,
      }));
    }
  }, [changingWidgetTheme]);

  // Seelcted Form
  const [selectedForm, setSelectedForm] = useState(values.widget_type);
  useEffect(() => {
    setSelectedForm(values.widget_type);
  }, [values.widget_type]);

  async function widgetdata() {
    await dispatch(getWidgetbyId(id));
  }

  const [ReportAction, setReportAction] = useState(values.report_action);

  // useEffect(() => {
  //   setOptionField(values.close_button_action);
  // }, [updateWidget, values.close_button_action]);

  // useEffect(() => {
  //   setReportAction(values.report_action);
  // }, [updateWidget, values.report_action]);



  const constValueEditMode = useCallback(() => {
    setValues((prevValues) => ({
      ...prevValues,
      name: updateWidget.name,
      widget_description: updateWidget.widget_description,
      widget_form_extra: updateWidget.widget_form_extra,
      widget_title: updateWidget.widget_title,
      widget_lang: updateWidget.widget_lang,
      available_lang: updateWidget.available_lang,
      smtp_verified: updateWidget.smtp_verified,
    }));
  }, [updateWidget]);
  useEffect(() => {
    if (id !== undefined) {
      constValueEditMode();
    }
  }, [updateWidget]);

  const changeTheValueWhenEditMode = useCallback(() => {
    setValues((prevValues) => ({
      ...prevValues,
      ...selectedWidgetTheme.theme,
      ...selectedWidgetTheme.style,
      widget_description: updateWidget.widget_description,
      widget_form_extra: updateWidget.widget_form_extra,
      widget_title: updateWidget.widget_title,
      smtp_verified: updateWidget.smtp_verified,
      widget_position: selectedWidgetTheme.theme.widget_position,
      widget_settings_extra: {
        ...prevValues.widget_settings_extra,
        ...selectedWidgetTheme.theme.widget_settings_extra,
        event_type: {
          ...prevValues.widget_settings_extra?.event_type,
          ...selectedWidgetTheme.theme.widget_settings_extra?.event_type,
        },
      },
      widget_type:
        selectedWidgetTheme.active_theme_type === "POPUP"
          ? 2
          : selectedWidgetTheme.active_theme_type === "FIXED"
          ? 1
          : selectedWidgetTheme.active_theme_type === "TAKEOVER"
          ? 4
          : selectedWidgetTheme.active_theme_type === "TWO_STEP"
          ? 5
          : 3,
      active_theme: selectedWidgetTheme.active_theme,
      active_theme_type: selectedWidgetTheme.active_theme_type,
      form_style: {
        ...prevValues.form_style,
        ...selectedWidgetTheme.theme?.style?.form_style,
      },
      form_body_style: {
        ...prevValues.form_body_style,
        ...selectedWidgetTheme.theme?.style?.form_body_style,
      },
      cta_button: {
        ...prevValues.cta_button,
        ...selectedWidgetTheme.theme?.style?.cta_button,
      },
      form_title: {
        ...prevValues.form_title,
        ...selectedWidgetTheme.theme?.style?.form_title,
      },
      tnc_style: {
        ...prevValues.tnc_style,
        ...selectedWidgetTheme.theme?.style?.tnc_style,
      },
      form_des: {
        ...prevValues.form_des,
        ...selectedWidgetTheme.theme?.style?.form_des,
      },
      form_extra_html: {
        ...prevValues.form_extra_html,
        ...selectedWidgetTheme.theme?.style?.form_extra_html,
      },
      input_field: {
        ...prevValues.input_field,
        ...selectedWidgetTheme.theme?.style?.input_field,
      },
      input_placeholder: {
        ...prevValues.input_placeholder,
        ...selectedWidgetTheme.theme?.style?.input_placeholder,
      },
      input_label: {
        ...prevValues.input_label,
        ...selectedWidgetTheme.theme?.style?.input_label,
      },
      input_error_message: {
        ...prevValues.input_error_message,
        ...selectedWidgetTheme.theme?.style?.input_error_message,
      },
      popup_icon: {
        ...prevValues.popup_icon,
        ...selectedWidgetTheme.theme?.style?.popup_icon,
      },
      close_btn: {
        ...prevValues.close_btn,
        ...selectedWidgetTheme.theme?.style?.close_btn,
      },
      report_close_btn: {
        ...prevValues.report_close_btn,
        ...selectedWidgetTheme.theme?.style?.report_close_btn,
      },
    }));
  }, [selectedWidgetTheme]);

  useEffect(() => {
    if (id !== undefined) {
      changeTheValueWhenEditMode();
    }
  }, [selectedWidgetTheme]);

  useEffect(() => {
    if (window.location.pathname !== "/create-widget") {
      widgetdata();
    }
    setValues({ ...values, action: "widget-setting" });
  }, []);
  const handleChangeSelect = useCallback((data,e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.name]: data.value,
    }));

  }, []);
  const handleChange = useCallback((event) => {
    // event.persist();
    const { name, value } = event.target;

    if (name !== "widget_auto_popup" || name !== "widget_popup_icon") {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "redirect_url" || name === "title") {
      setSomeEditedValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  }, []);

  const handleChangePopup = useCallback(
    (event) => {
      event.persist();
      const { name, value } = event.target;
      setValues((prevValues) => ({
        ...prevValues,
        widget_settings_extra: {
          ...prevValues.widget_settings_extra,
          [name]: value,
        },
      }));

      setSomeEditedValue((prevValues) => ({
        ...prevValues,
        widget_settings_extra: {
          ...values.widget_settings_extra,
          ...prevValues.widget_settings_extra,
          [name]: value,
        },
      }));
    },
    [values]
  );

  // Style changign objects
  const [changingObject, setChangingObject] = useState({});



  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setBtnLoader(true);

      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

      if (
        !values.name &
          !values.widget_description &
          !selectedForm &
          !values.close_button_extra.redirect_url &&
        (someEditedValue?.close_button_action === "redirect")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name_error: <span>{t("createWidget.form.error.4")}</span>,
          widget_type_error: <span>{t("createWidget.form.error.3")}</span>,
        }));

        window.scrollTo({
          top: "0",
          behavior: "smooth",
        });

        setBtnLoader(false);
      } else if (!values.name) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name_error: <span>{t("createWidget.form.error.4")}</span>,
        }));

        setBtnLoader(false);

        window.scrollTo({
          top: "0",
          behavior: "smooth",
        });
      } else if (
        (someEditedValue?.close_button_action === "redirect") &&
        (values?.close_button_extra?.redirect.redirect_url === "" ||
          someEditedValue?.close_button_extra?.redirect.redirect_url === "")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          redirect_url_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        (someEditedValue?.close_button_action === "redirect") &&
        !regexp.test(values?.close_button_extra?.redirect.redirect_url)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          redirect_url_error: <span>{t("createWidget.form.error.5")}</span>,
        }));
      } else if (
        values.close_button_action === "redirect" &&
        (values?.close_button_extra?.redirect?.title === "" ||
          someEditedValue?.close_button_extra?.redirect?.title === "")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          close_button_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.close_button_action === "default" &&
        (values?.close_button_extra?.default?.title === "" ||
          someEditedValue?.close_button_extra?.default?.title === "")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          close_button_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (!selectedForm || selectedForm === undefined) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          widget_type_error: <span>{t("createWidget.form.error.3")}</span>,
        }));
        setBtnLoader(false);
      } else if (
        values.wform_url.show_field !== 0 &&
        (values.show_field_text_as === 1 || values.show_field_text_as === 3) &&
        values?.wform_url.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          url_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_url.show_field !== 0 &&
        (values.show_field_text_as === 2 || values.show_field_text_as === 3) &&
        values?.wform_url.placeholder === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          url_placeholder_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_keyword.show_field !== 0 &&
        (values.show_field_text_as === 1 || values.show_field_text_as === 3) &&
        values?.wform_keyword.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          key_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_keyword.show_field !== 0 &&
        (values.show_field_text_as === 2 || values.show_field_text_as === 3) &&
        values?.wform_keyword.placeholder === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          key_placeholder_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_email.show_field !== 0 &&
        (values.show_field_text_as === 1 || values.show_field_text_as === 3) &&
        values?.wform_email.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_email.show_field !== 0 &&
        (values.show_field_text_as === 2 || values.show_field_text_as === 3) &&
        values?.wform_email.placeholder === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email_placeholder_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_phone.show_field !== 0 &&
        (values.show_field_text_as === 1 || values.show_field_text_as === 3) &&
        values?.wform_phone.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_phone.show_field !== 0 &&
        (values.show_field_text_as === 2 || values.show_field_text_as === 3) &&
        values?.wform_phone.placeholder === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_placeholder_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_name.show_field !== 0 &&
        (values.show_field_text_as === 1 || values.show_field_text_as === 3) &&
        values?.wform_name.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name_label_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_name.show_field !== 0 &&
        (values.show_field_text_as === 2 || values.show_field_text_as === 3) &&
        values?.wform_name.placeholder === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name_placeholder_error: <span>{t("common.required")}</span>,
        }));
      } else if (values?.widget_button_label === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lead_generation_btn_error: <span>{t("common.required")}</span>,
        }));
      } else if (
        values.wform_tnc.show_field !== 0 &&
        values?.wform_tnc.label === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          terms_condition_text_error: <span>{t("common.required")}</span>,
        }));
      } else {
        const valuess = {
          ...values,
          ...someEditedValue,
          close_button_extra: {
            ...values.close_button_extra,
            default: {
              ...values.close_button_extra.default,
              ...someEditedValue?.close_button_extra?.default,
            },
            redirect: {
              ...values.close_button_extra.redirect,
              ...someEditedValue?.close_button_extra?.redirect,
            },
          },
          report_action_extra: {
            ...values.report_action_extra,
            ...someEditedValue?.report_action_extra,
            popup: {
              ...values.report_action_extra.popup,
              ...someEditedValue?.report_action_extra?.popup,
            },
            redirect: {
              ...values.report_action_extra.redirect,
              ...someEditedValue?.report_action_extra?.redirect,
            },
            default: {
              ...values.report_action_extra.default,
              ...someEditedValue?.report_action_extra?.default,
            },
          },
          overright_style: {
            ...changingObject,
          },
        };

        const deletedItem = [
          "style",
          "form_des",
          "input_field",
          "form_extra_html",
          "input_placeholder",
          "input_label",
          "popup_icon",
          "close_btn",
          "report_close_btn",
          "input_error_message",
          "tnc_style",
          "cta_button",
          "form_body_style",
          "form_style",
          "form_title",
        ];

        await deletedItem.forEach((i) => delete valuess[i]);

        await dispatch(addWidget(valuess, history));

        setBtnLoader(false);
      }
    },
    [someEditedValue?.close_button_action, values]
  );


  const handleSubmitUpdate = useCallback(
    async (e, type) => {
      e.preventDefault();

      setBtnLoader(true);

      const valuess = {
        ...values,
        ...someEditedValue,
        close_button_extra: {
          ...values.close_button_extra,
          ...someEditedValue?.close_button_extra,
          redirect: {
            ...values.close_button_extra.redirect,
            ...someEditedValue?.close_button_extra?.redirect,
          },
          default: {
            ...values.close_button_extra.default,
            ...someEditedValue?.close_button_extra?.default,
          },
        },
        report_action_extra: {
          ...values.report_action_extra,
          ...someEditedValue?.report_action_extra,
          popup: {
            ...values.report_action_extra.popup,
            ...someEditedValue?.report_action_extra?.popup,
          },
          redirect: {
            ...values.report_action_extra.redirect,
            ...someEditedValue?.report_action_extra?.redirect,
          },
          default: {
            ...values.report_action_extra.default,
            ...someEditedValue?.report_action_extra?.default,
          },
        },
        overright_style: {
          ...changingObject,
        },
      };

      const deletedItem = [
        "style",
        "form_des",
        "input_field",
        "form_extra_html",
        "input_placeholder",
        "input_label",
        "popup_icon",
        "close_btn",
        "report_close_btn",
        "input_error_message",
        "tnc_style",
        "cta_button",
        "form_body_style",
        "form_style",
        "form_title",
      ];

      await deletedItem.forEach((i) => delete valuess[i]);

      await dispatch(addWidgetUpdate(id, valuess, history, type));

      if (type !== "go") {
        await dispatch(getWidgetbyId(id));
      }

      setBtnLoader(false);
    },
    [values]
  );

  const useProfileData = useSelector(
    (userData) => userData.subscription.useAccountDetails.data
  );

  const validData = {
    total: useProfileData
      ? useProfileData.subscription_details.widget_setting?.quota
      : 0,
    use: useProfileData
      ? useProfileData.subscription_details.widget_setting?.usage
      : 0,
    title: t("common.youCreated"),
    lastTitle: t("common.widget"),
    upgrade: t("createWidget.toCreate"),
  };

  

  

  const [selectedWt, setSelectedWt] = useState();

  // Theme type ID
  const [typeofTheme, setTypeOfTheme] = useState();

  useEffect(() => {
    if (window.location.pathname === "/create-widget") {
      setSelectedWt(widgetDefaultValue?.data?.all_theme?.[0]);
    }
  }, [widgetDefaultValue]);

  // Change widget type when edit mood
  const setWhenEditMoodAndChangeWidget = useCallback((datas) => {
    setSelectedWidgetTheme((prevStates) => ({
      ...prevStates,
      theme: {
        ...datas?.themes[0],
      },
      active_theme_type: datas?.themes[0].theme_type,
      active_theme: datas?.themes[0].theme_code,
    }));
  }, []);

  // Handle Change Widget type Radio
  const handleRadioChange = useCallback(
    async (e) => {
      setSelectedForm(parseInt(e.target.value));

      const selectedWidgets =
        widgetDefaultValue?.data?.all_theme[parseInt(e.target.value) - 1];

      await setSelectedWt(selectedWidgets);

      // await setTypeOfTheme(selectedWidgets?.themes[0]?.theme_code);
      // setTypeOfTheme(widgetDefaultValue?.data?.active_themes?.[selectedWt.theme_type]);

      if (id !== undefined) {
        if (selectedWidgets.theme_type === updateWidget.active_theme_type) {
          let editedTheme;

          editedTheme = updateWidget.all_theme.find(
            ({ theme_type }) => theme_type === updateWidget.active_theme_type
          );

          setTypeOfTheme(
            updateWidget.active_themes?.[updateWidget.active_theme_type]
          );

          const findalTheme = editedTheme.themes.find(
            ({ theme_code }) =>
              theme_code ===
              updateWidget.active_themes[updateWidget.active_theme_type]
          );

          setSelectedWt(editedTheme);

          setSelectedWidgetTheme((prevStates) => ({
            ...prevStates,
            theme: {
              ...findalTheme,
            },
            active_theme_type: updateWidget.active_theme_type,
            active_theme:
              updateWidget.active_themes[updateWidget.active_theme_type],
          }));
        } else {
          setWhenEditMoodAndChangeWidget(selectedWidgets);
          setTypeOfTheme(
            widgetDefaultValue?.data?.active_themes?.[
              selectedWidgets.theme_type
            ]
          );
        }
      }
    },
    [widgetDefaultValue, selectedWt]
  );

  const constantValueinCreateMode = useCallback(() => {
    setValues((prevState) => ({
      ...prevState,
      name: widgetDefaultValue?.data?.name,
      widget_lang: widgetDefaultValue?.data?.widget_lang,
      available_lang: widgetDefaultValue?.data?.available_lang,
      smtp_verified: widgetDefaultValue?.data?.smtp_verified,
      ...widgetDefaultValue?.data?.all_theme?.[0]?.themes?.[0].style,
    }));
  }, [widgetDefaultValue]);

  useEffect(() => {
    if (id === undefined) {
      constantValueinCreateMode();
    }
  }, [widgetDefaultValue]);

  useEffect(() => {
    if (typeof selectedWt !== "undefined") {
      if (window.location.pathname === "/create-widget") {
        setTypeOfTheme(
          widgetDefaultValue?.data?.active_themes?.[selectedWt.theme_type]
        );
        setChangingWidgetTheme((prevState) => ({
          ...prevState,
          ...selectedWt.themes[0],
          ...selectedWt.themes[0].style,
          active_theme_type: selectedWt.themes[0].theme_type,
          active_theme:
            widgetDefaultValue?.data?.active_themes?.[selectedWt.theme_type],
        }));
      }
    }
  }, [selectedWt]);

  // For widget edit selecteion default value
  useEffect(() => {
    let editedTheme;
    if (
      window.location.pathname !== "/create-widget" &&
      Object.keys(updateWidget).length !== 0
    ) {
      editedTheme = updateWidget.all_theme.find(
        ({ theme_type }) => theme_type === updateWidget.active_theme_type
      );

      setTypeOfTheme(
        updateWidget.active_themes?.[updateWidget.active_theme_type]
      );

      const findalTheme = editedTheme.themes.find(
        ({ theme_code }) =>
          theme_code ===
          updateWidget.active_themes[updateWidget.active_theme_type]
      );

      setSelectedWt(editedTheme);

      setSelectedWidgetTheme((prevStates) => ({
        ...prevStates,
        theme: {
          ...findalTheme,
        },
        active_theme_type: updateWidget.active_theme_type,
        active_theme:
          updateWidget.active_themes[updateWidget.active_theme_type],
      }));
    }
  }, [updateWidget]);

  // Theme change handler
  const handleRadioTheme = useCallback(
    (e) => {
      setTypeOfTheme(e.target.value);

      const forArr = selectedWt.themes.find(
        ({ theme_code }) => theme_code === e.target.value
      );

      setChangingWidgetTheme((prevStates) => ({
        ...prevStates,
        ...forArr,
        ...forArr.style,
        active_theme_type: forArr.theme_type,
        active_theme: forArr.theme_code,
      }));

      if (id !== undefined) {
        setSelectedWidgetTheme((prevStates) => ({
          ...prevStates,
          theme: {
            ...forArr,
          },
          active_theme_type: forArr.theme_type,
          active_theme: forArr.theme_code,
        }));
      }
    },
    [selectedWt]
  );

  // Reset WIdget funciton
  const resetWidgetHandler = async (datas, style) => {
    setSomeEditedValue({});
    setChangingObject({});
    let data;
    if (style) {
      data = {
        active_theme_type: values.active_theme_type,
        active_theme: values.active_theme,
        reset_option: [datas, style],
      };
    } else {
      data = {
        active_theme_type: values.active_theme_type,
        active_theme: values.active_theme,
        reset_option: [datas],
      };
    }

    if (id) {
      await dispatch(resetWidget(id, data));
    }

    setTimeout(() => {
      dispatch(getWidgetbyId(id));
    }, 1000);
  };

  // Note and help notice data
  const data = {
    supportText: t("common.needHelp"),
  };

  const dataTwo = {
    notButton: t("createWidget.supportBtn"),
  };

  const noteOne = [
    {
      value: `${t("createWidget.noteOne")} ${" "}`,
    },
    { value: <Drawers data={dataTwo} /> },
    {
      value: `, ${t("createWidget.noteOneTwo")}`,
    },
  ];

  const noteTwo = [
    {
      value: t("createWidget.noteTwo"),
    },
  ];

  const classes = useStyles();

  if (widgetDefaultValue.loading === true) return <PageLoader />;

  return (
    <Container maxWidth="false" className="">
      <section className="section-create-widget">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              className="allleadsHeading"
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div className="section-heading mb-0">
                <h2 className="mb-0">{t("createWidget.heading")}</h2>
                <p className="mb-0">
                  <span className="audit_title_P">{t("createWidget.des")}</span>
                </p>
              </div>
              <UsesStatus data={validData} />
            </Box>

            <Box className="tablemarginTop" sx={{ mb: "2rem", mt: "2rem" }}>
              <YellowNote data={noteTwo} />
            </Box>

            <form className="widget-form form_design">
              <div className={`${classes.mainRoot} widget-option `}>
                <TabContext value={tabValue}>
                  <Paper
                    elevation={4}
                    className="widget-form-width widget-form-margin"
                    style={{ padding: "16px" }}
                  >
                    <Box
                      className="allleadsHeading w-100"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <TabList
                        className="createWidgetList"
                        onChange={handleChangesTab}
                        aria-label="lab API tabs example"
                      >
                        {/* <Tab label="General Setup" value="1" /> */}
                        <Tab
                          label={t("createWidget.widgetTypeTitle")}
                          value="2"
                        />
                        <Tab
                          label={t("createWidget.formFieldTitle")}
                          value="3"
                        />
                        <Tab
                          label={t("createWidget.widgetStyle")}
                          value="4"
                        />
                        <a href="https://seoaudit.software/samples/" target="_blank" style={{color: "rgba(0, 0, 0, 0.6)",fontSize: "14px",fontWeight: "500",display: "flex",alignItems: "center",textTransform: "uppercase", padding: "12px 16px"}}>{t("createWidget.widgetPreview")} <i class="ml-2 fas fa-external-link-alt"></i></a>
                      </TabList>

                      {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                      {window.location.pathname === "/create-widget" ? (
                        <CustomButton
                          type="submit"
                          size="medium"
                          onClick={handleSubmit}
                          label={t("createWidget.checkBox.labels.colorLabel")}
                        />
                      ) : (
                        <Box>
                          <div id="outer">
                            <div className="inner">
                              <CustomButton
                                className="update"
                                type="submit"
                                size="medium"
                                onClick={(e) => handleSubmitUpdate(e, "update")}
                                label={t("common.update")}
                              />
                            </div>
                            <div className="inner">
                              <CustomButton
                                type="submit"
                                className="updateclose ml-lg-4 mr-lg-4"
                                color="success"
                                size="medium"
                                onClick={(e) => handleSubmitUpdate(e, "go")}
                                label={t("createWidget.updateClose")}
                              />
                            </div>
                            <div className="inner">
                              <ResetModal
                                resetWidgetHandler={resetWidgetHandler}
                              />
                            </div>
                          </div>
                          {/* <CustomButton
                            type="submit"
                            sx={{ ml: "2rem" }}
                            color="success"
                            size="medium"
                            onClick={(e) => handleSubmitUpdate(e, "go")}
                            label={t("createWidget.updateClose")}
                          /> */}

                          <span className="dashboard_table_mob" />
                        </Box>
                      )}
                      {/* {window.location.pathname !== "/create-widget" && (
                        <Box
                          className="dashboard_table_des"
                          sx={{ ml: "2rem" }}
                        >
                          <ResetModal resetWidgetHandler={resetWidgetHandler} />
                        </Box>
                      )} */}
                      {/* </Box> */}
                    </Box>
                  </Paper>
                  {/* <TabPanel sx={{ px: 0 }} value="1">
                    <Paper elevation={4}>
                      <GeneralForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleChangeSelect={handleChangeSelect}
                        handleSubmitUpdate={handleSubmitUpdate}
                        values={values}
                        setValues={setValues}
                        btnLoader={btnLoader}
                        errors={errors}
                        setErrors={setErrors}
                        setChangingObject={setChangingObject}
                        changingObject={changingObject}
                      />
                    </Paper>
                  </TabPanel> */}
                  <TabPanel sx={{ px: 0 }} value="2">
                    {values && values.widget_lang && (
                      <WidgetType
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                        errors={errors}
                        selectedForm={selectedForm}
                        handleRadioChange={handleRadioChange}
                        themeData={widgetDefaultValue}
                        selectedWt={selectedWt}
                        setChangingObject={setChangingObject}
                        changingObject={changingObject}
                        typeofTheme={typeofTheme}
                        handleRadioTheme={handleRadioTheme}
                        valuesWidgetPosition={values.widget_position}
                        handleChangeSelect={handleChangeSelect}
                        setErrors={setErrors}
                        someEditedValue={someEditedValue}
                        setSomeEditedValue={setSomeEditedValue}
                        handleChangePopup={handleChangePopup}
                      />
                    )}
                  </TabPanel>
                  <TabPanel sx={{ px: 0 }} value="3">
                    {values && (
                      <FormField
                        errors={errors}
                        setErrors={setErrors}
                        changingObject={changingObject}
                        setChangingObject={setChangingObject}
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                      />
                    )}
                  </TabPanel>
                  <TabPanel sx={{ px: 0 }} value="4">
                    <Box sx={{ mb: "1.5rem" }} className="note ">
                      <YellowNote data={noteOne} />
                    </Box>

                    {values && (
                      <FormStyle
                        changingObject={changingObject}
                        setChangingObject={setChangingObject}
                        values={values}
                        setValues={setValues}
                      />
                    )}
                  </TabPanel>
                </TabContext>

                <Box className="pleasetextbox-div" sx={{ mb: "2rem" }}>
                  <Paper elevation={4}>
                    <Box className="pleasetextbox" sx={{ textAlign: "left" }}>
                      <Drawers data={data} />
                      <Typography className="" variant="body1">
                        {t("createWidget.notBottom")}
                      </Typography>
                    </Box>
                  </Paper>
                </Box>

                {window.location.pathname === "/create-widget" ? (
                  <Box sx={{ mb: "2rem" }}>
                    <CustomButton
                      type="submit"
                      className="widgetsave"
                      size="large"
                      onClick={handleSubmit}
                      label={t("createWidget.checkBox.labels.colorLabel")}
                    />
                    <Typography
                      className="widgetsave"
                      variant="body1"
                      sx={{ mt: "1rem" }}
                    >
                      {t("createWidget.noteBottomTwo")}
                    </Typography>
                  </Box>
                ) : (
                  <React.Fragment>
                    <Box className="updateclosebutton" sx={{ mb: "2rem" }}>
                      <CustomButton
                        className="updateclosebutton-btn"
                        type="submit"
                        size="large"
                        onClick={(e) => handleSubmitUpdate(e, "update")}
                        label={t("common.update")}
                      />
                      <CustomButton
                        type="submit"
                        sx={{ ml: "2rem" }}
                        color="success"
                        size="large"
                        onClick={(e) => handleSubmitUpdate(e, "go")}
                        label={t("createWidget.updateClose")}
                      />
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </form>
          </Grid>
        </Grid>
      </section>
    </Container>
  );
};
export default React.memo(CreateWidget);
