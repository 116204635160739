import { Box } from "@mui/material";
import React from "react";
import SetServerUrl from "../../../modules/URL";
import useStyles from "../styles";

const ThemeType = ({
  selectedWt,
  typeofTheme,
  handleRadioTheme,
  valuesWidgetPosition,
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}
      className={classes.themeRadio}
    >
      {selectedWt !== undefined &&
        selectedWt.themes.map((singleTheme) => (
          <label key={singleTheme.id} className="radio-img radio-img2">
            <input
              checked={typeofTheme === singleTheme.theme_code}
              onChange={(e) =>
                handleRadioTheme(
                  e,
                  singleTheme.theme_code,
                  singleTheme.widget_position,
                  singleTheme.theme_type
                )
              }
              type="radio"
              name="layout"
              value={singleTheme.theme_code}
            />
            <div
              className="create-widget_image"
              style={{
                backgroundImage: `url(${SetServerUrl}/files/widget/v3/preview-image/${selectedWt.theme_type.toLowerCase()}-${
                  singleTheme.theme_code
                }-${
                  typeofTheme === singleTheme.theme_code
                    ? valuesWidgetPosition
                    : singleTheme.widget_position
                }.png)`,
              }}
            />
            <span className="theme__name"> {singleTheme.theme_name} </span>
          </label>
        ))}
    </Box>
  );
};

export default React.memo(ThemeType);
