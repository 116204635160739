import { Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontSize: "13px",
    fontWeight: "400",
    color: theme.palette.text.main,
    boxShadow: "0px 2px 10px 1px rgba(110, 110, 110, .25)",
    padding: "10px",
    lineHeight: "1.4"
  },
}));

function WhiteTip(props) {
  const classes = useStylesBootstrap();

  return (
    <Tooltip
      interactive="true"
      arrow={true}
      TransitionComponent={Zoom}
      placement="top"
      classes={classes}
      {...props}
    />
  );
}

export default WhiteTip;
