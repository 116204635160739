import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const STEP_ONE_DATA_POST_BEGIN = "STEP_ONE_DATA_POST_BEGIN";
export const STEP_ONE_DATA_POST_SUCCESS = "STEP_ONE_DATA_POST_SUCCESS";
export const STEP_ONE_DATA_POST_FAIL = "STEP_ONE_DATA_POST_FAIL";

export const STEP_ONE_DATA_GET_BEGIN = "STEP_ONE_DATA_GET_BEGIN";
export const STEP_ONE_DATA_GET_SUCCESS = "STEP_ONE_DATA_GET_SUCCESS";
export const STEP_ONE_DATA_GET_FAIL = "STEP_ONE_DATA_GET_FAIL";

export const STEP_ONE_DATA_UPDATE_BEGIN = "STEP_ONE_DATA_UPDATE_BEGIN";
export const STEP_ONE_DATA_UPDATE_SUCCESS = "STEP_ONE_DATA_UPDATE_SUCCESS";
export const STEP_ONE_DATA_UPDATE_FAIL = "STEP_ONE_DATA_UPDATE_FAIL";

export const postStepOne = (title, url, domain_type, group, history) => (dispatch) => {
  dispatch({
    type: STEP_ONE_DATA_POST_BEGIN
  });
  return serverCall({
    method: "POST",
    url: "/api/v1/project/create/",
    data: {
      title,
      url,
      domain_type,
      group
    }
  })
    .then((res) => {
      if(res.status >=200 && res.status <300){
        dispatch({
          type: STEP_ONE_DATA_POST_SUCCESS,
          payload: res
        });
        dispatch(setAlert(res.data.message, "success"));
        history.push(`/edit-engines/${res.data.data.project_id}`);
      }else{
        dispatch({
          type: STEP_ONE_DATA_POST_FAIL,
          payload: { res }
        });
        dispatch(setAlert(res.data.message, "danger"));
      } 
      
      return res;
    })
    .catch((error) => {
      dispatch({
        type: STEP_ONE_DATA_POST_FAIL,
        payload: { error }
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const getStepOne = (id) => (dispatch) => {
  dispatch({
    type: STEP_ONE_DATA_GET_BEGIN
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${id}/`
  })
    .then((res) => {
      dispatch({
        type: STEP_ONE_DATA_GET_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: STEP_ONE_DATA_GET_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const updateStepOne = (title, url, domain_type, group, history, project_id) => (dispatch) => {
    dispatch({
      type: STEP_ONE_DATA_UPDATE_BEGIN
    });
    return serverCall({
      method: "POST",
      url: `/api/v1/project/${project_id}/`,
      data: {
        step: 1,
        title,
        url,
        domain_type,
        group
      }
    })
      .then((res) => {
        if(res.status >=200 && res.status<300){
          dispatch({
            type: STEP_ONE_DATA_UPDATE_SUCCESS,
            payload: res
          });
          dispatch(setAlert(res.data.message, "success"));
          history.push(`/edit-engines/${project_id}`);
        }else{
          dispatch({
            type: STEP_ONE_DATA_UPDATE_FAIL,
            payload: { res }
          });
          dispatch(setAlert(res.data.message, "danger"));
        }
        return res;
      })
      .catch((error) => {
        dispatch({
          type: STEP_ONE_DATA_UPDATE_FAIL,
          payload: { error }
        });
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
        return error;
      });
  };
