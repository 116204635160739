import {
  GET_INVOICES_BEGIN,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_BEGIN,
  GET_INVOICE_FAIL,
  GET_INVOICE_SUCCESS
} from '../action/invoiceActions';

const initialState = {
  invoices: {
    data: [
      {
        id: '',
        date: '',
        total: '',
        membership: '',
        method: '',
        status: '',
        invoice: '',
      },
    ],
    loading: true,
    error: null,
  },
  invoice_details: null,
};
const invoicesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_INVOICES_BEGIN:
      return {
        ...state,
        invoices: {
          error: null,
          loading: true,
        },
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          data: payload,
          loading: false,
        },
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        invoices: {
          error: payload,
          loading: false,
        },
      };

    case GET_INVOICE_BEGIN:
      return {
        ...state,
        invoices: {
          error: null,
          loading: true,
        },
      };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: {
          loading: false,
        },
        invoice_details: payload,
      };
    case GET_INVOICE_FAIL:
      return {
        ...state,
        invoices: {
          error: payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default invoicesReducer;
