import axios from "axios";
import axiosRetry from "axios-retry";
import jwt_decode from "jwt-decode";
import { Store } from "react-notifications-component";
import Auth from "../Auth";
import jumpTo, { go } from "../Navigation";
import SetServerUrl from "../URL";

const options = {
  title: "",
  insert: "top",
  container: "top-right",
  width: 600,
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true
  }
};

axiosRetry(axios, {
  retries: 10, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 4000; // time interval between retries
  },
  retryCondition: (error) => {
    if (error.response.status === 504 || error.response.status === 400) {
      return false;
    }
    return false;
  }
});

var langParam = window.sessionStorage.getItem("activeLanguage");
langParam = langParam && langParam !=""? langParam:"en";
const serverCall = (config) => {
  const tokenCheck = Auth.getToken();
  if (Auth.data && tokenCheck) {
    const token = Auth.getToken();
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
  } else {
    config.headers = {
      "Content-Type": "application/json"
    };
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        error.response = {
          data: "network error",
          status: 500
        };
      }
      if (error.response.data.restrict_error === true) {
        Store.addNotification({
          title: "",
          message: error.response.data.message,
          type: "danger",
          ...options
        });
      }

      if (
        error.response.status === 401
        || error.response.data.detail === "Invalid token"
        || error.response.data.code === "jwt_auth_invalid_token"
        || error.response.data.code === "jwt_auth_bad_iss"
        || error.response.data.code === "jwt_auth_bad_request"
        || error.response.data.code === "jwt_auth_no_user_found"
        || error.response.data.code === "jwt_auth_bad_config"
      ) {
        Auth.logout();
        go("/");
      }

      // if (jwt_decode(tokenCheck).exp < Date.now() / 1000) {
      //   Store.addNotification({
      //     message: "Oops! Session expaired.",
      //     type: "danger",
      //     ...options
      //   });
      //   Auth.logout();
      //   jumpTo("/?logout=success");
      //   window.location.reload(true);
      // }

      return error.response;
    }
  );

  if (typeof config.baseURL == "undefined"){
    config.baseURL = SetServerUrl;
  }

  

  if (config.method === "GET") {
    config.params = { lang: langParam };
  }

  return axios(config);
};

export default serverCall;

// making this for user login token
export const login = (email, password) => {
  const body = {
    email,
    password
  };
  return serverCall({
    method: "POST",
    url: "/api/v1/user/rest-auth/token/login/",
    data: body
  }).then((res) => {
    Auth.setUserToken(res.data);
    return res;
  });
};

export const googlelogin = (response) => {
  const body = {
    access_token: response
  };
  return serverCall({
    method: "POST",
    url: "/api/v1/user/rest-auth/google/",
    data: body
  }).then((res) => {
    Auth.setUserToken(res.data);
    return res;
  });
};
