import serverCall from "../../modules/serverCall";

export const GET_DASHBOARD_BEGIN = "GET_DASHBOARD_BEGIN";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";

export const getDashboardData = () => (dispatch) => {
  dispatch({
    type: GET_DASHBOARD_BEGIN,
  });

  return serverCall({
    method: "GET",
    url: "/api/v1/dashboard/",
  })
    .then((res) => {
      dispatch({
        type: GET_DASHBOARD_SUCCESS,
        payload: res.data.data.data,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_DASHBOARD_FAIL,
        payload: { error },
      });
      return error;
    });
};
