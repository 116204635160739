import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import RequiredLabel from "../RequiredLabel";

const NameField = (props) => {
  const {
    values,
    wform_name,
    setValues,
    handlelabelPlaceChange,
    errors,
    setErrors,
  } = props;

  const { t } = useTranslation();
  return (
    <Box className="divider custom-selector2 ">
      <InputLabel
        label={t("createWidget.formField.21")}
        optional
        title={t("createWidget.formField.22")}
      />
      <div className="check ">
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="checkbox"
            className="custom-control-input"
            name="name_field"
            value={wform_name.show_field}
            id="name"
            onChange={() => {
              setValues({
                ...values,
                wform_name: {
                  ...values.wform_name,
                  show_field: wform_name.show_field > 0 ? 0 : 1,
                },
              });
            }}
            checked={wform_name.show_field !== 0}
          />
          <label className="custom-control-label" htmlFor="name">
            <Typography component="span" variant="body1">
              {t("createWidget.checkBox.labels.2")}
            </Typography>
          </label>
        </div>

        {/* // -----Placeholder Opiton------- // */}
        {wform_name.show_field >= 1 && (
          <React.Fragment>
            <div className="custom-control custom-checkbox custom-selector2 ">
              <input
                type="checkbox"
                className="custom-control-input"
                id="namerequired"
                name="name_field_r"
                value={wform_name.show_field}
                onChange={() => {
                  setValues({
                    ...values,
                    wform_name: {
                      ...values.wform_name,
                      show_field: wform_name.show_field === 1 ? 2 : 1,
                    },
                  });
                }}
                checked={wform_name.show_field === 2}
              />
              <label className="custom-control-label" htmlFor="namerequired">
                <Typography component="span" variant="body1">
                  {t("createWidget.checkBox.labels.3")}
                </Typography>
              </label>
            </div>

            <Box sx={{ mt: "1rem" }}>
              {(values.show_field_text_as === 1 ||
                values.show_field_text_as === 3) && (
                <Box className="input_wrapper custom-selector2 ">
                  <RequiredLabel label={t("common.label")} required />
                  <input
                    placeholder={t("createWidget.formField.23")}
                    name="label"
                    value={wform_name.label}
                    type="text"
                    onChange={(e) => {
                      handlelabelPlaceChange(e, "wform_name");
                      if (e.target.value === "") {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          name_label_error: <span>{t("common.required")}</span>,
                        }));
                      } else {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          name_label_error: "",
                        }));
                      }
                    }}
                  />
                  <div className="form__error">{errors.name_label_error}</div>
                </Box>
              )}
              {(values.show_field_text_as === 2 ||
                values.show_field_text_as === 3) && (
                <Box className="input_wrapper custom-selector2 ">
                  <RequiredLabel label={t("common.placeholder")} required />
                  <input
                    placeholder={t("createWidget.formField.24")}
                    name="placeholder"
                    value={wform_name.placeholder}
                    type="text"
                    onChange={(e) => {
                      handlelabelPlaceChange(e, "wform_name");
                      if (e.target.value === "") {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          name_placeholder_error: (
                            <span>{t("common.required")}</span>
                          ),
                        }));
                      } else {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          name_placeholder_error: "",
                        }));
                      }
                    }}
                  />
                  <div className="form__error">
                    {errors.name_placeholder_error}
                  </div>
                </Box>
              )}
            </Box>
            {values.widget_type === "5" || values.widget_type === 5 ?
            <div className="custom-control custom-checkbox custom-selector2 ">
              <input
                type="checkbox"
                className="custom-control-input"
                name="show_in_first_step"
                value={wform_name.show_in_first_step}
                id="wform_name_show_in_first_step"
                onChange={() => {
                  setValues({
                    ...values,
                    wform_name: {
                      ...values.wform_name,
                      show_in_first_step: wform_name.show_in_first_step > 0 ? 0 : 1,
                    },
                  });
                }}
                checked={wform_name.show_in_first_step !== 0}
              />
              <label className="custom-control-label" htmlFor="wform_name_show_in_first_step">
                <Typography component="span" variant="body1">
                  {t("createWidget.formField.35")}
                </Typography>
              </label>
            </div>
            :""}
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default NameField;
