import {
  GET_ALL_LOCATION_BEGIN,
  GET_ALL_LOCATION_SUCCESS,
  GET_ALL_LOCATION_FAIL,
  GET_ALL_USER_LOCATION_BEGIN,
  GET_ALL_USER_LOCATION_SUCCESS,
  GET_ALL_USER_LOCATION_FAIL,
} from "../action/locationAction";

const initialState = {
  locations: [],
  location_list_loading: false,

  userlocations: [],
  user_location_list_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LOCATION_BEGIN:
      return {
        ...state,
        location_list_loading: true,
      };

    case GET_ALL_LOCATION_SUCCESS:
      return {
        ...state,
        location_list_loading: false,
        locations: action.payload.data.data.location,
      };

    case GET_ALL_LOCATION_FAIL:
      return {
        ...state,
        location_list_loading: false,
      };

    case GET_ALL_USER_LOCATION_BEGIN:
      return {
        ...state,
        user_location_list_loading: true,
      };

    case GET_ALL_USER_LOCATION_SUCCESS:
      return {
        ...state,
        user_location_list_loading: false,
        userlocations: action.payload.data.data.location,
      };

    case GET_ALL_USER_LOCATION_FAIL:
      return {
        ...state,
        user_location_list_loading: false,
      };

    default:
      return state;
  }
};
