import LoaderSvg from "components/common/LoaderSvg";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import setAlert from "../../components/common/Alert";
import Auth from "../../modules/Auth";
import { useTranslation } from "react-i18next";
import serverCall from "../../modules/serverCall";
import i18n from "../../i18n";
const ExportData = ({ passData }) => {
  const { t } = useTranslation();
  const propsData = passData;

  const token = Auth.getToken();

  const dispatch = useDispatch();

  const [loaderfor, setLoadingBtn] = useState({
    load: false,
  });

  async function export_data(url, type, loading,fileName) {
    try {
      setLoadingBtn({
        load: loading === "loading",
      });
      serverCall({
        method: "GET",
        url: url,
      })
        .then((data) => {
            try {
                // Convert the Byte Data to BLOB object.
                let blob =  new Blob([data.data], { type: "text/csv;charset=utf-8;" });
                let file_name = data.headers["filename"];
                if (!file_name){
                    file_name = fileName;
                }
                // Check the Browser type and download the File.
                let isIE = false || !!document.documentMode;
                if (isIE) {
                  window.navigator.msSaveBlob(blob,file_name);
                } else {
                  let url = window.URL || window.webkitURL;
                  let link = url.createObjectURL(blob);
                  let a = document.createElement("a");
                  a.setAttribute("download",file_name);
                  a.setAttribute("href", link);
                  a.setAttribute("class", "delete-this-pdf-link");
                  document.querySelector("body").appendChild(a);
                  a.click();
                a.remove();
                }
              } catch (err) {
                // window.open(
                //   `${SetServerUrl}/api/v1/download_pdf/?file_name=report&share_key=${share_key}&id=${audit_id}&type=audit`
                // );
              }
            console.log(data);
            console.log(data.headers);
        })
        .catch((error) => {
          dispatch(setAlert(i18n.t("alert_messages.download_failed"), "danger", 10000));
        });
        setLoadingBtn({
            load: false,
          });
    } catch (err) {
      // window.open(
      //   `${SetServerUrl}/api/v1/download_pdf/?file_name=report&share_key=${share_key}&id=${audit_id}&type=audit`
      // );
    }
  }

  return (
    <Fragment>
      <button
        type="button"
        className="seo_btn gradient_blu loader"
        onClick={(e) => {
          e.preventDefault();
          export_data(propsData.url, propsData.type, propsData.loading, propsData.fileName);
        }}
      >
        {t("common.Export")} {loaderfor.load && <LoaderSvg />}
      </button>
    </Fragment>
  );
};

export default ExportData;
