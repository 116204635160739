import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import FixedForm from "../formOne/FixedForm";
import ExtraField from "./ExtraField";

const TakeOverForm = ({ handleChange, values, setValues }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="input_wrapper">
        <FixedForm values={values} handleChange={handleChange} />
      </div>
      {values && <ExtraField values={values} setValues={setValues} />}
    </Fragment>
  );
};
export default React.memo(TakeOverForm);
