import serverCall from "../../modules/serverCall";

export const GET_KEY_WORD_GRAPH_BEGIN = "GET_KEY_WORD_GRAPH_BEGIN";
export const GET_KEY_WORD_GRAPH_SUCCESS = "GET_KEY_WORD_GRAPH_SUCCESS";
export const GET_KEY_WORD_GRAPH_FAIL = "GET_KEY_WORD_GRAPH_FAIL";

export const GET_KEY_WORD_TABLE_BEGIN = "GET_KEY_WORD_TABLE_BEGIN";
export const GET_KEY_WORD_TABLE_SUCCESS = "GET_KEY_WORD_TABLE_SUCCESS";
export const GET_KEY_WORD_TABLE_FAIL = "GET_KEY_WORD_TABLE_FAIL";

export const GET_KEY_WORD_GROUP_BEGIN = "GET_KEY_WORD_GROUP_BEGIN";
export const GET_KEY_WORD_GROUP_SUCCESS = "GET_KEY_WORD_GROUP_SUCCESS";
export const GET_KEY_WORD_GROUP_FAIL = "GET_KEY_WORD_GROUP_FAIL";

export const getkeywordGraphData = (projectId, params, history) => (dispatch) => {
    let finalParams = "";

    Object.keys(params).forEach((param, i) => {
      if (!i) {
        finalParams = `${params[param]}`;
      } else {
        finalParams = `${params[param]}`;
      }
    });
    dispatch({
      type: GET_KEY_WORD_GRAPH_BEGIN,
    });
    return serverCall({
      method: "GET",
      url: `/api/v1/keyword-ranking/graph/${finalParams}/`,
    })
      .then((res) => {
        const data = {
          all_dates: res.data.data.all_dates,
          list: res.data.data.list,
          max: res.data.data.max_num
        };

        if (data.all_dates.length === 0 || data.list.length === 0) {
          history.push("/admin-site-list");
        }
        dispatch({
          type: GET_KEY_WORD_GRAPH_SUCCESS,
          payload: data,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: GET_KEY_WORD_GRAPH_FAIL,
          payload: { error },
        });
        return error;
      });
  };

export const getkeywordTableData = (projectId, params) => (dispatch) => {
  let finalParams = `${projectId}/`;

  Object.keys(params).forEach((param, i) => {
    if (!i) {
      finalParams += `?${param}=${params[param]}`;
    } else {
      finalParams += `&${param}=${params[param]}`;
    }
  });

  dispatch({
    type: GET_KEY_WORD_TABLE_BEGIN,
  });

  return serverCall({
    method: "GET",
    url: `/api/v1/keyword-ranking/rank/info/${finalParams}`,
  })
    .then((res) => {
      const data = {
        dates: res.data.data.dates,
        competitor: res.data.data.competitor,
        keywords: res.data.data.keywords,
        project_id: projectId,
        se_id: params.se_id,
        url: res.data.data.url,
      };

      dispatch({
        type: GET_KEY_WORD_TABLE_SUCCESS,
        payload: data,
      });

      // if (data.keywords == null || data.keywords.length === 0) {
      //   dispatch(setAlert("couldn't load keywords", "danger"));
      //   history.push("/admin-site-list");
      // }

      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEY_WORD_TABLE_FAIL,
        payload: { error },
      });
      return error;
    });
};

// This is called in create project
export const getKeywordDataTable = (projectId, rankId) => (dispatch) => {
  const finalParams = `rank?se_id=${rankId}`;
  dispatch({
    type: GET_KEY_WORD_TABLE_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/keyword-ranking/rank/info/${finalParams}/`,
  })
    .then((res) => {
      const data = {
        dates: res.data.data.dates,
        competitor: res.data.data.competitor,
        keywords: res.data.data.keywords,
        project_id: projectId,
        se_id: rankId,
      };

      dispatch({
        type: GET_KEY_WORD_TABLE_SUCCESS,
        payload: data,
      });

      // if (data.keywords == null || data.keywords.length === 0) {
      //   dispatch(setAlert("couldn't load keywords", "danger"));
      //   history.push("/admin-site-list");
      // }

      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEY_WORD_TABLE_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getKeywordGroup = (projectId) => (dispatch) => {
  dispatch({
    type: GET_KEY_WORD_GROUP_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${projectId}/keyword/group/list/`,
  })
    .then((res) => {
      const { groups = [] } = (res.data || {}).data || {};
      // const hasGeneralGroup = !!groups.find((group) => group.group_id === 0);
      // if (!hasGeneralGroup) {
      //   groups.unshift({
      //     group_id: 0,
      //     group_name: "General",
      //     project_id: projectId,
      //   });
      // }

      groups.forEach((group) => {
        group.group_id = group.group_id || group.id || 0;
        group.id = group.id || group.group_id || 0;
      });

      const data = {
        groups, // : res.data.data.groups,
      };

      dispatch({
        type: GET_KEY_WORD_GROUP_SUCCESS,
        payload: data,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEY_WORD_GROUP_FAIL,
        payload: { error },
      });
      return error;
    });
};
