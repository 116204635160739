import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";


export const POST_CREATEGROUP_BEGIN = "POST_CREATEGROUP_BEGIN";
export const POST_CREATEGROUP_SUCCESS = "POST_CREATEGROUP_SUCCESS";
export const POST_CREATEGROUP_FAIL = "POST_CREATEGROUP_FAIL";

export const GET_ALL_GROUPS_BEGIN = "GET_ALL_GROUPS_BEGIN";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const GET_ALL_GROUPS_FAIL = "GET_ALL_GROUPS_FAIL";

export const DELETE_GROUP_BEGIN = "DELETE_GROUP_BEGIN";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

export const getAllGroups = () => (dispatch) => {
  dispatch({
    type: GET_ALL_GROUPS_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/project/group/list/",
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_GROUPS_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_GROUPS_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const createGroup = (group_name) => (dispatch) => {
  dispatch({
    type: POST_CREATEGROUP_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: "/api/v1/project/group/create/",
    data: {
      group_name,
    },
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: POST_CREATEGROUP_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: POST_CREATEGROUP_FAIL,
          payload: { res },
        });
          dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: POST_CREATEGROUP_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      throw error;
    });
};

export const deleteGroup = (group_id) => (dispatch) => {
  dispatch({
    type: DELETE_GROUP_BEGIN,
  });
  return serverCall({
    method: "DELETE",
    url: `/api/v1/project/group/${group_id}/`,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload: true,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: DELETE_GROUP_FAIL,
          payload: { res },
        });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_GROUP_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      throw error;
    });
};
