import CssBaseline from "@mui/material/CssBaseline";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "1.5rem 0 4rem 0",
    "&.fixed": {
      position: "sticky",
      top: "64px",

      zIndex: "5",
      "& .MuiStepper-root": {
        boxShadow: "0 10px 10px 1px rgba(155, 155, 155, .20)",
        backgroundColor: "#fff",
        height: "5em",
      },
    },

    "& .MuiStepper-root": {
      backgroundColor: "inherit",
      paddingBottom: "2rem",
      paddingTop: "1rem",
    },

    "& .MuiStep-root": {
      "&.MuiStep-alternativeLabel": {
        zIndex: "1",
      },

      "& .MuiStepLabel-labelContainer": {
        position: "absolute",
        top: "1rem",
        left: "50%",
        transform: "translateX(-50%)",

        "& .MuiStepLabel-label.MuiStepLabel-active": {
          color: theme.palette.common.yellow,
          fontSize: "14px",
        },
      },

      "& .MuiStepConnector-alternativeLabel": {
        top: "50%",
        transform: "translateY(-50%)",
        left: "calc(-50% + 12px)",
        right: "calc(50% + 12px)",
        zIndex: "-1",

        "&.Mui-active, &.Mui-completed": {
          "& .MuiStepConnector-lineHorizontal": {
            borderColor: theme.palette.common.yellow,
          },
        },

        "& .MuiStepConnector-lineHorizontal": {
          borderColor: "#e2e2e2",
          borderTopWidth: "3px",
        },
      },

      "& .MuiStepLabel-iconContainer": {
        width: "1.5rem",
        height: "1.5rem",
        cursor: "pointer",

        "& svg": {
          width: "1.5rem",
          height: "1.5rem",
          color: "#e2e2e2",
          "&.Mui-completed, &.Mui-active": {
            color: theme.palette.common.yellow,
          },
        },
      },
    },
  },

  button: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ProjectStep = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = [
    t("createProject.addProject"),
    t("createProject.addSec"),
    t("createProject.inputField.labels.btnAdd"),
  ];
  const { stepNumber, projectId } = props;

  const history = useHistory();

  const [scrollPosition, setScrollPosition] = useState(55);

  const handleScroll = () => {
    const element = document.getElementById("hidded__bar");
    const position = element.getBoundingClientRect().top;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const icons = document.querySelectorAll(".MuiStepIcon-root");

    icons[0].addEventListener("click", () => {
      if (stepNumber !== 0 && stepNumber !== 2 && projectId !== null) {
        history.push(`/edit-project/${projectId}`);
      }
    });

    icons[1].addEventListener("click", () => {
      if (stepNumber !== 1 && projectId !== null) {
        history.push(`/edit-engines/${projectId}`);
      }
    });

    icons[2].addEventListener("click", () => {
      if (stepNumber !== 2 && stepNumber !== 0) {
        history.push(`/edit-keywords/${projectId}`);
      }
    });
  }, [stepNumber, projectId]);

  // icons[1].addEventListener("click", () => {
  //   console.log("CLICKED");
  // });

  return (
    <Fragment>
      <div id="hidded__bar" />
      <div
        id="stepBar"
        className={`${classes.root} ${scrollPosition < 54 && "fixed"}`}
      >
        <CssBaseline />
        <Stepper alternativeLabel activeStep={stepNumber}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </Fragment>
  );
};

export default ProjectStep;
