import {
  GET_DASHBOARD_BEGIN,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_SUCCESS
} from "../action/dashboardAction";

const initialState = {
  dashboardData: {},
  loading: true,
  error: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: payload,
        loading: false,
      };
    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
