import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import InputLabel from "../../../InputLabel";
import FixedForm from "../formOne/FixedForm";

const PopupForm = ({
  handleChange,
  selects,
  values,
  handlepChange,
  someEditedValue,
  handleChangePopup
}) => {
  const { t } = useTranslation();
  console.log("hey",values);
  console.log("hey2",someEditedValue);
  return (
    <Fragment>
      <div className="input_wrapper">
        <FixedForm values={values} handleChange={handleChange} />
      </div>
      <div className="input_wrapper">
        <InputLabel
          title="Set time after how many seconds you want your Popup form to appear when a visitor visits the page. For eg, after 5, 10 seconds. If you set 0 it will popup automatically."
          label={t("createWidget.form.labels.12")}
          optional
        />
        <input
          type="number"
          name="widget_auto_popup"
          value={
            someEditedValue?.widget_settings_extra?.widget_auto_popup !== undefined
              ? someEditedValue?.widget_settings_extra?.widget_auto_popup
              : values.widget_settings_extra.widget_auto_popup
          }
          placeholder={t("createWidget.form.labels.18")}
          onChange={handleChangePopup}
        />
      </div>
      <div className="input_wrapper">
        <InputLabel
          label={t("createWidget.form.labels.13")}
          title="Use a transparent logo or icon from your server to reduce loading time, and later you can change the background color and other styles from the Widget styles tab. Our recommended width and height for the icon is 60px (max). But you can use any size to make it fit for your website."
          optional
        />
        <textarea
          type="text"
          rows="4"
          placeholder={t("createWidget.form.place.2")}
          value={
            values.widget_settings_extra.widget_popup_icon || ""
          }
          name="widget_popup_icon"
          onChange={handleChangePopup}
        />
      </div>
      <div className="input_wrapper">
        <Box sx={{ pb: "1rem !important" }} className="form-full">
          <InputLabel
            label={t("createWidget.form.labels.14")}
            title="Choose a suitable position for your widget to appear on your webpage."
            optional
          />
          <div className="custom-selector">
            <Select
              options={selects}
              onChange={handlepChange}
              value={selects.find(({ value }) => value === values.widget_position) || selects[0]}
            />
          </div>
        </Box>
        {/* <select name="widget_position" id="" onChange={handleChange}>
          {selects.map((position, i) => (
            <option key={i} value={position.value}>
              {position.name}
            </option>
          ))}
        </select> */}
      </div>
    </Fragment>
  );
};
export default React.memo(PopupForm);
