import {
  GET_CRAWLED_PAGES_BEGIN, GET_CRAWLED_PAGES_FAIL, GET_CRAWLED_PAGES_SUCCESS,
  GET_FOUND_LINKS_BEGIN, GET_FOUND_LINKS_FAIL, GET_FOUND_LINKS_SUCCESS,
  GET_FOUND_RESOURCE_BEGIN, GET_FOUND_RESOURCE_FAIL, GET_FOUND_RESOURCE_SUCCESS
} from "../../action/websiteAudit/types";

const crawledState = {
  crawledPages: [],
  loading: false,
  error: false,
};

const foundLinksState = {
  foundLinks: [],
  loading: false,
  error: false,
};

const foundResourceState = {
  foundResources: [],
  loading: false,
  error: false,
};

export const crawledPages = (state = crawledState, action) => {
  switch (action.type) {
    case GET_CRAWLED_PAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CRAWLED_PAGES_SUCCESS:
      return {
        ...state,
        crawledPages: action.payload.data,
        loading: false,
      };
    case GET_CRAWLED_PAGES_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const foundLinks = (state = foundLinksState, action) => {
  switch (action.type) {
    case GET_FOUND_LINKS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FOUND_LINKS_SUCCESS:
      return {
        ...state,
        foundLinks: action.payload.data,
        loading: false,
      };
    case GET_FOUND_LINKS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export const foundResources = (state = foundResourceState, action) => {
  switch (action.type) {
    case GET_FOUND_RESOURCE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FOUND_RESOURCE_SUCCESS:
      return {
        ...state,
        foundResources: action.payload.data,
        loading: false,
      };
    case GET_FOUND_RESOURCE_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
