import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import {
  borderTypeOptions,
  fontWeightOptions,
  textTransformOption,
} from "./selectOptions";

const FieldStyle = ({
  values,
  setValues,
  changingObject,
  setChangingObject,
}) => {
  const classes = useStyles();

  const { input_field } = values;

  const handleFieldStyle = useCallback((e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      input_field: {
        ...prevValues.input_field,
        [name]: value,
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      input_field: {
        ...prevValues.input_field,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      input_field: {
        ...prevValues.input_field,
        [name]: data.value,
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      input_field: {
        ...prevValues.input_field,
        [name]: data.value,
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Box sx={{ flexWrap: "wrap" }} className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>Background</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="input_field"
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.text")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="color"
              type="none"
              nested="input_field"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.size")}
          </Typography>
          <div>
            <input
              name="text_size"
              value={input_field.text_size}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.transform")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={textTransformOption}
              className="react__select"
              placeholder={t("common.select")}
              onChange={(data) => hanldeSelect(data, "text_transform")}
              value={textTransformOption.find(
                ({ value }) => value === input_field.text_transform
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.fontWeight")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={fontWeightOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptions.find(
                ({ value }) => value === input_field.font_weight
              )}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={input_field.border_top}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={input_field.border_right}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={input_field.border_bottom}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={input_field.border_left}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              type="none"
              nested="input_field"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={borderTypeOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === input_field.border_type
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={input_field.border_radius}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="margin_top"
              value={input_field.margin_top}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={input_field.margin_right}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={input_field.margin_bottom}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={input_field.margin_left}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={input_field.padding_top}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={input_field.padding_right}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={input_field.padding_bottom}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={input_field.padding_left}
              onChange={handleFieldStyle}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(FieldStyle);
