import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";


export const ADD_WIDGET_BEGIN = "ADD_WIDGET_BEGIN";
export const ADD_WIDGET_SUCCESS = "ADD_WIDGET_SUCCESS";
export const ADD_WIDGET_FAIL = "ADD_WIDGET_FAIL";

export const ADD_WIDGET_UPDATE_BEGIN = "ADD_WIDGET_UPDATE_BEGIN";
export const ADD_WIDGET_UPDATE_SUCCESS = "ADD_WIDGET_UPDATE_SUCCESS";
export const ADD_WIDGET_UPDATE_FAIL = "ADD_WIDGET_UPDATE_FAIL";

export const GET_WIDGET_DEFAULT_BEGIN = "GET_WIDGET_DEFAULT_BEGIN";
export const GET_WIDGET_DEFAULT_SUCCESS = "GET_WIDGET_DEFAULT_SUCCESS";
export const GET_WIDGET_DEFAULT_FAIL = "GET_WIDGET_DEFAULT_FAIL";

export const addWidgetSuccessUpdate = (datas) => ({
  type: ADD_WIDGET_UPDATE_SUCCESS,
  payload: datas.data.widget_id,
});

export const addWidgetFailUpdate = () => ({
  type: ADD_WIDGET_UPDATE_FAIL,
});

export const addWidgetSuccess = (data) => ({
  type: ADD_WIDGET_SUCCESS,
  payload: data.data.widget_id,
});

export const addWidgetFail = () => ({
  type: ADD_WIDGET_FAIL,
});

export const addWidget = (widgetData, history) => (dispatch) => {
  dispatch({ type: ADD_WIDGET_BEGIN });
  return serverCall({
    method: "POST",
    url: "/api/v1/widget/create/",
    data: widgetData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch(addWidgetSuccess(res));
        dispatch(setAlert(res.data.message, "success"));
        history.push("/widget-list");
      }else{
        dispatch(addWidgetFail(res));
        dispatch(setAlert(res.data.message, "danger"));
      }
      
    })
    .catch((error) => {
      dispatch(addWidgetFail(error));
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
    });
};

export const addWidgetUpdate = (wID, widgetData, history, type) => (dispatch) => {
  dispatch({ type: ADD_WIDGET_UPDATE_BEGIN });
  return serverCall({
    method: "POST",
    url: `/api/v1/widget/${wID}/`,
    data: widgetData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch(addWidgetSuccessUpdate(res));
        dispatch(setAlert(res.data.message, "success"));
        
        if (type === "go") {
          history.push("/widget-list");
        }
      }else{
        dispatch(addWidgetFailUpdate(res));
        dispatch(setAlert(res.data.message, "danger"));
      }
      
    })
    .catch((error) => {
      dispatch(addWidgetFailUpdate(error));
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      
      // dispatch(setAlert(error, "danger"));
    });
};

export const getWidgetDefaultAction = () => (dispatch) => {
  dispatch({ type: GET_WIDGET_DEFAULT_BEGIN });
  return serverCall({
    method: "GET",
    url: "/api/v1/widget/themes/default/",
  })
    .then((res) => {
      dispatch({
        type: GET_WIDGET_DEFAULT_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_WIDGET_DEFAULT_FAIL, payload: err });
    });
};
