import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import InputLabel from "../InputLabel";
import useStyles from "../styles";
import EmailField from "./EmailField";
import KeywordField from "./KeywordField";
import NameField from "./NameField";
import Sample from "./Sample";
import PhoneField from "./PhoneField";
import TermsCondition from "./TermsCondition";
import WebsiteUrl from "./WebsiteUrl";

const FormField = (props) => {
  const { values, setValues, handleChange, errors, setErrors } = props;
  const { t } = useTranslation();

  const showAs = [
    { value: 1, label: t("createWidget.formField.1") },
    { value: 2, label: t("createWidget.formField.2") },
    { value: 3, label: t("createWidget.formField.3") },
  ];
  const handleChangeExtra = useCallback((event) => {
    const { name, value } = event.target;
      setValues((prevState) => ({
        ...prevState,
        widget_settings_extra: {
          ...prevState.widget_settings_extra,
          [name]: value
        }
      }));
  }, []);
  const handleTextTypeSelect = useCallback((data) => {
    setValues((prevValues) => ({
      ...prevValues,
      show_field_text_as: data.value,
    }));
  }, []);

  const {
    wform_name,
    wform_phone,
    wform_email,
    wform_tnc,
    wform_keyword,
    wform_url,
    can_advance_audit,
    input_field,
  } = values;

  const handlelabelPlaceChange = useCallback((e, sub) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [sub]: {
        ...prevValues[sub],
        [name]: value,
      },
    }));
  }, []);
  console.log(values);
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid className="configureForm edit_widiget_formt_config" item xs={12}>
        <Typography
          className="title"
          variant="h5"
          sx={{ mt: "5px !important" }}
        >
          <div>{t("createWidget.checkBox.labels.configure")}</div>
        </Typography>
        <Typography variant="body1" sx={{ mb: 0 }}>
          <div> {t("createWidget.checkBox.labels.short")}</div>
        </Typography>
      </Grid>
      <Grid className="create_form_list pt-0" item sm={12} md={5}>
        <Paper
          elevation={4}
          className="widget-form-margin"
          style={{ padding: "16px" }}
        >
          <Box className={`${classes.dividers} `}>
            <Box paddingBottom="2rem !important" className="form-full divider">
              <InputLabel label={t("createWidget.formField.4")} />
              <Box sx={{ mt: ".6rem" }} className="custom-selector">
                <Select
                  options={showAs}
                  value={showAs.find(
                    ({ value }) => value === values.show_field_text_as
                  )}
                  onChange={handleTextTypeSelect}
                />
              </Box>
            </Box>
            <WebsiteUrl
              errors={errors}
              setErrors={setErrors}
              handlelabelPlaceChange={handlelabelPlaceChange}
              wform_url={wform_url}
              wform_keyword={wform_keyword}
              values={values}
              setValues={setValues}
              can_advance_audit={can_advance_audit}

            />

            <KeywordField
              errors={errors}
              setErrors={setErrors}
              values={values}
              handlelabelPlaceChange={handlelabelPlaceChange}
              wform_keyword={wform_keyword}
              setValues={setValues}
            />

            <EmailField
              errors={errors}
              setErrors={setErrors}
              wform_email={wform_email}
              handlelabelPlaceChange={handlelabelPlaceChange}
              values={values}
              setValues={setValues}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid className="create_form_list pt-0" item md={6}>
        <Paper
          elevation={4}
          className="widget-form-margin mt-4 mt-lg-0"
          style={{ padding: "16px" }}
        >
          <Sample
               wform_url={wform_url}
               wform_keyword={wform_keyword}
            />
          <Box className={classes.dividers}>
            <NameField
              errors={errors}
              setErrors={setErrors}
              wform_name={wform_name}
              handlelabelPlaceChange={handlelabelPlaceChange}
              values={values}
              setValues={setValues}
            />

            <PhoneField
              errors={errors}
              setErrors={setErrors}
              input_field={input_field}
              wform_phone={wform_phone}
              handlelabelPlaceChange={handlelabelPlaceChange}
              values={values}
              setValues={setValues}
            />

            <TermsCondition
              errors={errors}
              setErrors={setErrors}
              wform_tnc={wform_tnc}
              values={values}
              setValues={setValues}
              handlelabelPlaceChange={handlelabelPlaceChange}
            />
            {values.widget_type === "5" || values.widget_type === 5 ? <Box sx={{ mt: 0 }} className="button-lbl ">
              <InputLabel
                label={t("createWidget.checkBox.labels.stepBtnLabel")}
                required
                title={t("createWidget.formField.34")}
              />
              <Box className="input_wrapper  ">
                <input
                  name="first_step_submit_text"
                  type="text"
                  placeholder={t("createWidget.formField.33")}
                  value={values.widget_settings_extra.first_step_submit_text || ""}
                  onChange={handleChangeExtra}
                  onFocus={() =>
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      lead_generation_btn_error: "",
                    }))
                  }
                />
                <div className="form__error">
                  {errors.lead_generation_btn_error}
                </div>
              </Box>
            </Box>:""}
            
            <Box sx={{ mt: 0 }} className="button-lbl ">
              <InputLabel
                label={t("createWidget.checkBox.labels.btnLabel")}
                required
                title={t("createWidget.formField.6")}
              />
              <Box className="input_wrapper  ">
                <input
                  name="widget_button_label"
                  type="text"
                  placeholder={t("createWidget.formField.5")}
                  value={values.widget_button_label}
                  onChange={handleChange}
                  onFocus={() =>
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      lead_generation_btn_error: "",
                    }))
                  }
                />
                <div className="form__error">
                  {errors.lead_generation_btn_error}
                </div>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default React.memo(FormField);
