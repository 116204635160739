import uuid from "uuid";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch, getState) => {
    const state = getState();
    if (state.alert.length > 0) {
      dispatch({ type: REMOVE_ALERT, payload: state.alert[0].id });
    }
    const id = uuid.v4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

export const removeAlert = () => (dispatch, getState) => {
  const state = getState();
  if (state.alert.length > 0) {
    dispatch({ type: REMOVE_ALERT, payload: state.alert[0].id });
  }
};
