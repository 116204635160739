import {
  CLEAR_SUBSCRIPTION_LINK,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAIL,
  GET_PAYMENTINFO_INFO_BEGIN,
  GET_PAYMENTINFO_INFO_FAIL,
  GET_PAYMENTINFO_INFO_SUCCESS,
  GET_SINGLESUBSCRIBE_INFO_BEGIN,
  GET_SINGLESUBSCRIBE_INFO_FAIL,
  GET_SINGLESUBSCRIBE_INFO_SUCCESS,
  GET_SUBSCRIPTION_INFO_BEGIN,
  GET_SUBSCRIPTION_INFO_FAIL,
  GET_SUBSCRIPTION_INFO_SUCCESS,
  GET_SUBSCRIPTION_PACKAGE_BEGIN,
  GET_SUBSCRIPTION_PACKAGE_FAIL,
  GET_SUBSCRIPTION_PACKAGE_SUCCESS,
  GET_USERACCOUNT_INFO_BEGIN,
  GET_USERACCOUNT_INFO_FAIL,
  GET_USERACCOUNT_INFO_SUCCESS,
  SUBMIT_SUBSCRIBE_FORM_BEGIN,
  SUBMIT_SUBSCRIBE_FORM_FAIL,
  SUBMIT_SUBSCRIBE_FORM_SUCCESS
} from '../action/subscriptionActions';

const initialState = {
  subscriptionInfo: {
    data: {
      email: '',
      type: '',
      created: '',
      expires: '',
      methode: '',
      subscription_id: '',
    },
    loading: true,
    error: null,
  },

  paymentData: {
    all_payments: [],
  },

  singleSubscribeData: {
    details: [],
  },

  useAccountDetails: {},

  subscriptionInfos: {
    subscription_details: {},
    subscription_id: '',
    subscription_name: '',
    price: '',
  },

  link: null,
  loading: true,
  error: null,

  userData: {
    fullNmame: '',
    businessEmail: '',
    mobileNo: '',
    webUrl: '',
    address: '',
  },
};

const subscriptionInfoReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SUBSCRIPTION_INFO_BEGIN:
      return {
        ...state,
        subscriptionInfo: {
          data: payload,
          error: null,
          loading: true,
        },
      };
    case GET_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          data: payload.data,
          loading: false,
        },
      };
    case GET_SUBSCRIPTION_INFO_FAIL:
      return {
        ...state,
        subscriptionInfo: {
          error: payload,
          loading: false,
        },
      };
    case GET_SUBSCRIPTION_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTION_PACKAGE_SUCCESS:
      return {
        ...state,
        subscriptionInfos: {
          data: payload,
        },
        link: payload,
        loading: false,
      };
    case GET_SUBSCRIPTION_PACKAGE_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_PAYMENTINFO_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PAYMENTINFO_INFO_SUCCESS:
      return {
        ...state,
        paymentData: {
          all_payments: payload,
        },
        link: payload,
        loading: false,
      };

    case GET_PAYMENTINFO_INFO_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_USERACCOUNT_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_USERACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        useAccountDetails: {
          data: payload,
        },
        link: payload,
        loading: false,
      };

    case GET_USERACCOUNT_INFO_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_SINGLESUBSCRIBE_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLESUBSCRIBE_INFO_SUCCESS:
      return {
        ...state,
        singleSubscribeData: {
          details: payload,
        },
        link: payload,
        loading: false,
      };

    case GET_SINGLESUBSCRIBE_INFO_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case SUBMIT_SUBSCRIBE_FORM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_SUBSCRIBE_FORM_SUCCESS:
      return {
        ...state,
        userData: {
          data: payload,
        },
        link: payload,
        loading: false,
      };
    case SUBMIT_SUBSCRIBE_FORM_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_SUBSCRIPTION_LINK:
      return {
        ...state,
        link: null,
      };

    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default subscriptionInfoReducer;
