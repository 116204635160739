import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    background: "#fffde5",
    border: "1px solid #ffcf00",
    padding: "15px 10px",
    lineHeight: "20px",
    fontSize: "15px",
    borderRadius: "5px",
    "& strong": {
      lineHeight: "initial",
      fontSize: "15px",
      display: "inline-block",
      fontWeight: "700",
    },
  },
}));

const YellowNote = ({ data }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <div>{(data || []).map(({ value }) => value)}</div>
    </Box>
  );
};

export default YellowNote;
