import { makeStyles } from "@mui/styles";

export const modalStyle = makeStyles((theme) => ({
  fuck: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(183, 183, 183, .2)",
    },
    "& .body": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "650px",
      minWidth: "450px",
      minHeight: "250px",
      background: "#fff",
      borderRadius: ".5rem",
      boxShadow: "0px 2px 100px 15px rgba(214, 208, 208, .60)",
      padding: "1.5rem",
      textAlign: "Center",
      display: "flex",

      "& .wrapper": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        "& .buttons": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around"
        },

        "& .close__button": {
          position: "absolute",
          top: "0",
          right: "0",
          color: theme.palette.common.red
        }

      },

      "& h2": {
        fontSize: "1.2rem",
        fontWeight: "600",
        marginBottom: "1.8rem",
        marginTop: "1rem",
      },

      "& p": {
        fontSize: ".895rem",
        fontWeight: "400",
        marginBottom: "1.8rem"
      }
    }
  },
}));

export const labelStyle = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));
