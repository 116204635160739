import serverCall from "modules/serverCall";
import { setAlert } from "../alert";
import i18n from "../../../i18n";
// POST SMTP DATA
export const POST_SMTP_SETTING_BEGIN = "POST_SMTP_SETTING_BEGIN";
export const POST_SMTP_SETTING_SUCCESS = "POST_SMTP_SETTING_SUCCESS";
export const POST_SMTP_SETTING_FAIL = "POST_SMTP_SETTING_FAIL";

// POST EMAIL DATA
export const POST_EMAIL_SETTING_BEGIN = "POST_EMAIL_SETTING_BEGIN";
export const POST_EMAIL_SETTING_SUCCESS = "POST_EMAIL_SETTING_SUCCESS";
export const POST_EMAIL_SETTING_FAIL = "POST_EMAIL_SETTING_FAIL";

// POST RESET DATA
export const POST_RESET_SETTING_BEGIN = "POST_RESET_SETTING_BEGIN";
export const POST_RESET_SETTING_SUCCESS = "POST_RESET_SETTING_SUCCESS";
export const POST_RESET_SETTING_FAIL = "POST_RESET_SETTING_FAIL";

// POST TEST EMAIL
export const POST_TEST_SETTING_BEGIN = "POST_TEST_SETTING_BEGIN";
export const POST_TEST_SETTING_SUCCESS = "POST_TEST_SETTING_SUCCESS";
export const POST_TEST_SETTING_FAIL = "POST_TEST_SETTING_FAIL";

// GET SMPT DATA
export const GET_SMTP_SETTING_BEGIN = "GET_SMTP_SETTING_BEGIN";
export const GET_SMTP_SETTING_SUCCESS = "GET_SMTP_SETTING_SUCCESS";
export const GET_SMTP_SETTING_FAIL = "GET_SMTP_SETTING_FAIL";

// GET EMAIL DATA
export const GET_EMAIL_SETTING_BEGIN = "GET_EMAIL_SETTING_BEGIN";
export const GET_EMAIL_SETTING_SUCCESS = "GET_EMAIL_SETTING_SUCCESS";
export const GET_EMAIL_SETTING_FAIL = "GET_EMAIL_SETTING_FAIL";

export const postSmtpSettingData = (data,setSmtpErrorMsg) => (dispatch) => {
  dispatch({
    type: POST_SMTP_SETTING_BEGIN,
  });
  setSmtpErrorMsg("");
  return serverCall({
    method: "POST",
    url: "api/v1/verify-smtp/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: POST_SMTP_SETTING_SUCCESS,
          payload: data,
        });
        dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch(setAlert(res.data.message, "danger"));
        setSmtpErrorMsg(res.data.data.response);

      }
      
      return data;
    })
    .catch((error) => {
      dispatch({
        type: POST_SMTP_SETTING_FAIL,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      throw error;
    });
};

// POST EMAIL SETTING
export const postEmailSettingData = (data) => (dispatch) => {
  dispatch({
    type: POST_EMAIL_SETTING_BEGIN,
  });

  return serverCall({
    method: "POST",
    url: "api/v1/emails-settings/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: POST_EMAIL_SETTING_SUCCESS,
          payload: data,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return data;
    })
    .catch((error) => {
      dispatch({
        type: POST_EMAIL_SETTING_FAIL,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

      throw error;
    });
};

// POST EMAIL SETTING
export const postResetSettingData = (datas) => (dispatch) => {
  dispatch({
    type: POST_RESET_SETTING_BEGIN,
  });

  const data = {
    "email-id": datas,
  };

  return serverCall({
    method: "POST",
    url: "api/v1/reset-email/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){

        dispatch({
          type: POST_RESET_SETTING_SUCCESS,
          payload: res.data.data.email,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: POST_RESET_SETTING_FAIL,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

      throw error;
    });
};

// POST TEST EMAIL
export const postTestSettingData = (data) => (dispatch) => {
  dispatch({
    type: POST_TEST_SETTING_BEGIN,
  });

  return serverCall({
    method: "POST",
    url: "api/v1/send-test-email/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: POST_TEST_SETTING_SUCCESS,
          payload: res.data.data,
        });
        dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: POST_TEST_SETTING_FAIL,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;

    });
};

// Get SMTP SETTING DATA
export const getSmtpSettingData = () => (dispatch) => {
  dispatch({
    type: GET_SMTP_SETTING_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/smtp-settings/",
  })
    .then((res) => {
      dispatch({
        type: GET_SMTP_SETTING_SUCCESS,
        payload: res.data.data.smtp_settings,
      });
      return res.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_SMTP_SETTING_FAIL,
        payload: { error },
      });
      return error;
    });
};

// Get EMIAL SETTING DATA
export const getEmailSettingData = () => (dispatch) => {
  dispatch({
    type: GET_EMAIL_SETTING_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/emails-settings/",
  })
    .then((res) => {
      dispatch({
        type: GET_EMAIL_SETTING_SUCCESS,
        payload: res.data.data.emails,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_EMAIL_SETTING_FAIL,
        payload: { error },
      });
      return error;
    });
};
