import { Box, FormGroup, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: theme.palette.text.main,
      "&.MuiOutlinedInput-input": {
        padding: "8px 1rem",
        border: "1px solid",
        borderColor: "#d7d5e1",
        borderRadius: "4px",
      },
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.main,
        opacity: 0.5,
        fontSize: ".85rem",
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0",
    },

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0",
      },
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0",
      },
    },
  },
  formGroup: {
    marginBottom: "1.2rem",
    position: "relative",
    "& .MuiTextField-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& label": {
      fontWeight: "500",
      fontSize: ".897rem",
      wordSpacing: ".01em",
      textTransform: "uppercase",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      bottom: "-2rem",
      right: "0",
      zIndex: "5",
      margin: "8px 0 0 0",
      padding: "2px 5px",
      backgroundColor: theme.palette.common.red,
      maxWidth: "fit-content",
      borderRadius: "5px",
      color: theme.palette.common.white,
      fontSize: "13px",
      lineHeight: "initial",
      marginLeft: "auto",
      "&::after": {
        content: "''",
        position: "absolute",
        top: "-8px",
        left: "10px",
        borderBottom: "8px solid",
        borderBottomColor: theme.palette.common.red,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        zIndex: 0,
      },
    },
  },
}));

const CustomInput = forwardRef(
  ({ styled, notEditAble, label, ...rest }, ref) => {
    const classes = useStyles();
    return (
      <FormGroup className={`${classes.formGroup} ${styled || ""} `}>
        {label || notEditAble ? (
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {label && <Typography component="label">{label}</Typography>}

            {notEditAble && (
              <Typography className="  " component="small">
                {notEditAble}
              </Typography>
            )}
          </Box>
        ) : null}
        <TextField
          {...rest}
          autoComplete="off"
          variant="outlined"
          className={classes.root}
          inputRef={ref}
        />
      </FormGroup>
    );
  }
);

export default CustomInput;
