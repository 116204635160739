/* eslint-disable import/no-duplicates */
import axios from "axios";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// escape eslint
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import SetServerUrl from "./modules/URL";
const LANGUAGES = [
  "en",
  "nl",
  "es",
  "it",
  "fr",
  "pt",
  "de",
  "el",
  "sv",
  "pl",
  "nb",
  "da",
];

i18n.t("key", { what: "i18next", how: "great" });

const getData = axios.create({
  baseURL: SetServerUrl,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});

const loadResources = async (locale) =>
  getData
    .get("/api/v1/translation/", { params: { lang: locale } })
    .then((res) => res.data)
    .catch((err) => err.response);

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ["cookie", "localStorage", "navigator"],

  // keys or params to lookup language from
  lookupCookie: "locale",
  lookupLocalStorage: "locale",

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|");
      loadResources(lng).then((res) => {
        callback(null, {
          data: res,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(backend)
  .init({
    backend: backendOptions,
    fallbackLng: "en",
    whitelist: LANGUAGES,
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;