import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import InputLabel from "../InputLabel";
import { CustomButton, ToolTip } from "@components";
import {
  Link as RouterLink,
} from "react-router-dom";

const ReportActionForm = (props) => {
  const {
    values,
    errors,
    setErrors,
    someEditedValue,
    setValues,
    setSomeEditedValue,
  } = props;

  const { t } = useTranslation();
  console.log(values);
  const handleChangeAction = useCallback((data) => {
    console.log(values);
    console.log(someEditedValue);
    setValues((prevValues) => ({
      ...prevValues,
      report_action: data.value,
    }));
    setSomeEditedValue((prevValues) => ({
      ...prevValues,
      report_action: data.value,
    }));
    console.log(values);
    console.log(someEditedValue);
  }, []);
  const handleChangeThis = useCallback(
    (event) => {
      const { name, value } = event.target;
      console.log(values);

      if (name === "text" || name === "redirect_url") {
        setValues((prevValues) => ({
          ...prevValues,
          report_action_extra: {
            ...prevValues.report_action_extra,
            [values.report_action]: {
              ...prevValues.report_action_extra?.[values.report_action],
              [name]: value,
            },
          },
        }));
        setSomeEditedValue((prevValues) => ({
          ...prevValues,
          report_action_extra: {
            ...prevValues.report_action_extra,
            [values.report_action]: {
              ...prevValues.report_action_extra?.[values.report_action],
              [name]: value,
            },
          },
        }));
      }
    },
    [values.report_action]
  );

  // Extra Select Optons
  const buttonOptions = [
    { value: "default", label: t("createWidget.widgetType.33") },
    { value: "redirect", label: t("createWidget.widgetType.34") },
    { value: "popup", label: t("createWidget.widgetType.35") },
  ];

  return (
    <Grid container spacing={4} style={{ marginLeft: "15px !important" }}>
      <Grid item xs={12} lg={12} xl={8}>
        <Box paddingBottom="1rem !important" className="form-full">
          <Typography variant="h5" className="title">
            {t("createWidget.widgetType.31")}
          </Typography>
          <Typography variant="body1">
            {t("createWidget.widgetType.32")}
          </Typography>
          <Box
            sx={{ mt: ".6rem" }}
            className="custom-selector custom-selector3  "
          >
            <Select
              options={buttonOptions}
              onChange={handleChangeAction}
              value={
                someEditedValue?.report_action !== undefined
                  ? buttonOptions.find(
                      ({ value }) =>
                        value === someEditedValue?.report_action
                    )
                  : buttonOptions.find(
                      ({ value }) => value === values.report_action
                    )
              }
            />
          </Box>
        </Box>
        
        {((someEditedValue?.report_action && someEditedValue?.report_action === "redirect")||(!someEditedValue?.report_action && values?.report_action === "redirect")) && (
          <div className="input_wrapper custom-selector3 ">
            <InputLabel
              label={t("createWidget.widgetType.17")}
              required
              title={t("createWidget.widgetType.18")}
            />
            <input
              name="redirect_url"
              onChange={(e) => {
                handleChangeThis(e);
                if (e.target.value === "") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    report_action_redirect_url_error: <span>{t("common.required")}</span>,
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    report_action_redirect_url_error: "",
                  }));
                }
              }}
              value={values.report_action_extra?.redirect?.redirect_url || ""}
              type="text"
              placeholder={t("createWidget.widgetType.19")}
            />
            <div className="form__error">{errors.report_action_redirect_url_error}</div>
          </div>
        )}
        {((someEditedValue?.report_action && someEditedValue?.report_action === "popup")||(!someEditedValue?.report_action && values?.report_action === "popup")) && (
          <div className="input_wrapper custom-selector3 ">
            <InputLabel
              label={t("createWidget.widgetType.36")}
              required
              title={t("createWidget.widgetType.37")}
            />
            <textarea 
              rows="4"
              name="text"
              onChange={(e) => {
                handleChangeThis(e);
                if (e.target.value === "") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    report_action_text_error: <span>{t("common.required")}</span>,
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    report_action_text_error: "",
                  }));
                }
              }}
              value={values.report_action_extra?.popup?.text || ""}
              type="text"
              placeholder={t("createWidget.widgetType.37")}
            />
            <div className="form__error">{errors.report_action_text_error}</div>
          </div>
        )}

    <Box sx={{ display: "inline-block" }}>
          {values?.smtp_verified ? (
                    <div className="check" aria-label="Configure SMTP to send PDF Report via Email">
                    <div className="custom-control custom-checkbox custom-selector2 " style={{"zIndex":0}}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="phone"
                        value={
                          values.report_action_extra?.[values.report_action]?.send_report
                        }
                        name="send_report"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            report_action_extra: {
                              ...values.report_action_extra,
                              [values.report_action]: {
                                ...values.report_action_extra?.[values.report_action],
                                send_report: values.report_action_extra?.[values.report_action]?.send_report > 0 ? 0 : 1,
                              }
                            },
                          });
                        }}
                        checked={values.report_action_extra?.[values.report_action]?.send_report === 1}
                      />
                      <label className="custom-control-label" htmlFor="phone">
                        <Typography component="span" variant="body1">
                          {t("createWidget.checkBox.labels.5")}
                        </Typography>
                      </label>
                    </div>
                  </div>
          ) : (
        <ToolTip title={t("common.smtp_tooltip")}>
          <Box>
            <CustomButton
              component={RouterLink}
              to="/setting"
              color="success"
              label={t("common.configure_smtp")}
            />
          </Box>
        </ToolTip>
      )}
    </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(ReportActionForm);
