import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import '../../assets/sass/sync-lead.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory, useParams } from "react-router-dom";

import Zapier from './zapier';
import Webhook from './webhook';
import Sendinblue from './sendinblue';
import Mailchimp from './mailchimp';
import ConstantContact from './constant-contact';
const SyncLead = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  var { sync_with } = useParams();


  var tabIndex = 0;
  if(sync_with){
    sync_with = sync_with.toUpperCase()
  }
  switch(sync_with) {
    case "WEBHOOK":
      tabIndex = 0;
      break;
    // case "ZAPIER":
    //   tabIndex = 1;
    //   break;
    case "SENDINBLUE":
      tabIndex = 1;
      break;
    case "MAILCHIMP":
      tabIndex = 2;
      break;
    case "CONSTANT_CONTACT":
      tabIndex = 3;
      break;
    default:
      history.push(`/sync-lead/webhook`);
      break;
          
  }
  const setTabIndex = (index) => {
    switch(index) {
      case 0:
        history.push(`/sync-lead/webhook`);
        break;
      // case 1:
      //   history.push(`/sync-lead/zapier`);
      //   break;
      case 1:
        history.push(`/sync-lead/sendinblue`);
        break;
      case 2:
        history.push(`/sync-lead/mailchimp`);
        break;
      case 3:
        history.push(`/sync-lead/constant_contact`);
        break;
            
    } 
  };
  return (
    <div className="sync-lead">
      <div class="text-left section-heading"><h2 class="text-uppercase">{t("settingPage.tab.sync_lead")}</h2></div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>
            <p>{t("sync_lead.Webhook")}</p>
          </Tab>
          {/* <Tab>
            <p>{t("sync_lead.Zapier")}</p>
          </Tab> */}
          <Tab>
            <p>{t("sync_lead.Sendinblue")}</p>
          </Tab>
          <Tab>
            <p>{t("sync_lead.Mailchimp")}</p>
          </Tab>
          <Tab>
            <p>{t("sync_lead.Constant_Contact")}</p>
          </Tab>
        </TabList>

        <TabPanel>
          <div className="panel-content">
          {sync_with == "WEBHOOK"?(
            <Webhook />
          ):(<></>)}
          </div>
        </TabPanel>
        {/* <TabPanel>
          <div className="panel-content">
          {sync_with == "ZAPIER"?(
            <Zapier />
          ):(<></>)}
          </div>
        </TabPanel> */}
        <TabPanel>
          <div className="panel-content">
          {sync_with == "SENDINBLUE"?(
            <Sendinblue />
          ):(<></>)}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          {sync_with == "MAILCHIMP"?(
            <Mailchimp />
          ):(<></>)}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          {sync_with == "CONSTANT_CONTACT"?(
            <ConstantContact />
          ):(<></>)}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default React.memo(SyncLead);
