import { CustomInput, CustomLoadingBtn } from "@components";
import { Box, FormGroup } from "@mui/material";
import React, { Fragment } from "react";
import { TextArea } from "semantic-ui-react";
import { useForm } from "react-hook-form";

const Support = ({ register, errors, loading,setvalue }) => {
  const error = {
    email: errors.email?.message,
    subject: errors.subject?.message,
    message: errors.message?.message,
  };

  return (
    <Fragment>
      <CustomInput
        {...register("email")}
        helperText={error.email}
        placeholder="Contact Email"
        type="text"
        error={error.email && true}
      />
      <CustomInput
        {...register("subject")}
        helperText={error.subject}
        placeholder="Subject"
        type="text"
        error={error.subject && true}
      />
      <FormGroup>
        <TextArea {...register("message")} type="text" placeholder="Message" onChange={(e) => setvalue("message", e.target.value)}/>
        {error.message && (
          <div className="form__error">
            <span>{error.message}</span>
          </div>
        )}
      </FormGroup>
      <Box marginTop="2rem" marginBottom="1rem" textAlign="right">
        <CustomLoadingBtn
          id="submit_support"
          color="primary"
          label="Send Email"
          loadingPosition="center"
          type="submit"
          loading={loading}
        />
      </Box>
    </Fragment>
  );
};

export default Support;
