import {
  GET_AUDIT_REPORT_BEGIN,
  GET_AUDIT_REPORT_FAIL,
  GET_AUDIT_REPORT_SUCCESS,
  GET_AUDIT_REPORT_URL_BEGIN,
  GET_AUDIT_REPORT_URL_FAIL,
  GET_AUDIT_REPORT_URL_SUCCESS
} from "../action/auditReportAction";

const initialState = {
  audit_report_loading: false,
  content: "",
  id: "",
  auditData: {
    share_key: "",
    audit_id: "",
    content: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIT_REPORT_BEGIN:
      return {
        ...state,
        audit_report_loading: true,
      };

    case GET_AUDIT_REPORT_SUCCESS:
      return {
        ...state,
        audit_report_loading: false,
        content: action.payload.res.data.data.content,
        id: action.payload.report_id,
      };

    case GET_AUDIT_REPORT_FAIL:
      return {
        ...state,
        audit_report_loading: false,
      };
    case GET_AUDIT_REPORT_URL_BEGIN:
      return {
        ...state,
        audit_report_loading: true,
      };

    case GET_AUDIT_REPORT_URL_SUCCESS:
      return {
        ...state,
        audit_report_loading: false,
        content: action.payload.data.data.page_states.content,
        id: action.payload.data.data.page_states.audit_id,
      };

    case GET_AUDIT_REPORT_URL_FAIL:
      return {
        ...state,
        audit_report_loading: false,
      };
    default:
      return state;
  }
};
