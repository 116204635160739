import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const Zapier = (props) => {
  const { t } = useTranslation();

  return (
    <h4>Zapier</h4>
    
  );
};

export default React.memo(Zapier);