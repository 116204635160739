export const LOGIN = "LOGIN";
export const CREATE_PROJECT_GROUP = "CREATE_PROJECT_GROUP";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_SEARCH_ENGINE = "CREATE_SEARCH_ENGINE";
export const CREATE_KEYWORD_GROUP = "CREATE_KEYWORD_GROUP";
export const ADD_KEYWORD = "ADD_KEYWORD";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const GET_COUNTRY = "GET_COUNTRY";
export const DELETE_SEARCH_ENGINE = "DELETE_SEARCH_ENGINE";
export const GET_PROJECT_SEARCH_ENGINE = "GET_PROJECT_SEARCH_ENGINE";
export const SEARCH_ENGINE_UPDATE = "SEARCH_ENGINE_UPDATE";
export const ADD_SEARCH_ENGINE = "ADD_SEARCH_ENGINE";
export const GET_LOCATION = "GET_LOCATION";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const GET_ACTIVE_KEYWORD = "GET_ACTIVE_KEYWORD";
export const KEYWORD_RANKING = "KEYWORD_RANKING";

export const SAVE_TEMP_AUDIT = "SAVE_TEMP_AUDIT";
export const REMOVE_TEMP_AUDIT = "REMOVE_TEMP_AUDIT";

export const GET_KEYRANK_OVERVIEW_BEGIN = "GET_KEYRANK_OVERVIEW_BEGIN";
export const GET_KEYRANK_OVERVIEW_SUCCESS = "GET_KEYRANK_OVERVIEW_SUCCESS";
export const GET_KEYRANK_OVERVIEW_FAIL = "GET_KEYRANK_OVERVIEW_FAIL";
