import {
  GET_NOTIFICATION_BEGIN,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS
} from "../../action/Notifications";

const initialState = {
  loading: true,
  notification: {
    data: [],
  },
};

const getNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...state.notification,
        notification: {
          data: payload.data.data.notifications,
        },

        loading: true,
      };
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default getNotificationReducer;
