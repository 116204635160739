import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import {
  borderTypeOptions,
  fontWeightOptions,
  textTransformOption,
} from "./selectOptions";

const CtaButton = ({
  values,
  setValues,
  setChangingObject,
  changingObject,
}) => {
  const classes = useStyles();

  const { cta_button } = values;

  const handleCtaChange = useCallback((e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      cta_button: {
        ...prevState.cta_button,
        [name]: value,
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      cta_button: {
        ...prevState.cta_button,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevState) => ({
      ...prevState,
      cta_button: {
        ...prevState.cta_button,
        [name]: data.value,
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      cta_button: {
        ...prevState.cta_button,
        [name]: data.value,
      },
    }));
  }, []);

  const buttonPosition = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
    { label: "Center", value: "center" },
  ];

  const { t } = useTranslation();

  return (
    <Box className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box> {t("createWidget.formStyle.text")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.background")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="cta_button"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="color"
              nested="cta_button"
              type="none"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.size")}
          </Typography>
          <div>
            <input
              name="text_size"
              value={cta_button.text_size}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.transform")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={textTransformOption}
              placeholder={t("common.select")}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "text_transform")}
              value={textTransformOption.find(
                ({ value }) => value === cta_button.text_transform
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.fontWeight")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={fontWeightOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptions.find(
                ({ value }) => value === cta_button.font_weight
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.buttonPosition")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={buttonPosition}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "position")}
              value={buttonPosition.find(
                ({ value }) => value === cta_button.position
              )}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box> {t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              nested="cta_button"
              type="none"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={cta_button.border_radius}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              menuPlacement="top"
              options={borderTypeOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === cta_button.border_type
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={cta_button.border_top}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={cta_button.border_right}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={cta_button.border_bottom}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={cta_button.border_left}
              onChange={handleCtaChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box> {t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="margin_top"
              value={cta_button.margin_top}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={cta_button.margin_right}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={cta_button.margin_bottom}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={cta_button.margin_left}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box> {t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={cta_button.padding_top}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={cta_button.padding_right}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={cta_button.padding_bottom}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={cta_button.padding_left}
              onChange={handleCtaChange}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(CtaButton);
