import serverCall from "../../modules/serverCall";

export const GET_ENGINE_LIST_BEGIN = "GET_ENGINE_LIST_BEGIN";
export const GET_ENGINE_LIST_SUCCESS = "GET_ENGINE_LIST_SUCCESS";
export const GET_ENGINE_LIST_FAIL = "GET_ENGINE_LIST_FAIL";

export const getEngineList = () => (dispatch) => {
  dispatch({
    type: GET_ENGINE_LIST_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/search-engine/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ENGINE_LIST_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ENGINE_LIST_FAIL,
        payload: { error },
      });
      return error;
    });
};
