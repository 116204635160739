import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "../../../InputLabel";

const FixedForm = ({ values, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <InputLabel
        label={t("createWidget.widgetType.26")}
        title={t("createWidget.widgetType.27")}
        optional
      />
      <textarea
        rows="3"
        placeholder={t("createWidget.widgetType.28")}
        onChange={handleChange}
        name="widget_form_extra"
        value={values.widget_form_extra || ""}
      />
    </Fragment>
  );
};

export default React.memo(FixedForm);
