import {
  STEP_TWO_DATA_POST_BEGIN,
  STEP_TWO_DATA_POST_FAIL,
  STEP_TWO_DATA_POST_SUCCESS
} from "../action/stepTwoAction";

const initialState = {
  step_two_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STEP_TWO_DATA_POST_BEGIN:
      return {
        ...state,
        step_two_loading: true,
      };

    case STEP_TWO_DATA_POST_SUCCESS:
      return {
        ...state,
        step_two_loading: false,
      };

    case STEP_TWO_DATA_POST_FAIL:
      return {
        ...state,
        step_two_loading: false,
      };

    default:
      return state;
  }
};
