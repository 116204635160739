import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg": {
      color: theme.palette.common.solidGray,
      fontSize: "1.2rem",
      marginRight: ".5rem",
    },
    "& p": {
      fontSize: ".875rem",
      color: theme.palette.text.gray,
    },
  },
}));

const InfoNote = ({ label }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="body1">
        <InfoIcon />
        {label}
      </Typography>
    </Box>
  );
};

export default InfoNote;
