import {
  GET_KEY_WORD_GRAPH_BEGIN,
  GET_KEY_WORD_GRAPH_FAIL,
  GET_KEY_WORD_GRAPH_SUCCESS,
  GET_KEY_WORD_GROUP_BEGIN,
  GET_KEY_WORD_GROUP_FAIL,
  GET_KEY_WORD_GROUP_SUCCESS,
  GET_KEY_WORD_TABLE_BEGIN,
  GET_KEY_WORD_TABLE_FAIL,
  GET_KEY_WORD_TABLE_SUCCESS
} from "../action/keywordRankingActions";

const initialState = {
  graphData: {
    all_dates: [],
    list: [],
    max: ""
  },

  pdf_data: "",

  tableData: {
    project_id: "",
    se_id: "",
    dates: [],
    competitor: {
      active: [
        {
          id: "",
          url: "",
        },
      ],
      inactive: [
        {
          id: "",
          url: "",
        },
      ],
    },

    keywords: [
      {
        key_id: "",
        keyword: "",
        ranks: {
          self: {
            url: "",
            rank: [],
          },
          competitor: [
            {
              url: "",
              rank: [],
            },
          ],
        },
      },
    ],
  },
  groupTable: {
    groups: [
      {
        group_id: 0,
        group_name: "",
      },
    ],
  },

  loading: true,
  error: null,
};
const keywordRankingtReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_KEY_WORD_GRAPH_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_KEY_WORD_GRAPH_SUCCESS:
      return {
        ...state,
        graphData: {
          all_dates: payload.all_dates,
          list: payload.list,
          max: payload.max,
        },
        loading: true,
      };
    case GET_KEY_WORD_GRAPH_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_KEY_WORD_TABLE_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_KEY_WORD_TABLE_SUCCESS:
      // console.log(payload);
      return {
        ...state,
        tableData: {
          dates: payload.dates,
          competitor: payload.competitor,
          keywords: payload.keywords,
          project_id: payload.project_id,
          se_id: payload.se_id,
          url: payload.url,
        },
        loading: true,
      };
    case GET_KEY_WORD_TABLE_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    // ---------start keyword group case ---------
    case GET_KEY_WORD_GROUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_KEY_WORD_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupTable: {
          groups: payload.groups,
        },
      };

    case GET_KEY_WORD_GROUP_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default keywordRankingtReducer;
