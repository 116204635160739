import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const GET_WIDGET_LIST_BEGIN = "GET_WIDGET_LIST_BEGIN";
export const GET_WIDGET_LIST_SUCCESS = "GET_WIDGET_LIST_SUCCESS";
export const GET_WIDGET_LIST_FAIL = "GET_WIDGET_LIST_FAIL";

export const SELECT_WIDGET_BEGIN = "SELECT_WIDGET_BEGIN";
export const SELECT_WIDGET_SUCCESS = "SELECT_WIDGET_SUCCESS";

export const DELETE_WIDGET = "DELETE_WIDGET";
export const DELETE_WIDGET_FAIL = "DELETE_WIDGET_FAIL";

export const GET_DELETED_WIDGET_BEGIN = "GET_DELETED_WIDGET_BEGIN";
export const GET_DELETED_WIDGET_SUCCESS = "GET_DELETED_WIDGET_SUCCESS";
export const GET_DELETED_WIDGET_FAIL = "GET_WIDGET_LIST_FAIL";

export const GET_WIDGET_BEGIN = "GET_WIDGET_BEGIN";
export const GET_WIDGET_SUCCESS = "GET_WIDGET_SUCCESS";
export const GET_WIDGET_FAIL = "GET_WIDGET_FAIL";

export const REMOVE_WIDGET = "REMOVE_WIDGET";
export const SET_CURRENT_PAGE_NUM_DELETE = "SET_CURRENT_PAGE_NUM_DELETE";
export const SET_CURRENT_PAGE_NUM = "SET_CURRENT_PAGE_NUM";

export const RESET_WIDGET_BEGIN = "RESET_WIDGET_BEGIN";
export const RESET_WIDGET_SUCCESS = "RESET_WIDGET_SUCCESS";
export const RESET_WIDGET_FAIL = "RESET_WIDGET_FAIL";

export const getWidgetList = (pageNumber) => (dispatch) => {
  dispatch({ type: GET_WIDGET_LIST_BEGIN });

  return serverCall({
    method: "GET",
    url: `/api/v1/widget/list/?page_item=20&page=${pageNumber}`,
  })
    .then((res) => {
      dispatch({
        type: GET_WIDGET_LIST_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_WIDGET_LIST_FAIL, payload: err });
    });
};

export const selectWidget = (widgetData) => (dispatch) => {
  dispatch({ type: SELECT_WIDGET_BEGIN });

  return dispatch({
    type: SELECT_WIDGET_SUCCESS,
    payload: widgetData,
  });
};

// Delete Widget
export const deleteWidget = (formData) => async (dispatch) => serverCall({
    method: "DELETE",
    url: "/api/v1/widget/delete/",
    data: formData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_WIDGET,
          payload: res,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_WIDGET_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });

export const resetWidget = (id, resetData) => (dispatch) => {
  dispatch({
    type: RESET_WIDGET_BEGIN,
  });

  return serverCall({
    method: "POST",
    url: `/api/v1/widget/${id}/reset/`,
    data: resetData,
  }).then((res) => {
    if(res.status >=200 && res.status<300){
      dispatch({
        type: RESET_WIDGET_SUCCESS,
        payload: res,
      });
      dispatch(setAlert(res.data.message, "success"));

    }else{
      dispatch(setAlert(res.data.message, "danger"));
    }
    return res;
  }).catch((err) => {
    dispatch({
      type: RESET_WIDGET_FAIL,
      payload: err
    });
    dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

    return err;
  });
};

export const setCurrentPageNumber = (data) => (dispatch) => {
  // console.log(data)
  dispatch({
    type: GET_WIDGET_LIST_BEGIN,
  });
  dispatch({
    type: SET_CURRENT_PAGE_NUM,
    payload: data,
  });
  dispatch({
    type: GET_WIDGET_LIST_FAIL,
    payload: { error: "There is an error" },
  });
};

export const setCurrentPageNumberDelete = (data) => (dispatch) => {
  // console.log(data)
  dispatch({
    type: GET_WIDGET_LIST_BEGIN,
  });
  dispatch({
    type: SET_CURRENT_PAGE_NUM_DELETE,
    payload: data,
  });
  dispatch({
    type: GET_WIDGET_LIST_FAIL,
    payload: { error: "There is an error" },
  });
};

export const remove_my_widget = () => (dispatch) => {
  // console.log(data)
  dispatch({
    type: REMOVE_WIDGET,
  });
};

export const fetchDeletedWidget = (pageNumber) => (dispatch) => {
  dispatch({
    type: GET_DELETED_WIDGET_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/widget/list/?&status=deleted&page_item=20&page=${pageNumber}`,
  })
    .then((res) => {
      dispatch({
        type: GET_DELETED_WIDGET_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_DELETED_WIDGET_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getWidgetbyId = (id) => (dispatch) => {
  dispatch({ type: GET_WIDGET_BEGIN });
  return serverCall({
    method: "GET",
    url: `/api/v1/widget/${id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_WIDGET_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_WIDGET_FAIL, payload: err });
    });
};
