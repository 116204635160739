import {
  DELETE_WIDGET,
  DELETE_WIDGET_FAIL,
  GET_DELETED_WIDGET_BEGIN,
  GET_DELETED_WIDGET_FAIL,
  GET_DELETED_WIDGET_SUCCESS,
  GET_WIDGET_BEGIN,
  GET_WIDGET_FAIL,
  GET_WIDGET_LIST_BEGIN,
  GET_WIDGET_LIST_FAIL,
  GET_WIDGET_LIST_SUCCESS,
  GET_WIDGET_SUCCESS, RESET_WIDGET_BEGIN,
  RESET_WIDGET_FAIL,
  RESET_WIDGET_SUCCESS,
  SELECT_WIDGET_BEGIN,
  SELECT_WIDGET_SUCCESS
} from "../action/widgetListAction";

const initialState = {
  listData: [],
  loading: false,
  error: false,
  selectedWidget: null,
  deletedwidget: [],
  widgetdata: {},
  widgetdatas: {},
  totalpages: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELECT_WIDGET_SUCCESS:
      return {
        ...state,
        selectedWidget: action.payload,
        loading: false,
      };
    case GET_WIDGET_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_WIDGET_LIST_SUCCESS:
      return {
        ...state,
        listData: action.payload.widgets,
        loading: false,
        totalpages: action.payload.total_page,
      };
    case GET_WIDGET_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    case DELETE_WIDGET:
      return {
        ...state,
        loading: false,
      };
    case DELETE_WIDGET_FAIL:
      return {
        ...state,
        error: true,
      };
    case GET_DELETED_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DELETED_WIDGET_SUCCESS:
      return {
        ...state,
        deletedwidget: action.payload.widgets,
        loading: false,
        totalpages: action.payload.total_page,
      };
    case GET_DELETED_WIDGET_FAIL:
      return {
        ...state,
        error: true,
      };
    case GET_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_WIDGET_SUCCESS:
      return {
        ...state,
        widgetdata: action.payload,
        loading: false,
      };
    case GET_WIDGET_FAIL:
      return {
        ...state,
        error: true,
      };

    case RESET_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESET_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_WIDGET_FAIL:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
};
