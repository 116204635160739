import { ButtonLoader } from "@components";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as adminSiteListActions from "../../../appRedux/action/adminSiteListActions";
import { deleteGroup } from "../../../appRedux/action/groupAction";
import SubBodySE from "./SubBodySE";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  const { groupId } = props;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  async function deleteGroupData() {
    setLoading(true);

    await dispatch(deleteGroup(groupId));

    await dispatch(adminSiteListActions.getAdminSiteListData());

    setLoading(false);

    handleClose();
  }

  return (
    <div className="single-data actions">
      <button
        type="button"
        className=" seo_btn btn_red btn_xs"
        onClick={handleShow}
      >
        {t("common.deleteGroup")}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>{t("modalMessage.one")}</h5>
        </Modal.Header>
        <Modal.Body>
          <small>{t("modalMessage.two")}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button className="seo_btn danger" onClick={deleteGroupData}>
            {t("modalMessage.btnOne")} {loading && <ButtonLoader />}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function SubBody({ group, props }) {
  const [collapsed, setCollapsed] = useState("");
  const { dataRoot } = props;
  function handleCollapse() {
    if (collapsed === "none") {
      setCollapsed("");
    } else setCollapsed("none");
  }

  return (
    <div className="list-body-full" key={group.id}>
      <div className="list-main-body">
        <div
          aria-hidden="true"
          className="main-body-data"
          onClick={handleCollapse}
        >
          <div className="single-data">
            <span>
              <i className="far fa-folder-open" />
            </span>
            <span>
              <i
                className={
                  collapsed === "none"
                    ? "fas fa-chevron-right"
                    : "fas fa-chevron-down"
                }
              />
            </span>
            <strong>{group.name}</strong>
          </div>
          <div className="single-data">
            <div className="rank" />
          </div>
          <div className="single-data single-data-999">
            <span />
          </div>
          <div className="single-data">
            <div className="ran-up" />
          </div>
          <span>
            <DeleteModal groupId={group.id} />
          </span>
        </div>
      </div>
      <div className="sub-body-main" style={{ display: collapsed }}>
        {group.sites.map((site, index) => (
          <SubBodySE
            key={site.id}
            site={site}
            selectProject={props.selectProject}
            props={props}
            dataRoot={dataRoot}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
export default SubBody;
