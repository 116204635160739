import { Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { ColorPicker as ColorPick, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useTranslation } from "react-i18next";
import useStyls from "./styles";

const ColorPicker = ({
 setValues, values, name, type, nested, setChangingObject
}) => {
  const classes = useStyls();

  const [displayPicker, setDisplayPicker] = useState(false);
  const [mainColor, setMainColor] = useState("#ffffff");

  const [theColor, setColor] = useColor(
    "hex",
    !nested
      ? values[name]
      : nested && values?.[nested]?.[name]
      ? values?.[nested]?.[name]
      : mainColor
  );

  const handleChange = useCallback((color) => {
    const { hex } = color;

    setColor(color);

    setMainColor(hex);

    if (nested !== "") {
      setValues((prevValues) => ({
        ...prevValues,
        [nested]: {
          ...prevValues[nested],
          [name]: hex
        }
      }));
      setChangingObject((prevValues) => ({
        ...prevValues,
        [nested]: {
          ...prevValues[nested],
          [name]: hex
        }
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: hex }));
    }
  }, []);

  const handleClick = useCallback(() => {
    setDisplayPicker((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setDisplayPicker(false);
  }, []);

  // Translation file
  const { t } = useTranslation();
  // Translation file

  return (
    <button
      className={classes.button}
      style={{ background: `${type === "cta" ? "#fffffff" : "transparent"}` }}
      type="button"
    >
      <Typography className={classes.swatch} component="span" onClick={handleClick}>
        <div
          className={classes.color}
          style={{
            background:
              !nested && values[name]
                ? values[name]
                : nested && values[nested][name]
                ? values[nested][name]
                : mainColor
          }}
        />
      </Typography>
      {displayPicker ? (
        <div className={classes.popover}>
          <Typography component="span" className={classes.cover} onClick={handleClose} />
          <ColorPick
            width={250}
            height={150}
            color={theColor}
            onChange={handleChange}
            name="widget_button_color"
            hideHSV
            hideRGB
            alpha
          />
        </div>
      ) : null}
    </button>
  );
};
export default React.memo(ColorPicker);
