import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import { borderTypeOptions } from "./selectOptions";

const FormBody = ({ values, setValues, setChangingObject, changingObject }) => {
  const classes = useStyles();

  const { form_body_style } = values;

  const handChangeBody = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      form_body_style: {
        ...values.form_body_style,
        [name]: parseInt(value),
      },
    });

    setChangingObject({
      ...changingObject,
      form_body_style: {
        ...changingObject.form_body_style,
        [name]: parseInt(value),
      },
    });
  };

  const hanldeSelect = (data, name) => {
    setValues({
      ...values,
      form_body_style: {
        ...values.form_body_style,
        [name]: data.value,
      },
    });

    setChangingObject({
      ...changingObject,
      form_body_style: {
        ...changingObject.form_body_style,
        [name]: data.value,
      },
    });
  };

  const { t } = useTranslation();

  return (
    <Box className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.background")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="form_body_style"
            />
          </div>
        </Box>
      </div>

      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={form_body_style.border_top}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={form_body_style.border_right}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={form_body_style.border_bottom}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={form_body_style.border_left}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              type="none"
              nested="form_body_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={borderTypeOptions}
              className="react__select"
              placeholder={t("common.select")}
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === form_body_style.border_type
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={form_body_style.border_radius}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>

      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="margin_top"
              value={form_body_style.margin_top}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={form_body_style.margin_right}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={form_body_style.margin_bottom}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={form_body_style.margin_left}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={form_body_style.padding_top}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={form_body_style.padding_right}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={form_body_style.padding_bottom}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={form_body_style.padding_left}
              onChange={handChangeBody}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(FormBody);
