import { Box, Link as MuiLink } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SetServerUrl from "../../../../modules/URL";
import LangBtn from "../../dashboard-layout/_header/cmponent/LangBtn";

const HeaderAuth = (props) => {
  const { toggleSidebar, isMenuOpen, isLoading } = props;

  const { t } = useTranslation();

  const { siteSettingData } = props;

  const settingData = {
    logo: (siteSettingData || {}).logo,
    smallLogo: (siteSettingData || {}).small_logo,
  };

  return (
    <div className={`section-nav ${isMenuOpen === true ? "burger-fixed" : ""}`}>
      <div className="navbar">
        <div className="container-fluid">
          <div className="navbar-full py-md-2">
            <div className="seo-logo">
              <div className="logo-left">
                {isLoading ? (
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    style={{ width: "130px", height: "30px" }}
                  />
                ) : (
                  <Link to="/">
                    <img
                      className="logo-full"
                      src={`${SetServerUrl}${settingData.logo}`}
                      alt="Logo"
                    />
                    <img
                      className="logo-small"
                      src={`${SetServerUrl}${settingData.smallLogo}`}
                      alt=""
                    />
                  </Link>
                )}
              </div>
              <div className="toggler">
                <div
                  id=""
                  aria-hidden="true"
                  onClick={toggleSidebar}
                  className="btn-menu"
                >
                  <div className="line line-1" />
                  <div className="line line-2" />
                  <div className="line line-3" />
                </div>
              </div>
            </div>

            <div className="header">
              <ul className="main-ul">
              {(siteSettingData || "").show_multilangual && (
                  <li className="{classes.root} languageHeader1 sas">
                    <LangBtn />
                  </li>
                )}
                {(siteSettingData || "").show_support && (
                  <li className="support__btn sd">
                    <Box marginRight="1rem">
                      <a
                        target="__blank"
                        href={`${(siteSettingData || "").support_link}`}
                      >
                        <i className="far fa-envelope" />
                        {(siteSettingData || "").support_text}
                      </a>
                    </Box>
                  </li>
                )}
                <li className="login">
                  {/* <button
                      className="mt-0 google-login goo__gle"
                      onClick={() => {
                        Auth.socialLogin("google", "en");
                      }}
                    >
                      <div className="logo">
                        <img src={GoogleLogo} alt="" />
                      </div>
                      <span>Login With Google</span>
                    </button> */}
                  <MuiLink
                    className="seo_btn gradient_blu tryitfree"
                    to="/"
                    component={Link}
                  >
                    {t("login.tryItFree")}
                  </MuiLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAuth;
