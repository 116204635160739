import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const ADD_ENGINE_BEGIN = "ADD_ENGINE_BEGIN";
export const ADD_ENGINE_SUCCESS = "ADD_ENGINE_SUCCESS";
export const ADD_ENGINE_FAIL = "ADD_ENGINE_FAIL";

export const GET_ENGINE_DETAILS_BEGIN = "GET_ENGINE_DETAILS_BEGIN";
export const GET_ENGINE_DETAILS_SUCCESS = "GET_ENGINE_DETAILS_SUCCESS";
export const GET_ENGINE_DETAILS_FAIL = "GET_ENGINE_DETAILS_FAIL";

export const GET_ALL_ENGINE_BEGIN = "GET_ALL_ENGINE_BEGIN";
export const GET_ALL_ENGINE_SUCCESS = "GET_ALL_ENGINE_SUCCESS";
export const GET_ALL_ENGINE_FAIL = "GET_ALL_ENGINE_FAIL";

export const DELETE_ENGINE_BEGIN = "DELETE_ENGINE_BEGIN";
export const DELETE_ENGINE_SUCCESS = "DELETE_ENGINE_SUCCESS";
export const DELETE_ENGINE_FAIL = "DELETE_ENGINE_FAIL";

export const UPDATE_ENGINE_BEGIN = "UPDATE_ENGINE_BEGIN";
export const UPDATE_ENGINE_SUCCESS = "UPDATE_ENGINE_SUCCESS";
export const UPDATE_ENGINE_FAIL = "UPDATE_ENGINE_FAIL";

export const addEngine = (
  project_id,
  searce_engine,
  se_country,
  se_language,
  se_location,
  competetors
) => (dispatch) => {
  dispatch({
    type: ADD_ENGINE_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/project/${project_id}/search-engine/create/`,
    data: {
      project_id,
      searce_engine,
      se_country,
      se_language,
      se_location,
      competetor: competetors,
    },
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: ADD_ENGINE_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: ADD_ENGINE_FAIL,
          payload: { res },
        });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })

    .catch((error) => {
      dispatch({
        type: ADD_ENGINE_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

      return error;
    });
};

export const getEngineDetails = (project_id, se_id) => (dispatch) => {
  dispatch({
    type: GET_ENGINE_DETAILS_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/search-engine/${se_id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ENGINE_DETAILS_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ENGINE_DETAILS_FAIL,
        payload: { error },
      });
      return error;
    });
};

// Keyword Ranking
export const getAllEngine = (project_id) => (dispatch) => {
  dispatch({
    type: GET_ALL_ENGINE_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/keyword-ranking/details_with_se/${project_id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_ENGINE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_ENGINE_FAIL,
        payload: { error },
      });
    });
};
// Keyword rankking

export const getAllEngineTwo = (project_id) => (dispatch) => {
  dispatch({
    type: GET_ALL_ENGINE_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/search-engine/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_ENGINE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_ENGINE_FAIL,
        payload: { error },
      });
    });
};

export const deleteEngine = (project_id, se_id) => (dispatch) => {
  dispatch({
    type: DELETE_ENGINE_BEGIN,
  });
  return serverCall({
    method: "DELETE",
    url: `/api/v1/project/${project_id}/search-engine/${se_id}/`,
    data: {
      project_id,
      se_id,
    },
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_ENGINE_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: DELETE_ENGINE_FAIL,
          payload: res,
        });
          dispatch(setAlert(res.data.message, "danger"));
        }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_ENGINE_FAIL,
        payload: error,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const updateEngine = (
  project_id,
  se_id,
  searce_engine,
  se_country,
  se_language,
  se_location,
  competetor
) => (dispatch) => {
  dispatch({
    type: UPDATE_ENGINE_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/project/${project_id}/search-engine/${se_id}/`,
    data: {
      se_id,
      project_id,
      searce_engine,
      se_country,
      se_language,
      se_location,
      competetor,
    },
  })
    .then((res) => {
      dispatch({
        type: UPDATE_ENGINE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_ENGINE_FAIL,
        payload: { error },
      });
      return error;
    });
};
