import {
  GET_ALL_COUNTRY_BEGIN,
  GET_ALL_COUNTRY_FAIL,
  GET_ALL_COUNTRY_SUCCESS
} from '../action/countryAction';

const initialState = {
  countries: [],
  country_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRY_BEGIN:
      return {
        ...state,
        country_loading: true,
      };

    case GET_ALL_COUNTRY_SUCCESS:
      return {
        ...state,
        country_loading: false,
        countries: action.payload.data.data.country,
      };

    case GET_ALL_COUNTRY_FAIL:
      return {
        ...state,
        country_loading: false,
      };

    default:
      return state;
  }
};
