import serverCall from '../../modules/serverCall';
import { setAlert } from './alert';

export const getAllLocation = (search_text, se_country) => (dispatch) => {
  dispatch({
    type: GET_ALL_LOCATION_BEGIN,
  });
  return serverCall({
    method: 'GET',
    url: `/api/v1/location/list/?search=${search_text}&country=${se_country}`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_LOCATION_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_LOCATION_FAIL,
        payload: error,
      });

      if (error.response && error.response.data) {
        dispatch(setAlert(error.response.data.message, 'danger'));
      }
      return error;
    });
};

export const getAllProfileLocation = (search_text, se_country) => (dispatch) => {
  dispatch({
    type: GET_ALL_USER_LOCATION_BEGIN,
  });
  return serverCall({
    method: 'GET',
    url: `/api/v1/city/list/?search=${search_text}&country=${se_country}`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_USER_LOCATION_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_USER_LOCATION_FAIL,
        payload: error,
      });

      if (error.response && error.response.data) {
        dispatch(setAlert(error.response.data.message, 'danger'));
      }
      return error;
    });
};

export const GET_ALL_LOCATION_BEGIN = 'GET_ALL_LOCATION_BEGIN';
export const GET_ALL_LOCATION_SUCCESS = 'GET_ALL_LOCATION_SUCCESS';
export const GET_ALL_LOCATION_FAIL = 'GET_ALL_LOCATION_FAIL';

export const GET_ALL_USER_LOCATION_BEGIN = 'GET_ALL_USER_LOCATION_BEGIN';
export const GET_ALL_USER_LOCATION_SUCCESS = 'GET_ALL_USER_LOCATION_SUCCESS';
export const GET_ALL_USER_LOCATION_FAIL = 'GET_ALL_USER_LOCATION_FAIL';
