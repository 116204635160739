import throttle from "lodash.throttle";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducer";

const middlewares = [thunk];

const composeEnhancers = (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("seoaudit");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("seoaudit", serializedState);
  } catch (e) {
    // I'm ignore this
  }
}

const persistedState = loadFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;

store.subscribe(
  throttle(() => {
    saveToLocalStorage(store.getState());
  }, 1000)
);

// import rootReducer from '../reducer'
// import {createStore, applyMiddleware, compose} from 'redux'
// import thunk from 'redux-thunk'

// const initialState = {}
// const middlewares=[thunk]
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store=createStore(
//     rootReducer,
//     initialState,
//     composeEnhancers(
//       applyMiddleware(...middlewares)
//     )
//   )

// export default store

// import { promiseMiddleware, localStorageMiddleware } from './middleware';
