import React from "react";

const LoaderSvg = () => (
  <span id="loader">
    <svg
      width="40px"
      height="40px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="lds-rolling"
      style={{
        width: "15px",
        height: "15px",
      }}
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        ng-attr-stroke="{{config.color}}"
        ng-attr-stroke-width="{{config.width}}"
        ng-attr-r="{{config.radius}}"
        ng-attr-stroke-dasharray="{{config.dasharray}}"
        stroke="#ffffff"
        strokeWidth={15}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(264.595 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </span>
);

export default LoaderSvg;
