import { Box, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import { borderTypeOptions } from "./selectOptions";

const CloseBtn = ({ setValues, values, changingObject, setChangingObject }) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(values.close_btn.display === 1);

  const { close_btn } = values;

  const handleChanges = useCallback((event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      close_btn: {
        ...prevValues.close_btn,
        [name]: parseInt(value),
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      close_btn: {
        ...prevValues.close_btn,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const handleSwitch = useCallback((e) => {
    setChecked(e.target.checked);
    setValues((prevValues) => ({
      ...prevValues,
      close_btn: {
        ...prevValues.close_btn,
        display: e.target.checked === true ? 1 : 0,
      },
    }));
    setChangingObject((prevValues) => ({
      ...prevValues,
      close_btn: {
        ...prevValues.close_btn,
        display: e.target.checked === true ? 1 : 0,
      },
    }));
  });

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevState) => ({
      ...prevState,
      close_btn: {
        ...prevState.close_btn,
        [name]: data.value,
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      close_btn: {
        ...prevState.close_btn,
        [name]: data.value,
      },
    }));
  }, []);

  const fontWeightOptionss = [
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 300, label: "300" },
    { value: 400, label: "400" },
    { value: 500, label: "500" },
  ];

  const { t } = useTranslation();

  return (
    <Box sx={{ flexWrap: "wrap" }} className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.icon")}</Box>
        <Box>
          <Typography component="label">
            {t("createWidget.formStyle.background")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="close_btn"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="icon_color"
              type="none"
              nested="close_btn"
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={close_btn.border_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={close_btn.border_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={close_btn.border_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={close_btn.border_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              type="none"
              nested="close_btn"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={close_btn.border_radius}
              onChange={handleChanges}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={borderTypeOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === close_btn.border_type
              )}
              placeholder={t("common.select")}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="margin_top"
              value={close_btn.margin_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={close_btn.margin_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={close_btn.margin_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={close_btn.margin_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={close_btn.padding_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={close_btn.padding_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={close_btn.padding_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={close_btn.padding_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.font")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.size")}
          </Typography>
          <div>
            <input
              name="text_size"
              value={close_btn.text_size}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.weight")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={fontWeightOptionss}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptionss.find(
                ({ value }) => value === close_btn.font_weight
              )}
              placeholder={t("common.select")}
            />
          </div>
        </Box>
        <div className={classes.switch}>
          <label>{t("createWidget.formStyle.showCloseButton")}</label>
          <Switch
            name="display"
            checked={checked}
            onChange={handleSwitch}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
    </Box>
  );
};

export default React.memo(CloseBtn);
