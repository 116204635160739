import { ButtonLoader, KeywordTooltip, ToolTip } from "@components";
import InfoTooltip from "@components/InfoTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Zoom } from "@mui/material";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as adminSiteListActions from "../../../../appRedux/action/adminSiteListActions";
import { deleteEngine } from "../../../../appRedux/action/engineAction";
import {
  getEngineRankData,
  getProjectRankData,
} from "../../../../appRedux/action/RankTopDataAction";
import "../../../../assets/sass/FlagButton.scss";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { project_id, se_id } = props;
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  async function deleteSearchengine() {
    setLoading(true);

    await dispatch(deleteEngine(project_id, se_id));

    await dispatch(adminSiteListActions.getAdminSiteListData());

    setLoading(false);

    handleClose();
  }

  return (
    <span>
      <ToolTip title={`${t("common.deleteSearchEngine")}`}>
        <i
          aria-hidden="true"
          onClick={handleShow}
          className="fas fa-trash-alt"
        />
      </ToolTip>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5> {t("modalMessage.one")} </h5>
        </Modal.Header>
        <Modal.Body>
          <small>{t("modalMessage.two")}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button className="seo_btn danger" onClick={deleteSearchengine}>
            {t("modalMessage.btnOne")} {loading && <ButtonLoader />}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

const GroupDeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { groupId } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  async function deleteGroupData() {
    setLoading(true);

    await dispatch(adminSiteListActions.deleteAdminSiteListData(groupId));

    await dispatch(adminSiteListActions.getAdminSiteListData());

    setLoading(false);

    handleClose();
  }

  return (
    <span>
      <button
        type="button"
        className=" seo_btn btn_red btn_xs"
        onClick={handleShow}
      >
        {t("common.deleteSite")}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5> {t("modalMessage.one")} </h5>
        </Modal.Header>
        <Modal.Body>
          <small>{t("modalMessage.two")}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button className="seo_btn danger loader" onClick={deleteGroupData}>
            {t("modalMessage.btnOne")} {loading && <ButtonLoader />}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

function SubBodyWebsite({ site, props }) {
  const [collapsed, setCollapsed] = useState("");
  function handleCollapse() {
    if (collapsed === "none") {
      setCollapsed("");
    } else setCollapsed("none");
  }
  const { dataRoot } = props;
  const getTopRankData = useSelector(
    (topRankData) => topRankData.rankData.rankData
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const validateData = {
    topFiveData: (getTopRankData.projectData || {}).topFiveData || [],
    topTenData: (getTopRankData.projectData || {}).topTenData || [],
    topThirteenData: (getTopRankData.projectData || {}).topThirTeenData || [],
  };

  const validateEngineData = {
    topFiveData: (getTopRankData.searchEngineData || {}).topFiveData || [],
    topTenData: (getTopRankData.searchEngineData || {}).topTenData || [],
    topThirteenData:
      (getTopRankData.searchEngineData || {}).topThirTeenData || [],
  };

  const [projectValuLoading, setProjectValueLoading] = useState({
    load: false,
  });
  async function hoverHandler(id, topValue) {
    await setProjectValueLoading({
      load: true,
    });
    if (
      topValue === "5" &&
      id === site.id &&
      validateData.topFiveData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }

    if (
      topValue === "10" &&
      id === site.id &&
      validateData.topTenData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }

    if (
      topValue === "30" &&
      id === site.id &&
      validateData.topThirteenData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }
    await setProjectValueLoading({
      load: false,
    });
  }

  const [seValuLoading, setSeValueLoading] = useState({
    load: false,
  });

  async function hoverEngineData(id, topValue) {
    await setSeValueLoading({
      load: true,
    });
    if (topValue === "5") {
      await dispatch(getEngineRankData(id, topValue));
    }

    if (topValue === "10") {
      await dispatch(getEngineRankData(id, topValue));
    }

    if (topValue === "30") {
      await dispatch(getEngineRankData(id, topValue));
    }
    setSeValueLoading({
      load: false,
    });
  }

  return (
    <>
      <div className="list-body-full " key={site.id}>
        <div className="sub-body-main top dashboard_table_mob">
          <div
            className="sub-body dashboard_table_mob"
            aria-hidden="true"
            onClick={handleCollapse}
          >
            <div className="sub-data dashboard_table_mob">
              <div className="site-name subbodywebsite_data">
                <span className="site_title_1">
                  <strong>{site.title}</strong>
                  <i
                    className={
                      collapsed === "none"
                        ? "fas fa-chevron-right"
                        : "fas fa-chevron-down"
                    }
                  />

                  <tbody className="dashboard_table_mob homeaudit">
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            Top 5 :
                            <ToolTip
                              title={
                                <KeywordTooltip
                                  loading={projectValuLoading}
                                  data={validateData.topFiveData}
                                />
                              }
                            >
                              <span
                                onMouseEnter={() => {
                                  hoverHandler(site.id, "5");
                                }}
                              />
                            </ToolTip>
                          </span>
                          {site.top[0]}
                        </div>
                      </td>
                      <br />
                    </tr>
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            Top 10 :
                            <ToolTip
                              title={
                                <KeywordTooltip
                                  loading={projectValuLoading}
                                  data={validateData.topTenData}
                                />
                              }
                            >
                              <span
                                onMouseEnter={() => {
                                  hoverHandler(site.id, "10");
                                }}
                              />
                            </ToolTip>
                          </span>
                          {site.top[1]}
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            Top 30 :
                            <ToolTip
                              loading={seValuLoading}
                              title={
                                <KeywordTooltip
                                  loading={projectValuLoading}
                                  data={validateData.topThirteenData}
                                />
                              }
                            >
                              <span
                                onMouseEnter={() => {
                                  hoverHandler(site.id, "30");
                                }}
                              />
                            </ToolTip>
                          </span>

                          {site.top[2]}
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span"> {t("common.keywords")}:</span>

                          <span>
                            {`${site.keyword_count} ${t("common.keywords")} / ${
                              site.searc_engines.length
                            } ${
                              site.searc_engines.length > 1
                                ? t("common.locations")
                                : t("common.location")
                            }`}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            AVG.POSITION:
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            {dataRoot.dataRoot.avg_position_date[0]}:
                          </span>
                          <span>{site.avg_position[0]}</span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span">
                            {" "}
                            {dataRoot.dataRoot.avg_position_date[1]}:
                          </span>
                          <span>{site.avg_position[1]}</span>
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td className="lead-info lead-margin">
                        <div
                          className="text table_data_condition"
                          style={{ fontWeigth: "Bold" }}
                        >
                          <span className="table_data_span"> ACTION </span>
                          <span className="green green_duplicate mr-2">
                            <ToolTip title={t("common.editProject")}>
                              <Link to={`/edit-project/${site.id}`}>
                                <i className="fas fa-cog fas-class " />
                              </Link>
                            </ToolTip>
                          </span>
                          <GroupDeleteModal groupId={site.id} />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <div
                        className="sub-body-main dashboard_table_des"
                        style={{ display: "center" }}
                      >
                        {site.searc_engines.map((searchEngine, subIndex) => (
                          <div className="sub-body" key={subIndex}>
                            <div className="sub-data">
                              <div
                                aria-hidden="true"
                                className="engine"
                                onClick={(e) => {
                                  e.preventDefault();
                                  props.selectProject(site.id, searchEngine.id);
                                }}
                              >
                                <span>
                                  <i className="fab fa-google" />
                                </span>
                                <strong>{searchEngine.title}</strong>

                                {searchEngine?.location && (
                                  <InfoTooltip title={searchEngine?.location}>
                                    <InfoIcon />
                                  </InfoTooltip>
                                )}
                              </div>
                            </div>
                            <div className="sub-data">
                              <div className="rank">
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topFiveData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverEngineData(searchEngine.id, "5");
                                    }}
                                  >
                                    {searchEngine.top[0]}
                                  </span>
                                </ToolTip>
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topTenData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverEngineData(searchEngine.id, "10");
                                    }}
                                  >
                                    {searchEngine.top[1]}
                                  </span>
                                </ToolTip>
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topThirteenData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverEngineData(searchEngine.id, "30");
                                    }}
                                  >
                                    {searchEngine.top[2]}
                                  </span>
                                </ToolTip>
                              </div>
                            </div>
                            <div className="sub-data">
                              <span>{searchEngine.keyword_count}</span>
                            </div>
                            <div className="sub-data">
                              {searchEngine.avg_position.map((avg, index) => (
                                <span key={index}>
                                  {index ? "" : " "} {avg}
                                </span>
                              ))}
                            </div>
                            <div className="sub-data actions sub-data-action-button">
                              <div className="t-option">
                                <ToolTip title={t("common.viewRanking")}>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.selectProject(
                                        site.id,
                                        searchEngine.id
                                      );
                                    }}
                                    className={`${searchEngine.title.toLowerCase()} flag__button green`}
                                  >
                                    <i />
                                  </button>
                                </ToolTip>
                                <span className="green green_duplicate">
                                  <ToolTip
                                    TransitionComponent={Zoom}
                                    placement="top"
                                    arrow={true}
                                    title={t("common.editSearchEngine")}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/edit-engines/${site.id}`,
                                        seId: searchEngine.id,
                                      }}
                                    >
                                      <i className="fas fa-cog" />
                                    </Link>
                                  </ToolTip>
                                </span>
                                <span className="red">
                                  <DeleteModal
                                    project_id={site.id}
                                    se_id={searchEngine.id}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </tr>
                    <hr />
                    {site.searc_engines.map((searchEngine, subIndex) => (
                      <>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                <div
                                  aria-hidden="true"
                                  className="engine"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.selectProject(
                                      site.id,
                                      searchEngine.id
                                    );
                                  }}
                                >
                                  <span>
                                    <i className="fab fa-google" />
                                  </span>
                                  <strong>{searchEngine.title}</strong>

                                  {searchEngine?.location && (
                                    <InfoTooltip title={searchEngine?.location}>
                                      <InfoIcon />
                                    </InfoTooltip>
                                  )}
                                </div>
                              </span>
                              {/* <span>{site.avg_position[1]}</span> */}
                            </div>
                          </td>
                        </tr>

                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                Top 5 :
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topFiveData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverHandler(searchEngine.id, "5");
                                    }}
                                  />
                                </ToolTip>
                              </span>
                              {searchEngine.top[0]}
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                Top 10 :
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topTenData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverHandler(searchEngine.id, "10");
                                    }}
                                  />
                                </ToolTip>
                              </span>
                              {searchEngine.top[1]}
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                Top 30 :
                                <ToolTip
                                  title={
                                    <KeywordTooltip
                                      loading={seValuLoading}
                                      data={validateEngineData.topTenData}
                                    />
                                  }
                                >
                                  <span
                                    onMouseEnter={() => {
                                      hoverHandler(searchEngine.id, "30");
                                    }}
                                  />
                                </ToolTip>
                              </span>
                              {searchEngine.top[2]}
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                {dataRoot.dataRoot.avg_position_date[0]}:
                              </span>
                              <span>{searchEngine.avg_position[0]}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                {dataRoot.dataRoot.avg_position_date[1]}:
                              </span>
                              <span>{searchEngine.avg_position[1]}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span">
                                {" "}
                                Keywords:
                              </span>

                              <span>
                                <span>{searchEngine.keyword_count}</span>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="lead-info lead-margin">
                            <div
                              className="text table_data_condition"
                              style={{ fontWeigth: "Bold" }}
                            >
                              <span className="table_data_span"> ACTION </span>
                              <ToolTip title={t("common.viewRanking")}>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.selectProject(
                                      site.id,
                                      searchEngine.id
                                    );
                                  }}
                                  className={`${searchEngine.title.toLowerCase()} flag__button green`}
                                >
                                  <i />
                                </button>
                              </ToolTip>
                              <span className="green green_duplicate">
                                <ToolTip
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  arrow={true}
                                  title={t("common.editSearchEngine")}
                                >
                                  <Link
                                    to={{
                                      pathname: `/edit-engines/${site.id}`,
                                      seId: searchEngine.id,
                                    }}
                                  >
                                    <i className="fas fa-cog fas-class" />
                                  </Link>
                                </ToolTip>
                              </span>
                              <span className="red">
                                <DeleteModal
                                  project_id={site.id}
                                  se_id={searchEngine.id}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="list-body-full dashboard_table_deskop_keyword_table "
        key={site.id}
      >
        <div className="sub-body-main top">
          <div className="sub-body" aria-hidden="true" onClick={handleCollapse}>
            <div className="sub-data">
              <div className="site-name ">
                <span>
                  <strong>{site.title}</strong>
                  <i
                    className={
                      collapsed === "none"
                        ? "fas fa-chevron-right"
                        : "fas fa-chevron-down"
                    }
                  />
                </span>
              </div>
            </div>
            <div className="sub-data">
              <div className="rank">
                <ToolTip
                  title={
                    <KeywordTooltip
                      loading={projectValuLoading}
                      data={validateData.topFiveData}
                    />
                  }
                >
                  <span
                    onMouseEnter={() => {
                      hoverHandler(site.id, "5");
                    }}
                  >
                    {site.top[0]}
                  </span>
                </ToolTip>
                <ToolTip
                  title={
                    <KeywordTooltip
                      loading={projectValuLoading}
                      data={validateData.topTenData}
                    />
                  }
                >
                  <span
                    onMouseEnter={() => {
                      hoverHandler(site.id, "10");
                    }}
                  >
                    {site.top[1]}
                  </span>
                </ToolTip>
                <ToolTip
                  loading={seValuLoading}
                  title={
                    <KeywordTooltip
                      loading={projectValuLoading}
                      data={validateData.topThirteenData}
                    />
                  }
                >
                  <span
                    onMouseEnter={() => {
                      hoverHandler(site.id, "30");
                    }}
                  >
                    {site.top[2]}
                  </span>
                </ToolTip>
              </div>
            </div>
            <div className="sub-data">
              <div className="kwrd">
                <span>
                  {`${site.keyword_count} Keywords / ${
                    site.searc_engines.length
                  } ${
                    site.searc_engines.length > 1 ? "Locations" : "Location"
                  }`}
                </span>
              </div>
            </div>
            <div className="sub-data">
              <div className="position">
                {site.avg_position.map((avg, i) => (
                  <span key={i}>
                    {i ? "" : " "} {avg}
                  </span>
                ))}
              </div>
            </div>
            <div className="sub-data actions sub-body-button">
              <div className="t-option">
                <span className="green green_duplicate">
                  <ToolTip title={t("common.editProject")}>
                    <Link to={`/edit-project/${site.id}`}>
                      <i className="fas fa-cog" />
                    </Link>
                  </ToolTip>
                </span>
                <GroupDeleteModal groupId={site.id} />
              </div>
            </div>
          </div>
        </div>
        <div className="sub-body-main" style={{ display: "center" }}>
          {site.searc_engines.map((searchEngine, subIndex) => (
            <div className="sub-body" key={subIndex}>
              <div className="sub-data">
                <div
                  aria-hidden="true"
                  className="engine"
                  onClick={(e) => {
                    e.preventDefault();
                    props.selectProject(site.id, searchEngine.id);
                  }}
                >
                  <span>
                    <i className="fab fa-google" />
                  </span>
                  <strong>{searchEngine.title}</strong>

                  {searchEngine?.location && (
                    <InfoTooltip title={searchEngine?.location}>
                      <InfoIcon />
                    </InfoTooltip>
                  )}
                </div>
              </div>
              <div className="sub-data">
                <div className="rank">
                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topFiveData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "5");
                      }}
                    >
                      {searchEngine.top[0]}
                    </span>
                  </ToolTip>
                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topTenData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "10");
                      }}
                    >
                      {searchEngine.top[1]}
                    </span>
                  </ToolTip>
                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topThirteenData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "30");
                      }}
                    >
                      {searchEngine.top[2]}
                    </span>
                  </ToolTip>
                </div>
              </div>
              <div className="sub-data">
                <span>{searchEngine.keyword_count}</span>
              </div>
              <div className="sub-data">
                {searchEngine.avg_position.map((avg, index) => (
                  <span key={index}>
                    {index ? "" : " "} {avg}
                  </span>
                ))}
              </div>
              <div className="sub-data actions sub-data-action-button">
                <div className="t-option">
                  <ToolTip title={t("common.viewRanking")}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        props.selectProject(site.id, searchEngine.id);
                      }}
                      className={`${searchEngine.title.toLowerCase()} flag__button green`}
                    >
                      <i />
                    </button>
                  </ToolTip>
                  <span className="green green_duplicate">
                    <ToolTip
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow={true}
                      title={t("common.editSearchEngine")}
                    >
                      <Link
                        to={{
                          pathname: `/edit-engines/${site.id}`,
                          seId: searchEngine.id,
                        }}
                      >
                        <i className="fas fa-cog fas-class" />
                      </Link>
                    </ToolTip>
                  </span>
                  <span className="red">
                    <DeleteModal project_id={site.id} se_id={searchEngine.id} />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default SubBodyWebsite;
