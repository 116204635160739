import { ButtonLoader, KeywordTooltip, ToolTip } from "@components";
import { getProjectRankData } from "appRedux/action/RankTopDataAction";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as adminSiteListActions from "../../../appRedux/action/adminSiteListActions";
import SearchEngineGroupedsite from "./SearchEngineGroupedsite";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { siteId } = props;

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  async function deleteSiteData() {
    setLoading(true);

    await dispatch(adminSiteListActions.deleteAdminSiteListData(siteId));

    await dispatch(adminSiteListActions.getAdminSiteListData());

    setLoading(false);

    handleClose();
  }

  return (
    <span>
      <button
        type="button"
        className=" seo_btn btn_red btn_xs"
        onClick={handleShow}
      >
        {t("common.deleteSite")}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>{t("modalMessage.one")}</h5>
        </Modal.Header>
        <Modal.Body>
          <small>{t("modalMessage.two")}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button className="seo_btn danger" onClick={deleteSiteData}>
            {t("modalMessage.btnOne")} {loading && <ButtonLoader />}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

const SubBodySE = ({ site, selectProject, props }) => {
  const [collapsedSE, setCollapsedSE] = useState("");
  function handleCollapseSE() {
    if (collapsedSE === "none") {
      setCollapsedSE("");
    } else setCollapsedSE("none");
  }

  const { t } = useTranslation();

  const getTopRankData = useSelector(
    (topRankData) => topRankData.rankData.rankData
  );
  const { dataRoot } = props;

  const dispatch = useDispatch();

  const validateData = {
    topFiveData: (getTopRankData.projectData || {}).topFiveData || [],
    topTenData: (getTopRankData.projectData || {}).topTenData || [],
    topThirteenData: (getTopRankData.projectData || {}).topThirTeenData || [],
  };

  // Hook
  const [projectValuLoading, setProjectValueLoading] = useState({
    load: false,
  });
  async function hoverHandler(id, topValue) {
    await setProjectValueLoading({
      load: true,
    });
    if (
      topValue === "5" &&
      id === site.id &&
      validateData.topFiveData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }

    if (
      topValue === "10" &&
      id === site.id &&
      validateData.topTenData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }

    if (
      topValue === "30" &&
      id === site.id &&
      validateData.topThirteenData.length === 0
    ) {
      await dispatch(getProjectRankData(id, topValue));
    }
    await setProjectValueLoading({
      load: false,
    });
  }

  return (
    <>
      <div className="sub-body-main dashboard_table_mob">
        <div
          aria-hidden="true"
          className="sub-body web__site"
          onClick={handleCollapseSE}
        >
          <div className="sub-data">
            <div className="engine">
              <i className="fas fa-globe" />
              <strong className="site__name">{site.title}</strong>
              <span>
                <i
                  className={
                    collapsedSE === "none"
                      ? "fas fa-chevron-right"
                      : "fas fa-chevron-down"
                  }
                />
              </span>
            </div>
          </div>
          <div
            className="
          "
          >
            <div className="">
              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> Top 5 :</span>

                    <ToolTip
                      title={
                        <KeywordTooltip
                          loading={projectValuLoading}
                          data={validateData.topFiveData}
                        />
                      }
                    >
                      <span
                        onMouseEnter={() => {
                          hoverHandler(site.id, "5");
                        }}
                      >
                        {site.top[0]}
                      </span>
                    </ToolTip>
                    {/* {site.top[0]} */}
                  </div>
                </td>
              </tr>

              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> Top 10 :</span>

                    <ToolTip
                      title={
                        <KeywordTooltip
                          loading={projectValuLoading}
                          data={validateData.topTenData}
                        />
                      }
                    >
                      <span
                        onMouseEnter={() => {
                          hoverHandler(site.id, "10");
                        }}
                      >
                        {site.top[1]}
                      </span>
                    </ToolTip>
                    {/* {site.top[0]} */}
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> Top 30 :</span>

                    <ToolTip
                      title={
                        <KeywordTooltip
                          loading={projectValuLoading}
                          data={validateData.topThirteenData}
                        />
                      }
                    >
                      <span
                        onMouseEnter={() => {
                          hoverHandler(site.id, "30");
                        }}
                      >
                        {site.top[2]}
                      </span>
                    </ToolTip>
                    {/* {site.top[0]} */}
                  </div>
                </td>
              </tr>

              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> AVG.POSITION:</span>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span">
                      {" "}
                      {dataRoot && dataRoot.dataRoot.avg_position_date[0]}:
                    </span>
                    <span>{site.avg_position[0]}</span>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span">
                      {" "}
                      {dataRoot && dataRoot.dataRoot.avg_position_date[1]}:
                    </span>
                    <span>{site.avg_position[1]}</span>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> Keywords:</span>

                    <span>
                      {`${site.keyword_count} Keywords / ${
                        site.searc_engines.length
                      } ${
                        site.searc_engines.length > 1 ? "Locations" : "Location"
                      }`}
                    </span>
                  </div>
                </td>
              </tr>

              <tr className="">
                <td className="lead-info lead-margin">
                  <div
                    className="text table_data_condition"
                    style={{ fontWeigth: "Bold" }}
                  >
                    <span className="table_data_span"> ACTION </span>
                    <span className="green green_duplicate mr-4">
                      <ToolTip title={t("common.editProject")}>
                        <Link to={`/edit-project/${site.id}`}>
                          <i className="fas fa-cog fas-class mr-2" />
                        </Link>
                      </ToolTip>
                    </span>
                    <DeleteModal siteId={site.id} />
                  </div>
                </td>
              </tr>
            </div>
          </div>
        </div>

        <div className="sub-body-main " style={{ display: collapsedSE }}>
          {site.searc_engines.map((searchEngine, index) => (
            <SearchEngineGroupedsite
              searchEngine={searchEngine}
              site_id={site.id}
              selectProject={selectProject}
              key={searchEngine.id}
              dataRoot={dataRoot}
              index={index}
              props={props}
            />
          ))}
        </div>
      </div>
      <div className="sub-body-main dashboard_table_des ">
        <div
          aria-hidden="true"
          className="sub-body web__site"
          onClick={handleCollapseSE}
        >
          <div className="sub-data">
            <div className="engine">
              <i className="fas fa-globe" />
              <strong className="site__name">{site.title}</strong>
              <span>
                <i
                  className={
                    collapsedSE === "none"
                      ? "fas fa-chevron-right"
                      : "fas fa-chevron-down"
                  }
                />
              </span>
            </div>
          </div>
          <div className="sub-data">
            <div className="rank">
              <ToolTip
                title={
                  <KeywordTooltip
                    loading={projectValuLoading}
                    data={validateData.topFiveData}
                  />
                }
              >
                <span
                  onMouseEnter={() => {
                    hoverHandler(site.id, "5");
                  }}
                >
                  {site.top[0]}
                </span>
              </ToolTip>
              <ToolTip
                title={
                  <KeywordTooltip
                    loading={projectValuLoading}
                    data={validateData.topTenData}
                  />
                }
              >
                <span
                  onMouseEnter={() => {
                    hoverHandler(site.id, "10");
                  }}
                >
                  {site.top[1]}
                </span>
              </ToolTip>
              <ToolTip
                title={
                  <KeywordTooltip
                    loading={projectValuLoading}
                    data={validateData.topThirteenData}
                  />
                }
              >
                <span
                  onMouseEnter={() => {
                    hoverHandler(site.id, "30");
                  }}
                >
                  {site.top[2]}
                </span>
              </ToolTip>
            </div>
          </div>
          <div className="sub-data">
            <span>
              {`${site.keyword_count} ${t("common.keywords")} / ${site.searc_engines.length} ${
                site.searc_engines.length > 1 ? t("common.location") : t("common.locations")
              }`}
            </span>
          </div>
          <div className="sub-data">
            {site.avg_position.map((avg, index) => (
              <span key={index}>
                {index ? "" : " "} {avg}
              </span>
            ))}
          </div>
          <div className="sub-data actions">
            <div className="t-option">
              <span className="green">
                <ToolTip title={t("common.editProject")}>
                  <Link to={`/edit-project/${site.id}`}>
                    <i className="fas fa-cog" />
                  </Link>
                </ToolTip>
              </span>
              <DeleteModal siteId={site.id} />
            </div>
          </div>
        </div>

        <div className="sub-body-main" style={{ display: collapsedSE }}>
          {site.searc_engines.map((searchEngine) => (
            <SearchEngineGroupedsite
              searchEngine={searchEngine}
              site_id={site.id}
              selectProject={selectProject}
              key={searchEngine.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SubBodySE;
