import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Auth from "../../../../../modules/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "1rem 0 1rem 0",

      "& ul": {
        "& li": {
          padding: "0",
          transition: ".4s ease-in-out",
          "&:hover": {
            backgroundColor: theme.palette.gray.main,
          },
          "& a": {
            fontWeight: "500",
            padding: ".6rem 1.8rem",
            display: "block",
            fontSize: ".987rem",
          },
        },
      },
    },
  },
  button: {
    "&.MuiButtonBase-root": {
      padding: "2px 0",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function HSettings() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <Fragment>
          <Button
            style={{ marginLeft: "15px", padding: 0 }}
            className="classes.button settingLi"
            {...bindTrigger(popupState)}
          >
            <i className="fas fa-cog" />
          </Button>
          <Popover
            className={classes.root}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            elevation={4}
          >
            <ul>
              <li>
                <Link to="/my-profile">{t("header.options.1")}</Link>
              </li>
              <li>
                <Link to="manage-subscription">{t("header.options.2")}</Link>
              </li>
              <li>
                <Link to="/setting#smtp-setting">{t("common.setting")}</Link>
              </li>
              <li>
                <a
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    // const randomNumber = Math.floor(
                    //   Math.random() * 100000000000
                    // );
                    window.location.href = "/?logout=success";
                  }}
                >
                  {t("header.options.3")}
                </a>
              </li>
            </ul>
          </Popover>
        </Fragment>
      )}
    </PopupState>
  );
}
export default HSettings;
