import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const GET_LEADS_BEGIN = "GET_LEADS_BEGIN";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAIL = "GET_LEADS_FAIL";

export const GET_LEAD_REPORT_BEGIN = "GET_LEAD_REPORT_BEGIN";
export const GET_LEAD_REPORT_SUCCESS = "GET_LEAD_REPORT_SUCCESS";
export const GET_LEAD_REPORT_FAIL = "GET_LEAD_REPORT_FAIL";

export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL";

export const GET_DELETED_LEADS_BEGIN = "GET_DELETED_LEADS_BEGIN";
export const GET_DELETED_LEADS_SUCCESS = "GET_DELETED_LEADS_SUCCESS";
export const GET_DELETED_LEADS_FAIL = "GET_DELETED_LEADS_FAIL";

export const getLeadsList = (pageNumber) => (dispatch) => {
  dispatch({ type: GET_LEADS_BEGIN });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/lead/list/?type=lead&page_item=20&page=${pageNumber}`,
  })
    .then((res) => {
      dispatch({ type: GET_LEADS_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: GET_LEADS_FAIL, payload: err });
    });
};

export const getLeadReport = (lead_id) => (dispatch) => {
  dispatch({
    type: GET_LEAD_REPORT_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/lead/report/${lead_id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_LEAD_REPORT_SUCCESS,
        payload: res,
        audit_id: res.data.data.audit_id,
        content: res.data.data.content,
        share_key: res.data.data.share_key,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_LEAD_REPORT_FAIL,
        payload: { error },
      });
      return error;
    });
};

// Delete Lead
export const deleteLead = (formData) => async (dispatch) => {
  serverCall({
    method: "DELETE",
    url: `/api/v1/lead/delete/`,
    data: formData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_LEAD,
          payload: res,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: DELETE_LEAD_FAIL,
          payload: { res },
        });
          dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_LEAD_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const getDeletedLeads = (pageNumber) => (dispatch) => {
  dispatch({
    type: GET_DELETED_LEADS_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/lead/list/?type=lead&status=deleted&page_item=20&page=${pageNumber}`,
  })
    .then((res) => {
      dispatch({
        type: GET_DELETED_LEADS_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_DELETED_LEADS_FAIL,
        payload: { error },
      });
      return error;
    });
};
