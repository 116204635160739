import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import { borderTypeOptions } from "./selectOptions";

const FormMain = ({
  values,
  setValues,
  handleStyleChange,
  setChangingObject,
  changingObject,
}) => {
  const classes = useStyles();

  const { form_style } = values;

  const [checked, setChecked] = useState(values?.form_style?.full_width === 1);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      form_style: {
        ...prevValues.form_style,
        [name]: data.value,
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      form_style: {
        ...prevValues.form_style,
        [name]: data.value,
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Box className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.background")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="form_style"
            />
          </div>
        </Box>
      </div>

      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={form_style.border_top}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={form_style.border_right}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={form_style.border_bottom}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={form_style.border_left}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              type="none"
              nested="form_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              options={borderTypeOptions}
              placeholder={t("common.select")}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === form_style.border_type
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={form_style.border_radius}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>

      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            Top
          </Typography>
          <div>
            <input
              name="margin_top"
              value={form_style.margin_top}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={form_style.margin_right}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={form_style.margin_bottom}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={form_style.margin_left}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={form_style.padding_top}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={form_style.padding_right}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={form_style.padding_bottom}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={form_style.padding_left}
              onChange={handleStyleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>

      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.width")}</Box>
        <Box>
          <FormControlLabel
            onChange={(e) => {
              setChecked(!checked);
              setValues((prevValues) => ({
                ...prevValues,
                form_style: {
                  ...prevValues.form_style,
                  full_width: e.target.checked === true ? 1 : 0,
                },
              }));
              setChangingObject((prevValues) => ({
                ...prevValues,
                form_style: {
                  ...prevValues.form_style,
                  full_width: e.target.checked === true ? 1 : 0,
                },
              }));
            }}
            control={<Checkbox checked={checked} color="primary" />}
            label={t("createWidget.formStyle.fullWidth")}
          />
        </Box>
        {!checked && (
          <Box>
            <Typography variant="body1" component="label">
              {t("createWidget.formStyle.maxWidth")}
            </Typography>
            <div>
              <input
                name="max_width"
                value={form_style.max_width}
                onChange={handleStyleChange}
                type="number"
              />
              <Typography variant="body1" component="small">
                px
              </Typography>
            </div>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default React.memo(FormMain);
