import { CustomButton, CustomLoadingBtn } from "@components";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import React, { Fragment, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "500",
    },

    "&.MuiDialog-paper": {
      textAlign: "center",
    },
  },

  openButton: {
    margin: "0",
    padding: "0",
    background: "none",
  },
}));

function CustomModal({
  data,
  groupId,
  submitGroupDelete,
  removeActiveCompetitor,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Handle Close function
  const handleClose = () => {
    setOpen(false);
  };

  const datas = {
    groupId,
    projectId: data.projectId,
  };

  // Handle Delete Function
  const handleDelete = async () => {
    await setLoading(true);

    await submitGroupDelete(datas);

    await setOpen(false);

    await setLoading(false);
  };

  return (
    <Fragment>
      <CustomButton
        label={data.label}
        color="secondary"
        size="small"
        onClick={handleClickOpen}
      />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
      >
        <DialogTitle id="responsive-dialog-title"> {data.title} </DialogTitle>
        <DialogContent>
          <DialogContentText>{data.des}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} color="success" label="Cancel" />
          <CustomLoadingBtn
            label={data.btnLabelThree}
            onClick={handleDelete}
            color="secondary"
            loadingPosition="center"
            type="submit"
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CustomModal;
