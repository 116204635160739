import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function CustomPagination() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination variant="text" color="primary" count={10} shape="rounded" />
    </div>
  );
}

export default CustomPagination;
