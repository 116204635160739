import {
  GET_BILLING_DATA_BEGIN,
  GET_BILLING_DATA_FAIL,
  GET_BILLING_DATA_SUCCESS,
  UPDATA_BILLING_DATA_BEGIN,
  UPDATA_BILLING_DATA_FAIL,
  UPDATA_BILLING_DATA_SUCCESS
} from "../../action/billing/billingAction";
import { LOGIN } from "../../types";

const initialState = {
  login: {
    data: {},
    isLogin: false,
  },
  billingData: {
    data: {},
    loading: true,
    error: null,
  },
};
const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        login: {
          data: payload,
          isLogin: true,
        },
      };
    case GET_BILLING_DATA_BEGIN:
      return {
        ...state,
        billingData: {
          error: null,
          loading: true,
        },
      };
    case GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingData: {
          data: payload,
          loading: false,
        },
      };
    case GET_BILLING_DATA_FAIL:
      return {
        ...state,
        billingData: {
          error: payload.error.response,
          loading: false,
        },
      };
    case UPDATA_BILLING_DATA_BEGIN:
      return {
        ...state,
        billingData: {
          data: payload,
          loading: true,
        },
      };
    case UPDATA_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingData: {
          data: payload,
          loading: false,
        },
      };
    case UPDATA_BILLING_DATA_FAIL:
      return {
        ...state,
        billingData: {
          error: payload.error.response,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
