import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import RequiredLabel from "../RequiredLabel";

const WebsiteUrl = ({
  wform_url,
  wform_keyword,
}) => {
  const useYellowBoxStyles = makeStyles(() => ({
    root: {
        display: "inline-block",
        color: "#fff",
        background: "#f08322",
        borderRadius: "2px",
        lineHeight: "1",
        padding: "3px 4px",
    },
  }));
  const classesYellowBox = useYellowBoxStyles();

  const { t } = useTranslation();
  console.log(wform_url.advance_audit);
  return (
    <Box className="divider ">
      <p>
      <strong>{t("createWidget.sample_pdf_text")}: </strong><br/>
        <a href="https://seoaudit.software/pdf/technical-seo-audit-sample-report.pdf" target="_blank" className="text-blue">{t("createWidget.sample_name_onpage")} <i class="fas fa-external-link-alt"></i> {(wform_url.advance_audit !==1 && wform_keyword.show_field !== 0) && (<span className={classesYellowBox.root}>Selected</span>)}</a><br/>
        <a href="https://seoaudit.software/pdf/webpage-audit-report.pdf" target="_blank" className="text-blue">{t("createWidget.sample_name_webpage")} <i class="fas fa-external-link-alt"></i> {(wform_url.advance_audit !==1 && wform_keyword.show_field === 0) && (<span className={classesYellowBox.root}>Selected</span>)}</a><br/>
        <a href="https://seoaudit.software/pdf/advance-seo-audit-sample-report.pdf" target="_blank" className="text-blue">{t("createWidget.sample_name_advance_onpage")} <i class="fas fa-external-link-alt"></i> {(wform_url.advance_audit ===1 && wform_keyword.show_field !== 0) && (<span className={classesYellowBox.root}>Selected</span>)}</a><br/>
        <a href="https://seoaudit.software/pdf/advanced-webpage-audit-report.pdf" target="_blank" className="text-blue">{t("createWidget.sample_name_advance_webpage")} <i class="fas fa-external-link-alt"></i> {(wform_url.advance_audit ===1 && wform_keyword.show_field === 0) && (<span className={classesYellowBox.root}>Selected</span>)}</a><br/>
      </p>
    </Box>
  );
};

export default WebsiteUrl;
