// class Auth {
//     constructor() {
//       this.user_token = JSON.parse(localStorage.getItem('auth'))||{}
//     }
//     getToken() {
//       return this.user_token.token
//     }
//     getUserId() {
//       return this.user_token.user_id
//     }
//     setUserToken(new_token) {
//       this.user_token = new_token
//       localStorage.setItem('auth', JSON.stringify(new_token))
//     }
//     logout() {
//       localStorage.removeItem('auth')
//     }
//   }
// export default new Auth()

class Auth {
  constructor() {
    this.data = JSON.parse(localStorage.getItem("auth")) || {};
    this.loginWindow = "";
    this.loginWindowTimer = "";
    this.redirectTo = "/";
    this.online_domain = "api.seoaudit.software/api";
    this.referer = "&REF=0-0";
    this.first_page = "";
    this.source = "";
    this.group = "";
    this.kw = "";
    this.tg = "";
  }

  getToken() {
    return this.data?.key || this.data?.auth_token;
  }

  getUserId() {
    return this.user_token.user_id;
  }

  getUserEmail() {
    return this.data.user_email;
  }

  setUserToken(new_token) {
    this.data = new_token;
    localStorage.setItem("auth", JSON.stringify(new_token));
  }

  logout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("seoaudit");
  }

  // ie_ver() {
  //   let e = 0;
  //   const a = /MSIE (\d+\.\d+);/.test(navigator.userAgent);
  //   const t = !!navigator.userAgent.match(/Trident\/7.0/);
  //   const n = navigator.userAgent.indexOf("rv:11.0");
  //   return (
  //     a && (e = Number(RegExp.$1)),
  //     navigator.appVersion.indexOf("MSIE 10") != -1 && (e = 10),
  //     t && n != -1 && (e = 11),
  //     e
  //   );
  // }

  // openSocialAuthPopup(e) {
  //     var a = 700;
  //     var t = 500;
  //     var n = (window.innerWidth - a) / 2;
  //     var l = (window.innerHeight - t) / 2;
  //     const r = document.getElementById("keep");
  //   if (this.ie_ver() > 0) {
  //     window.location.replace(
  //       `${e}&sign_up_url=${encodeURIComponent(window.location.href)}`,
  //       "LoginWindow",
  //       `location=1,scrollbars=1,width=${a},height=${t},left=${n},top=${l}`
  //     );
  //     } else {
  //     if (r && r.checked) (e += "&keep_auth=1");

  //     window.open(
  //       `${e}&sign_up_url=${encodeURIComponent(window.location.href)}`,
  //       "LoginWindow",
  //       `location=1,scrollbars=1,width=${a},height=${t},left=${n},top=${l}`
  //     );

  //     window.addEventListener("message", function (e) {
  //       if (typeof e.data === "object"
  //         && e.data.type === "auth_redirect") { (window.location.href = e.data.redirect ? e.data.redirect : this.redirectTo); }
  //     });
  //   }
  // }

  // createSocialAuthUrl(e, a) {
  //   let redirect_str = "";
  //   if (window.location.pathname.toString().includes("/audit-report/temp/")) {
  //     // console.log('working')
  //     const split_path = window.location.pathname.split("/");
  //     const temp_key = split_path[split_path.length - 1];
  //     const redirect_url_str = `${window.location.origin}/temp-audit-save/${temp_key}`;
  //     // console.log('')
  //     redirect_str = `redirect_to=${redirect_url_str}`;
  //   } else {
  //     redirect_str = `redirect_to=${window.location.href}`;
  //   }
  //   console.log(redirect_str);
  //   console.log(btoa(redirect_str));
  //   return (
  //     a || (a = "en"),
  //     `${this.online_domain}/wp-login.php?apsl_login_id=${e}_login&s=${encodeURIComponent(
  //       this.source
  //     )}&group=${encodeURIComponent(this.group)}&kw=${encodeURIComponent(
  //       this.kw
  //     )}&tg=${encodeURIComponent(this.tg)}&referer=${encodeURIComponent(
  //       this.referer
  //     )}&first_page=${encodeURIComponent(this.first_page)}&lang=${encodeURIComponent(
  //       a
  //     )}&state=${btoa(redirect_str)}`
  //   );
  // }

  // socialLogin(e, a) {
  //   console.log(this.createSocialAuthUrl(e, a));
  //   this.openSocialAuthPopup(this.createSocialAuthUrl(e, a));
  // }
}
export default new Auth();
