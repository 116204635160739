import { Box, Grid, Paper, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SetServerUrl from "../../../modules/URL";
import GeneralForm from "../GeneralForm";
import ReportActionForm from "../GeneralForm/report-action";
import InputLabel from "../InputLabel";
import LabelTooltip from "../LabelTooltip";
import PreviewModal from "../PreviewModal";
import * as FormType from "../widget-components/formtype";
import ThemeType from "./ThemeType";
import Select from "react-select";

const WidgetType = (props) => {
  const {
    values,
    setValues,
    handleRadioChange,
    themeData,
    selectedForm,
    handleChange,
    errors,
    selectedWt,
    handleRadioTheme,
    typeofTheme,
    valuesWidgetPosition,
    setErrors,
    handleChangeSelect,
    handleChangeAction,
    someEditedValue,
    setSomeEditedValue,
    handleChangePopup,
  } = props;

  const { all_theme = [] } = themeData.data;

  const { t } = useTranslation();


  var showLang = [
  ];
  if(values.available_lang){
    values.available_lang.forEach(function(item, index){
      showLang.push({ value: item[0], label: item[1]});
    });
  }

  // Theme preview and thumbnail image
  const [preImage, setPreImage] = useState();

  const setPreviewImage = useCallback(() => {
    setPreImage(
      `${SetServerUrl}/files/widget/v3/preview-image/${values.active_theme_type.toLowerCase()}-${
        values.active_theme
      }-${values.widget_position}.png`
    );
  }, [values.active_theme_type, values.active_theme, values.widget_position]);

  useEffect(() => {
    if (values) {
      setPreviewImage();
    }
  }, [values.active_theme_type, values.active_theme, values.widget_position]);

  const handlepChange = useCallback((data) => {
    setValues((prevValues) => ({
      ...prevValues,
      widget_position: data.value,
    }));
  }, []);

  const [widgetPrevParam, setWidgetPrevParam] = useState("");

  useEffect(() => {
    if (values.widget_type === "2" || values.widget_type === 2) {
      if (values.widget_position === "right") {
        setWidgetPrevParam("https://seoaudit.software/one-or-two-column-form-bottom-right/");
      } else if (values.widget_position === "center") {
        setWidgetPrevParam("https://seoaudit.software/one-or-two-column-form-center-page/");
      } else {
        setWidgetPrevParam("https://seoaudit.software/free-seo-analysis-report/");
      }
    } else if (values.widget_type === "1" || values.widget_type === 1) {
      if (values.active_theme === "theme_1") {
        setWidgetPrevParam("https://seoaudit.software/seo/");
      } else if (values.active_theme === "theme_2") {
        setWidgetPrevParam("https://seoaudit.software/seo-visibility-score/");
      } else if (values.active_theme === "in_one_row") {
        setWidgetPrevParam("https://seoaudit.software/one-row-fixed-form/");
      }
    } else if (values.widget_type === "3" || values.widget_type === 3) {
      if (values.widget_position === "bottom") {
        setWidgetPrevParam("https://seoaudit.software/bottom-slim-form-one-row/");
      } else {
        setWidgetPrevParam("https://seoaudit.software/top-slim-form-one-row/");
      }
    } else if (values.widget_type === "4" || values.widget_type === 4) {
      if (values.active_theme === "theme_1") {
        setWidgetPrevParam("https://seoaudit.software/full-screen-one-column-takeover-form/");
      } else if (values.active_theme === "theme_2") {
        setWidgetPrevParam("https://seoaudit.software/full-screen-two-column-takeover-form/");
      } else if (values.active_theme === "in_one_row") {
        setWidgetPrevParam("https://seoaudit.software/full-screen-one-row-takeover-form/");
      }
    }
    else if (values.widget_type === "5" || values.widget_type === 5) {
      
      setWidgetPrevParam("https://seoaudit.software/two-step-forms/");
      
    }
  }, [values.widget_position, values.widget_type, values.active_theme]);

  const selectType = useMemo(
    () => (
      <FormControl component="fieldset">
        {/* <Typography sx={{ mt: "0 !important", mb: "1rem !important" }}
      variant="h4">Select Widget Type</Typography> */}
        <RadioGroup
          aria-label="widget_type"
          sx={{ flexDirection: "row" }}
          name="widget_type"
          onChange={handleRadioChange}
          value={values.widget_type}
        >
          {all_theme.map((item, index) => (
            <FormControlLabel
              key={item.id}
              value={index + 1}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    ),
    [values]
  );

  const chosenComponent = useCallback((selectedForms) => {
    switch (selectedForms) {
      case 1:
        return "FixedForm";
      case 2:
        return "PopupForm";
      case 3:
        return "SlimForm";
      case 4:
        return "TakeOverForm";
      default:
        return "FixedForm";
    }
  }, []);

  const selects = [
    { value: "right", label: `${t("createWidget.right")}` },
    { value: "left", label: `${t("createWidget.left")}` },
    { value: "center", label: `${t("createWidget.center")}` },
  ];

  const useSelectedForm = (selected) => {
    if (!selected) return <span />;
    const Form = FormType[chosenComponent(selected)];
    return (
      <Form
        handleChange={handleChange}
        handleChangePopup={handleChangePopup}
        handlepChange={handlepChange}
        selects={selects}
        values={values}
        setValues={setValues}
        someEditedValue={someEditedValue}
      />
    );
  };

  const { previewContent } = useSelector((state) => state.widgetPreview);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {/* <Paper elevation={4}>
        </Paper> */}
        <Paper sx={{ mt: "0rem" }} elevation={4} className="widget-form-margin">
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography
              sx={{ mb: "0 !important" }}
              className="title"
              variant="h5"
            >
              {t("createWidget.widgetType.1")}
            </Typography>
            <LabelTooltip title={t("createWidget.widgetType.2")} />
          </Box>
          <Box sx={{ marginBottom: "20px", }} className="select-widget-type">
            {selectType}
            <div className="form__error">{errors.widget_type_error}</div>
          </Box>
          <Box sx={{ mb: "1rem" }}>
            <Typography>
              {values.widget_type === 2
                ? t("createWidget.widgetType.3")
                : values.widget_type === 3
                ? t("createWidget.widgetType.4")
                : values.widget_type === 4
                ? t("createWidget.widgetType.5")
                : t("createWidget.widgetType.6")}
            </Typography>
          </Box>
          <ThemeType
            selectedWt={selectedWt}
            handleRadioTheme={handleRadioTheme}
            typeofTheme={typeofTheme}
            valuesWidgetPosition={valuesWidgetPosition}
          />
        </Paper>
        <Paper
          sx={{ mt: "1.5rem" }}
          className="widget-form-margin"
          elevation={4}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: "1rem" }}>
            <Typography
              sx={{ mb: "0 !important" }}
              className="title"
              variant="h5"
            >
              {t("createWidget.widgetType.0")}
            </Typography>
            <LabelTooltip title={t("createWidget.widgetType.7")} />
          </Box>
          <div className="input_wrapper ">
            <InputLabel
              title={t("createWidget.widgetType.8")}
              label={t("createWidget.widgetType.9")}
              required={true}
            />
            <input
              name="name"
              type="text"
              placeholder={t("createWidget.widgetType.10")}
              value={values.name}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === "") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    name_error: <span>{t("common.required")}</span>,
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    name_error: "",
                  }));
                }
              }}
            />
            <div className="form__error">{errors.name_error}</div>
          </div>
          <div className="input_wrapper ">
            <InputLabel
              title={t("createWidget.widgetType.38")}
              label={t("createWidget.form.labels.lang")}
            />
            <Box
            sx={{ mt: ".6rem" }}
            className="custom-selector custom-selector3  "
          >
            <Select
              options={showLang}
              defaultValue={showLang.find(
                ({ value }) => value === values.widget_lang
              )}
              name="widget_lang"
              onChange={handleChangeSelect}
            />
            </Box>
          </div>
          <div className="input_wrapper ">
            <InputLabel
              title={t("createWidget.widgetType.11")}
              label={t("createWidget.form.labels.1")}
              optional
            />
            <input
              name="widget_title"
              type="text"
              placeholder={t("createWidget.form.labels.16")}
              value={values.widget_title}
              onChange={handleChange}
            />
          </div>
          <Box>
            {selectedForm !== 3 ? (
              <div className="input_wrapper ">
                <InputLabel
                  title={t("createWidget.widgetType.12")}
                  label={t("createWidget.form.labels.2")}
                  optional
                />
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder={t("createWidget.form.labels.wdLabel")}
                  name="widget_description"
                  value={values.widget_description || ""}
                  onChange={handleChange}
                />
                <div className="form__error">{errors.description_error}</div>
              </div>
            ) : null}
          </Box>
          <Box>{useSelectedForm(selectedForm)}</Box>
        </Paper>
      </Grid>
      <Grid
        style={{ marginLeft: "0px !important" }}
        className="widgetImage"
        item
        xs={12}
        md={6}
      >
        <Paper elevation={4} className="widget-form-width widget-form-margin">
          <Typography className="title" variant="h5">
            {t("createWidget.widgetType.13")}
          </Typography>
          <Typography variant="body1">
            {t("createWidget.widgetType.14")}
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <img
              style={{ maxHeight: "450px" }}
              src={preImage}
              alt="Widget Preview"
            />
          </Box>
          <Box sx={{ mt: "1.5rem" }}>
            <PreviewModal widgetPrevParam={widgetPrevParam}>
              {previewContent && ReactHtmlParser(previewContent)}
            </PreviewModal>
          </Box>
        </Paper>
        
        <Paper className="homeaudit" sx={{ mt: "1.5rem" }} elevation={4}>
          <ReportActionForm
            handleChange={handleChange}
            handleChangeAction={handleChangeAction}
            values={values}
            errors={errors}
            setErrors={setErrors}
            someEditedValue={someEditedValue}
            setSomeEditedValue={setSomeEditedValue}
            setValues={setValues}
          />
        </Paper>
        <Paper className="homeaudit" sx={{ mt: "1.5rem" }} elevation={4}>
          <GeneralForm
            handleChange={handleChange}
            handleChangeSelect={handleChangeSelect}
            values={values}
            errors={errors}
            setErrors={setErrors}
            someEditedValue={someEditedValue}
            setSomeEditedValue={setSomeEditedValue}
            setValues={setValues}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default React.memo(WidgetType);
