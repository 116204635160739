import { ToolTip } from "@components";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStylesBootstrap = makeStyles(() => ({
  root: {
    "& svg": {
      color: "#9d9d9d",
      fontSize: "1.1rem"
    }
  }
}));

function InfoTooltip({ title, children, ...props }) {
  const classes = useStylesBootstrap();
  return (
    <div className={classes.root}>
      <ToolTip {...props} title={title}>
        {children}
      </ToolTip>
    </div>
  );
}

export default InfoTooltip;
