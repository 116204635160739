import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import React, { forwardRef } from "react";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormControlLabel-label": {
      fontSize: ".87rem",
      textTransform: "uppercase",
      fontWeight: "500",
    },
    "& .MuiSwitch-switchBase": {
      padding: "8px",
      top: "7px",
      left: "6px",
    },
    "& .MuiSwitch-root": {
      width: "65px",
      height: "45px",
    },
    "& .MuiSwitch-track": {
      borderRadius: "20px",
      opacity: "1",
      backgroundColor: "#dcdcdc",
    },
    "& .MuiSwitch-thumb": {
      color: "#fd552f",
      width: "15px",
      height: "15px",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        color: "#fff",
      },
    },
    "& .Mui-checked+.MuiSwitch-track": {
      opacity: "1 !important",
      backgroundColor: "#0dc236 !important",
    },
  },
}));

const CustomSwitch = forwardRef(({ ariaLabel, ...rest }, ref) => {
  console.log(rest);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Switch inputRef={ref} {...rest} />
    </div>
  );
});

export default CustomSwitch;
