import serverCall from "../../../modules/serverCall";

export const GET_OVERVIEW_DATA_BEGIN = "GET_OVERVIEW_DATA_BEGIN";
export const GET_OVERVIEW_DATA_SUCCESS = "GET_OVERVIEW_DATA_SUCCESS";
export const GET_OVERVIEW_DATA_FAIL = "GET_OVERVIEW_DATA_FAIL";

export const getOverviewPageData = (siteId, auditId) => (dispatch) => {
  dispatch({ type: GET_OVERVIEW_DATA_BEGIN });
  return serverCall({
    method: "GET",
    url: `/api/v1/website-audit/${siteId}/index/?id=${auditId}`,
  })
    .then((res) => {
      dispatch({
        type: GET_OVERVIEW_DATA_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_OVERVIEW_DATA_FAIL,
        payload: err,
      });
    });
};
