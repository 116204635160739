import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  getAccountData,
  sendSupportEmail,
} from "../../appRedux/action/userActions";
import useStyles from "./styles";
import Support from "./Support";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email field is required"),
  subject: yup
    .string()
    .required("Subject field is required")
    .min(3, "Minimum 3 character"),
  message: yup
    .string()
    .required("Message field is required")
    .min(10, "Minimum 10 character"),
});

const Drawers = (props) => {
  const classes = useStyles();
  const { data } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountData());
  }, [dispatch]);

  const users = useSelector(({ user }) => user.userData.data);

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (e) => {
    e.preventDefault();
    setOpenDrawer(!openDrawer);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      console.log('formData', data)
      console.log('validation result', await yupResolver(schema)(data, context, options))
      return yupResolver(schema)(data, context, options)
    },
    defaultValues: {
      email: users ? users.email : "",
    },
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (datas, e) => {
    await setLoading(true);
    const dataFormat = {
      email: datas.email,
      subject: datas.subject,
      message: datas.message,
    };
    await dispatch(sendSupportEmail(dataFormat));
    await reset();
    await setLoading(false);
    await toggleDrawer(e);
  };

  return (
    <React.Fragment>
      {data?.supportText ? (
        <>
          <span className="delete_autit_icon">
            <Button
              id="support"
              className={classes.button}
              onClick={toggleDrawer}
            >
              <i className="far fa-envelope" />
              {data?.supportText}
            </Button>
          </span>
          <span className="dashboard_table_mob">
            <Button
              id="support"
              className={`${classes.button} sup_btn_with_margin`}
              onClick={toggleDrawer}
            >
              <i className="fas fa-envelope envelope" />
            </Button>
          </span>
        </>
      ) : (
        <Typography
          sx={{ cursor: "pointer", fontWeight: "700" }}
          variant="body1"
          component="strong"
          onClick={toggleDrawer}
        >
          {data?.notButton}
        </Typography>
      )}
      <Drawer
        className={`${classes.root} zindex3000`}
        anchor="bottom"
        open={openDrawer}
        onClose={toggleDrawer}
      >
        <Typography
          onClick={toggleDrawer}
          className={classes.closeButton}
          component="span"
        >
          <CloseIcon />
        </Typography>
        <Box className={classes.custom}>
          <Typography variant="h4">Request for support</Typography>
        </Box>
        <form id="support_form" onSubmit={handleSubmit(onSubmit)}>
          <Support
            loading={loading}
            errors={errors}
            register={register}
            control={control}
            setvalue={setValue}
          />
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default Drawers;
