import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LabelTooltip from "./LabelTooltip";
import useStyles from "./styles";

const InputLabel = ({
 label, title, required, optional
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.commonLabel}>
      <Typography variant="body1" component="label">
        {label}

        {required && (
          <Typography variant="body2" component="small">
            *
          </Typography>
        )}

        {optional && (
          <Typography variant="body2" component="span">
            &nbsp;({t("common.optional")})
          </Typography>
        )}
      </Typography>
      {title && <LabelTooltip title={title} />}
    </Box>
  );
};

export default InputLabel;
