import { Box } from "@mui/material";
import React from "react";
import Select from "react-select";
import useStyles from "./styles";

function CustomSelect({ placeholder, name, ...rest }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Select
        name={name}
        placeholder={placeholder}
        {...rest}
      />
    </Box>
  );
}
export default CustomSelect;
