import {
  ADD_WIDGET_BEGIN, ADD_WIDGET_FAIL,
  ADD_WIDGET_SUCCESS,
  ADD_WIDGET_UPDATE_BEGIN,
  ADD_WIDGET_UPDATE_FAIL,
  ADD_WIDGET_UPDATE_SUCCESS,
  GET_WIDGET_DEFAULT_BEGIN,
  GET_WIDGET_DEFAULT_FAIL,
  GET_WIDGET_DEFAULT_SUCCESS
} from "../action/addWidgetAction";

const initialState = {
  data: {
    widget_title: "",
    widget_lang: "",
    widget_type: null,
    widget_auto_popup: " ",
    widget_popup_icon: "",
    widget_position: "",
    widget_form_img: "",
    style_active: "",
    widget_description: "",
    name_field: "",
    name_field_r: "",
    widget_button_label: "",
    widget_button_color: "",
    action: "",
  },
  loading: false,
  error: false,
};

const widgetDefault = {
    data: {},
    loading: false,
    error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ADD_WIDGET_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_WIDGET_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_WIDGET_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export const getWidgetDefaultReducer = (state = widgetDefault, action) => {
  switch (action.type) {
    case GET_WIDGET_DEFAULT_BEGIN:
      return {
        ...state,
          data: action.payload,
          loading: true,

      };
    case GET_WIDGET_DEFAULT_SUCCESS:
      return {
        ...state,
          data: action.payload,
          loading: false,

      };
    case GET_WIDGET_DEFAULT_FAIL:
      return {
        ...state,
          data: action.payload,
          loading: false,

      };

    default:
      return state;
  }
};
