import { GET_ISSUE_REPORT_BEGAIN, GET_ISSUE_REPORT_FAIL, GET_ISSUE_REPORT_SUCCESS } from "../../action/websiteAudit/types";

const initialState = {
  issueReport: [],
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ISSUE_REPORT_BEGAIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ISSUE_REPORT_SUCCESS:
      return {
        ...state,
        issueReport: action.payload.data,
        loading: false,
      };
    case GET_ISSUE_REPORT_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
