import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  GET_ACCOUNT_DATA_BEGIN,
  GET_ACCOUNT_DATA_FAIL,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_PAGE_SETTING_BEGIN,
  GET_PAGE_SETTING_FAIL,
  GET_PAGE_SETTING_SUCCESS,
  SEND_SUPPORT_BEGIN,
  SEND_SUPPORT_FAIL,
  SEND_SUPPORT_SUCCESS,
  UPDATA_ACCOUNT_DATA_BEGIN,
  UPDATA_ACCOUNT_DATA_FAIL,
  UPDATA_ACCOUNT_DATA_SUCCESS
} from "../action/userActions";
import { LOGIN } from "../types";

const initialState = {
  login: {
    data: {},
    isLogin: false,
  },
  userData: {
    data: {},
    loading: true,
    error: null,
  },

  supportData: {
    data: {
      email: "",
      subject: "",
      message: "",
    },
    loading: false,
    error: null,
  },

  settingData: {},
  deleteLoading: true,
};

const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        login: {
          data: payload,
          isLogin: true,
        },
      };
    case GET_ACCOUNT_DATA_BEGIN:
      return {
        ...state,
        userData: {
          error: null,
          loading: true,
        },
      };
    case GET_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        userData: {
          data: payload,
          loading: false,
        },
      };
    case GET_ACCOUNT_DATA_FAIL:
      return {
        ...state,
        userData: {
          error: payload.error.response,
          loading: false,
        },
      };

    case GET_PAGE_SETTING_BEGIN:
      return {
        ...state,
        settingData: {
          error: null,
          loading: true,
        },
      };
    case GET_PAGE_SETTING_SUCCESS:
      return {
        ...state,
        settingData: {
          data: payload,
          loading: false,
        },
      };
    case GET_PAGE_SETTING_FAIL:
      return {
        ...state,
        settingData: {
          error: payload.error.response,
          loading: false,
        },
      };

    case UPDATA_ACCOUNT_DATA_BEGIN:
      return {
        ...state,
        userData: {
          data: payload,
          loading: true,
        },
      };
    case UPDATA_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        userData: {
          data: payload,
          loading: false,
        },
      };
    case UPDATA_ACCOUNT_DATA_FAIL:
      return {
        ...state,
        userData: {
          error: payload.error.response,
          loading: false,
        },
      };

    case DELETE_ACCOUNT:
      return {
        ...state,
        deleteLoading: false,
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: payload,
      };

    case SEND_SUPPORT_BEGIN:
      return {
        ...state,
        supportData: {
          data: payload,
          loading: true,
        },
      };
    case SEND_SUPPORT_SUCCESS:
      return {
        ...state,
        supportData: {
          data: payload,
          loading: false,
        },
      };
    case SEND_SUPPORT_FAIL: {
      return {
        ...state,
        supportData: {
          data: payload,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
