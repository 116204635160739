import {
  DELETE_GROUP_BEGIN,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  GET_ALL_GROUPS_BEGIN,
  GET_ALL_GROUPS_FAIL,
  GET_ALL_GROUPS_SUCCESS,
  POST_CREATEGROUP_BEGIN,
  POST_CREATEGROUP_FAIL,
  POST_CREATEGROUP_SUCCESS
} from "../action/groupAction";

const initialState = {
  groups: [],
  group: null,
  loading: null,
  creategroup_loading: false,
  error: null,
  addedgroup: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.payload.data.data.groups,
      };

    case GET_ALL_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response?.data,
      };

    case POST_CREATEGROUP_BEGIN:
      return {
        ...state,
        creategroup_loading: true,
        addedgroup: null,
      };

    case POST_CREATEGROUP_SUCCESS:
      return {
        ...state,
        creategroup_loading: false,
        addedgroup: action.payload.data.data.group,
      };

    case POST_CREATEGROUP_FAIL:
      return {
        ...state,
        creategroup_loading: false,
        error: action.payload.error.response.data,
        addedgroup: null,
      };

    case DELETE_GROUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data,
      };

    default:
      return state;
  }
};
