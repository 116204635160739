// julhas code
import { combineReducers } from "redux";
import { getWidgetDefaultReducer } from "./addWidgetReducer";
import adminSiteListReducer from "./adminSiteListReducer";
import alert from "./alert";
import auditReportReducer from "./auditReportReducer";
import billingReducer from "./billing/billingReducer";
import leadSyncReducer from "./leadSyncReducer";
import countryReducer from "./countryReducer";
import dashboardReducer from "./dashboardReducer";
import enginelistReducer from "./enginelistReducer";
import engineReducer from "./engineReducer";
import fetchWidgetListReducer from "./fetchWidgetListReducer";
import getLeadsReducer from "./getLeadsReducer";
import groupReducer from "./groupReducer";
import invoicesReducer from "./invoceReducer";
import keywordRankingReducer from "./keywordRanking/keywordRankingReducer";
import keywordRankingtReducer from "./keywordRankingReducer";
import keywordReducer from "./keywordReducer";
import languageReducer from "./languageReducer";
import leadReportReducer from "./leadReportReducer";
import locationReducer from "./locationReducer";
import manageGroupReducer from "./manage-group/manageGroupReducer";
import myAuditReducer from "./myAudit/myAuditReducer";
import getNotificationReducer from "./Notifications";
import rankTopDataReducer from "./RankTopDataReducer";
import SendEmailReducer from "./SendEmailReducer";
import signinReducer from "./signinReducer";
import stepOneReducer from "./stepOneReducer";
import stepTwoReducer from "./stepTwoReducer";
import subscriptionInfoReducer from "./subscriptionReducer";
import tempAuditReducer from "./tempAuditReducer";
import tokenReducer from "./tokenReducer";
import userReducer from "./userReducer";
import getAuditedListReducer from "./websiteAuditReducer/getAuditedListReducer";
import getOverviewDataReducer from "./websiteAuditReducer/getOverviewDataReducer";
import issueReportReducer from "./websiteAuditReducer/issueReportReducer";
import websiteAuditListReducer from "./websiteAuditReducer/websiteAuditListReducer";
import {
  crawledPages,
  foundLinks,
  foundResources,
} from "./websiteAuditReducer/websiteAuditReducer";
import whiteLabelReducer from "./whiteLabelReducer";
import widgetSettingReducer from "./widgetSettingsReducer";
import previewReducer from "./widgetSettingsReducer/previewReducer";

export default combineReducers({
  group: groupReducer,
  stepOne: stepOneReducer,
  stepTwo: stepTwoReducer,
  token: tokenReducer,
  signin: signinReducer,
  country: countryReducer,
  language: languageReducer,
  engine: engineReducer,
  keyword: keywordReducer,
  auditreport: auditReportReducer,
  whitelabel: whiteLabelReducer,
  enginelist: enginelistReducer,
  locations: locationReducer,
  invoice: invoicesReducer,
  subscription: subscriptionInfoReducer,
  user: userReducer,
  adminSiteList: adminSiteListReducer,
  myaudit: myAuditReducer,
  widget: fetchWidgetListReducer,
  leads: getLeadsReducer,
  leadreport: leadReportReducer,
  keyWordRanking: keywordRankingtReducer,
  alert,
  tempaudit: tempAuditReducer,
  billing: billingReducer,
  leadSync: leadSyncReducer,
  dashboardData: dashboardReducer,
  notifications: getNotificationReducer,
  rankData: rankTopDataReducer,
  websiteAuditLists: websiteAuditListReducer,
  overviewPageData: getOverviewDataReducer,
  auditSiteList: getAuditedListReducer,
  widgetSmtp: widgetSettingReducer,
  manageGroupData: manageGroupReducer,
  keyRankOverview: keywordRankingReducer,
  issueReport: issueReportReducer,
  foundLink: foundLinks,
  crawledPage: crawledPages,
  foundResource: foundResources,
  SendEmailReducer,
  widgetDefaultValue: getWidgetDefaultReducer,
  widgetPreview: previewReducer,
});
