import { Box } from "@mui/system";
import React from "react";
import Select from "react-select";
import InputLabel from "../../../InputLabel";

const SlimForm = ({ values, setValues, handlepChange }) => {
  const optionss = [
    { value: "top", label: "Top" },
    { value: "bottom", label: "Bottom" }
  ];

  return (
    <Box sx={{ pb: "1rem !important" }} className="form-full">
      <InputLabel
        label="Slim Form Widget Position"
        required
        title="Choose a suitable position for your widget to appear on your webpage."
      />
      <div className="custom-selector">
        <Select
          options={optionss}
          onChange={handlepChange}
          value={optionss.find(({ value }) => value === values.widget_position) || optionss[0]}
        />
      </div>
    </Box>
  );
};

export default React.memo(SlimForm);
