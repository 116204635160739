import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import {
  borderTypeOptions,
  fontWeightOptions,
  textTransformOption,
} from "./selectOptions";

const ReportCloseBtn = ({
  setValues,
  values,
  changingObject,
  setChangingObject,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(true);

  const { report_close_btn } = values;

  const handleChanges = useCallback((event) => {
    const { name, value } = event.target;
    setChecked(checked);
    setValues((prevState) => ({
      ...prevState,
      report_close_btn: {
        ...prevState.report_close_btn,
        [name]: parseInt(value),
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      report_close_btn: {
        ...prevState.report_close_btn,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevState) => ({
      ...prevState,
      report_close_btn: {
        ...prevState.report_close_btn,
        [name]: data.value,
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      report_close_btn: {
        ...prevState.report_close_btn,
        [name]: data.value,
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Box sx={{ flexWrap: "wrap" }} className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.background")}</Box>
        <Box>
          <Typography component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="background"
              type="none"
              nested="report_close_btn"
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.text")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="color"
              type="none"
              nested="report_close_btn"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.transform")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={textTransformOption}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "text_transform")}
              value={textTransformOption.find(
                ({ value }) => value === report_close_btn.text_transform
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.fontWeight")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={fontWeightOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptions.find(
                ({ value }) => value === report_close_btn.font_weight
              )}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.border")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="border_top"
              value={report_close_btn.border_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="border_right"
              value={report_close_btn.border_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="border_bottom"
              value={report_close_btn.border_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="border_left"
              value={report_close_btn.border_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="border_color"
              type="none"
              nested="report_close_btn"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.radius")}
          </Typography>
          <div>
            <input
              name="border_radius"
              value={report_close_btn.border_radius}
              onChange={handleChanges}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.type")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={borderTypeOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "border_type")}
              value={borderTypeOptions.find(
                ({ value }) => value === report_close_btn.border_type
              )}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.margin")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="margin_top"
              value={report_close_btn.margin_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="margin_right"
              value={report_close_btn.margin_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="margin_bottom"
              value={report_close_btn.margin_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="margin_left"
              value={report_close_btn.margin_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.padding")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.top")}
          </Typography>
          <div>
            <input
              name="padding_top"
              value={report_close_btn.padding_top}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.right")}
          </Typography>
          <div>
            <input
              name="padding_right"
              value={report_close_btn.padding_right}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bottom")}
          </Typography>
          <div>
            <input
              name="padding_bottom"
              value={report_close_btn.padding_bottom}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.left")}
          </Typography>
          <div>
            <input
              name="padding_left"
              value={report_close_btn.padding_left}
              onChange={handleChanges}
              type="text"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(ReportCloseBtn);
