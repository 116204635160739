import { REMOVE_TEMP_AUDIT, SAVE_TEMP_AUDIT } from "../types";

const initialState = {
  temp_id: "",
  site_url: "",
  site_keyword: "",
  temp_audits: "",
  audit_run_time: "",
  content: "",
};

const tempAuditReducer = (state = initialState, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case SAVE_TEMP_AUDIT:
      // console.log(action.payload);
      let temp_audit = {
        ...action.payload,
      };
      localStorage && localStorage.setItem("temp_audit", JSON.stringify(temp_audit));
      return {
        ...state,
        ...action.payload,
        temp_audits: action.payload,
      };
    case REMOVE_TEMP_AUDIT:
      return initialState;
    default:
      return state;
  }
};

export default tempAuditReducer;
