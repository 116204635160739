import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, CustomInput, ToolTip } from "@components";
import * as leadSyncAction from "../../appRedux/action/leadSyncAction";
import LoaderSvg from "components/common/LoaderSvg";
import { Link,useHistory,useLocation  } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const Mailchimp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [saving, setSaving] = useState({
    load: false,
  });

  const leadSync = useSelector(({ leadSync }) => leadSync.leadSyncData["MAILCHIMP"]);
  const [token, setToken] = useState(leadSync?leadSync.token:"");
  const [tokenError, setTokenError] = useState(false);
  let listIds = [];
  async function subbmitForm(e) {
    e.preventDefault();

    await setSaving({
      load: true,
    });

    if (!token) {
      setTokenError(true);
    } else {
      await dispatch(leadSyncAction.updateLeadSyncToken("MAILCHIMP",token));
      await dispatch(leadSyncAction.getLeadSyncData("MAILCHIMP"));
    }

    await setSaving({
      laod: false,
    });
  }
  async function subbmitListForm(e) {
    e.preventDefault();

    await setSaving({
      load: true,
    });

    await dispatch(leadSyncAction.updateLeadSyncField("Mailchimp","list_ids",listIds));


    await setSaving({
      laod: false,
    });
  }
  
  async function deleteToken(e) {
    e.preventDefault();
    await dispatch(leadSyncAction.deleteLeadSyncToken("Mailchimp"));
    await dispatch(leadSyncAction.getLeadSyncData("Mailchimp"));
  }
  const handleListChange = (selectedOptions) => {
    let list_ids = [];
    if(selectedOptions){
      selectedOptions.map(function(element){
        list_ids.push(element["value"]);
      });
    }
    
    listIds = list_ids;
  }
  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    if (name === "token") {
      setToken(value);
      setTokenError(false);
    }
  }
  useEffect(() => {
    dispatch(leadSyncAction.getLeadSyncData("Mailchimp"));
  }, [dispatch]);
  if(leadSync){
    if(leadSync.profile){
      if(Array.isArray(leadSync["list_ids"])){
        listIds = leadSync["list_ids"];
      }
      var options = [];
      let defaultValue = []
      if (typeof leadSync["lists"]["lists"] != undefined && Array.isArray(leadSync["lists"]["lists"])){
        leadSync["lists"]["lists"].map(function(element){
          if(listIds.includes(element["id"])){
            defaultValue.push({value: element["id"], label: element["name"] });

          }
          options.push({ value: element["id"], label: element["name"] })
      });
      }

      return (
        <>
        <div>
        <h4>{t("sync_lead.Mailchimp")}</h4>
								<p>
									{leadSync.profile['first_name'] + ' ' + leadSync.profile['last_name']}&nbsp;-&nbsp;{leadSync.profile['email']}
                  <br></br>
									<a href="#" className="text-red text-decoration-none" onClick={deleteToken}><strong><i class="fa fa-angle-right"></i>&nbsp;{t("common.Logout")}</strong></a>
								</p>
							</div>
              <label><b>{t("sync_lead.send_in_multi")}</b></label>
              <Select 
              defaultValue={defaultValue}
              options={options} 
              isMulti
              name="list_ids"
              onChange={handleListChange}
              />
              <div className="save-btn" style={{ paddingTop: "15px" }}>
                <button
                  type="button"
                  className="seo_btn btn-save gradient_blu loader"
                  onClick={subbmitListForm}
                >
                  <i className="fas fa-save mr-2" />
                  {t("myAccount.save")} {saving.load && <LoaderSvg />}
                </button>
                <button style={{ marginLeft: "2rem" }} className="seo_btn btn-save gradient__orange" onClick={() => {window.open("https://seoaudit.software/faqs/how-to-connect-mailchimp-with-seo-lead-generation-tool/ ", '_blank', 'noreferrer')}} >
                {t("sync_lead.config_button")} <i class="ml-2 fas fa-external-link-alt"></i>
                </button>
              </div>
      </>
      )
    }else{
      return (
        <>
        {leadSync.has_access !== true?<>
          <div className="not-access-text">
          {t("sync_lead.not_access_text")} <Link
                onClick={() => {
                  history.push("/pricing");
                }}
                to="/pricing"
              >
                <i class="ml-2 fas fa-external-link-alt text-white"></i>
              </Link>
          </div>
      </>:<></>}
        <h4>{t("sync_lead.Mailchimp")}</h4>
      <form className={`form_design mt-3`}>
        <div className="form-row form-full"  style={{ paddingBottom: "15px" }}>
          <div className="form-group col-md-6 mb-0" style={{ padding: "5px" }}>
              <label className="billing-lable">{t("common.api_key")}</label>
              <input
              type="text"
              name="token"
              value={token}
              className = {tokenError?"error":""}
              onChange={handleInputChange}
            />
            </div>
          
          
        </div>
        <div className="save-btn">
            <button
              type="button"
              className="seo_btn btn-save gradient_blu loader"
              onClick={subbmitForm}
              disabled = {leadSync.has_access !== true? true:false}
            >
              <i className="fas fa-save mr-2" />
              {t("common.Login")} {saving.load && <LoaderSvg />}
            </button>
            <button style={{ marginLeft: "2rem" }} className="seo_btn btn-save gradient__orange" onClick={() => {window.open("https://seoaudit.software/faqs/how-to-connect-mailchimp-with-seo-lead-generation-tool/ ", '_blank', 'noreferrer')}} >
                {t("sync_lead.config_button")} <i class="ml-2 fas fa-external-link-alt"></i>
              </button>
            
          </div>
      </form>
      </>
      )
    }
  }else{
    return (
      <>
      </>
    );
  }
  
};

export default React.memo(Mailchimp);