import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles(() => ({
  paper: {
    background: "#fff",
  },
}));

const CustomPaper = ({ title, isTitle }) => {
  const classes = useStyle();
  return (
    <div>
      <Paper className={classes.paper}>
        {isTitle && <Typography variant="h4">{title}</Typography>}
      </Paper>
    </div>
  );
};

export default CustomPaper;
