import {
  GET_PREVIEW_MODAL_BEGIN,
  GET_PREVIEW_MODAL_FAIL,
  GET_PREVIEW_MODAL_SUCCESS,
} from "../../action/widgetSettingsActions/previewAction";

const initialState = {
  previewContent: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Start Get SMTP DATA case
    case GET_PREVIEW_MODAL_BEGIN:
      return {
        ...state,
        previewContent: payload,
        loading: true,
      };
    case GET_PREVIEW_MODAL_SUCCESS:
      return {
        ...state,
        previewContent: payload,
        loading: false,
      };
    case GET_PREVIEW_MODAL_FAIL:
      return {
        ...state,
        previewContent: payload,
        loading: false,
      };
    default:
      return state;
    // Start Get EMAIL DATA case
  }
};
