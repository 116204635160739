import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";
export const DELETE_SITE_GROUPS_BEGIN = "DELETE_SITE_GROUPS_BEGIN";
export const DELETE_SITE_GROUPS_SUCCESS = "DELETE_SITE_GROUPS_SUCCESS";
export const DELETE_SITE_GROUPS_FAIL = "DELETE_SITE_GROUPS_FAIL";

export const GET_SITE_GROUPS_BEGIN = "GET_SITE_GROUPS_BEGIN";
export const GET_SITE_GROUPS_SUCCESS = "GET_SITE_GROUPS_SUCCESS";
export const GET_SITE_GROUPS_FAIL = "GET_SITE_GROUPS_FAIL";



export const selectSite = () => (dispatch) => {
  dispatch({
    type: GET_SITE_GROUPS_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/keyword-ranking/site-list/",
  })
    .then((res) => {
      const data = {
        ungrouped: res.data.data.site_groups.ungrouped,
        grouped: res.data.data.site_groups.grouped,
      };

      dispatch({
        type: GET_SITE_GROUPS_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_SITE_GROUPS_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getAdminSiteListData = () => (dispatch) => {
  dispatch({
    type: GET_SITE_GROUPS_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/keyword-ranking/site-list/",
  })
    .then((res) => {
      const data = {
        ungrouped: res.data.data.site_groups.ungrouped,
        grouped: res.data.data.site_groups.grouped,
        dataRoot: res.data.data,
      };

      dispatch({
        type: GET_SITE_GROUPS_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_SITE_GROUPS_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const deleteAdminSiteListData = (id) => (dispatch) => {
  dispatch({
    type: DELETE_SITE_GROUPS_BEGIN,
  });
  return serverCall({
    method: "DELETE",
    url: `/api/v1/project/${id}/`,
  })
    .then((res) => {
      dispatch({
        type: DELETE_SITE_GROUPS_SUCCESS,
        payload: true,
      });
      dispatch(setAlert(i18n.t("alert_messages.deleted_success"), "success"));
      getAdminSiteListData();
      return true;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SITE_GROUPS_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.deleted_failed"), "danger"));
      throw error;
    });
};
