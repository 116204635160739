import serverCall from '../../modules/serverCall';

export const GET_INVOICES_BEGIN = 'GET_INVOICES_BEGIN';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

export const GET_INVOICE_BEGIN = 'GET_INVOICE_BEGIN';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';

export const getInvoicesData = () => (dispatch) => {
  dispatch({
    type: GET_INVOICES_BEGIN,
  });
  return serverCall({
    method: 'GET',
    url: `/api/v1/account/billing/`,
  })
    .then((res) => {
      console.log('RESPONSE FROM REDUX ACTION', res);
      const data = res.data.data.billing_info;

      dispatch({
        type: GET_INVOICES_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_INVOICES_FAIL,
        payload: error,
      });
      return error;
    });
};

export const getInvoiceDetails = (id) => (dispatch) => {
  dispatch({
    type: GET_INVOICE_BEGIN,
  });
  return serverCall({
    method: 'GET',
    url: `/api/v1/account/billing/${id}/`,
  })
    .then((res) => {
      console.log(res);
      const data = res.data.data.billing_info;

      dispatch({
        type: GET_INVOICE_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_INVOICE_FAIL,
        payload: error,
      });
      return error;
    });
};
