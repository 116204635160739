import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    "& .MuiDrawer-paperAnchorBottom": {
      width: "400px",
      bottom: "0",
      right: "0",
      left: "initial",

      "& form": {
        "& .MuiFormGroup-root": {
          position: "relative",
          marginBottom: "2.2rem",
          "& textarea": {
            border: "1px solid transparent",
            borderColor: "rgba(63, 29, 253, .10) !important",
            borderRadius: "4px",
            padding: "1rem 1.2rem",
            color: theme.palette.text.main,
            "&::placeholder": {
              opacity: ".6",
            },
            "&:focus": {
              outline: "none",
            },
          },
        },
      },
    },
  },
  custom: {
    textAlign: "left",
    margin: "1rem 0 2rem 0",
  },

  button: {
    padding: "2px 0",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& i": {
      marginRight: ".3rem",
    },
  },

  closeButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.red,
    cursor: "pointer",
    color: theme.palette.text.light,
    width: 30,
    height: 30,
    borderRadius: "50%",
  },
}));
