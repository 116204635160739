const SetServerUrl = () => {
  let URL;

  let serverUrl;

  const commonurl = window.location.host;

  const partnerDomain = {
    agency: "dashboard.seoaudit.agency",
    inc: "app.seoauditinc.com",
    creativeLab: "jasim.creativelab.agency",
    localHost: "localhost:3000",
    app: "app.seoaudit.software",
  };

  switch (commonurl) {
    case partnerDomain.app:
      URL = "https://api.seoaudit.software";
      // URL = "https://dev.seoaudit.software";
      break;
    case partnerDomain.agency:
      URL = "https://api.seoaudit.agency";
      // URL = "https://dev.seoaudit.software";
      break;
    case partnerDomain.inc:
      URL = "https://api.seoauditinc.com";
      // URL = "https://dev.seoaudit.software";
      break;
    case partnerDomain.creativeLab:
      // URL = "https://dev.seoaudit.software";
      URL = "https://dev.seoaudit.software";
      break;
    case partnerDomain.localHost:
      // URL = "https://dev.seoaudit.software";
      URL = "https://dev.seoaudit.software";
      break;
    default:
      URL = "https://dev.seoaudit.software";
    // URL = "https://dev.seoaudit.software";
  }

  if (URL !== "") {
    localStorage.setItem("mainUrl", URL);
    serverUrl = localStorage.getItem("mainUrl");
  }

  if (serverUrl !== undefined) {
    return serverUrl;
  }

  return URL;
};

export default SetServerUrl();