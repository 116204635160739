import serverCall from "../../../modules/serverCall";
import { setAlert } from "../alert";
import i18n from "../../../i18n";
export const SEND_AUDIT_EMAIL_SUCCESS = "SEND_AUDIT_EMAIL_SUCCESS";
export const SEND_AUDIT_EMAIL_FAIL = "SEND_AUDIT_EMAIL_FAIL";
export const SEND_AUDIT_EMAIL_BEGIAN = "SEND_AUDIT_EMAIL_BEGIAN";

export const sendAuditEmail = (id, email) => (dispatch) => {
  dispatch({ type: SEND_AUDIT_EMAIL_BEGIAN });
  const data = {
    "email-address": email,
    "audit-id": id
  };
  return serverCall({
    method: "POST",
    url: "/api/v1/send-audit-email/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: SEND_AUDIT_EMAIL_SUCCESS,
          payload: data,
        });
        dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch(setAlert(res.data.message, "danger"));

      }
      return data;
    })
    .catch((error) => {
      dispatch({
        type: SEND_AUDIT_EMAIL_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

      throw error;
    });
};
