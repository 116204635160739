import Box from "@mui/material/Box";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import * as React from "react";

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"),
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  center: {
    position: "fixed",
    top: "48%",
    left: "53%",
    transform: "translate(-50%, -50%)",
    zIndex: "9",
  },
});

function CustomizedProgressBars() {
  const classes = useStyles();
  return (
    <Box className={classes.center}>
      <Box sx={{ flexGrow: 1 }}>
        <FacebookCircularProgress />
      </Box>
    </Box>
  );
}

export default CustomizedProgressBars;
