import { Box, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { default as DE } from "../../../../../assets/images/flags/de.svg";
import DK from "../../../../../assets/images/flags/dk.svg";
import ES from "../../../../../assets/images/flags/es.svg";
import FR from "../../../../../assets/images/flags/fr.svg";
import GR from "../../../../../assets/images/flags/gr.svg";
import IT from "../../../../../assets/images/flags/it.svg";
import NL from "../../../../../assets/images/flags/nl.svg";
import NO from "../../../../../assets/images/flags/no.svg";
import PL from "../../../../../assets/images/flags/pl.svg";
import PT from "../../../../../assets/images/flags/pt.svg";
import SE from "../../../../../assets/images/flags/se.svg";
import US from "../../../../../assets/images/flags/us.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiOutlinedInput-root": {
      width: "auto",
      margin: "0",
      height: "auto",
      padding: "0",
    },
    "& img": {
      width: "20px",
      marginLeft: ".3rem",
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      minHeight: "auto",
      padding: "0",
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      margin: "0",
      padding: "0",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "6px",
    minWidth: 120,
  },
  img: {
    "& img": {
      width: "20px",
      marginLeft: ".3rem",
    },
  },
}));

function LangBtn() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [age, setAge] = React.useState("en");
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // const handleClick = async (e) => {
  //   console.log("E", e);
  //   i18n.changeLanguage(e.target.value);
  //   setAge(e.target.value);
  // };

  const [targetLng, setTargetLng] = useState("");
  const history = useHistory();
  const handleClick = (e) => {
    setTargetLng(e.target.value);
    window.sessionStorage.setItem("activeLanguage", e.target.value);
    setAge(e.target.value);
    history.go(0);
  };

  if (targetLng && targetLng !== i18n.language) {
    // not yet loaded namespaces -> load them -> and trigger suspense
    throw new Promise((resolve) => {
      i18n.changeLanguage(targetLng, () => {
        resolve();
      });
    });
  }
  
  useEffect(() => {
    if (i18n.language !== age) {
      setAge(i18n.language);
      window.sessionStorage.setItem("activeLanguage", i18n.language);
    }
  }, []);

  return (
    <Box
      className={`${classes.formControl} new-popoverform language_mb languageHeader`}
    >
      <Select
        className={`language__select ${classes.root}`}
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={i18n.language}
        onChange={handleClick}
        elevation={4}
      >
        <MenuItem className={classes.img} value="en">
          <img className="imag_lang" src={US} alt="" />
          English
        </MenuItem>
        <MenuItem className={classes.img} value="nl">
          <img className="imag_lang" src={NL} alt="" />
          Dutch
        </MenuItem>
        <MenuItem className={classes.img} value="es">
          <img className="imag_lang" src={ES} alt="" />
          Spanish
        </MenuItem>
        <MenuItem className={classes.img} value="it">
          <img className="imag_lang" src={IT} alt="" />
          Italian
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="fr">
          <img className="imag_lang" src={FR} alt="" />
          French
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="pt">
          <img className="imag_lang" src={PT} alt="" />
          Portuguese
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="de">
          <img className="imag_lang" src={DE} alt="" />
          German
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="el">
          <img className="imag_lang" src={GR} alt="" />
          Greek
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="sv">
          <img className="imag_lang" src={SE} alt="" />
          Swedish
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="pl">
          <img className="imag_lang" src={PL} alt="" />
          Polish
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="nb">
          <img className="imag_lang" src={NO} alt="" />
          Norwegian
        </MenuItem>{" "}
        <MenuItem className={classes.img} value="da">
          <img className="imag_lang" src={DK} alt="" />
          Danish
        </MenuItem>
      </Select>
    </Box>
  );
}

export default LangBtn;
