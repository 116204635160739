const initialState = {
  white_label_data: null,
};

export default (state = initialState, action) => {
  if (action.type == "GET_WHITE_LABEL_SUCCESS"){
    return {
      ...state,
      white_label_loading: false,
      white_label_data: action.payload.data.data.white_label,
    };
  }else{
    state[action.type]=action.value;
    return state
  }
};
