import LoaderSvg from "components/common/LoaderSvg";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import setAlert from "../../components/common/Alert";
import Auth from "../../modules/Auth";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const DownloadPDF = ({ passData }) => {
  const { t } = useTranslation();
  const propsData = passData;

  const token = Auth.getToken();

  const dispatch = useDispatch();

  const [loaderfor, setLoadingBtn] = useState({
    load: false,
  });

  async function downloadPdf(url, type, loading, fileName) {
    try {
      setLoadingBtn({
        load: loading === "loading",
      });

      let xhr = new XMLHttpRequest();

      await xhr.open("GET", url, true);

      await xhr.send();

      xhr.onreadystatechange = await function () {
        if (xhr.readyState === 2) {
          if (xhr.status === 200) {
            xhr.responseType = "blob";
          } else {
            xhr.responseType = "text";
            dispatch(setAlert(i18n.t("alert_messages.download_failed"), "danger", 10000));
          }
        }
      };

      xhr.onload = await function () {
        try {
          // Convert the Byte Data to BLOB object.
          let blob = new Blob([xhr.response], { type: "application/octetstream" });

          // Check the Browser type and download the File.
          let isIE = false || !!document.documentMode;
          if (isIE) {
            window.navigator.msSaveBlob(blob, fileName);
          } else {
            let url = window.URL || window.webkitURL;
            let link = url.createObjectURL(blob);
            let a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("href", link);
            a.setAttribute("class", "delete-this-pdf-link");
            document.querySelector("body").appendChild(a);
            a.click();
            a.remove();
          }
        } catch (err) {
          // window.open(
          //   `${SetServerUrl}/api/v1/download_pdf/?file_name=report&share_key=${share_key}&id=${audit_id}&type=audit`
          // );
        }
        setLoadingBtn({
          load: false,
        });
      };

      xhr.onerror = await function () {
        setLoadingBtn({
          load: false,
        });
        dispatch(setAlert(i18n.t("alert_messages.download_failed"), "danger", 10000));
        console.error(xhr.statusText);
      };
    } catch (err) {
      // window.open(
      //   `${SetServerUrl}/api/v1/download_pdf/?file_name=report&share_key=${share_key}&id=${audit_id}&type=audit`
      // );
    }
  }

  return (
    <Fragment>
      <button
        type="button"
        className="seo_btn gradient_blu loader"
        onClick={(e) => {
          e.preventDefault();
          downloadPdf(propsData.url, propsData.type, propsData.loading, propsData.fileName);
        }}
      >
        {t("common.downloadPdf")} {loaderfor.load && <LoaderSvg />}
      </button>
    </Fragment>
  );
};

export default DownloadPDF;
