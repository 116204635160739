import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, CustomInput, ToolTip } from "@components";
import * as leadSyncAction from "../../appRedux/action/leadSyncAction";
import LoaderSvg from "components/common/LoaderSvg";
import { Link,useHistory,useLocation  } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const Webhook = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [saving, setSaving] = useState({
    load: false,
  });

  const leadSync = useSelector(({ leadSync }) => leadSync.leadSyncData["WEBHOOK"]);
  const [url, setUrl] = useState(leadSync?leadSync.url:"");
  const [token, setToken] = useState(leadSync?leadSync.token:"");
  const [input_changed, setInputChanged] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  let listIds = [];
  async function subbmitForm(e) {
  e.preventDefault();

    await setSaving({
      load: true,
    });

    if (!token) {
      setTokenError(true);
    } else {
      await dispatch(leadSyncAction.updateLeadSyncField("WEBHOOK","url",url));
      await dispatch(leadSyncAction.updateLeadSyncField("WEBHOOK","token",token));
      await dispatch(leadSyncAction.getLeadSyncData("WEBHOOK"));
    }

    await setSaving({
      laod: false,
    });
  }

  
 
  const handleInputChange = (event) => {
    setInputChanged(true);
    event.persist();
    const { name, value } = event.target;

    if (name === "url") {
      setUrl(value);
    }
    if (name === "token") {
      setToken(value);
      if(value){
        setTokenError(false);

      }else{
        setTokenError(true);

      }
    }
  }
  useEffect(() => {
    dispatch(leadSyncAction.getLeadSyncData("Webhook"));
  }, [dispatch]);
  if(leadSync){
    if(!input_changed && !token){
setUrl(leadSync.url);
setToken(leadSync.token);
    }
      return (
        <>
      {leadSync.has_access !== true?<>
      <div className="not-access-text">
        {t("sync_lead.not_access_text")} <Link
                onClick={() => {
                  history.push("/pricing");
                }}
                to="/pricing"
              >
                <i class="ml-2 fas fa-external-link-alt text-white"></i>
              </Link>
        </div>
      </>:<></>}
      <h4>{t("sync_lead.Webhook")}</h4>
      <form className={`form_design mt-3`}>
      <div style={{marginBottom:"5px",fontSize:"15px"}}>
        <div className="form-row form-full"  style={{ paddingBottom: "15px" }}>
            <div className="form-group col-md-6 mb-0" style={{ padding: "5px" }}>
                <label className="billing-lable">{t("common.Token")}</label>
                <p>{t("common.TokenInfo")}</p>
                <input
                type="text"
                name="token"
                value={token}
                onChange={handleInputChange}
                className = {tokenError?"error":""}
              />
              </div>
              
            
            
          </div>
        
        </div>
        <div className="form-row form-full"  style={{ paddingBottom: "15px" }}>
          <div className="form-group col-md-6 mb-0" style={{ padding: "5px" }}>
              <label className="billing-lable">{t("common.UrlToPost")}</label>
              <input
              type="url"
              name="url"
              value={url}
              onChange={handleInputChange}
              className = {urlError?"error":""}
            />
            </div>
            
          
          
        </div>
        <div className="save-btn">
            <button
              type="button"
              className="seo_btn btn-save gradient_blu loader"
              onClick={subbmitForm}
              disabled = {leadSync.has_access !== true || (urlError || tokenError)? true:false}
            >
              <i className="fas fa-save mr-2" />
              {t("myAccount.save")} {saving.load && <LoaderSvg />}
            </button>
            <button style={{ marginLeft: "2rem" }} className="seo_btn btn-save gradient__orange" onClick={() => {window.open("https://seoaudit.software/faqs/how-to-setup-a-webhook-for-seo-leads/", '_blank', 'noreferrer')}} >
                {t("sync_lead.config_button")} <i class="ml-2 fas fa-external-link-alt"></i>
              </button>
          </div>
      </form>
      </>
      )
  }else{
    return (
      <>
      </>
    );
  }
};

export default React.memo(Webhook);