import Auth from "../../modules/Auth";
import { googlelogin, login } from "../../modules/serverCall";

export const POST_TOKEN_BEGIN = "POST_TOKEN_BEGIN";
export const POST_TOKEN_SUCCESS = "POST_TOKEN_SUCCESS";
export const POST_TOKEN_FAIL = "POST_TOKEN_FAIL";

export const INSERT_TOKEN_SUCCESS = "INSERT_TOKEN_SUCCESS";
export const INSERT_TOKEN_FAIL = "INSERT_TOKEN_FAIL";

export const GOOGLE_LOGIN_TOKEN_REQUEST_BEGIN = "GOOGLE_LOGIN_TOKEN_REQUEST_BEGIN";
export const GOOGLE_LOGIN_TOKEN_REQUEST_SUCCESS = "GOOGLE_LOGIN_TOKEN_REQUEST_SUCCESS";
export const GOOGLE_LOGIN_TOKEN_REQUEST_FAIL = "GOOGLE_LOGIN_TOKEN_REQUEST_FAIL";

export const postToken = (email, password) => (dispatch) => {
  dispatch({
    type: POST_TOKEN_BEGIN,
  });
  return login(email, password)
    .then((res) => {
      dispatch({
        type: POST_TOKEN_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: POST_TOKEN_FAIL,
        payload: { error },
      });
    });
};

export const insertToken = () => (dispatch) => {
  let token;
  if (localStorage.getItem("auth")) {
    token = JSON.parse(localStorage.getItem("auth"));
    const tokenCheck = Auth.getToken();
    dispatch({
      type: INSERT_TOKEN_SUCCESS,
      payload: token,
    });
  } else {
    dispatch({
      type: INSERT_TOKEN_FAIL,
    });
  }
};

export const googleLogin = (email, username) => (dispatch) => {
  dispatch({
    type: GOOGLE_LOGIN_TOKEN_REQUEST_BEGIN,
  });
  return googlelogin(email, username)
    .then((res) => {
      console.log("resonse from google login", res);
      dispatch({
        type: GOOGLE_LOGIN_TOKEN_REQUEST_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GOOGLE_LOGIN_TOKEN_REQUEST_FAIL,
        payload: { error },
      });
      return error;
    });
};
