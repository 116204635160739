import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles(() => ({
  root: {
    overflow: "hidden",
    alignItems: "center",
    background: "rgba(19, 46, 156, .95)",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "999",
    width: "100vw",
    height: "100vh",
    color: "#fff",

    "& h2": {
      color: "#fff",
      textTransform: "uppercase",
    },

    "& p": {
      color: "#fff",
    },

    "& div": {
      textAlign: "center",
      display: "block !important",

      "& p, h2, h4, h3, h1": {
        marginBottom: "1rem !important",
      },

      "& a": {
        margin: ".5rem auto",
      },
    },

    "& a": {
      backgroundImage: "linear-gradient(90deg,#f7c129,#fc8031 0,#fd2d2d 88%)",
      padding: ".4rem 1rem",
      display: "block",
      maxWidth: "fit-content",
      borderRadius: ".2rem",
      color: "#fff",
    },
  },
}));

const UpdateBlock = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div>
        <h2>We are Updating The App</h2>
        <p>We will get back at 11:30PM (GMT +6)</p>
        <a target="__blank" className="seo_btn gradient__orange" href="https://seoaudit.software">
          Visit SEO Audit Software
        </a>
      </div>
    </div>
  );
};

export default UpdateBlock;
