import serverCall from "../../../modules/serverCall";

export const GET_WEBSITE_AUDIT_LIST_BEGIN = "GET_WEBSITE_AUDIT_LIST_BEGIN";
export const GET_WEBSITE_AUDIT_LIST_SUCCESS = "GET_WEBSITE_AUDIT_LIST_SUCCESS";
export const GET_WEBSITE_AUDIT_LIST_FAIL = "GET_WEBSITE_AUDIT_LIST_FAIL";

export const getWebsiteAuditList = () => (dispatch) => {
  dispatch({ type: GET_WEBSITE_AUDIT_LIST_BEGIN });
  return serverCall({
    method: "GET",
    url: "/api/v1/website-audit/list/",
  })
    .then((res) => {
      dispatch({
        type: GET_WEBSITE_AUDIT_LIST_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_WEBSITE_AUDIT_LIST_FAIL,
        payload: err,
      });
    });
};
