import { PageLoader } from "@components";
import { ThemeProvider } from "@mui/material/styles";
import React, { Suspense } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./appRedux/store";
import theme from "./config/theme";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

const loadingStyle = {
  width: "100%",
  height: "vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

ReactDOM.render(
  <Suspense
    fallback={(
      <div className="page-wrapper">
        <div className="main-content">
          <div className="container-fluid">
            <div style={{ loadingStyle }}>
              <PageLoader />
            </div>
          </div>
        </div>
      </div>
    )}
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById("content-portal")
);

serviceWorker.unregister();
