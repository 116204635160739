import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SubBodyWebsite from "./SubBodyWebsite";

function WeisiteTable(props) {
  const { ungrouped, dataRoot } = props;
  const { t } = useTranslation();

  return (
    <div className="ranking-table box__shadow">
      <div className="table-title">
        <h3>{t("siteList.tableOne.title")}</h3>
      </div>

      <div className="site-list-table ">
        <div className="site-list-head">
          <div className="list-head-title">
            <div className="single-head">
              <strong>{t("siteList.tableOne.title")}</strong>
            </div>
            <div className="single-head dashboard_table_des">
              <strong className="d-block">
                <span>{t("siteList.tableOne.thead.8")} 5</span>
                <span>{t("siteList.tableOne.thead.8")} 10</span>
                <span>{t("siteList.tableOne.thead.8")} 30</span>
              </strong>
            </div>
            <div className="single-head dashboard_table_des">
              <strong>{t("siteList.tableOne.thead.3")}</strong>
            </div>

            <div className="single-head dashboard_table_des_table">
              <strong className="d-block">
                {t("siteList.tableOne.thead.4")}
              </strong>
              {Object.keys(dataRoot).length > 0 &&
                dataRoot.dataRoot.avg_position_date.map((date, index) => (
                  <span className="m-0 date" key={index}>
                    {index ? "" : " "} {date}
                  </span>
                ))}
            </div>

            <div className="single-head actions dashboard_table_des">
              <strong>{t("siteList.tableOne.thead.7")}</strong>
            </div>
          </div>
        </div>
        {/* End table head  */}

        {/* Start table body  */}
        {ungrouped.sites.length > 0 ? (
          <div className="site-list-body ">
            {ungrouped.sites ? (
              ungrouped.sites.map((site, index) => (
                <SubBodyWebsite
                  key={index}
                  site={site}
                  dataRoot={dataRoot}
                  index={index}
                  props={props}
                />
              ))
            ) : (
              <Fragment />
            )}
          </div>
        ) : (
          <h2 className="no_data_found">{t("common.noData")}</h2>
        )}
      </div>
    </div>
  );
}

export default WeisiteTable;
