import { createBrowserHistory } from "history";
// On redéclare ensuite les méthodes
export const history = createBrowserHistory();

export const registerNav = () => "/";

const jumpTo = (uri) => {
  history.push(uri);
};

export const go = (uri) => {
  history.go(uri);
};

export default jumpTo;
