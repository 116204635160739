import React from "react";
import { useTranslation } from "react-i18next";
import SubBody from "./SubBody";

function GroupTable(props) {
  const { t } = useTranslation();

  const { grouped, dataRoot } = props;

  function searchEngineByGroup() {
    return grouped.map((group) => {
      group.sitesData = { site_id: "", seData: [] };
      group.sites.map((site) => {
        group.sitesData = {
          site_id: site.id,
          seData: [...group.sitesData.seData, ...site.searc_engines],
        };
        return null;
      });
      return null;
    });
  }

  searchEngineByGroup();

  function getGroupKeyWordCount(index) {
    return grouped[index].sites.reduce(
      (value, el) => value + parseInt(el.keyword_count),
      0
    );
  }

  function getGroupLastUpdate(index) {
    if (grouped[index].sites.length !== 0) {
      const data = grouped[index].sites;
      let lastUpdate = data[0].last_updated;
      if (data.length > 1) {
        for (let i = 1; i < data.length; i += 1) {
          const firstDate = new Date(lastUpdate);
          const secondDate = new Date(data[i].lastUpdate);
          if (firstDate < secondDate) {
            lastUpdate = data[i].lastUpdate;
          }
        }
      }
      return lastUpdate;
    }
    return index;
  }

  return (
    <div className="my-4 ranking-table box__shadow">
      <div className="table-title">
        <h3>{t("siteList.tableTwo.title")}</h3>
      </div>
      <div className="site-list-table ">
        <div className="site-list-head">
          <div className="list-head-title">
            <div className="single-head">
              <strong>{t("siteList.tableTwo.thead.1")}</strong>
            </div>
            <div className="single-head dashboard_table_des">
              <strong className="d-block">
                <span>{t("siteList.tableOne.thead.8")} 5</span>
                <span>{t("siteList.tableOne.thead.8")} 10</span>
                <span>{t("siteList.tableOne.thead.8")} 30</span>
              </strong>
            </div>
            <div className="single-head dashboard_table_des">
              <strong>{t("siteList.tableTwo.thead.3")}</strong>
            </div>

            <div className="single-head dashboard_table_des_table">
              <strong className="d-block">
                {t("siteList.tableOne.thead.4")}
              </strong>
              {Object.keys(dataRoot).length > 0 &&
                dataRoot.dataRoot.avg_position_date.map((date, index) => (
                  <span className="m-0 date" key={index}>
                    {index ? "" : " "} {date}
                  </span>
                ))}
            </div>

            <div className="single-head actions dashboard_table_des">
              <strong>{t("siteList.tableTwo.thead.7")}</strong>
            </div>
          </div>
        </div>
        {/* End table head  */}

        {/* Start table body  */}
        {grouped.length > 0 ? (
          <div className="site-list-body ">
            {grouped.map((group, index) => (
              <SubBody
                index={index}
                key={group.id}
                getGroupKeyWordCount={getGroupKeyWordCount}
                getGroupLastUpdate={getGroupLastUpdate}
                group={group}
                dataRoot={dataRoot}
                props={props}
              />
            ))}
          </div>
        ) : (
          <h2 className="no_data_found"> {t("common.noData")} </h2>
        )}
      </div>
    </div>
  );
}

export default GroupTable;
