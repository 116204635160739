import serverCall from '../../modules/serverCall';

export const getAllLanguage = (language_id) => (dispatch) => {
  dispatch({
    type: GET_ALL_LANGUAGE_BEGIN,
  });
  return serverCall({
    method: 'GET',
    url: `/api/v1/language/${language_id}/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_LANGUAGE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_LANGUAGE_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const GET_ALL_LANGUAGE_BEGIN = 'GET_ALL_LANGUAGE_BEGIN';
export const GET_ALL_LANGUAGE_SUCCESS = 'GET_ALL_LANGUAGE_SUCCESS';
export const GET_ALL_LANGUAGE_FAIL = 'GET_ALL_LANGUAGE_SUCCESS';
