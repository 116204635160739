import {
	GET_MANAGE_GROUP_LIST_BEGIN,
	GET_MANAGE_GROUP_LIST_FAIL,
	GET_MANAGE_GROUP_LIST_SUCCESS,
} from "../../action/manage-group/manageGroupAction";

const initialState = {
	groupLists: [],
	loading: false,
	error: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_MANAGE_GROUP_LIST_BEGIN:
			return {
				...state,
				loading: true,
			};
		case GET_MANAGE_GROUP_LIST_SUCCESS:
			return {
				...state,
				groupLists: action.payload.data,
				loading: false,
			};
		case GET_MANAGE_GROUP_LIST_FAIL:
			return {
				...state,
				error: true,
			};
		default:
			return state;
	}
};
