import React from "react";
import Helmet from "react-helmet";
import ReactHtmlParser from "react-html-parser";

function Meta({ headerElements }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {ReactHtmlParser(headerElements)}
    </Helmet>
  );
}

export default Meta;
