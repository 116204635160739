import InfoTooltip from "@components/InfoTooltip";
import InfoIcon from "@mui/icons-material/Info";
import {
 Box, Checkbox, FormControl, Typography
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import React, {
 Fragment, useCallback, useEffect, useState
} from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "../../../InputLabel";

const useStyles = makeStyles((theme) => ({
  radio: {
    marginBottom: "1rem",
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      "& > label": {
        marginTop: "0"
      }
    }
  },
  selectEvent: {
    "& > label": {
      marginTop: "0 !important"
    }
  },
  checkInput: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: ".8fr 1fr",
    [theme.breakpoints.down("xl")]: {
      gridTemplateColumns: "1fr"
    },
    "& > label": {
      marginTop: ".5rem !important",
      "& .MuiFormControlLabel-label": {
        display: "flex",
        alignItems: "center",
        "& > div": {
          marginLeft: "5px"
        }
      }
    },
    "& > div": {
      minWidth: "50%",
      whiteSpace: "nowrap",
      "& > input": {
        marginTop: ".5rem !important",
        marginBottom: "0 !important"
      }
    },
    "& .input__box": {
      display: "grid",
      gridTemplateColumns: "1.2fr .5fr",
      alignItems: "center"
    }
  }
}));

const ExtraField = ({ values, setValues }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { event_type, show_form_view_as } = values.widget_settings_extra;
  const [formView, setFormView] = useState(show_form_view_as);
  const [checked, setChecked] = useState(event_type?.on_exit === 1);
  const [checkedScroll, setCheckedScroll] = useState(event_type?.on_scroll?.toString() !== "-1");
  const [checkedTime, setCheckedTime] = useState(event_type?.on_timeout?.toString() !== "-1");

  useEffect(() => {
    if (event_type) {
      setChecked(event_type.on_exit === 1);
      setCheckedScroll(event_type.on_scroll.toString() !== "-1");
      setCheckedTime(event_type.on_timeout.toString() !== "-1");
    }

    setFormView(show_form_view_as);
  }, [values]);

  const handleChangeExtra = useCallback((event) => {
    const { name, value } = event.target;

    console.log(event);

    if (name !== "show_form_view_as") {
      setValues((prevState) => ({
        ...prevState,
        widget_settings_extra: {
          ...prevState.widget_settings_extra,
          event_type: {
            ...prevState.widget_settings_extra.event_type,
            [name]: parseInt(value)
          }
        }
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        widget_settings_extra: {
          ...prevState.widget_settings_extra,
          [name]: parseInt(value)
        }
      }));
    }
  }, []);

  return (
    <Fragment>
      <FormControl className={classes.radio} component="fieldset">
        <InputLabel
          title="Set the Takeover Form as per session or page view. You can show this form just once for a user when they land on your website. Or, you can show this form whenever a visitor visits the same page"
          label={t("Takeover Form View as")}
          required
        />
        <RadioGroup
          aria-label="page_view"
          value={formView || 1}
          name="show_form_view_as"
          onChange={handleChangeExtra}
        >
          <FormControlLabel value={1} control={<Radio />} label="Per Session" />
          <FormControlLabel value={2} control={<Radio />} label="Per Page View" />
        </RadioGroup>
      </FormControl>
      <div className="input_wrapper">
        <InputLabel
          title="In Takeover Form, we have three events that how you want to engage with your visitors. Select anyone."
          label={t("Select The Takeover Form Event as")}
          required
        />
        <Box className={classes.selectEvent}>
          <Box className={classes.checkInput}>
            <FormControlLabel
              onChange={(e) => {
                setChecked(!checked);
                setValues((prevState) => ({
                  ...prevState,
                  widget_settings_extra: {
                    ...prevState.widget_settings_extra,
                    event_type: {
                      ...prevState.widget_settings_extra.event_type,
                      on_exit: e.target.checked === true ? 1 : 0
                    }
                  }
                }));
              }}
              control={<Checkbox checked={checked} color="primary" />}
              name="on_exit"
              label={(
                <>
                  Show Takeover Form On Exit
                  <InfoTooltip
                    bgWhite
                    width={250}
                    title="In Takeover Form, we have three events that show how you want to engage with your visitors. Select anyone."
                  >
                    <span>
                      <InfoIcon />
                    </span>
                  </InfoTooltip>
                </>
              )}
            />
          </Box>
          <Box className={classes.checkInput}>
            <FormControlLabel
              onChange={(e) => {
                setCheckedTime(!checkedTime);
                setValues((prevState) => ({
                  ...prevState,
                  widget_settings_extra: {
                    ...prevState.widget_settings_extra,
                    event_type: {
                      ...prevState.widget_settings_extra.event_type,
                      on_timeout:
                        e.target.checked === true
                          ? event_type.on_timeout === -1
                            ? ""
                            : event_type.on_timeout
                          : -1
                    }
                  }
                }));
              }}
              control={<Checkbox checked={checkedTime} color="primary" />}
              label={(
                <>
                  <span>Show Takeover Form After</span>
                  <InfoTooltip
                    bgWhite
                    width={250}
                    title="When a visitor scrolls the page, set the time after how many seconds you want the Takeover Form to appear. For example: after 5, 10 seconds. If you select 0, it will Takeover automatically."
                  >
                    <InfoIcon />
                  </InfoTooltip>
                </>
              )}
              name="on_timeout"
            />
            {checkedTime && (
              <Box className="input__box">
                <input
                  type="number"
                  placeholder={t("Enter Seconds")}
                  name="on_timeout"
                  onChange={handleChangeExtra}
                  value={event_type?.on_timeout}
                />

                <Typography sx={{ ml: "1rem" }} component="small">
                  Second
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.checkInput}>
            <FormControlLabel
              onChange={(e) => {
                setCheckedScroll(!checkedScroll);
                setValues((prevState) => ({
                  ...prevState,
                  widget_settings_extra: {
                    ...prevState.widget_settings_extra,
                    event_type: {
                      ...prevState.widget_settings_extra.event_type,
                      on_scroll:
                        e.target.checked === true
                          ? event_type.on_scroll === -1
                            ? ""
                            : event_type.on_scroll
                          : -1
                    }
                  }
                }));
              }}
              control={<Checkbox checked={checkedScroll} color="primary" />}
              label={(
                <Fragment>
                  <span>Show Takeover Form After Scrolling</span>
                  <InfoTooltip
                    bgWhite
                    width={250}
                    title="Set the pixel for Takeover Form to appear when a visitor scrolls the page. For example, after scrolling above the fold section, when they go to the middle of the page, or when they go to the footer section."
                  >
                    <InfoIcon />
                  </InfoTooltip>
                </Fragment>
              )}
              name="on_scroll"
            />
            {checkedScroll && (
              <Box className="input__box">
                <input
                  type="number"
                  placeholder={t("Enter Pixels")}
                  name="on_scroll"
                  onChange={handleChangeExtra}
                  value={event_type?.on_scroll}
                />
                <Typography sx={{ ml: "1rem" }} component="small">
                  Pixel
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};
export default React.memo(ExtraField);
