import {
  ADD_KEYWORD_BEGIN,
  ADD_KEYWORD_FAIL,
  ADD_KEYWORD_SUCCESS,
  CREATE_KEYWORD_GROUP_BEGIN,
  CREATE_KEYWORD_GROUP_FAIL,
  CREATE_KEYWORD_GROUP_SUCCESS,
  DELETE_KEYWORD_BEGIN,
  DELETE_KEYWORD_FAIL,
  DELETE_KEYWORD_SUCCESS,
  GET_ACTIVE_KEYWORD_BEGIN,
  GET_ACTIVE_KEYWORD_FAIL,
  GET_ACTIVE_KEYWORD_SUCCESS,
  GET_KEYWORD_GROUPED_BEGIN,
  GET_KEYWORD_GROUPED_FAIL,
  GET_KEYWORD_GROUPED_SUCCESS,
  GET_KEYWORD_GROUP_BEGIN,
  GET_KEYWORD_GROUP_FAIL,
  GET_KEYWORD_GROUP_SUCCESS,
  GET_KEYWORD_UNGROUPED_BEGIN,
  GET_KEYWORD_UNGROUPED_FAIL,
  GET_KEYWORD_UNGROUPED_SUCCESS,
  STEP_THREE_DATA_POST_BEGIN,
  STEP_THREE_DATA_POST_FAIL,
  STEP_THREE_DATA_POST_SUCCESS
} from "../action/keywordAction";

const initialState = {
  create_keyword_loading: false,
  group_id: null,
  get_keyword_loading: false,
  add_keyword_loading: false,
  delete_keyword_loading: false,
  update_project: false,
  groupskeyword: [],
  keyword_list_loading: false,
  keyword_list_ungrouped: {},
  keyword_list: {},
  keyword_list_grouped: [],
  keyword_lists: [],
  error: {},
  active_keywords: [],
  addedgroup: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ---------- create keyword section---------
    case STEP_THREE_DATA_POST_BEGIN:
      return {
        ...state,
        update_project: true,
      };

    case STEP_THREE_DATA_POST_SUCCESS:
      return {
        ...state,
        update_project: false,
        keyword_list: {},
      };

    case STEP_THREE_DATA_POST_FAIL:
      return {
        ...state,
        update_project: false,
      };
    case CREATE_KEYWORD_GROUP_BEGIN:
      return {
        ...state,
        create_keyword_loading: true,
        addedgroup: null,
      };

    case CREATE_KEYWORD_GROUP_SUCCESS:
      return {
        ...state,
        create_keyword_loading: false,
        addedgroup: action.payload.data.data.group,
      };

    case CREATE_KEYWORD_GROUP_FAIL:
      return {
        ...state,
        create_keyword_loading: false,
        addedgroup: null,
      };
    // -------- get keyword group -------------
    case GET_KEYWORD_GROUP_BEGIN:
      return {
        ...state,
        get_keyword_loading: true,
      };

    case GET_KEYWORD_GROUP_SUCCESS:
      return {
        ...state,
        get_keyword_loading: false,
        groupskeyword: action.payload.data.data.groups,
      };

    case GET_KEYWORD_GROUP_FAIL:
      return {
        ...state,
        get_keyword_loading: false,
      };

    // ---------- get keyword ungrouped-----------------
    case GET_KEYWORD_UNGROUPED_BEGIN:
      return {
        ...state,
        keyword_list_loading: true,
      };

    case GET_KEYWORD_UNGROUPED_SUCCESS:
      return {
        ...state,
        keyword_list_loading: false,
        keyword_list_ungrouped: action.payload.data.data.keyword_groups.ungrouped,
      };

    case GET_KEYWORD_UNGROUPED_FAIL:
      return {
        ...state,
        keyword_list_loading: false,
        error: action.payload.error.data,
      };

    // ---------- get keyword grouped-----------------
    case GET_KEYWORD_GROUPED_BEGIN:
      return {
        ...state,
        keyword_list_loading: true,
      };

    case GET_KEYWORD_GROUPED_SUCCESS:
      return {
        ...state,
        keyword_list_loading: false,
        keyword_list_grouped: action.payload.data.data.keyword_groups.grouped,
      };

    case GET_KEYWORD_GROUPED_FAIL:
      return {
        ...state,
        keyword_list_loading: false,
        error: action.payload.error.data,
      };

    // ------- add keyword ----------------------
    case ADD_KEYWORD_BEGIN:
      return {
        ...state,
        add_keyword_loading: true,
      };

    case ADD_KEYWORD_SUCCESS:
      return {
        ...state,
        // keyword_list: {
        //   ...state.keyword_list,
        //   ...action.payload.data.data.added_keywords,
        // },
        keyword_list: action.payload.data.data.added_keywords,
        add_keyword_loading: false,
      };

    case ADD_KEYWORD_FAIL:
      return {
        ...state,
        add_keyword_loading: false,
      };

    // ------- delete keyword ----------------------
    case DELETE_KEYWORD_BEGIN:
      return {
        ...state,
        delete_keyword_loading: true,
      };

    case DELETE_KEYWORD_SUCCESS:
      return {
        ...state,
        delete_keyword_loading: false,
      };

    case DELETE_KEYWORD_FAIL:
      return {
        ...state,
        delete_keyword_loading: false,
      };
    // ------- get active keyword ----------------------
    case GET_ACTIVE_KEYWORD_BEGIN:
      return {
        ...state,
        keyword_list_loading: true,
      };

    case GET_ACTIVE_KEYWORD_SUCCESS:
      return {
        ...state,
        active_keywords: action.payload.data.data.active_keyword,
        keyword_list_loading: false,
      };

    case GET_ACTIVE_KEYWORD_FAIL:
      return {
        ...state,
        keyword_list_loading: false,
      };

    default:
      return state;
  }
};
