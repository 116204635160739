import LoaderSvg from "@components/ButtonLoader";
import { makeStyles } from "@mui/styles";
import React from "react";

const useToolTipStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "no-wrap",
    minWidth: "400px",
    minHeight: "45px",
    "& > div": {
      maxWidth: "55px",
      minWidth: "65px",
      textAlign: "center",
      borderRight: "1px solid #212c3f",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "&:last-child": {
        borderRight: "0",
      },
    },
    "& span": {
      color: theme.palette.common.white,
      display: "block",
      "&:first-child": {
        color: "#98b7ea",
        fontWeight: "600",
      },
      "&:last-child": {
        fontWeight: "400",
      },
    },
  },
}));

function ToolTipData({ data, loading }) {
  const classes = useToolTipStyle();
  return (
    <div className={classes.root}>
      {data.map((ite, index) => (
        <div key={index}>
          {loading.load ? (
            <LoaderSvg />
          ) : (
            <React.Fragment>
              <span>{ite.date}</span>
              <span>{ite.data}</span>
            </React.Fragment>
          )}
        </div>
      ))}
    </div>
  );
}

export default ToolTipData;
