import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import CloseBtn from "./CloseBtn";
import CtaButton from "./CtaButton";
import FieldStyle from "./FieldStyle";
import FormBody from "./FormBody";
import FormDes from "./FormDes";
import FormMain from "./FormMain";
import FormTitle from "./FormTitle";
import InputError from "./InputError";
import InputLabel from "./InputLabel";
import InputPlaceholder from "./InputPlaceholder";
import PopupIcon from "./PopupIcon";
import ReportCloseBtn from "./ReportCloseBtn";
import TncStyle from "./TncStyle";

const FormStyle = ({
  values,
  setValues,
  setChangingObject,
  changingObject,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStyleChange = useCallback((e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      form_style: {
        ...prevValues.form_style,
        [name]: parseInt(value),
      },
    }));
    setChangingObject((prevValues) => ({
      ...prevValues,
      form_style: {
        ...prevValues.form_style,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <div className={`${classes.accordion} margin-16px`}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChangeAccordion("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.title")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.des")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormMain
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
                handleStyleChange={handleStyleChange}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box>
                <Typography sx={{ mt: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.1")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.2")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormBody
                changingObject={changingObject}
                setChangingObject={setChangingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.3")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.4")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormTitle
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.5")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.6")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormDes
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel111"}
            onChange={handleChangeAccordion("panel111")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel111bh-content"
              id="panel111bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.7")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.8")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TncStyle
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChangeAccordion("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.9")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.10")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FieldStyle
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChangeAccordion("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.11")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.12")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InputLabel
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChangeAccordion("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8bh-content"
              id="panel8bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.13")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.14")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InputPlaceholder
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChangeAccordion("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel9bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.15")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.16")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <InputError
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChangeAccordion("panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11bh-content"
              id="panel11bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.19")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.20")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <CloseBtn
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel112"}
            onChange={handleChangeAccordion("panel112")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel112bh-content"
              id="panel112bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.21")}
                </Typography>
                <Typography variant="body1">
                  {t("createWidget.formStyle.22")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ReportCloseBtn
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChangeAccordion("panel12")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12bh-content"
              id="panel12bh-header"
            >
              <Box>
                <Typography sx={{ m: "0 !important" }} variant="h4">
                  {t("createWidget.formStyle.23")}
                </Typography>
                <Typography>{t("createWidget.formStyle.24")}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <CtaButton
                setChangingObject={setChangingObject}
                changingObject={changingObject}
                values={values}
                setValues={setValues}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </Grid>
  );
};

export default React.memo(FormStyle);
