import {
    GET_EMAIL_SETTING_BEGIN,
    GET_EMAIL_SETTING_FAIL,
    GET_EMAIL_SETTING_SUCCESS,
    GET_SMTP_SETTING_BEGIN,
    GET_SMTP_SETTING_FAIL,
    GET_SMTP_SETTING_SUCCESS,
    POST_EMAIL_SETTING_BEGIN,
    POST_EMAIL_SETTING_FAIL,
    POST_EMAIL_SETTING_SUCCESS,
    POST_RESET_SETTING_BEGIN,
    POST_RESET_SETTING_FAIL,
    POST_RESET_SETTING_SUCCESS,
    POST_SMTP_SETTING_BEGIN,
    POST_SMTP_SETTING_FAIL,
    POST_SMTP_SETTING_SUCCESS,
    POST_TEST_SETTING_BEGIN,
    POST_TEST_SETTING_FAIL,
    POST_TEST_SETTING_SUCCESS
} from "../../action/widgetSettingsActions";

const initialState = {
    smtpData: {
        data: {
            sender_name: "",
            sender_email: "",
            host: "",
            port: "",
            auto_tls: "",
            authentication: "",
            user_name: "",
            password: "",
            smtp_verified: "",
            active_lead_email: "",
        },
        loading: false,
        error: null,
    },

    emailData: {
        data: {},
        loading: false,
        error: null,
    },

    getSmtpData: {
        data: {},
        loading: false,
    },
    getEmailData: {
        data: {},
        loading: false,
    },

    resetData: {
        data: {},
        loading: false,
        error: null,
    },

    sendTestData: {
        data: {},
        loading: false,
        error: null,
    },
};

const widgetSettingReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case POST_SMTP_SETTING_BEGIN:
            return {
                ...state,
                smtpData: {
                    data: payload,
                    loading: true,
                },
            };
        case POST_SMTP_SETTING_SUCCESS:
            return {
                ...state,
                smtpData: {
                    data: payload,
                    loading: false,
                },
            };
        case POST_SMTP_SETTING_FAIL:
            return {
                ...state,
                smtpData: {
                    data: payload,
                    loading: false,
                },
            };

        case POST_EMAIL_SETTING_BEGIN:
            return {
                ...state,
                emailData: {
                    data: payload,
                    loading: true,
                },
            };
        case POST_EMAIL_SETTING_SUCCESS:
            return {
                ...state,
                emailData: {
                    data: payload,
                    loading: false,
                },
            };
        case POST_EMAIL_SETTING_FAIL:
            return {
                ...state,
                emailData: {
                    data: payload,
                    loading: false,
                },
            };

        case POST_RESET_SETTING_BEGIN:
            return {
                ...state,
                resetData: {
                    data: payload,
                    loading: true,
                },
            };
        case POST_RESET_SETTING_SUCCESS:
            return {
                ...state,
                resetData: {
                    data: payload,
                    loading: false,
                },
            };
        case POST_RESET_SETTING_FAIL:
            return {
                ...state,
                resetData: {
                    data: payload,
                    loading: false,
                },
            };

        case POST_TEST_SETTING_BEGIN:
            return {
                ...state,
                sendTestData: {
                    data: payload,
                    loading: true,
                },
            };
        case POST_TEST_SETTING_SUCCESS:
            return {
                ...state,
                sendTestData: {
                    data: payload,
                    loading: false,
                },
            };
        case POST_TEST_SETTING_FAIL:
            return {
                ...state,
                sendTestData: {
                    data: payload,
                    loading: false,
                },
            };

            // Start Get SMTP DATA case
        case GET_SMTP_SETTING_BEGIN:
            return {
                ...state,
                getSmtpData: {
                    data: payload,
                    loading: true,
                },
            };
        case GET_SMTP_SETTING_SUCCESS:
            return {
                ...state,
                getSmtpData: {
                    data: payload,
                    loading: false,
                },
            };
        case GET_SMTP_SETTING_FAIL:
            return {
                ...state,
                getSmtpData: {
                    data: payload,
                    loading: false,
                },
            };
            // Start Get EMAIL DATA case
        case GET_EMAIL_SETTING_BEGIN:
            return {
                ...state,
                getEmailData: {
                    data: payload,
                    loading: true,
                },
            };
        case GET_EMAIL_SETTING_SUCCESS:
            return {
                ...state,
                getEmailData: {
                    data: payload,
                    loading: false,
                },
            };
        case GET_EMAIL_SETTING_FAIL:
            return {
                ...state,
                getEmailData: {
                    data: payload,
                    loading: false,
                },
            };
        default:
            return state;
    }
};

export default widgetSettingReducer;
