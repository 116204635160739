import { Box, Typography } from "@mui/material";
import React from "react";
import LabelTooltip from "./LabelTooltip";
import useStyles from "./styles";

const RequiredLabel = ({
 label, title, required, optional
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.requiredLabel}>
      <Typography variant="body1" component="label">
        {label}

        {required && (
          <Typography variant="body2" component="small">*</Typography>
        )}

        {optional && (
          <Typography variant="body2" component="span">&nbsp;(Optional)</Typography>
        )}

      </Typography>
      {title && (
        <LabelTooltip title={title} />
      )}
    </Box>
  );
};

export default RequiredLabel;
