import serverCall from "../../../modules/serverCall";
import { setAlert } from "../alert";
import i18n from "../../../i18n";

export const GET_MANAGE_GROUP_LIST_BEGIN = "GET_MANAGE_GROUP_LIST_BEGIN";
export const GET_MANAGE_GROUP_LIST_SUCCESS = "GET_MANAGE_GROUP_LIST_SUCCESS";
export const GET_MANAGE_GROUP_LIST_FAIL = "GET_MANAGE_GROUP_LIST_FAIL";

export const GET_MANAGE_GROUP_DELETE_BEGIN = "GET_MANAGE_GROUP_DELETE_BEGIN";
export const GET_MANAGE_GROUP_DELETE_SUCCESS = "GET_MANAGE_GROUP_DELETE_SUCCESS";
export const GET_MANAGE_GROUP_DELETE_FAILED = "GET_MANAGE_GROUP_DELETE_FAILED";

export const GET_MANAGE_GROUP_EDIT_BEGIN = "GET_MANAGE_GROUP_EDIT_BEGIN";
export const GET_MANAGE_GROUP_EDIT_SUCCESS = "GET_MANAGE_GROUP_EDIT_SUCCESS";
export const GET_MANAGE_GROUP_EDIT_FAILED = "GET_MANAGE_GROUP_EDIT_FAILED";

export const manageGroupAction = (id) => (dispatch) => {
  dispatch({ type: GET_MANAGE_GROUP_LIST_BEGIN });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${id}/keyword/group/list/`,
  })
    .then((res) => {
      console.log(id);
      dispatch({
        type: GET_MANAGE_GROUP_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MANAGE_GROUP_LIST_FAIL,
        payload: err,
      });
    });
};

export const deleteGroupAction = (data) => (dispatch) => {
  dispatch({ type: GET_MANAGE_GROUP_DELETE_BEGIN });
  return serverCall({
    method: "DELETE",
    url: `/api/v1/project/${data.projectId}/keyword/group/${data.groupId}/`,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: GET_MANAGE_GROUP_DELETE_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_MANAGE_GROUP_DELETE_FAILED,
        payload: err,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
    });
};

export const editGroupAction = (data) => (dispatch) => {
  dispatch({ type: GET_MANAGE_GROUP_EDIT_BEGIN });
  return serverCall({
    method: "POST",
    url: `/api/v1/project/${data.projectId}/keyword/group/${data.groupId}/`,
    data: {
      group_name: data.groupName,
    },
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: GET_MANAGE_GROUP_EDIT_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_MANAGE_GROUP_EDIT_FAILED,
        payload: err,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
    });
};
