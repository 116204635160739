import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const UPDATA_LEADSYNC_DATA_BEGIN = "UPDATA_LEADSYNC_DATA_BEGIN";
export const UPDATA_LEADSYNC_DATA_SUCCESS = "UPDATA_LEADSYNC_DATA_SUCCESS";
export const UPDATA_LEADSYNC_DATA_FAIL = "UPDATA_LEADSYNC_DATA_FAIL";

export const GET_LEADSYNC_DATA_BEGIN = "GET_LEADSYNC_DATA_BEGIN";
export const GET_LEADSYNC_DATA_SUCCESS = "GET_LEADSYNC_DATA_SUCCESS";
export const GET_LEADSYNC_DATA_FAIL = "GET_LEADSYNC_DATA_FAIL";


export const getLeadSyncData = (sync_type) => (dispatch) => {
    dispatch({
      type: GET_LEADSYNC_DATA_BEGIN,
    });
    return serverCall({
      method: "GET",
      url: `/api/v1/leadsync/${sync_type}/`,
    })
      .then((res) => {
        dispatch({
          type: GET_LEADSYNC_DATA_SUCCESS,
          payload: res.data.data.details,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: GET_LEADSYNC_DATA_FAIL,
          payload: { error },
        });
        return error;
      });
  };
  export const updateLeadSyncToken = (sync_type,token) => (dispatch) => {
    dispatch({
      type: UPDATA_LEADSYNC_DATA_BEGIN,
    });
    const data = {
      token: token,
    };
    return serverCall({
      method: "POST",
      url: `/api/v1/leadsync/${sync_type}/`,
      data,
    })
      .then((res) => {
        if(res.status >=200 && res.status<300){
          dispatch({
            type: UPDATA_LEADSYNC_DATA_SUCCESS,
            payload: data,
          });
          if(res.data.message){
            dispatch(setAlert(res.data.message, "success"));

          }
  
        }else{
          dispatch(setAlert(res.data.message, "danger"));
        }
        return data;
      })
      .catch((error) => {
        dispatch({
          type: UPDATA_LEADSYNC_DATA_FAIL,
          payload: { error },
        });
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
  
      });
  };
  export const updateLeadSyncCode = (sync_type,token,current_url) => (dispatch) => {
    dispatch({
      type: UPDATA_LEADSYNC_DATA_BEGIN,
    });
    const data = {
      token: token,
      current_url: current_url,
    };
    return serverCall({
      method: "POST",
      url: `/api/v1/leadsync/${sync_type}/`,
      data,
    })
      .then((res) => {
        console.log(res);
        if(res.status >=200 && res.status<300){
          dispatch({
            type: UPDATA_LEADSYNC_DATA_SUCCESS,
            payload: data,
          });
          if(res.data.message){
            dispatch(setAlert(res.data.message, "success"));
          }
          window.location.href ='/sync-lead/constant_contact';
  
        }else{
          dispatch(setAlert(res.data.message, "danger"));
        }
        return data;
      })
      .catch((error) => {
        dispatch({
          type: UPDATA_LEADSYNC_DATA_FAIL,
          payload: { error },
        });
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
  
      });
  };
  export const updateLeadSyncField = (sync_type,field,value) => (dispatch) => {
    var data = {
     
    };
    data[field] = value;
    return serverCall({
      method: "POST",
      url: `/api/v1/leadsync/${sync_type}/`,
      data,
    })
      .then((res) => {
        if(res.status >=200 && res.status<300){
          if(res.data.message){
            dispatch(setAlert(res.data.message, "success"));
          }
  
        }else{
          dispatch(setAlert(res.data.message, "danger"));
        }
        return data;
      })
      .catch((error) => {
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
  
      });
  };
  export const deleteLeadSyncToken = (sync_type) => (dispatch) => {
    const data = {
      token: "",
    };
    return serverCall({
      method: "POST",
      url: `/api/v1/leadsync/${sync_type}/`,
      data,
    })
      .then((res) => {
        if(res.status >=200 && res.status<300){
          dispatch({
            type: UPDATA_LEADSYNC_DATA_SUCCESS,
            payload: data,
          });
  
        }
        return data;
      })
      .catch((error) => {
        dispatch({
          type: UPDATA_LEADSYNC_DATA_FAIL,
          payload: { error },
        });
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
  
      });
  };
  export const updateLeadSyncData = (user) => (dispatch) => {
    dispatch({
      type: UPDATA_LEADSYNC_DATA_BEGIN,
    });
    const data = {
      name: user.name,
      company_name: user.company_name,
      phone: user.phone,
      email: user.email,
      zipcode: user.zipcode,
      street: user.street,
      tax_number: user.tax_number,
      invoice_note: user.invoice_note,
    };
    return serverCall({
      method: "POST",
      url: `/api/v1/account/billing/update/`,
      data,
    })
      .then((res) => {
        if(res.status >=200 && res.status<300){
          dispatch({
            type: UPDATA_LEADSYNC_DATA_SUCCESS,
            payload: data,
          });
          dispatch(setAlert(res.data.message, "success"));
  
        }else{
          dispatch(setAlert(res.data.message, "danger"));
        }
        return data;
      })
      .catch((error) => {
        dispatch({
          type: UPDATA_LEADSYNC_DATA_FAIL,
          payload: { error },
        });
        dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
  
      });
  };
  