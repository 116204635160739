import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import { fontWeightOptions, textTransformOption } from "./selectOptions";

const InputError = ({
  values,
  setValues,
  setChangingObject,
  changingObject,
}) => {
  const classes = useStyles();

  const { input_error_message } = values;

  const handleInputError = useCallback((e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      input_error_message: {
        ...prevValues.input_error_message,
        [name]: parseInt(value),
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      input_error_message: {
        ...prevValues.input_error_message,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      input_error_message: {
        ...prevValues.input_error_message,
        [name]: data.value,
      },
    }));

    setChangingObject((prevValues) => ({
      ...prevValues,
      input_error_message: {
        ...prevValues.input_error_message,
        [name]: data.value,
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Box className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.text")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="color"
              type="none"
              nested="input_error_message"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.size")}
          </Typography>
          <div>
            <input
              name="text_size"
              value={input_error_message.text_size}
              onChange={handleInputError}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.transform")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={textTransformOption}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "text_transform")}
              value={textTransformOption.find(
                ({ value }) => value === input_error_message.text_transform
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.fontWeight")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={fontWeightOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptions.find(
                ({ value }) => value === input_error_message.font_weight
              )}
            />
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(InputError);
