import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import LoaderSvg from "components/common/LoaderSvg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "500",
    },

    "&.MuiDialog-paper": {
      textAlign: "center",
    },
  },

  openButton: {
    margin: "0",
    padding: "0",
    background: "none",
  },
}));

function DeletionModal({ data, submitDeleteEngines, removeActiveCompetitor }) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Handle Close function
  const handleClose = () => {
    setOpen(false);
  };

  // Handle Delete Function
  const handleDelete = async () => {
    await setLoading(true);

    await submitDeleteEngines(data.id, data.event.id);

    await removeActiveCompetitor();

    await setOpen(false);
  };

  return (
    <div>
      <button
        className={`${classes.openButton} seo_btn btn_xs`}
        type="button"
        variant="outlined"
        style={{ background: "white" }}
        color="primary"
        onClick={handleClickOpen}
      >
        {data.label}
      </button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
      >
        <DialogTitle id="responsive-dialog-title"> {data.title} </DialogTitle>
        <DialogContent>
          <DialogContentText>{data.des}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="seo_btn gradient_green btn_small"
            type="button"
            onClick={handleClose}
            color="primary"
          >
            {t("common.cancel")}
          </button>
          <button
            type="button"
            className="seo_btn btn_red btn_small"
            onClick={handleDelete}
            color="primary"
          >
            {t("common.delete")} {loading && <LoaderSvg />}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeletionModal;
