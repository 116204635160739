import serverCall from "../../../modules/serverCall";
import { setAlert } from "../alert";
import i18n from "../../../i18n";

export const UPDATA_BILLING_DATA_BEGIN = "UPDATA_BILLING_DATA_BEGIN";
export const UPDATA_BILLING_DATA_SUCCESS = "UPDATA_BILLING_DATA_SUCCESS";
export const UPDATA_BILLING_DATA_FAIL = "UPDATA_BILLING_DATA_FAIL";

export const GET_BILLING_DATA_BEGIN = "GET_BILLING_DATA_BEGIN";
export const GET_BILLING_DATA_SUCCESS = "GET_BILLING_DATA_SUCCESS";
export const GET_BILLING_DATA_FAIL = "GET_BILLING_DATA_FAIL";

export const getBillingData = () => (dispatch) => {
  dispatch({
    type: GET_BILLING_DATA_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/account/billing/`,
  })
    .then((res) => {
      dispatch({
        type: GET_BILLING_DATA_SUCCESS,
        payload: res.data.data.billing_info,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_BILLING_DATA_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const updateBillingData = (user) => (dispatch) => {
  dispatch({
    type: UPDATA_BILLING_DATA_BEGIN,
  });
  const data = {
    name: user.name,
    company_name: user.company_name,
    phone: user.phone,
    email: user.email,
    zipcode: user.zipcode,
    street: user.street,
    tax_number: user.tax_number,
    invoice_note: user.invoice_note,
  };
  return serverCall({
    method: "POST",
    url: `/api/v1/account/billing/update/`,
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: UPDATA_BILLING_DATA_SUCCESS,
          payload: data,
        });
        dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return data;
    })
    .catch((error) => {
      dispatch({
        type: UPDATA_BILLING_DATA_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

    });
};
