import { Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

function ToolTip(props) {
  const { setWidth } = props;

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.text.main,
    },
    tooltip: {
      "&.MuiTooltip-tooltip": {
        boxShadow: "0px 2px 10px 1px rgba(110, 110, 110, .25)",
        backgroundColor: `${
          props?.bgWhite ? theme.palette.common.white : theme.palette.text.main
        }`,
        fontSize: "13px",
        fontWeight: "400",
        padding: "10px",
        lineHeight: "1.5",
        color: `${
          props?.bgWhite ? theme.palette.text.main : theme.palette.common.white
        }`,
        maxWidth: `${props?.width ? `${props.width}px` : "460px"}`,
        "& .MuiTooltip-arrow": {
          color: `${
            props?.bgWhite
              ? theme.palette.common.white
              : theme.palette.text.main
          }`,
        },
      },
    },
  }));

  const classes = useStylesBootstrap();

  return (
    <Tooltip
      arrow={true}
      TransitionComponent={Zoom}
      placement="top"
      classes={classes}
      style={{ maxWidth: !setWidth && "250px" }}
      {...props}
    />
  );
}

export default ToolTip;
