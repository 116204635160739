import {
  GET_OVERVIEW_DATA_BEGIN,
  GET_OVERVIEW_DATA_FAIL,
  GET_OVERVIEW_DATA_SUCCESS
} from "../../action/websiteAudit/getOverviewDataAction";

const initialState = {
  overviewData: [],
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERVIEW_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_OVERVIEW_DATA_SUCCESS:
      return {
        ...state,
        overviewData: action.payload.data,
        loading: false,
      };
    case GET_OVERVIEW_DATA_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
