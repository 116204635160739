import {
    GET_LEADSYNC_DATA_BEGIN,
    GET_LEADSYNC_DATA_FAIL,
    GET_LEADSYNC_DATA_SUCCESS,
    UPDATA_LEADSYNC_DATA_BEGIN,
    UPDATA_LEADSYNC_DATA_FAIL,
    UPDATA_LEADSYNC_DATA_SUCCESS
  } from "../action/leadSyncAction";
  import { LOGIN } from "../types";
  
  const initialState = {
    login: {
      data: {},
      isLogin: false,
    },
    leadSyncData: {
      data: {},
      loading: true,
      error: null,
    },
  };
  const userReducer = (state = initialState, { payload, type }) => {
    switch (type) {
      case LOGIN:
        return {
          ...state,
          login: {
            data: payload,
            isLogin: true,
          },
        };
      case GET_LEADSYNC_DATA_BEGIN:
        return {
          ...state,
          leadSyncData: {
            error: null,
            loading: true,
          },
        };
      case GET_LEADSYNC_DATA_SUCCESS:
        var data = {loading: false}
        data[payload["sync_with"]] = payload;
        return {
          ...state,
          leadSyncData: data,
        };
      case GET_LEADSYNC_DATA_FAIL:
        return {
          ...state,
          leadSyncData: {
            error: payload.error.response,
            loading: false,
          },
        };
    //   case UPDATA_LEADSYNC_DATA_BEGIN:
    //     return {
    //       ...state,
    //       leadSyncData: {
    //         data: payload,
    //         loading: true,
    //       },
    //     };
      case UPDATA_LEADSYNC_DATA_SUCCESS:
        return {
          ...state,
          leadSyncData: {
            data: payload,
            loading: false,
          },
        };
      case UPDATA_LEADSYNC_DATA_FAIL:
        return {
          ...state,
          leadSyncData: {
            error: payload.error.response,
            loading: false,
          },
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  