import serverCall from '../../modules/serverCall';

export const postStepTwo = (
  project_id,

  searce_engine,
  se_country,
  se_language,
  se_location,
  competetors
) => (dispatch) => {
  dispatch({
    type: STEP_TWO_DATA_POST_BEGIN,
  });
  return serverCall({
    method: 'POST',
    url: `/api/v1/project/${project_id}/`,
    data: {
      project_id,
      step: 2,
      se_country,
      se_language,
      searce_engine,
      competetor: competetors,
      se_location,
    },
  })
    .then((res) => {
      dispatch({
        type: STEP_TWO_DATA_POST_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: STEP_TWO_DATA_POST_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const STEP_TWO_DATA_POST_BEGIN = 'STEP_TWO_DATA_POST_BEGIN';
export const STEP_TWO_DATA_POST_SUCCESS = 'STEP_TWO_DATA_POST_SUCCESS';
export const STEP_TWO_DATA_POST_FAIL = 'STEP_TWO_DATA_POST_FAIL';
