import { FormGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { forwardRef } from "react";
import { TextArea } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    "& textarea": {
      width: "100%",
      border: "1px solid",
      borderColor: "rgba(63, 29, 253, .10)",
      padding: ".8rem 1rem",
      borderRadius: "4px",
      color: theme.palette.text.main,
      "&::placeholder": {
        color: theme.palette.text.main,
        opacity: ".5",
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
}));

const CustomTextarea = forwardRef(({ error, label, ...rest }, ref) => {
  const classes = useStyles();
  console.log(rest);
  return (
    <FormGroup className={classes.root}>
      <TextArea inputRef={ref} {...rest} />
      {error !== undefined && (
        <div className="form__error">
          <span>{error}</span>
        </div>
      )}
    </FormGroup>
  );
});

export default CustomTextarea;
