import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import RequiredLabel from "../RequiredLabel";

const TermsCondition = (props) => {
  const {
    values,
    setValues,
    wform_tnc,
    handlelabelPlaceChange,
    errors,
    setErrors,
  } = props;

  const { t } = useTranslation();

  return (
    <Box className="divider custom-selector2 ">
      <InputLabel
        label={t("createWidget.formField.29")}
        optional
        title={t("createWidget.formField.30")}
      />
      <div className="check">
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="checkbox"
            className="custom-control-input"
            id="terms"
            value={wform_tnc.show_field}
            name="terms_condition"
            onChange={() => {
              setValues({
                ...values,
                wform_tnc: {
                  ...values.wform_tnc,
                  show_field: wform_tnc.show_field > 0 ? 0 : 1,
                },
              });
            }}
            checked={wform_tnc.show_field !== 0}
          />
          <label className="custom-control-label" htmlFor="terms">
            <Typography component="span" variant="body1">
              {t("createWidget.formField.32")}
            </Typography>
          </label>
        </div>

        {/* Phone field placeholder */}
        {wform_tnc.show_field > 0 && (
          <Box>
            <div className="custom-control custom-checkbox custom-selector2 ">
              <input
                type="checkbox"
                className="custom-control-input"
                id="terms_r"
                name="terms_condition_r"
                value={wform_tnc.show_field}
                onChange={() => {
                  setValues({
                    ...values,
                    wform_tnc: {
                      ...values.wform_tnc,
                      show_field: wform_tnc.show_field === 1 ? 2 : 1,
                    },
                  });
                }}
                checked={wform_tnc.show_field === 2}
              />
              <label className="custom-control-label" htmlFor="terms_r">
                <Typography component="span" variant="body1">
                  {t("createWidget.formField.18")}
                </Typography>
              </label>
            </div>
            <Box
              sx={{ mt: "1rem" }}
              className="input_wrapper custom-selector2 "
            >
              <RequiredLabel label={t("common.tAndC")} required />
              <textarea
                value={wform_tnc.label}
                type="text"
                onChange={(e) => {
                  handlelabelPlaceChange(e, "wform_tnc");
                  if (e.target.value === "") {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      terms_condition_text_error: (
                        <span>{t("common.required")}</span>
                      ),
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      terms_condition_text_error: "",
                    }));
                  }
                }}
                cols="5"
                rows="5"
                placeholder={t("createWidget.formField.31")}
                name="label"
              />
              <div className="form__error">
                {errors.terms_condition_text_error}
              </div>
            </Box>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default TermsCondition;
