import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const CREATE_KEYWORD_GROUP_BEGIN = "CREATE_KEYWORD_GROUP_BEGIN";
export const CREATE_KEYWORD_GROUP_SUCCESS = "CREATE_KEYWORD_GROUP_SUCCESS";
export const CREATE_KEYWORD_GROUP_FAIL = "CREATE_KEYWORD_GROUP_FAIL";

export const STEP_THREE_DATA_POST_BEGIN = "STEP_THREE_DATA_POST_BEGIN";
export const STEP_THREE_DATA_POST_SUCCESS = "STEP_THREE_DATA_POST_SUCCESS";
export const STEP_THREE_DATA_POST_FAIL = "STEP_THREE_DATA_POST_FAIL";

export const GET_KEYWORD_GROUP_BEGIN = "GET_KEYWORD_GROUP_BEGIN";
export const GET_KEYWORD_GROUP_SUCCESS = "GET_KEYWORD_GROUP_SUCCESS";
export const GET_KEYWORD_GROUP_FAIL = "GET_KEYWORD_GROUP_FAIL";

export const GET_KEYWORD_UNGROUPED_BEGIN = "GET_KEYWORD_UNGROUPED_BEGIN";
export const GET_KEYWORD_UNGROUPED_SUCCESS = "GET_KEYWORD_UNGROUPED_SUCCESS";
export const GET_KEYWORD_UNGROUPED_FAIL = "GET_KEYWORD_UNGROUPED_FAIL";

export const GET_KEYWORD_GROUPED_BEGIN = "GET_KEYWORD_GROUPED_BEGIN";
export const GET_KEYWORD_GROUPED_SUCCESS = "GET_KEYWORD_GROUPED_SUCCESS";
export const GET_KEYWORD_GROUPED_FAIL = "GET_KEYWORD_GROUPED_FAIL";

export const ADD_KEYWORD_BEGIN = "ADD_KEYWORD_BEGIN";
export const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS";
export const ADD_KEYWORD_FAIL = "ADD_KEYWORD_FAIL";

export const DELETE_KEYWORD_BEGIN = "DELETE_KEYWORD_BEGIN";
export const DELETE_KEYWORD_SUCCESS = "DELETE_KEYWORD_SUCCESS";
export const DELETE_KEYWORD_FAIL = "DELETE_KEYWORD_FAIL";

export const GET_ACTIVE_KEYWORD_BEGIN = "GET_ACTIVE_KEYWORD_BEGIN";
export const GET_ACTIVE_KEYWORD_SUCCESS = "GET_ACTIVE_KEYWORD_SUCCESS";
export const GET_ACTIVE_KEYWORD_FAIL = "GET_ACTIVE_KEYWORD_FAIL";

export const postStepThree =
  (project_id, keyword_list, history) => (dispatch) => {
    dispatch({
      type: STEP_THREE_DATA_POST_BEGIN,
    });
    return serverCall({
      method: "POST",
      url: `/api/v1/project/${project_id}/`,
      data: {
        se_key: keyword_list,
        step: 3,
      },
    })
      .then((res) => {
        dispatch({
          type: STEP_THREE_DATA_POST_SUCCESS,
          payload: res,
        });
        dispatch(setAlert("keyword added successfully", "success"));
        history.push("/admin-site-list");
        return res;
      })
      .catch((error) => {
        dispatch({
          type: STEP_THREE_DATA_POST_FAIL,
          payload: { error },
        });
        history.push("/admin-site-list");
        if (error.response && error.response.data) {
          dispatch(setAlert(error.response.data.message, "danger"));
        }
        return error;
      });
  };

export const createKeywordGroup = (project_id, groupName) => (dispatch) => {
  dispatch({
    type: CREATE_KEYWORD_GROUP_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/project/${project_id}/keyword/group/create/`,
    data: {
      project_id,
      group_name: groupName,
    },
  })
    .then((res) => {
      dispatch({
        type: CREATE_KEYWORD_GROUP_SUCCESS,
        payload: res,
      });
      dispatch(setAlert("Group created successfully", "success"));
      return res;
    })
    .catch((error) => {
      dispatch({
        type: CREATE_KEYWORD_GROUP_FAIL,
        payload: { error },
      });
      dispatch(setAlert("Group creating failed", "danger"));
      return error;
    });
};

export const getKeywordGroup = (project_id) => (dispatch) => {
  dispatch({
    type: GET_KEYWORD_GROUP_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/keyword/group/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_KEYWORD_GROUP_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEYWORD_GROUP_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getKeywordUngrouped = (project_id) => (dispatch) => {
  dispatch({
    type: GET_KEYWORD_UNGROUPED_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/keyword/list/ungrouped/`,
  })
    .then((res) => {
      dispatch({
        type: GET_KEYWORD_UNGROUPED_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEYWORD_UNGROUPED_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getKeywordGrouped = (project_id) => (dispatch) => {
  dispatch({
    type: GET_KEYWORD_GROUPED_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/keyword/list/grouped/`,
  })
    .then((res) => {
      dispatch({
        type: GET_KEYWORD_GROUPED_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_KEYWORD_GROUPED_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const addKeyword = (project_id, group_id, keywords) => (dispatch) => {
  dispatch({
    type: ADD_KEYWORD_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/project/${project_id}/keyword/create/`,
    data: {
      project_id,
      group_id,
      keywords,
    },
  })
    .then((res) => {
      if (res.data?.info !== "") {
        dispatch(setAlert(res.data.info, "warning"));
      }
      if(res.status >=200 && res.status<300){
      dispatch({
        type: ADD_KEYWORD_SUCCESS,
        payload: res,
      });
      console.log();
      dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch({
          type: ADD_KEYWORD_FAIL,
          payload: { res },
        });
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: ADD_KEYWORD_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const deleteKeyword = (project_id, keyword_id) => (dispatch) => {
  dispatch({
    type: DELETE_KEYWORD_BEGIN,
  });
  return serverCall({
    method: "DELETE",
    url: `/api/v1/project/${project_id}/keyword/${keyword_id}/`,
    // data: {
    //   keyword_id: keyword_id,
    // },
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_KEYWORD_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch({
          type: DELETE_KEYWORD_FAIL,
          payload: { res },
        });
        dispatch(setAlert(res.data.message, "danger"));
      }

    })
    .catch((error) => {
      dispatch({
        type: DELETE_KEYWORD_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const getActiveKeyword = (project_id) => (dispatch) => {
  dispatch({
    type: GET_ACTIVE_KEYWORD_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/project/${project_id}/keyword/list/active/`,
  })
    .then((res) => {
      dispatch({
        type: GET_ACTIVE_KEYWORD_SUCCESS,
        payload: res,
      });
      return res;
    })

    .catch((error) => {
      dispatch({
        type: GET_ACTIVE_KEYWORD_FAIL,
        payload: { error },
      });
      return error;
    });
};
