import { Drawers } from "@components";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import SetServerUrl from "../../../../modules/URL";
import HSettings from "./cmponent/HSettings";
import LangBtn from "./cmponent/LangBtn";
import "./Header.scss";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      width: "auto",
      margin: "0",
      padding: "0",
      display: "initial",
      "& .MuiInput-underline:before": {
        content: "none",
      },
      "& .MuiInput-underline:after": {
        content: "none",
      },
    },
  },
}));

const Header = (props) => {
  const { toggleSidebar, isMenuOpen, siteSettingData, isLoading } = props;

  const datas = {
    supportText: (siteSettingData || "").support_text,
  };

  const classes = useStyles();

  return (
    <div className={`section-nav ${isMenuOpen === true ? "burger-fixed" : ""}`}>
      <div className="navbar navbar2">
        <div className="p-0 m-0">
          <div className="navbar-full">
            <div className="seo-logo seo-logo2" style={{ marginLeft: "10px" }}>
              <div className="logo-left">
                {isLoading ? (
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    style={{ width: "130px", height: "30px" }}
                  />
                ) : (
                  <Link to="/">
                    <img
                      className="logo-full"
                      src={`${siteSettingData && SetServerUrl}${
                        (siteSettingData || "").logo || ""
                      }`}
                      alt="Logo"
                    />
                    <img
                      className="logo-small"
                      src={`${siteSettingData && SetServerUrl}${
                        (siteSettingData || "").small_logo || ""
                      }`}
                      alt=""
                    />
                  </Link>
                )}
              </div>
              <div className="toggler logo_ext">
                <div
                  id=""
                  aria-hidden="true"
                  onClick={toggleSidebar}
                  className="btn-menu"
                >
                  <div className="line line-1" />
                  <div className="line line-2" />
                  <div className="line line-3" />
                </div>
              </div>
            </div>

            <div className="header">
              <ul className="main-ul">
                {/* }
                <li className="main-li">
                  <a href="!#">
                    <i className="fas fa-comment"></i>
                  </a>
                </li>

                <li className="main-li">
                  <a href="!#">
                    <i className="fas fa-bell"></i>
                  </a>
                </li> */}

                {(siteSettingData || "").show_multilangual && (
                  <li className="{classes.root} languageHeader1">
                    <LangBtn />
                  </li>
                )}

                {(siteSettingData || "").show_support && (
                  <li className="main-li ">
                    <Drawers data={datas} />
                  </li>
                )}

                <li className="main-li settingLi ">
                  <HSettings />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
