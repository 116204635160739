import { CustomButton } from "@components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../assets/muicss";
import SetServerUrl from "../../modules/URL";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "136px",
    zIndex: "999999 !important",
    "& .body": {
      minWidth: "100vw",
      padding: "0 !important",
      height: "100vh",
      "& iframe": {
        width: "100vw",
        height: "calc(100vh - 50px)",
        border: "none",
        padding: "0",
        marginTop: "50px"
      },
      "& .wrapper": {
        "& .close__but": {
          position: "absolute",
          top: "0",
          right: "0",
          textAlign: "right",
          height: "50px",
          backgroundColor: "#262626",
          borderBottom: "2px solid #4a4646",
          width: "100%",
          "& button": {
            marginTop: "7px",
            marginRight: "18px"
          }
        }
      }
    }
  }
}));

export default function PreviewModal({ widgetPrevParam }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    window.open(prev_url,'_blank')
  };
  const handleClose = () => setOpen(false);

  const classes = modalStyle();
  const classesTwo = useStyles();

  const { t } = useTranslation();
  if(widgetPrevParam.includes("http")){
    var prev_url = widgetPrevParam;

  }else{
    var prev_url = `${SetServerUrl}/api/v1/frontend_template/${widgetPrevParam}`;

  }
  return (
    <>
      <CustomButton
        label={t("common.samplePreview")}
        sx={{ ml: "2rem" }}
        color="success"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${classes.fuck} ${classesTwo.root}`}
      >
        <Box className="body">
          <div className="wrapper">
            <Box className="close__but">
              <CustomButton label="Close" onClick={handleClose} color="secondary" size="medium" />
            </Box>
            <Box className="">
              <iframe
                title="preview"
                src={prev_url}
              />
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}
