import serverCall from "../../../modules/serverCall";

export const GET_PROJECT_RANK_DATA_BEGIN = "GET_PROJECT_RANK_DATA_BEGIN";
export const GET_PROJECT_RANK_DATA_SUCCESS = "GET_PROJECT_RANK_DATA_SUCCESS";
export const GET_PROJECT_RANK_DATA_FAIL = "GET_PROJECT_RANK_DATA_FAIL";

export const GET_ENGINE_RANK_DATA_BEGIN = "GET_ENGINE_RANK_DATA_BEGIN";
export const GET_ENGINE_RANK_DATA_SUCCESS = "GET_ENGINE_RANK_DATA_SUCCESS";
export const GET_ENGINE_RANK_DATA_FAIL = "GET_ENGINE_RANK_DATA_FAIL";

export const GET_AVG_RANK_DATA_BEGIN = "GET_AVG_RANK_DATA_BEGIN";
export const GET_AVG_RANK_DATA_SUCCESS = "GET_AVG_RANK_DATA_SUCCESS";
export const GET_AVG_RANK_DATA_FAIL = "GET_AVG_RANK_DATA_FAIL";

export const getProjectRankData = (id, top) => (dispatch) => {
  dispatch({
    type: GET_PROJECT_RANK_DATA_BEGIN,
  });

  return serverCall({
    method: "GET",
    url: `api/v1/top-project-keyword/${id}/?top=${top}`,
  })
    .then((res) => {
      const data = {
        topFiveData: [],
        topTenData: [],
        topThirTeenData: [],
      };

      if (top === "5") {
        data.topFiveData = res.data.data.data;
      } else if (top === "10") {
        data.topTenData = res.data.data.data;
      } else {
        data.topThirTeenData = res.data.data.data;
      }

      dispatch({
        type: GET_PROJECT_RANK_DATA_SUCCESS,
        payload: data,
      });

      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_PROJECT_RANK_DATA_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const getEngineRankData = (id, top) => (dispatch) => {
  dispatch({
    type: GET_ENGINE_RANK_DATA_BEGIN,
  });

  return serverCall({
    method: "GET",
    url: `api/v1/top-se-keyword/${id}/?top=${top}`,
  })
    .then((res) => {
      const data = {
        topFiveData: [],
        topTenData: [],
        topThirTeenData: [],
      };

      if (top === "5") {
        data.topFiveData = res.data.data.data;
      } else if (top === "10") {
        data.topTenData = res.data.data.data;
      } else {
        data.topThirTeenData = res.data.data.data;
      }

      dispatch({
        type: GET_ENGINE_RANK_DATA_SUCCESS,
        payload: data,
      });

      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_ENGINE_RANK_DATA_FAIL,
        payload: { error },
      });
      return error;
    });
};
