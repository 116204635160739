import {
  GET_ENGINE_LIST_BEGIN,
  GET_ENGINE_LIST_FAIL,
  GET_ENGINE_LIST_SUCCESS
} from "../action/enginelistAction";

const initialState = {
  enginelist: [],
  engine_list_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ENGINE_LIST_BEGIN:
      return {
        ...state,
        engine_list_loading: true,
      };

    case GET_ENGINE_LIST_SUCCESS:
      return {
        ...state,
        engine_list_loading: false,
        enginelist: action.payload.data.data.se,
      };

    case GET_ENGINE_LIST_FAIL:
      return {
        ...state,
        engine_list_loading: false,
      };

    default:
      return state;
  }
};
