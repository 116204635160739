import serverCall from "../../../modules/serverCall";

export const GET_AUDITED_LIST_BEGIN = "GET_AUDITED_LIST_BEGIN";
export const GET_AUDITED_LIST_SUCCESS = "GET_AUDITED_LIST_SUCCESS";
export const GET_AUDITED_LIST_FAIL = "GET_AUDITED_LIST_FAIL";

export const getAuditedList = (id) => (dispatch) => {
  dispatch({ type: GET_AUDITED_LIST_BEGIN });
  return serverCall({
    method: "GET",
    url: `/api/v1/website-audit/${id}/list/`,
  })
    .then((res) => {
      dispatch({
        type: GET_AUDITED_LIST_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_AUDITED_LIST_FAIL,
        payload: err,
      });
    });
};
