import {
  DELETE_LEAD,
  DELETE_LEAD_FAIL,
  GET_DELETED_LEADS_BEGIN,
  GET_DELETED_LEADS_FAIL,
  GET_DELETED_LEADS_SUCCESS,
  GET_LEADS_BEGIN,
  GET_LEADS_FAIL,
  GET_LEADS_SUCCESS
} from "../action/getLeadsAction";

const initialState = {
  listData: [],
  loading: false,
  error: false,
  deletedlead: null,
  totalpages: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        listData: action.payload.audit,
        loading: false,
        totalpages: action.payload.total_page,
      };
    case GET_LEADS_FAIL:
      return {
        ...state,
        error: true,
      };
    case DELETE_LEAD:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LEAD_FAIL:
      return {
        ...state,
        error: true,
      };
    case GET_DELETED_LEADS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DELETED_LEADS_SUCCESS:
      return {
        ...state,
        deletedlead: action.payload.audit,
        loading: false,
        totalpages: action.payload.total_page,
      };
    case GET_DELETED_LEADS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
