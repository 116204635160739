export const GET_ISSUE_REPORT_BEGAIN = "GET_ISSUE_REPORT_BEGAIN";
export const GET_ISSUE_REPORT_SUCCESS = "GET_ISSUE_REPORT_SUCCESS";
export const GET_ISSUE_REPORT_FAIL = "GET_ISSUE_REPORT_FAIL";

export const GET_CRAWLED_PAGES_BEGIN = "GET_CRAWLED_PAGES_BEGIN";
export const GET_CRAWLED_PAGES_SUCCESS = "GET_CRAWLED_PAGES_SUCCESS";
export const GET_CRAWLED_PAGES_FAIL = "GET_CRAWLED_PAGES_FAIL";

export const GET_FOUND_LINKS_BEGIN = "GET_FOUND_LINKS_BEGIN";
export const GET_FOUND_LINKS_SUCCESS = "GET_FOUND_LINKS_SUCCESS";
export const GET_FOUND_LINKS_FAIL = "GET_FOUND_LINKS_FAIL";

export const GET_FOUND_RESOURCE_BEGIN = "GET_FOUND_RESOURCE_BEGIN";
export const GET_FOUND_RESOURCE_SUCCESS = "GET_FOUND_RESOURCE_SUCCESS";
export const GET_FOUND_RESOURCE_FAIL = "GET_FOUND_RESOURCE_FAIL";
