import serverCall from "../../../modules/serverCall";
// GET SMPT DATA
export const GET_PREVIEW_MODAL_BEGIN = "GET_PREVIEW_MODAL_BEGIN";
export const GET_PREVIEW_MODAL_SUCCESS = "GET_PREVIEW_MODAL_SUCCESS";
export const GET_PREVIEW_MODAL_FAIL = "GET_PREVIEW_MODAL_FAIL";

// Get SMTP SETTING DATA
export default () => (dispatch) => {
  dispatch({
    type: GET_PREVIEW_MODAL_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/frontend_template/fixed-form-two-column/",
  })
    .then((res) => {
      dispatch({
        type: GET_PREVIEW_MODAL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_PREVIEW_MODAL_FAIL,
        payload: { error },
      });
      return error;
    });
};
