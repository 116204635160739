import { ToolTip } from "@components";
import UserAlert from "@components/UserAlert";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Fragment, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";
import ReactHtmlParser from "react-html-parser";
import { ReactNotifications } from 'react-notifications-component'
// Login Layouts
// alert info box
import "react-notifications-component/dist/theme.css";
import { useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
// import useGaTracker from "useGaTracker";
import "./App.css";
import { getUserAccountData } from "./appRedux/action/subscriptionActions";
import { getAccountData } from "./appRedux/action/userActions";
import "./assets/sass/Main.scss";
// This is important to keep to the bootom on the all imported component
import "./assets/sass/responsive/Responsive.scss";
import Header from "./components/layout/dashboard-layout/_header/Header";
import HeaderAuth from "./components/layout/login-layout/_header-auth/HeaderAuth";
import { AppBar, Main } from "./components/layout/Single";
import Auth from "./modules/Auth";
import Meta from "./modules/Meta";
// import AuditReportLastone from "./pages/audit-report/AuditReportLastone";
// Navigation to url
import { history, registerNav } from "./modules/Navigation";
import serverCall from "./modules/serverCall";
import SetServerUrl from "./modules/URL";
import AdminSiteList from "./pages/admin-sitelist/AdminSiteList";
import CreateWidget from "./pages/create-widget/CreateWidget";
import SyncLead from "./pages/sync-lead";


// const { AppBar, Main } = React.lazy(() => import("./components/layout/Single"));
// import TempAuditSave from "./components/tempAuditSave";
const TempAuditSave = React.lazy(() => import("./components/tempAuditSave"));
// import FooterAuth from "./components/layout/login-layout/_footer-auth/FooterAuth";
const FooterAuth = React.lazy(() =>
  import("./components/layout/login-layout/_footer-auth/FooterAuth")
);
// const HeaderAuth = React.lazy(() =>
//   import("./components/layout/login-layout/_header-auth/HeaderAuth")
// );
// import SidebarAuth from "./components/layout/login-layout/_sidebar-auth/SidebarAuth";
const SidebarAuth = React.lazy(() =>
  import("./components/layout/login-layout/_sidebar-auth/SidebarAuth")
);
// import RightDrawer from "./components/layout/RightDrawer";
const RightDrawer = React.lazy(() => import("./components/layout/RightDrawer"));
// Dashboard layout
// Login Layouts
// import LoginLayoutRoute from "./components/layout/login-layout/LoginLayout";
const LoginLayoutRoute = React.lazy(() =>
  import("./components/layout/login-layout/LoginLayout")
);

// import Alert from "./components/common/Alert";
const Alert = React.lazy(() => import("./components/common/Alert"));

// import Note from "./components/common/Note";
const Note = React.lazy(() => import("./components/common/Note"));

// import NotFound from "./components/common/NotFound";
const NotFound = React.lazy(() => import("./components/common/NotFound"));

// Dashboard layout
// import DashboardLayoutRoute from "./components/layout/dashboard-layout/DahsboardLayout";
const DashboardLayoutRoute = React.lazy(() =>
  import("./components/layout/dashboard-layout/DahsboardLayout")
);

// import Footer from "./components/layout/dashboard-layout/_footer/Footer";
const Footer = React.lazy(() =>
  import("./components/layout/dashboard-layout/_footer/Footer")
);

// const Header = React.lazy(() =>
//   import("./components/layout/dashboard-layout/_header/Header")
// );

// import Sidebar from "./components/layout/dashboard-layout/_sidebar/Sidebar";
const Sidebar = React.lazy(() =>
  import("./components/layout/dashboard-layout/_sidebar/Sidebar")
);

// import LoginAuth from "./components/layout/login-layout/LoginAuth/LoginAuth";
const LoginAuth = React.lazy(() =>
  import("./components/layout/login-layout/LoginAuth/LoginAuth")
);
// const BillingContainer = React.lazy(() => import("./pages/home-page/HomePage"));
// import MyProfile from "./pages/home-page/HomePage";
// Billing Page New
// import BillingContainer from "./pages/my-profile/Billing/BillingContainer";
const BillingContainer = React.lazy(() =>
  import("./pages/my-profile/Billing/BillingContainer")
);

// Container Section
// import SigninContainer from "./pages/create-project/loginsignin/SigninContainer";
// import LoginContainer from "./pages/create-project/loginsignin/LoginContainer";
// import CreateMainContainer from "./pages/create-project/CreateMainContainer";
// import AuditReport from "./pages/audit-report/AuditReport";
const AuditReport = React.lazy(() =>
  import("./pages/audit-report/AuditReport")
);

// import BillingMain from "./pages/billing/component/BillingMain";
const BillingMain = React.lazy(() =>
  import("./pages/billing/component/BillingMain")
);

// import EditProject from "./pages/create-project/EditStepOne/EditStepOne";
const EditProject = React.lazy(() =>
  import("./pages/create-project/EditStepOne/EditStepOne")
);

// import CreateMain from "./pages/create-project/general/GeneralFormContainer";
const CreateMain = React.lazy(() =>
  import("./pages/create-project/general/GeneralFormContainer")
);

// import EditKeywords from "./pages/create-project/keyword/KeywordFormContainer";
const EditKeywords = React.lazy(() =>
  import("./pages/create-project/keyword/KeywordFormContainer")
);

// import EditEngines from "./pages/create-project/searchEngine/SearchEngineContainer";
const EditEngines = React.lazy(() =>
  import("./pages/create-project/searchEngine/SearchEngineContainer")
);

// const CreateWidget = React.lazy(() =>
//   import("./pages/create-widget/CreateWidget")
// );

// import EmailConfirmation from "./pages/email/EmailConfirmation";
const EmailConfirmation = React.lazy(() =>
  import("./pages/email/EmailConfirmation")
);

// import KeywordRanking from "./pages/keyword-ranking/KeywordRanking";
const KeywordRanking = React.lazy(() =>
  import("./pages/keyword-ranking/KeywordRanking")
);
// import AllLeads from "./pages/lead-page/AllLeads";
const AllLeads = React.lazy(() => import("./pages/lead-page/AllLeads"));
// import DeletedLeadsList from "./pages/lead-page/deletedLead/MyDeletedLead";
const DeletedLeadsList = React.lazy(() =>
  import("./pages/lead-page/deletedLead/MyDeletedLead")
);
// import ManageGroup from "./pages/manage-group";
const ManageGroup = React.lazy(() => import("./pages/manage-group"));
// import MyDeletedAudits from "./pages/my-audit/deletedAudit/MyDeletedAudits";
const MyDeletedAudits = React.lazy(() =>
  import("./pages/my-audit/deletedAudit/MyDeletedAudits")
);
// import MyAudits from "./pages/my-audit/MyAudits";
const MyAudits = React.lazy(() => import("./pages/my-audit/MyAudits"));

const MyProfile = React.lazy(() => import("./pages/my-profile/MyProfile"));
// import AddPage from "./pages/page-change-monitor/component/AddPage/AddPage";
const AddPage = React.lazy(() =>
  import("./pages/page-change-monitor/component/AddPage/AddPage")
);
// import Changes from "./pages/page-change-monitor/component/Changes/Changes";
const Changes = React.lazy(() =>
  import("./pages/page-change-monitor/component/Changes/Changes")
);
// import MonitoredItems from "./pages/page-change-monitor/component/Monitoring/MonitoredItems";
const MonitoredItems = React.lazy(() =>
  import("./pages/page-change-monitor/component/Monitoring/MonitoredItems")
);
// Page Change Monitor
// import PageChangeMonitor from "./pages/page-change-monitor/PageChangeMonitor";
const PageChangeMonitor = React.lazy(() =>
  import("./pages/page-change-monitor/PageChangeMonitor")
);
// import SeoAnalyzer from "./pages/seo-analyzer/SeoAnalyzer";
const SeoAnalyzer = React.lazy(() =>
  import("./pages/seo-analyzer/SeoAnalyzer")
);
// import SeoAudit from "./pages/seo-audit";
const SeoAudit = React.lazy(() => import("./pages/seo-audit"));
// import Settings from "./pages/settings/Settings";
const Settings = React.lazy(() => import("./pages/settings/Settings"));
// import Canonical from "./pages/site-crowl/canonical/Canonical";
const Canonical = React.lazy(() =>
  import("./pages/site-crowl/canonical/Canonical")
);
// import HttpStatus from "./pages/site-crowl/http-status/HttpStatus";
const HttpStatus = React.lazy(() =>
  import("./pages/site-crowl/http-status/HttpStatus")
);
// import Indexing from "./pages/site-crowl/indexing/Indexing";
const Indexing = React.lazy(() =>
  import("./pages/site-crowl/indexing/Indexing")
);
// V3 pages
// import Onpage from "./pages/site-crowl/on-page/Onpage";
const Onpage = React.lazy(() => import("./pages/site-crowl/on-page/Onpage"));
// import InvoiceContainer from "./pages/subscription/invoice/InvoiceContainer";
const InvoiceContainer = React.lazy(() =>
  import("./pages/subscription/invoice/InvoiceContainer")
);
// import ManageSubscription from "./pages/subscription/ManageSubscription";
const ManageSubscription = React.lazy(() =>
  import("./pages/subscription/ManageSubscription")
);
// import Payment from "./pages/subscription/Payment";
const Payment = React.lazy(() => import("./pages/subscription/Payment"));
// import PaymentCancel from "./pages/subscription/PaymentCancel";
const PaymentCancel = React.lazy(() =>
  import("./pages/subscription/PaymentCancel")
);
// import PaymentConfirm from "./pages/subscription/PaymentConfirm";
const PaymentConfirm = React.lazy(() =>
  import("./pages/subscription/PaymentConfirm")
);
// import PaymentSuccess from "./pages/subscription/PaymentSuccess";
const PaymentSuccess = React.lazy(() =>
  import("./pages/subscription/PaymentSuccess")
);
// Pages
// import Pricing from "./pages/subscription/Pricing";
const Pricing = React.lazy(() => import("./pages/subscription/Pricing"));
// import SubscribeForm from "./pages/subscription/SubscribeForm";
const SubscribeForm = React.lazy(() =>
  import("./pages/subscription/SubscribeForm")
);
// Crawl Compariosn
// import CrawlComparison from "./pages/website-audit/crawl-comparison";
const CrawlComparison = React.lazy(() =>
  import("./pages/website-audit/crawl-comparison")
);
// import CrawledPages from "./pages/website-audit/crawled-pages";
const CrawledPages = React.lazy(() =>
  import("./pages/website-audit/crawled-pages")
);
// Found Links
// import FoundLinks from "./pages/website-audit/found-links";
const FoundLinks = React.lazy(() =>
  import("./pages/website-audit/found-links")
);
// Found Resources
// import FoundResources from "./pages/website-audit/found-resources";
const FoundResources = React.lazy(() =>
  import("./pages/website-audit/found-resources")
);
// Found Resources
// import IssueReport from "./pages/website-audit/issue-report/IssueReport";
const IssueReport = React.lazy(() =>
  import("./pages/website-audit/issue-report/IssueReport")
);
// Website Audit Pages
//= =======================//
// import Overview from "./pages/website-audit/overview/Overview";
const Overview = React.lazy(() =>
  import("./pages/website-audit/overview/Overview")
);
// import PageChangeMonitors from "./pages/website-audit/PageChangeMonitor";
const PageChangeMonitors = React.lazy(() =>
  import("./pages/website-audit/PageChangeMonitor")
);
// import AddPages from "./pages/website-audit/PageChangeMonitor/AddPages";
const AddPages = React.lazy(() =>
  import("./pages/website-audit/PageChangeMonitor/AddPages")
);
// import RunAaudit from "./pages/website-audit/runAudit";
const RunAaudit = React.lazy(() => import("./pages/website-audit/runAudit"));
// import DeletedWidgetList from "./pages/widget-list/deletedWidget/MyDeletedWidgets";
const DeletedWidgetList = React.lazy(() =>
  import("./pages/widget-list/deletedWidget/MyDeletedWidgets")
);
// import WidgetList from "./pages/widget-list/WidgetList";
const WidgetList = React.lazy(() => import("./pages/widget-list/WidgetList"));

// const AdminSiteList = React.lazy(() =>
//   import("./pages/admin-sitelist/AdminSiteList")
// );

const AuditPage = React.lazy(() => import("./pages/audit-page/AuditPage"));
const AdvanceAuditPage = React.lazy(() => import("./pages/audit-page/AdvanceAuditPage"));
const WebpageAudit = React.lazy(() => import("./pages/audit-page/WebpageAudit"));
const AdvanceWebpageAudit = React.lazy(() => import("./pages/audit-page/AdvanceWebpageAudit"));

// import HomePage from "./pages/home-page/HomePage";
const HomePage = React.lazy(() => import("./pages/home-page/HomePage"));
const tagManagerArgs = {
  gtmId: "GTM-K3RK5V5",
};

TagManager.initialize(tagManagerArgs);
function App() {
  const [isloggedin, setIsloggedin] = useState(false);
  const [proHideClass, setProhideClass] = useState("production__element__hide");
  const [settingData, setSettingData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const tokencheck = Auth.getToken();

  useEffect(() => {
    if (tokencheck) {
      setIsloggedin(true);
    } else {
      setIsloggedin(false);
    }
  }, []);

  

  function removeURLParameter(url, parameter) {
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split("?");
    if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(parameter)}=`;
      const pars = urlparts[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0; ) {
        // idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = `${urlparts[0]}?${pars.join("&")}`;
      return url;
    }
    return url;
  }

  useEffect(() => {
    const commonurl = window.location.host;

    // Setting api function
    async function getSettingsData() {
      setIsLoading(true);
      await serverCall({
        method: "GET",
        url: "/api/v1/settings/",
      })
        .then((res) => {
          if (res.status === 200) {
            const getData = res.data.data.settings;
            localStorage.setItem(
              "pageSettingData",
              JSON.stringify({ getData })
            );
            const pageDataFromLocal = JSON.parse(
              localStorage.getItem("pageSettingData")
            );
            setSettingData(pageDataFromLocal);
          }
        })
        .catch((err) => err.response);
      setIsLoading(false);
    }
    // Setting api function

    if (
      commonurl === "localhost:3000" ||
      commonurl === "jasim.creativelab.agency"
    ) {
      setProhideClass("");
    } else {
      setProhideClass("production__element__hide");
    }

    const queryString = window.location.search;

    const updateClassName = () => {
      setIsMobile(window.innerWidth < 990);
    };

    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("login-token")) {
        Auth.setUserToken({ key: urlParams.get("login-token") });

        let redirect_to = removeURLParameter(
          window.location.href,
          "login-token"
        );

        const { length } = redirect_to;
        if (redirect_to.charAt(length - 1) === "?") {
          redirect_to = redirect_to.slice(0, length - 1);
        }

        // const message = { type: "auth_redirect", redirect: redirect_to };
        window.location.replace(redirect_to);
        // if (window.opener) {
        //   if (message) {
        //     window.opener.postMessage(message, "*");
        //   }
        //   window.close();
        // } else {
        //   window.location.replace(redirect_to);
        // }
      }
    }

    // Call page setting data
    getSettingsData();
    if (window.innerWidth < 990) {
      console.log("time");
      setTimeout(() => {
        setTimeout(updateClassName, 500);
      });
    }
    window.addEventListener("resize", updateClassName);
  }, []);

  // For toggle the sidebar menu
  const toggleSidebar = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // For toggle the sidebar menu

  const className = isMobile ? "mobile" : "";

  function favIcon(icon) {
    const favIcone = document.getElementById("favicon");

    if (icon) {
      favIcone.href = `${SetServerUrl}/${icon}`;
    }
  }

  useEffect(() => {
    const getDatas = (settingData || {}).getData;

    if (getDatas) {
      favIcon(getDatas.fav_icon);
    }
  }, [settingData]);

  // For Adding External CSS
  useEffect(() => {
    let link = document.createElement("link");

    // set the attributes for link element
    link.rel = "stylesheet";

    link.type = "text/css";

    link.href = `${SetServerUrl}/api/v1/font-end/style/`;

    // Get HTML head element to append
    // link element to it
    document.getElementsByTagName("body")[0].append(link);
  }, []);

  // For Adding External CSS

  const getPageSettingData = JSON.parse(
    localStorage.getItem("pageSettingData")
  );

  const validData = (getPageSettingData || {}).getData;

  const setData = {
    show_branded_tool: (validData || "").show_branded_tool,
    branded_tool_link: (validData || "").branded_tool_link,
    branded_tool_text: (validData || "").branded_tool_text,
    small_logo: (validData || "").small_logo,
    cookie_settings_text: (validData || "").cookie_settings_text,
    show_cookie_settings: (validData || "").show_cookie_settings,
    cookie_settings_button_text: (validData || "").cookie_settings_button_text,
    page_header_home: (validData || "").page_header_home,
    common_page_header: (validData || "").common_page_header,
  };

  // Add New Path Here
  const newPath = [
    "*",
    "/",
    "/audit-page",
    "/sync-lead",
    "/sync-lead/:sync_with",
    "/advance-audit-page",
    "/webpage-audit",
    "/advance-webpage-audit",
    "/my-audits",
    "/my-audits-deleted",
    "/my-profile",
    "/all-leads",
    "/deleted-lead-list",
    "/white-label",
    "/keyword-ranking",
    "/seo-analyzer",
    "/create-widget",
    "/edit-widget",
    "/widget-list",
    "/deleted-widget-list",
    "/admin-site-list",
    "/create-project",
    "/create-project",
    "/edit-project",
    "/edit-engines",
    "/edit-keywords",
    "/billing",
    "/email-confirmation",
    "/audit-report",
    "/audit-report",
    "/billing-detils",
    "/lead-report",
    "/pricing",
    "/manage-subscription",
    "/billing-details",
    "/billing",
    "/login-main",
    "/payment-method",
    "/on-page",
    "/http-status",
    "/indexing",
    "/canonical",
    "/globalUrl",
    "/seo-audit",
    "/setting",
    "/website-audit",
    "/manage-group",
    "/confirm-order",
    "/subscribe-form",
    "/payment",
  ];

  const [usePath, setUsePath] = useState(false);

  const nameOfSlice = window.location.pathname.split("/");
  const nameOf = `/${nameOfSlice[1]}`;

  const setItDone = () => {
    let pathCon = [];
    for (let i = 0; i < newPath.length; i += 1) {
      if (nameOf === newPath[i]) {
        pathCon.push(true);
      } else {
        pathCon.push(false);
      }
    }
    const found = pathCon.find((element) => element === true);
    if (found !== true) {
      setUsePath(true);
    }
  };

  useEffect(() => {
    setItDone();
  }, [history]);

  history.listen(() => {
    window.scrollTo({
      top: "0",
    });
    setUsePath(false);
  });

  useEffect(() => {
    if (tokencheck) {
      history.listen(() => {
        dispatch(getUserAccountData());
        if (window.location.pathname === "/") {
          dispatch(getAccountData());
        }
      });
    }
  }, []);

  // For Right side drawer
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
    setIsMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsMenuOpen(false);
  };

  const notParam = [
    "my-audits",
    "widget-list",
    "create-project",
    "edit-project",
    "edit-keywords",
    "edit-engines",
    "widet-list",
    "all-leads",
    "my-profile",
    "setting",
    "billing-details",
    "pricing",
    "manage-subscription",
    "billing",
    "admin-site-list",
    "keyword-ranking",
    "lead-report",
    "audit-report",
    "payment-method",
    "",
  ];

  const [showDoc, setShowDoc] = useState(false);

  useEffect(() => {
    const urlParam = window.location.pathname.split("/")[1];
    const show = notParam.every((item) => item !== urlParam);
    setShowDoc(show);
    history.listen(() => {
      const urlParams = window.location.pathname.split("/")[1];
      const shows = notParam.every((item) => item !== urlParams);
      setShowDoc(shows);
    });
  }, []);

  // For Right side drawer

  return (
    
    <Router history={history} ref={registerNav}>
      {!isloggedin && (
        <Fragment>
          {setData.show_cookie_settings && (
            <CookieConsent
              onAccept={() => {
                window.location.reload(true);
              }}
              location="bottom"
              buttonText={`${setData.cookie_settings_button_text}`}
              cookieName="myAwesomeCookieName2"
              expires={30}
            >
              <span style={{ color: "#fff " }}>
                {ReactHtmlParser(setData.cookie_settings_text)}
              </span>
            </CookieConsent>
          )}
          {setData.show_branded_tool && (
            <div className="button__sidebar">
              <a
                className="branded__seo"
                target="__blank"
                href={`${setData.branded_tool_link}`}
              >
                {setData.small_logo !== "" ? (
                  <img src={`${SetServerUrl}${setData.small_logo}`} alt="" />
                ) : (
                  ""
                )}

                {`${setData.branded_tool_text}`}
              </a>
            </div>
          )}
        </Fragment>
      )}

      {isloggedin ? (
        <Switch>
          <DashboardLayoutRoute
            exact
            path={[
              "*",
              "/",
              "/audit-page",
              "/advance-audit-page",
              "/my-audits",
              "/my-audits-deleted",
              "/my-profile",
              "/all-leads",
              "/deleted-lead-list",
              "/white-label",
              "/keyword-ranking/:idp/:idse",
              "/seo-analyzer",
              "/create-widget",
              "/edit-widget/:id",
              "/sync-lead/:sync_with",
              "/sync-lead",
              "/widget-list",
              "/deleted-widget-list",
              "/admin-site-list",
              "/create-project",
              "/create-project/:id",
              "/edit-project/:id",
              "/edit-engines/:id",
              "/edit-keywords/:id",
              "/billing",
              "/email-confirmation",
              "/audit-report/:id",
              "/audit-report",
              "/billing-detils",
              "/lead-report/:id",
              "/pricing",
              "/manage-subscription",
              "/billing-details",
              "/billing/:id",
              "/login-main",
              "/payment-method/:id",
              "/on-page",
              "/http-status",
              "/indexing",
              "/canonical",
              "/globalUrl",
              "/website-audit/overview/:id/:ids",
              "/website-audit/issue-report/:ids/:ids",
              "/website-audit/run-audit",
              "/website-audit/setting/:id/:ids",
              "/website-audit/crawled-pages/:id/:ids",
              "/website-audit/found-links/:id/:ids",
              "/website-audit/found-resources/:id/:ids",
              "manage-group/:idGroupProject",
            ]}
          >
            <Box
              sx={{ display: "flex" }}
              className={`page-wrapper asd ${proHideClass} ${className}`}
            >
              <ReactNotifications />

              <Meta headerElements={(setData || "").page_header_home} />

              <AppBar position="fixed" open={open}>
                <Header
                  siteSettingData={(settingData || {}).getData}
                  isLoading={isLoading}
                  isMenuOpen={isMenuOpen}
                  toggleSidebar={toggleSidebar}
                />
              </AppBar>

              <Main
                open={open}
                className={`content-wrapper ${
                  isMenuOpen === true ? "burger" : ""
                }`}
              >
                {setData.show_branded_tool && (
                  <div className="button__sidebar">
                    <a
                      className="branded__seo"
                      target="__blank"
                      href={`${setData.branded_tool_link}`}
                    >
                      {setData.small_logo !== "" ? (
                        <img
                          src={`${SetServerUrl}${setData.small_logo}`}
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                      {`${setData.branded_tool_text}`}
                    </a>
                  </div>
                )}

                <Sidebar
                  classMobile={className}
                  toggleSidebar={toggleSidebar}
                  isMenuOpen={isMenuOpen}
                />
                <div className="main-content">
                  <Alert />
                  <Note />
                  <UserAlert />
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/audit-page" component={AuditPage} />
                  <Route exact path="/advance-audit-page" component={AdvanceAuditPage} />
                  <Route exact path="/webpage-audit" component={WebpageAudit} />
                  <Route exact path="/advance-webpage-audit" component={AdvanceWebpageAudit} />
                  <Route exact path="/my-audits" component={MyAudits} />
                  <Route
                    exact
                    path="/my-audits-deleted"
                    component={MyDeletedAudits}
                  />
                  <Route exact path="/my-profile" component={MyProfile} />
                  <Route exact path="/all-leads" component={AllLeads} />
                  <Route
                    exact
                    path="/deleted-lead-list"
                    component={DeletedLeadsList}
                  />
                  {/* <Route exact path="/white-label" component={WhiteLabelPreload} /> */}
                  <Route
                    exact
                    path="/keyword-ranking/:idp/:idse"
                    component={KeywordRanking}
                  />
                  <Route exact path="/seo-analyzer" component={SeoAnalyzer} />
                  <Route
                    exact
                    path="/admin-site-list"
                    component={AdminSiteList}
                  />
                  <Route exact path="/create-widget" component={CreateWidget} />
                  <Route
                    exact
                    path="/edit-widget/:id"
                    component={CreateWidget}
                  />
                  <Route
                    exact
                    path="/sync-lead"
                    component={SyncLead}
                  />
                  <Route
                    exact
                    path="/sync-lead/:sync_with"
                    component={SyncLead}
                  />
                  <Route exact path="/widget-list" component={WidgetList} />
                  <Route
                    exact
                    path="/deleted-widget-list"
                    component={DeletedWidgetList}
                  />
                  <Route exact path="/create-project" component={CreateMain} />
                  <Route
                    exact
                    path="/edit-project/:id"
                    component={EditProject}
                  />
                  <Route
                    exact
                    path="/edit-engines/:id"
                    component={EditEngines}
                  />
                  <Route
                    exact
                    path="/edit-keywords/:id"
                    component={EditKeywords}
                  />
                  <Route exact path="/billing" component={BillingMain} />
                  <Route
                    exact
                    path="/email-confirmation"
                    component={EmailConfirmation}
                  />
                  <Route
                    exact
                    path="/audit-report/:id"
                    component={AuditReport}
                  />
                  <Route
                    exact
                    path="/temp-audit-save/:id"
                    component={TempAuditSave}
                  />
                  {/* <Route
                      exact
                      exact path="/audit-report"
                      component={AuditReportLastone}
                    /> */}
                  <Route
                    exact
                    path="/lead-report/:id"
                    component={AuditReport}
                  />

                  <Route exact path="/pricing" component={Pricing} />
                  <Route
                    exact
                    path="/manage-subscription"
                    component={ManageSubscription}
                  />
                  <Route
                    exact
                    path="/billing/:id"
                    component={InvoiceContainer}
                  />
                  {/* <Route exact path="/login-main" component={LoginMainContainer} />, */}
                  {/* <Redirect to='/' /> */}

                  {/* V3 Pages Routes  */}
                  <Route exact path="/on-page" component={Onpage} />
                  <Route exact path="/http-status" component={HttpStatus} />
                  <Route exact path="/indexing" component={Indexing} />
                  <Route exact path="/canonical" component={Canonical} />
                  <Route
                    exact
                    path="/page-change-monitor"
                    component={PageChangeMonitor}
                  />
                  <Route exact path="/add-page" component={AddPage} />
                  <Route exact path="/monitoring" component={MonitoredItems} />
                  <Route exact path="/change-log" component={Changes} />
                  {/* End v3 pages */}

                  {/* Pricing page route */}
                  <Route exact path="/payment-method/:id" component={Payment} />
                  <Route
                    exact
                    path="/payment/success/:id"
                    component={PaymentSuccess}
                  />
                  <Route
                    exact
                    path="/payment/cancel/:id"
                    component={PaymentCancel}
                  />
                  <Route
                    exact
                    path="/confirm-order/:id/:id"
                    component={PaymentConfirm}
                  />
                  <Route
                    exact
                    path="/subscribe-form/:id/"
                    component={SubscribeForm}
                  />

                  <Route
                    exact
                    path="/billing-details"
                    component={BillingContainer}
                  />

                  {/* Website Audit Pages */}
                  <Route
                    exact
                    path="/website-audit/overview/:id/:ids"
                    component={Overview}
                  />
                  <Route
                    exact
                    path="/website-audit/issue-report/:id/:ids"
                    component={IssueReport}
                  />
                  <Route
                    exact
                    path="/website-audit/crawled-pages/:id/:ids"
                    component={CrawledPages}
                  />
                  <Route
                    exact
                    path="/website-audit/found-resources/:id/:ids"
                    component={FoundResources}
                  />
                  <Route
                    exact
                    path="/website-audit/found-links/:id/:ids"
                    component={FoundLinks}
                  />
                  <Route
                    exact
                    path="/website-audit/crawl-comparison/:id/:ids"
                    component={CrawlComparison}
                  />
                  <Route
                    exact
                    path="/page-change-monitors"
                    component={PageChangeMonitors}
                  />
                  <Route
                    exact
                    path="/website-audit/run-audit"
                    component={RunAaudit}
                  />
                  <Route exact path="/setting" component={Settings} />
                  <Route
                    exact
                    path="/manage-group/:idGroupProject"
                    component={ManageGroup}
                  />

                  <Route exact path="/add-pages" component={AddPages} />

                  <Route exact path="/seo-audit" component={SeoAudit} />
                  {usePath && <Route component={NotFound} />}
                </div>
              </Main>
              {open && (
                <RightDrawer
                  open={open}
                  handleDrawerClose={handleDrawerClose}
                />
              )}
              {/* {showDoc && (
                <ToolTip placement="left" title="Knowledge Base">
                  <Box
                    className="mob_chat"
                    component="span"
                    sx={{
                      position: "fixed",
                      bottom: "3.5rem",
                      backgroundColor: theme.palette.primary.main,
                      right: ".5rem",
                      zIndex: "11",
                      width: "2.8rem",
                      height: "2.8rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <IconButton>
                      <DescriptionIcon
                        sx={{ color: theme.palette.common.white }}
                      />
                    </IconButton>
                  </Box>
                </ToolTip>
              )} */}
            </Box>
            <Footer
              isLoading={isLoading}
              siteSettingData={(settingData || {}).getData}
            />
          </DashboardLayoutRoute>
        </Switch>
      ) : (
        <Switch>
          <LoginLayoutRoute path="/">
            <div className={`page-wrapper login ${className}`}>
              <Meta headerElements={(setData || "").page_header_home} />
              <AppBar>
                <HeaderAuth
                  siteSettingData={(settingData || {}).getData}
                  isMenuOpen={isMenuOpen}
                  isLoading={isLoading}
                  toggleSidebar={toggleSidebar}
                />
              </AppBar>
              <div
                className={`content-wrapper ${
                  isMenuOpen === true ? "burger" : ""
                }`}
              >
                <SidebarAuth
                  classMobile={className}
                  toggleSidebar={toggleSidebar}
                  isMenuOpen={isMenuOpen}
                />
                <div className="main-content">
                  <Alert />
                  <div className="note-box " />
                  <Route path="/audit-page" component={AuditPage} />
                  <Route path="/advance-audit-page" component={AdvanceAuditPage} />
                  <Route path="/webpage-audit" component={WebpageAudit} />
                  <Route path="/advance-webpage-audit" component={AdvanceWebpageAudit} />
                  <Route
                    path="/audit-report/temp/:temp_id"
                    component={AuditReport}
                  />
                  <Route path="/pricing" component={Pricing} />
                  <Route exact path="/" component={LoginAuth} />
                  {usePath && <Route component={NotFound} />}
                </div>
              </div>
            </div>
            <FooterAuth
              isLoading={isLoading}
              siteSettingData={(settingData || {}).getData}
            />
          </LoginLayoutRoute>
        </Switch>
      )}
    </Router>
  );
}

export default App;
