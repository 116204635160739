import { PageLoader, UsesStatus } from "@components";
import { Box, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as adminSiteListActions from "../../appRedux/action/adminSiteListActions";
import GroupTable from "./component/GroupTable";
import WeisiteTable from "./component/website/WeisiteTable";
import "./SiteList.scss";

function AdminSiteList() {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const grouped = useSelector(
    ({ adminSiteList }) => adminSiteList.siteGroups.grouped
  );
  const ungrouped = useSelector(
    ({ adminSiteList }) => adminSiteList.siteGroups.ungrouped
  );
  const dataRoot = useSelector(({ adminSiteList }) => adminSiteList.dataRoot);

  const loading = useSelector(({ adminSiteList }) => adminSiteList.loading);

  useEffect(() => {
    dispatch(adminSiteListActions.getAdminSiteListData());
  }, [dispatch]);

  async function showProjectRanking(project_id, se_id) {
    history.push(`/keyword-ranking/${project_id}/${se_id}/`);
  }

  const useProfileData = useSelector(
    (userData) => userData.subscription.useAccountDetails.data
  );

  const validData = {
    total: useProfileData
      ? useProfileData.subscription_details.number_of_project.quota
      : 0,
    use: useProfileData
      ? useProfileData.subscription_details.number_of_project.usage
      : 0,
    title: t("common.youUsed"),
    lastTitle: t("common.projects"),
  };

  return (
    <Fragment>
      {!loading ? (
        <section className="mb-4 section-site-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Box
                  className="allleadsHeading"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <div className="section-heading mb-0">
                    <h2 className="mb-0">{t("siteList.heading")}</h2>
                  </div>
                  <Box>
                    {useProfileData.subscription_details.number_of_project
                      .usage !== 0 && (
                      <Box sx={{ marginBottom: ".5rem" }}>
                        <Typography
                          component="strong"
                          sx={{ textTransform: "uppercase", fontWeight: "600" }}
                        >
                          {t("common.lastUpdateDate")}: &nbsp;
                          {dataRoot?.dataRoot?.avg_position_date?.[0]}
                        </Typography>
                      </Box>
                    )}
                    <div className="mb-0 mb-sm-3 mb-md-3 mb-lg-3">
                      <UsesStatus data={validData} />
                    </div>
                  </Box>
                </Box>
              </div>
              {grouped?.length > 0 || ungrouped?.sites?.length > 0 ? (
                <Fragment>
                  <div className="col-md-12 tablemarginTop homeaudit_padding  ">
                    {ungrouped ? (
                      <Fragment>
                        {ungrouped?.sites?.length > 0 ? (
                          <WeisiteTable
                            dataRoot={dataRoot}
                            ungrouped={ungrouped}
                            selectProject={(project_id, se_id) =>
                              showProjectRanking(project_id, se_id)
                            }
                          />
                        ) : null}
                      </Fragment>
                    ) : null}
                  </div>
                  <div className="col-md-12  homeaudit_padding">
                    {grouped ? (
                      <Fragment>
                        {grouped?.length > 0 ? (
                          <GroupTable
                            dataRoot={dataRoot}
                            grouped={grouped}
                            selectProject={(project_id, se_id) =>
                              showProjectRanking(project_id, se_id)
                            }
                          />
                        ) : null}
                      </Fragment>
                    ) : null}
                  </div>
                </Fragment>
              ) : (
                <div className="col-md-12">
                  <div className="no__data">
                    <div className="text__wrap">
                      <p className="no_data_found">
                        {t("siteList.noDataMessage")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="mt-4 all-btn">
                  <div className="top-btn">
                    <Link
                      to="/create-project"
                      className="seo_btn gradient_blu"
                      style={{ color: "white" }}
                    >
                      {t("siteList.btnCreate")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <PageLoader />
      )}
    </Fragment>
  );
}
export default AdminSiteList;
