import {
  GET_KEYRANK_OVERVIEW_BEGIN,
  GET_KEYRANK_OVERVIEW_FAIL,
  GET_KEYRANK_OVERVIEW_SUCCESS
} from "../../types";

const initialState = {
  keyOverview: [],
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_KEYRANK_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_KEYRANK_OVERVIEW_SUCCESS:
      return {
        ...state,
        keyOverview: action.payload.data,
        loading: false,
      };
    case GET_KEYRANK_OVERVIEW_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
