import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";

export const GET_AUDIT_REPORT_URL_BEGIN = "GET_AUDIT_REPORT_URL_BEGIN";
export const GET_AUDIT_REPORT_URL_SUCCESS = "GET_AUDIT_REPORT_URL_SUCCESS";
export const GET_AUDIT_REPORT_URL_FAIL = "GET_AUDIT_REPORT_URL_FAIL";
export const GET_AUDIT_REPORT_BEGIN = "GET_AUDIT_REPORT_BEGIN";
export const GET_AUDIT_REPORT_SUCCESS = "GET_AUDIT_REPORT_SUCCESS";
export const GET_AUDIT_REPORT_FAIL = "GET_AUDIT_REPORT_FAIL";

export const auditReportByUrl = (url, keyword, history, path) => (dispatch) => {
  dispatch({
    type: GET_AUDIT_REPORT_URL_BEGIN,
  });

  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/report?url=${url}&keyword=${keyword}`,
  })
    .then((res) => {
      dispatch({
        type: GET_AUDIT_REPORT_URL_SUCCESS,
        payload: res,
      });
      if (res.data.data.audit_id !== null) {
        history.push({
          pathname: `/audit-report/${res.data.data.audit_id}`,
          state: {
            from: path,
          },
        });
      } else {
        history.push(`/audit-report`);
      }

      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_AUDIT_REPORT_URL_FAIL,
        payload: { error },
      });

      if (error.response && error.response.data) {
        dispatch(setAlert(error.response.data.message, "danger"));
      }
      return error;
    });
};

export const getAuditReportById = (report_id) => (dispatch) => {
  dispatch({
    type: GET_AUDIT_REPORT_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/report/${report_id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_AUDIT_REPORT_SUCCESS,
        payload: { res, report_id },
        audit_id: res.data.audit_id,
        share_key: res.data.share_key,
        content: res.data.content,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_AUDIT_REPORT_FAIL,
        payload: { error },
      });
      return error;
    });
};
