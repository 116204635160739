import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import InputLabel from "../InputLabel";

const GeneralForm = (props) => {
  const {
    values,
    errors,
    setErrors,
    someEditedValue,
    setValues,
    setSomeEditedValue,
  } = props;

  const { t } = useTranslation();
  const handleChangeSelect = useCallback((data) => {
    setValues((prevValues) => ({
      ...prevValues,
      close_button_action: data.value,
    }));
    setSomeEditedValue((prevValues) => ({
      ...prevValues,
      close_button_action: data.value,
    }));
  }, []);
  const handleChangeThis = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (name === "title" || name === "redirect_url") {
        setValues((prevValues) => ({
          ...prevValues,
          close_button_extra: {
            ...prevValues.close_button_extra,
            [values.close_button_action]: {
              ...prevValues.close_button_extra?.[values.close_button_action],
              [name]: value,
            },
          },
        }));
        setSomeEditedValue((prevValues) => ({
          ...prevValues,
          close_button_extra: {
            ...prevValues.close_button_extra,
            [values.close_button_action]: {
              ...prevValues.close_button_extra?.[values.close_button_action],
              [name]: value,
            },
          },
        }));
      }
    },
    [values.close_button_action]
  );

  // Extra Select Optons
  const buttonOptions = [
    { value: "default", label: t("createWidget.widgetType.29") },
    { value: "redirect", label: t("createWidget.widgetType.30") },
  ];

  return (
    <Grid container spacing={4} style={{ marginLeft: "15px !important" }}>
      <Grid item xs={12} lg={12} xl={8}>
        <Box paddingBottom="1rem !important" className="form-full">
          <Typography variant="h5" className="title">
            {t("createWidget.widgetType.15")}
          </Typography>
          <Typography variant="body1">
            {t("createWidget.widgetType.16")}
          </Typography>
          <Box
            sx={{ mt: ".6rem" }}
            className="custom-selector custom-selector3  "
          >
            <Select
              options={buttonOptions}
              onChange={handleChangeSelect}
              value={
                someEditedValue?.close_button_action !== undefined
                  ? buttonOptions.find(
                      ({ value }) =>
                        value === someEditedValue?.close_button_action
                    )
                  : buttonOptions.find(
                      ({ value }) => value === values.close_button_action
                    )
              }
            />
          </Box>
        </Box>
        {((someEditedValue?.close_button_action && someEditedValue?.close_button_action === "redirect")||(!someEditedValue?.close_button_action && values?.close_button_action === "redirect")) && (
          <div className="input_wrapper custom-selector3 ">
            <InputLabel
              label={t("createWidget.widgetType.17")}
              required
              title={t("createWidget.widgetType.18")}
            />
            <input
              name="redirect_url"
              onChange={(e) => {
                handleChangeThis(e);
                if (e.target.value === "") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    redirect_url_error: <span>{t("common.required")}</span>,
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    redirect_url_error: "",
                  }));
                }
              }}
              value={values.close_button_extra?.redirect?.redirect_url || ""}
              type="text"
              placeholder={t("createWidget.widgetType.19")}
            />
            <div className="form__error">{errors.redirect_url_error}</div>
          </div>
        )}
        <Box className="input_wrapper custom-selector3 ">
          <InputLabel
            label={
              values.close_button_action === "redirect" ||
              someEditedValue?.close_button_action === "redirect"
                ? t("createWidget.widgetType.20")
                : t("createWidget.widgetType.21")
            }
            required
            title={
              values.close_button_action === "redirect" ||
              someEditedValue?.close_button_action === "redirect"
                ? t("createWidget.widgetType.22")
                : t("createWidget.widgetType.23")
            }
          />

          <input
            name="title"
            onChange={(e) => {
              handleChangeThis(e);
              if (e.target.value === "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  close_button_label_error: <span>{t("common.required")}</span>,
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  close_button_label_error: "",
                }));
              }
            }}
            value={
              values.close_button_extra?.[values.close_button_action]?.title
            }
            type="text"
            placeholder={
              values.close_button_action === "redirect" ||
              someEditedValue?.close_button_action === "redirect"
                ? t("createWidget.widgetType.24")
                : t("createWidget.widgetType.25")
            }
          />
          <div className="form__error">{errors.close_button_label_error}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(GeneralForm);
