import {
  GET_WEBSITE_AUDIT_LIST_BEGIN,
  GET_WEBSITE_AUDIT_LIST_FAIL,
  GET_WEBSITE_AUDIT_LIST_SUCCESS
} from "../../action/websiteAudit/websiteAuditListAction";

const initialState = {
  websiteList: [],
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WEBSITE_AUDIT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_WEBSITE_AUDIT_LIST_SUCCESS:
      return {
        ...state,
        websiteList: action.payload.data.data,
        loading: false,
      };
    case GET_WEBSITE_AUDIT_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
