import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import RequiredLabel from "../RequiredLabel";

const KeywordField = ({
  values,
  wform_keyword,
  setValues,
  handlelabelPlaceChange,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="divider ">
      <InputLabel
        label={t("createWidget.formField.11")}
        title={t("createWidget.formField.12")}
      />
      <div className="check ">
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="checkbox"
            className="custom-control-input"
            name="show_field"
            value={wform_keyword.show_field}
            id="show_field"
            onChange={() => {
              setValues({
                ...values,
                wform_keyword: {
                  ...values.wform_keyword,
                  show_field: wform_keyword.show_field > 0 ? 0 : 1,
                },
              });
            }}
            checked={wform_keyword.show_field !== 0}
          />
          <label className="custom-control-label" htmlFor="show_field">
            <Typography component="span" variant="body1">
              {t("createWidget.checkBox.labels.keyword")}
            </Typography>
          </label>
        </div>
      {wform_keyword.show_field >= 1 && (
      <>
      {(values.show_field_text_as === 1 || values.show_field_text_as === 3) && (
        <Box className="input_wrapper custom-selector2  ">
          <RequiredLabel label={t("common.label")} required />
          <input
            placeholder={t("createWidget.formField.13")}
            name="label"
            value={wform_keyword.label}
            type="text"
            onChange={(e) => {
              handlelabelPlaceChange(e, "wform_keyword");
              if (e.target.value === "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  key_label_error: <span>{t("common.required")}</span>,
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  key_label_error: "",
                }));
              }
            }}
          />
          <div className="form__error">{errors.key_label_error}</div>
        </Box>
      )}
      {(values.show_field_text_as === 2 || values.show_field_text_as === 3) && (
        <Box className="input_wrapper ">
          <RequiredLabel label={t("common.placeholder")} required />
          <input
            placeholder={t("createWidget.formField.label")}
            name="placeholder"
            value={wform_keyword.placeholder}
            type="text"
            onChange={(e) => {
              handlelabelPlaceChange(e, "wform_keyword");
              if (e.target.value === "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  key_placeholder_error: <span>{t("common.required")}</span>,
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  key_placeholder_error: "",
                }));
              }
            }}
          />
          <div className="form__error">{errors.key_placeholder_error}</div>
        </Box>
     
      )}
      {values.widget_type === "5" || values.widget_type === 5 ?
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="checkbox"
            className="custom-control-input"
            name="show_in_first_step"
            value={wform_keyword.show_in_first_step}
            id="wform_keyword_show_in_first_step"
            onChange={() => {
              setValues({
                ...values,
                wform_keyword: {
                  ...values.wform_keyword,
                  show_in_first_step: wform_keyword.show_in_first_step > 0 ? 0 : 1,
                },
              });
            }}
            checked={wform_keyword.show_in_first_step !== 0}
          />
          <label className="custom-control-label" htmlFor="wform_keyword_show_in_first_step">
            <Typography component="span" variant="body1">
              {t("createWidget.formField.35")}
            </Typography>
          </label>
        </div>
        :""}
      </>
          )}
       </div>
    </Box>
  );
};

export default KeywordField;
