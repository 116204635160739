import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ColorPicker from "../ColorPicker";
import useStyles from "../styles";
import { fontWeightOptions, textTransformOption } from "./selectOptions";

const TncStyle = ({ values, setValues, setChangingObject, changingObject }) => {
  const classes = useStyles();

  const { tnc_style } = values;

  const handleTtitleChange = useCallback((e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      tnc_style: {
        ...prevState.tnc_style,
        [name]: parseInt(value),
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      tnc_style: {
        ...prevState.tnc_style,
        [name]: parseInt(value),
      },
    }));
  }, []);

  const hanldeSelect = useCallback((data, name) => {
    setValues((prevState) => ({
      ...prevState,
      tnc_style: {
        ...prevState.tnc_style,
        [name]: data.value,
      },
    }));

    setChangingObject((prevState) => ({
      ...prevState,
      tnc_style: {
        ...prevState.tnc_style,
        [name]: data.value,
      },
    }));
  }, []);

  const { t } = useTranslation();

  return (
    <Box className={classes.formStyle}>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.text")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.color")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="color"
              type="none"
              nested="tnc_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.size")}
          </Typography>
          <div>
            <input
              name="text_size"
              value={tnc_style.text_size}
              onChange={handleTtitleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.transform")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={textTransformOption}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "text_transform")}
              value={textTransformOption.find(
                ({ value }) => value === tnc_style.text_transform
              )}
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.fontWeight")}
          </Typography>
          <div className="custom-selector">
            <Select
              placeholder={t("common.select")}
              options={fontWeightOptions}
              className="react__select"
              onChange={(data) => hanldeSelect(data, "font_weight")}
              value={fontWeightOptions.find(
                ({ value }) => value === tnc_style.font_weight
              )}
            />
          </div>
        </Box>
      </div>
      <div className={`${classes.flexCenter} formMain_table`}>
        <Box>{t("createWidget.formStyle.checkBox")}</Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.bgColor")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="check_background"
              type="none"
              nested="tnc_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.activeBgColor")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="check_active_background"
              type="none"
              nested="tnc_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.checkMarkColor")}
          </Typography>
          <div className="custom__color__picker">
            <ColorPicker
              changingObject={changingObject}
              setChangingObject={setChangingObject}
              className="color__picker"
              setValues={setValues}
              values={values}
              name="check_mark_color"
              type="none"
              nested="tnc_style"
            />
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.width")}
          </Typography>
          <div>
            <input
              name="check_width"
              value={tnc_style.check_width}
              onChange={handleTtitleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.height")}
          </Typography>
          <div>
            <input
              name="check_height"
              value={tnc_style.check_height}
              onChange={handleTtitleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            {t("createWidget.formStyle.borderRadius")}
          </Typography>
          <div>
            <input
              name="check_border_radius"
              value={tnc_style.check_border_radius}
              onChange={handleTtitleChange}
              type="number"
            />
            <Typography variant="body1" component="small">
              px
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(TncStyle);
