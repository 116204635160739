import {
  DELETE_SITE_GROUPS_BEGIN,
  DELETE_SITE_GROUPS_FAIL,
  DELETE_SITE_GROUPS_SUCCESS,
  GET_SITE_GROUPS_BEGIN,
  GET_SITE_GROUPS_FAIL,
  GET_SITE_GROUPS_SUCCESS
} from "../action/adminSiteListActions";

const initialState = {
  dataRoot: {},
  siteGroups: {
    ungrouped: {
      name: "",
      sites: [
        {
          id: "",
          title: "",
          top: "",
          keyword_count: "",
          avg_position: [],
          web_audit: [],
          last_updated: [],
          searc_engines: [
            {
              id: "",
              title: "",
              top: "",
              keyword_count: "",
              avg_position: [],
              web_audit: [],
              last_updated: [],
            },
          ],
        },
      ],
    },
    grouped: [
      {
        name: "",
        id: "",
        sites: [
          {
            id: "",
            title: "",
            top: "",
            keyword_count: "",
            avg_position: [],
            web_audit: [],
            last_updated: "",
            searc_engines: [
              {
                id: "",
                title: "",
                top: "",
                keyword_count: "",
                avg_position: [],
                web_audit: [],
                last_updated: "",
              },
            ],
          },
        ],
      },
    ],
  },
  loading: true,
  error: null,
};
const adminSiteListReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SITE_GROUPS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_SITE_GROUPS_SUCCESS:
      return {
        ...state,
        dataRoot: payload,
        siteGroups: {
          ungrouped: payload.ungrouped,
          grouped: payload.grouped,
        },
        loading: false,
      };
    case GET_SITE_GROUPS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_SITE_GROUPS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_SITE_GROUPS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_SITE_GROUPS_FAIL:
      return {
        ...state,
        siteGroups: {
          error: payload.error.response.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default adminSiteListReducer;
