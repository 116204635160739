import {
  GET_LEAD_REPORT_BEGIN,
  GET_LEAD_REPORT_SUCCESS,
  GET_LEAD_REPORT_FAIL,
} from "../action/getLeadsAction";
const initialState = {
  lead_report_loading: false,
  content: "",
  audit_id: "",
  share_key: "",
  leadData: {
    audit_id: "",
    content: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAD_REPORT_BEGIN:
      return {
        ...state,
        lead_report_loading: true,
      };

    case GET_LEAD_REPORT_SUCCESS:
      return {
        ...state,
        lead_report_loading: false,
        content: action.payload.data.data.content,
        audit_id: action.payload.data.data.audit_id,
        share_key: action.payload.data.data.share_key,
      };

    case GET_LEAD_REPORT_FAIL:
      return {
        ...state,
        lead_report_loading: false,
      };
    default:
      return state;
  }
};
