import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const UPDATA_ACCOUNT_DATA_BEGIN = "UPDATA_ACCOUNT_DATA_BEGIN";
export const UPDATA_ACCOUNT_DATA_SUCCESS = "UPDATA_ACCOUNT_DATA_SUCCESS";
export const UPDATA_ACCOUNT_DATA_FAIL = "UPDATA_ACCOUNT_DATA_FAIL";

export const GET_ACCOUNT_DATA_BEGIN = "GET_ACCOUNT_DATA_BEGIN";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAIL = "GET_ACCOUNT_DATA_FAIL";

export const GET_PAGE_SETTING_BEGIN = "GET_PAGE_SETTING_BEGIN";
export const GET_PAGE_SETTING_SUCCESS = "GET_PAGE_SETTING_SUCCESS";
export const GET_PAGE_SETTING_FAIL = "GET_PAGE_SETTING_FAIL";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

export const SEND_SUPPORT_BEGIN = "SEND_SUPPORT_BEGIN";
export const SEND_SUPPORT_SUCCESS = "SEND_SUPPORT_SUCCESS";
export const SEND_SUPPORT_FAIL = "SEND_SUPPORT_FAIL";

export const getAccountData = () => (dispatch) => {
  dispatch({
    type: GET_ACCOUNT_DATA_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/account/profile/",
  })
    .then((res) => {
      dispatch({
        type: GET_ACCOUNT_DATA_SUCCESS,
        payload: res.data.data.profile_info,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_ACCOUNT_DATA_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const updateAccountData = (user) => (dispatch) => {
  dispatch({
    type: UPDATA_ACCOUNT_DATA_BEGIN,
  });
  const data = {
    first_name: user.firstName,
    last_name: user.lastName,
    password: user.password,
    language: user.language,
    test_sever_region: user.test_sever_region,
    test_sever_env: user.test_sever_env,
  };
  return serverCall({
    method: "POST",
    url: "/api/v1/account/profile/update/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: UPDATA_ACCOUNT_DATA_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
        
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: UPDATA_ACCOUNT_DATA_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));

    });
};

export const getPageSettingData = () => (dispatch) => {
  dispatch({
    type: GET_PAGE_SETTING_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: "/api/v1/settings/",
  })
    .then((res) => {
      const data = {
        settingData: res.data.data.settings,
      };

      dispatch({
        type: GET_PAGE_SETTING_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_PAGE_SETTING_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const deleteAccountAction = (history) => async (dispatch) => {
  serverCall({
    method: "DELETE",
    url: "api/v1/account/delete/",
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_ACCOUNT,
          payload: res,
        });

        dispatch(setAlert(res.data.message, "success"));

        if (res.status === 200) {
          history.push("/");
        }
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_ACCOUNT_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const sendSupportEmail = (data) => (dispatch) => {
  dispatch({
    type: SEND_SUPPORT_BEGIN,
  });

  return serverCall({
    method: "POST",
    url: "api/v1/user/support/",
    data,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: SEND_SUPPORT_SUCCESS,
          payload: data,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
        return data;
    })
    .catch((error) => {
      dispatch({
        type: SEND_SUPPORT_FAIL,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;

    });
};
