import {
  ADD_ENGINE_BEGIN,
  ADD_ENGINE_FAIL,
  ADD_ENGINE_SUCCESS,
  DELETE_ENGINE_BEGIN,
  DELETE_ENGINE_FAIL,
  DELETE_ENGINE_SUCCESS,
  GET_ALL_ENGINE_BEGIN,
  GET_ALL_ENGINE_FAIL,
  GET_ALL_ENGINE_SUCCESS,
  GET_ENGINE_DETAILS_BEGIN,
  GET_ENGINE_DETAILS_FAIL,
  GET_ENGINE_DETAILS_SUCCESS,
  UPDATE_ENGINE_BEGIN,
  UPDATE_ENGINE_FAIL,
  UPDATE_ENGINE_SUCCESS
} from "../action/engineAction";

const initialState = {
  // new dicussion for fix the state
  id: null,
  se_id: "",
  se_name: null,
  competitior: [],
  engine_loading: false,
  delete_engine_loading: false,
  get_engine_loading: false,
  search_engines: [],
  last_rank_update: "",
  project_name: "",
  usage: [],
  get_engine_details_loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Add engine section
    case ADD_ENGINE_BEGIN:
      return {
        ...state,
        engine_loading: true,
      };

    case ADD_ENGINE_SUCCESS:
      return {
        ...state,
        engine_loading: false,
      };

    case ADD_ENGINE_FAIL:
      return {
        engine_loading: false,
      };

    // get all engine section
    case GET_ALL_ENGINE_BEGIN:
      return {
        ...state,
        get_engine_loading: true,
      };

    case GET_ALL_ENGINE_SUCCESS:
      return {
        ...state,
        get_engine_loading: false,
        search_engines: action.payload.data.data.search_engines,
        usage: action.payload.data.data.usage,
        last_rank_update: action.payload.data.data.last_rank_update,
        project_name: action.payload.data.data.project_name,
      };

    case GET_ALL_ENGINE_FAIL:
      return {
        ...state,
        get_engine_loading: false,
      };

    // get engine details section

    case GET_ENGINE_DETAILS_BEGIN:
      return {
        ...state,
        get_engine_details_loading: true,
      };

    case GET_ENGINE_DETAILS_SUCCESS:
      return {
        ...state,
        get_engine_details_loading: false,
      };

    case GET_ENGINE_DETAILS_FAIL:
      return {
        ...state,
        get_engine_details_loading: false,
      };

    // delete engin section
    case DELETE_ENGINE_BEGIN:
      return {
        ...state,
        delete_engine_loading: true,
      };

    case DELETE_ENGINE_SUCCESS:
      return {
        ...state,
        delete_engine_loading: false,
      };

    case DELETE_ENGINE_FAIL:
      return {
        ...state,
        delete_engine_loading: false,
      };

    // update engine section
    case UPDATE_ENGINE_BEGIN:
      return {
        ...state,
        engine_loading: true,
      };

    case UPDATE_ENGINE_SUCCESS:
      return {
        ...state,
        engine_loading: false,
      };

    case UPDATE_ENGINE_FAIL:
      return {
        engine_loading: false,
      };

    default:
      return state;
  }
};
