import {
  SEND_AUDIT_EMAIL_BEGIAN, SEND_AUDIT_EMAIL_FAIL, SEND_AUDIT_EMAIL_SUCCESS
} from "../../action/SendEmailAction";

const initialState = {
  sendingLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_AUDIT_EMAIL_SUCCESS:
      return {
        ...state,
        sendingLoading: false,
      };

    case SEND_AUDIT_EMAIL_FAIL:
      return {
        sendingLoading: false,
      };

    // get all engine section
    case SEND_AUDIT_EMAIL_BEGIAN:
      return {
        ...state,
        sendingLoading: true,
      };
    default:
      return state;
  }
};
