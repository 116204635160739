import serverCall from "../../modules/serverCall";
import { setAlert } from "./alert";
import i18n from "../../i18n";

export const GET_SUBSCRIPTION_INFO_BEGIN = "GET_SUBSCRIPTION_INFO_BEGIN";
export const GET_SUBSCRIPTION_INFO_SUCCESS = "GET_SUBSCRIPTION_INFO_SUCCESS";
export const GET_SUBSCRIPTION_INFO_FAIL = "GET_SUBSCRIPTION_INFO_FAIL";

export const GET_SUBSCRIPTION_PACKAGE_BEGIN = "GET_SUBSCRIPTION_PACKAGE_BEGIN";
export const GET_SUBSCRIPTION_PACKAGE_SUCCESS = "GET_SUBSCRIPTION_PACKAGE_SUCCESS";
export const GET_SUBSCRIPTION_PACKAGE_FAIL = "GET_SUBSCRIPTION_PACKAGE_FAIL";
export const CLEAR_SUBSCRIPTION_LINK = "CLEAR_SUBSCRIPTION_LINK";

export const SUBMIT_SUBSCRIBE_FORM_BEGIN = "SUBMIT_SUBSCRIBE_FORM_BEGIN";
export const SUBMIT_SUBSCRIBE_FORM_SUCCESS = "SUBMIT_SUBSCRIBE_FORM_SUCCESS";
export const SUBMIT_SUBSCRIBE_FORM_FAIL = "SUBMIT_SUBSCRIBE_FORM_FAIL";

export const GET_PAYMENTINFO_INFO_BEGIN = "GET_PAYMENTINFO_INFO_BEGIN";
export const GET_PAYMENTINFO_INFO_SUCCESS = "GET_PAYMENTINFO_INFO_SUCCESS";
export const GET_PAYMENTINFO_INFO_FAIL = "GET_PAYMENTINFO_INFO_FAIL";

export const GET_USERACCOUNT_INFO_BEGIN = "GET_USERACCOUNT_INFO_BEGIN";
export const GET_USERACCOUNT_INFO_SUCCESS = "GET_USERACCOUNT_INFO_SUCCESS";
export const GET_USERACCOUNT_INFO_FAIL = "GET_USERACCOUNT_INFO_FAIL";

export const GET_SINGLESUBSCRIBE_INFO_BEGIN = "GET_SINGLESUBSCRIBE_INFO_BEGIN";
export const GET_SINGLESUBSCRIBE_INFO_SUCCESS = "GET_SINGLESUBSCRIBE_INFO_SUCCESS";
export const GET_SINGLESUBSCRIBE_INFO_FAIL = "GET_SINGLESUBSCRIBE_INFO_FAIL";

// Delete Subscription
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL";

export const getSubscriptionInfoData = () => (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_INFO_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/account/subscribe/`,
  })
    .then((res) => {
      const data = {
        email: res.data.data.subscription_info.email,
        type: res.data.data.subscription_info.type,
        created: res.data.data.subscription_info.created,
        expires: res.data.data.subscription_info.expires,
        methode: res.data.data.subscription_info.methode,
        subscription_id: res.data.data.subscription_id,
      };

      dispatch({
        type: GET_SUBSCRIPTION_INFO_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_SUBSCRIPTION_INFO_FAIL,
        payload: error,
      });
      return error;
    });
};

export const getPaymentData = () => (dispatch) => {
  dispatch({
    type: GET_PAYMENTINFO_INFO_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/account/subscriptions/`,
  })
    .then((res) => {
      dispatch({
        type: GET_PAYMENTINFO_INFO_SUCCESS,
        payload: res.data.data.all_payments,
      });
      return res.data.data.all_payments;
    })
    .catch((error) => {
      dispatch({
        type: GET_PAYMENTINFO_INFO_FAIL,
        payload: error,
      });
      return error;
    });
};

export const singleSubscribeDetails = (id) => (dispatch) => {
  dispatch({
    type: GET_SINGLESUBSCRIBE_INFO_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/account/subscriptions/${id}/`,
  })
    .then((res) => {
      dispatch({
        type: GET_SINGLESUBSCRIBE_INFO_SUCCESS,
        payload: res.data.data.details,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_SINGLESUBSCRIBE_INFO_FAIL,
        payload: error,
      });
      return error;
    });
};

export const getUserAccountData = () => (dispatch) => {
  dispatch({
    type: GET_USERACCOUNT_INFO_BEGIN,
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/account/`,
  })
    .then((res) => {
      dispatch({
        type: GET_USERACCOUNT_INFO_SUCCESS,
        payload: res.data.data.details,
      });
      return res.data.data.details;
    })
    .catch((error) => {
      dispatch({
        type: GET_USERACCOUNT_INFO_FAIL,
        payload: error,
      });
      return error;
    });
};

export const SubscribPackage = (soloData, history, goldData, platinumData) => (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_PACKAGE_BEGIN,
  });
  return serverCall({
    method: "POST",
    // url: `seo/api/v1/account/package/?update=${update}&keyword=${keyowrd}&duration=${1}`,
    url: `/api/v1/account/subscribe/`,
    // data: finalData,
    data: soloData,
    goldData,
    platinumData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        const data = {
          subscription_details: res.data.data.subscription_details,
          subscription_id: res.data.data.subscription_id,
          subscription_name: res.data.data.subscription_name,
          price: res.data.data.price,
        };
        dispatch({
          type: GET_SUBSCRIPTION_PACKAGE_SUCCESS,
          payload: data,
        });
        dispatch(setAlert(res.data.message, "success"));
        history.push(`/payment-method/${res.data.data.subscription_id}`);
        return data;
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
      
    })
    .catch((error) => {
      dispatch({
        type: GET_SUBSCRIPTION_PACKAGE_FAIL,
        payload: error.message,
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const subscribeForm = (userData, id, history) => (dispatch) => {
  dispatch({
    type: SUBMIT_SUBSCRIBE_FORM_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/account/subscription/payment/${id}/`,
    data: userData,
  })
    .then((res) => {
      if(res.status>=200 && res.status<300){
        dispatch({
          type: SUBMIT_SUBSCRIBE_FORM_SUCCESS,
          payload: res,
        });
        dispatch(setAlert(res.data.message, "success"));
        history.push(`/confirm-order/${id}/${res.data.data.payment_id}`);
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SUBMIT_SUBSCRIBE_FORM_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};

export const subscribeFormPaypal = (id) => (dispatch) => {
  dispatch({
    type: SUBMIT_SUBSCRIBE_FORM_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/account/subscription/payment/${id}/`,
    data: {
      type: "paypal",
      cancel_url: `${window.location.href.split("/payment-method")[0]}/payment/cancel/${id}`,
      return_url: `${window.location.href.split("/payment-method")[0]}/payment/success/${id}`,
    },
  })
    .then((res) => {
      if (res.data.code === "success") {
        dispatch({
          type: SUBMIT_SUBSCRIBE_FORM_SUCCESS,
          payload: res,
        });
        window.open(res.data.data.redirect_link);
      } else {
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SUBMIT_SUBSCRIBE_FORM_FAIL,
        payload: { error },
      });

      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};
export const subscribeFormPaypro = (id) => (dispatch) => {
  dispatch({
    type: SUBMIT_SUBSCRIBE_FORM_BEGIN,
  });
  return serverCall({
    method: "POST",
    url: `/api/v1/account/subscription/payment/${id}/`,
    data: {
      type: "paypro",
      cancel_url: `${window.location.href.split("/payment-method")[0]}/payment/cancel/${id}`,
      return_url: `${window.location.href.split("/payment-method")[0]}/payment/success/${id}`,
    },
  })
    .then((res) => {
      if (res.data.code === "success") {
        dispatch({
          type: SUBMIT_SUBSCRIBE_FORM_SUCCESS,
          payload: res,
        });
        window.open(res.data.data.redirect_link);
      } else {
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SUBMIT_SUBSCRIBE_FORM_FAIL,
        payload: { error },
      });

      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });
};
// Delete Subscription Package
export const deletePackage = ({ id, validData }) => async (dispatch) =>
  serverCall({
    method: "DELETE",
    url: `/api/v1/account/order/${id}/cancle/`,
    data: validData,
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_SUBSCRIPTION,
          payload: res,
        });

        dispatch(setAlert(res.data.message, "success"));
      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SUBSCRIPTION_FAIL,
        payload: { error },
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });

export const clearSubscriptionLink = () => (dispatch) => {
  dispatch({
    type: CLEAR_SUBSCRIPTION_LINK,
  });
};
