import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link,useHistory,useLocation  } from "react-router-dom"

import * as leadSyncAction from "../../appRedux/action/leadSyncAction";
import LoaderSvg from "components/common/LoaderSvg";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
const ConstantContact = (props) => {
    const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    console.log(window.location);
    if(params.get("code")){
      var current_url = window.location.origin+window.location.pathname;
      dispatch(leadSyncAction.updateLeadSyncCode("CONSTANT_CONTACT",params.get("code"),current_url));
      // window.location.href ='/sync-lead/constant_contact';
    }
  }, [dispatch]);
  
  const location_origin = window.location.origin;
  const [saving, setSaving] = useState({
    load: false,
  });

  const leadSync = useSelector(({ leadSync }) => leadSync.leadSyncData["CONSTANT_CONTACT"]);
  const [token, setToken] = useState(leadSync?leadSync.token:"");
  const [tokenError, setTokenError] = useState(false);
  let listIds = [];
  async function subbmitForm(e) {
    e.preventDefault();

    await setSaving({
      load: true,
    });

    if (!token) {
      setTokenError(true);
    } else {
      await dispatch(leadSyncAction.updateLeadSyncToken("CONSTANT_CONTACT",token));
      await dispatch(leadSyncAction.getLeadSyncData("CONSTANT_CONTACT"));
    }

    await setSaving({
      laod: false,
    });
  }
  async function subbmitListForm(e) {
    e.preventDefault();

    await setSaving({
      load: true,
    });

    await dispatch(leadSyncAction.updateLeadSyncField("CONSTANT_CONTACT","list_ids",listIds));


    await setSaving({
      laod: false,
    });
  }
  
  async function deleteToken(e) {
    e.preventDefault();
    await dispatch(leadSyncAction.deleteLeadSyncToken("CONSTANT_CONTACT"));
    await dispatch(leadSyncAction.getLeadSyncData("CONSTANT_CONTACT"));
  }
  const handleListChange = (selectedOptions) => {
    let list_ids = [];
    if(selectedOptions){
      selectedOptions.map(function(element){
        list_ids.push(element["value"]);
      });
    }
    listIds = list_ids;
  }
 
  useEffect(() => {
    dispatch(leadSyncAction.getLeadSyncData("CONSTANT_CONTACT"));
  }, [dispatch]);

  if(leadSync){
    if(leadSync.profile){
      if(Array.isArray(leadSync["list_ids"])){
        listIds = leadSync["list_ids"];
      }
      var options = [];
      let defaultValue = []
      if (typeof leadSync["lists"]["lists"] != undefined && Array.isArray(leadSync["lists"]["lists"])){
        leadSync["lists"]["lists"].map(function(element){
          if(listIds.includes(element["list_id"])){
            defaultValue.push({value: element["list_id"], label: element["name"] });

          }
          options.push({ value: element["list_id"], label: element["name"] })
      });
      }

      return (
        <>
        <div>
        <h4>{t("sync_lead.Constant_Contact")}</h4>
								<p>
									{leadSync.profile['first_name'] + ' ' + leadSync.profile['last_name']}&nbsp;-&nbsp;{leadSync.profile['contact_email']}
									
									<a className="text-red text-decoration-none" href="#" onClick={deleteToken}>&nbsp;<strong><i class="fa fa-angle-right"></i>&nbsp;{t("common.Logout")}</strong></a>
								</p>
							</div>
              <label><b>{t("sync_lead.send_in_multi")}</b></label>
              <Select 
              defaultValue={defaultValue}
              options={options} 
              isMulti
              name="list_ids"
              onChange={handleListChange}
              />
              <div className="save-btn" style={{ paddingTop: "15px" }}>
                <button
                  type="button"
                  className="seo_btn btn-save gradient_blu loader"
                  onClick={subbmitListForm}
                >
                  <i className="fas fa-save mr-2" />
                  {t("myAccount.save")} {saving.load && <LoaderSvg />}
                </button>
                <button style={{ marginLeft: "2rem" }} className="seo_btn btn-save gradient__orange" onClick={() => {window.open("https://seoaudit.software/faqs/how-to-connect-constant-contact-with-seo-lead-generation-tool/", '_blank', 'noreferrer')}} >
                {t("sync_lead.config_button")} <i class="ml-2 fas fa-external-link-alt"></i>
              </button>
              </div>
      </>
      )
    }else{
      return (
        <>
        
        {leadSync.has_access !== true?<>
          <div className="not-access-text">
          {t("sync_lead.not_access_text")} <Link
                onClick={() => {
                  history.push("/pricing");
                }}
                to="/pricing"
              >
                <i class="ml-2 fas fa-external-link-alt text-white"></i>
              </Link>
          </div>
      </>:<></>}
        <div className="SyncLeadContain">
        <h4>{t("sync_lead.Constant_Contact")}</h4>
            <div class="ctct-call-to-actions--item connect-account">
                <div>
                    <h3>{t("sync_lead.connect_constant")}</h3>
                    <p>{t("sync_lead.connect_constant_desc")}</p>
                </div>
                <button 
                onClick={() => {window.open(`https://authz.constantcontact.com/oauth2/default/v1/authorize?response_type=code&redirect_uri=${location_origin}/sync-lead/constant_contact&client_id=0134339a-7dda-41ec-a28c-b4d8639d1ba6&scope=contact_data%20offline_access%20account_read&state=0&nonce=0`)}} 
                class="button ctct-button button-blue ctct-connect"
                disabled = {leadSync.has_access !== true? true:false}
                >
                    {t("sync_lead.connect_plug")}				
                </button>
            </div>
            <div className="save-btn" style={{ paddingTop: "15px" }}>
                <button 
                className="seo_btn btn-save gradient__orange" 
                onClick={() => {window.open("https://seoaudit.software/faqs/how-to-connect-constant-contact-with-seo-lead-generation-tool/", '_blank', 'noreferrer')}} >
                {t("sync_lead.config_button")} <i class="ml-2 fas fa-external-link-alt"></i>
              </button>
              </div>
        </div>
      </>
      )
    }
  }else{
    return (
      <>
      </>
    );
  }
    // qqUip4o60dWtb7Lof5UnBg
    // 0134339a-7dda-41ec-a28c-b4d8639d1ba6
};

export default React.memo(ConstantContact);