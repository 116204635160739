import { CustomButton, CustomLoadingBtn } from "@components";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { modalStyle } from "../../assets/muicss";

export default function ResetModal({ resetWidgetHandler }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = React.useState(false);

  const classes = modalStyle();

  const handleResetClick = async (data, style) => {
    await setLoading(true);

    await resetWidgetHandler(data, style);

    handleClose();

    setLoading(false);
  };

  return (
    <div className="createwidget_reset_button">
      <CustomButton
        className="resetModal"
        label="Reset Widget"
        color="secondary"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        className={classes.fuck}
      >
        <Box className="body">
          <div className="wrapper">
            <IconButton onClick={handleClose} className="close__button">
              <CloseIcon />
            </IconButton>
            <Box className="">
              <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("createWidget.widgetDeleteConf")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t("createWidget.widgetDeleteConfDesc")}
              </Typography>
            </Box>
            <Box className="buttons">
              <CustomLoadingBtn
                loading={loading}
                onClick={() => handleResetClick("style")}
                size="medium"
                color="primary"
                label={t("createWidget.resetStyle")}
              />
              <CustomLoadingBtn
                loading={loading}
                onClick={() => handleResetClick("data", "style")}
                size="medium"
                color="secondary"
                label={t("createWidget.resetDataStyle")}
              />
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
