import {
  DELETE_AUDIT,
  DELETE_AUDIT_FAIL,
  DOWNLOAD_AUDIT,
  DOWNLOAD_AUDIT_FAIL,
  GET_ALL_AUDIT,
  GET_ALL_DELETE_AUDIT,
  GET_DELETED_AUDIT_BEGIN,
  GET_DELETED_AUDIT_FAIL,
  GET_DELETED_AUDIT_SUCCESS,
  GET_MY_AUDIT_BEGIN,
  GET_MY_AUDIT_FAIL,
  GET_MY_AUDIT_SUCCESS,
  REMOVE_AUDIT,
  SET_CURRENT_PAGE_NUM,
  SET_CURRENT_PAGE_NUM_DELETE
} from "../../action/myAudit/myAuditAction";

// const initialState = {
//   my_audit_loading: true,
//   audit: [],
//   error: {},
//   deletedaudit: [],
//   totalpages: null,
// };

const initialState = {
  my_audit_loading: true,
  audit: {},
  error: {},
  totalpages: null,
  current_page: null,
  deletedaudit: {
    audit: {},
    totalpages: null,
    current_page: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_AUDIT_BEGIN:
      return {
        ...state,
        my_audit_loading: true,
      };
    // case GET_MY_AUDIT_SUCCESS:
    //   return {
    //     ...state,
    //     audit: payload.data.data.audit,
    //     my_audit_loading: true,
    //     totalpages: payload.data.data.total_page,
    //   };
    case GET_MY_AUDIT_SUCCESS:
      const page = `page_${payload.data.data.current_page}`;
      let audit_data = { ...state.audit };
      // let new_audit = {}
      Object.keys(audit_data).map((item, index) => {
        const item_index = index + 1;
        // if(item_index >! parseInt(payload.data.data.total_page)) {
        //   console.log(item)
        // }
        // console.log(item)
        // const cond = !(item_index > payload.data.data.total_page || item_index !== payload.data.data.total_page);
        if (audit_data[item].length === 0) {
          // console.log('inside loop')
          delete audit_data[item];
        }
      });
      audit_data = {
        ...audit_data,
        [page]: payload.data.data.audit,
      };
      // console.log('audit_data', audit_data)
      return {
        ...state,
        audit: audit_data,
        my_audit_loading: true,
        totalpages: payload.data.data.total_page,
        current_page: payload.data.data.current_page,
      };
    case GET_ALL_AUDIT:
      const all_data = data_slice(payload);
      const all_new_data = {
        ...state.audit,
        ...all_data,
      };
      return {
        ...state,
        audit: all_new_data,
      };
    case GET_ALL_DELETE_AUDIT:
      const all_delete_data = data_slice(payload);
      // console.log('all_delete_data', ...all_delete_data)
      const new_data = {
        ...state.deletedaudit.audit,
        ...all_delete_data,
      };
      // console.log(new_data, 'mew')
      return {
        ...state,
        deletedaudit: {
          ...state.deletedaudit,
          audit: new_data,
        },
      };
    case GET_MY_AUDIT_FAIL:
      return {
        ...state,
        my_audit_loading: false,
      };
    case DELETE_AUDIT:
      return {
        ...state,
        my_audit_loading: false,
      };
    case DELETE_AUDIT_FAIL:
      return {
        ...state,
        my_audit_loading: false,
        error: payload,
      };
    case DOWNLOAD_AUDIT:
      return {
        ...state,
        my_audit_loading: false,
      };
    case DOWNLOAD_AUDIT_FAIL:
      return {
        ...state,
        my_audit_loading: false,
        error: payload,
      };
    case GET_DELETED_AUDIT_BEGIN:
      return {
        ...state,
        my_audit_loading: true,
      };
    // case GET_DELETED_AUDIT_SUCCESS:
    //   return {
    //     ...state,
    //     deletedaudit: payload.data.data.audit,
    //     my_audit_loading: true,
    //     totalpages: payload.data.data.total_page,
    //   };
    case GET_DELETED_AUDIT_SUCCESS:
      const curr_page = `page_${payload.data.data.current_page}`;

      let deleted_audit_data = { ...state.deletedaudit.audit };
      // let new_audit = {}
      Object.keys(deleted_audit_data).map((item, index) => {
        const item_index = index + 1;
        // if(item_index >! parseInt(payload.data.data.total_page)) {
        //   console.log(item)
        // }
        // console.log(item)
        // const cond = !(item_index > payload.data.data.total_page || item_index !== payload.data.data.total_page);
        if (deleted_audit_data[item].length === 0) {
          // console.log('inside loop')
          delete deleted_audit_data[item];
        }
      });
      deleted_audit_data = {
        ...deleted_audit_data,
        [curr_page]: payload.data.data.audit,
      };

      return {
        ...state,
        deletedaudit: {
          ...state.deletedaudit,
          audit: deleted_audit_data,
          totalpages: payload.data.data.total_page,
          current_page: payload.data.data.current_page,
        },
      };
    case GET_DELETED_AUDIT_FAIL:
      return {
        ...state,
        my_audit_loading: false,
      };
    case SET_CURRENT_PAGE_NUM:
      return {
        ...state,
        current_page: payload,
      };
    case SET_CURRENT_PAGE_NUM_DELETE:
      return {
        ...state,
        deletedaudit: {
          ...state.deletedaudit,
          current_page: payload,
        },
      };
    case REMOVE_AUDIT:
      return initialState;
    default:
      return state;
  }
}

const data_slice = (payload) => {
  const arr_data = payload.data.data.audit.length > 20 && payload.data.data.audit.slice(20);
  // let total_page = payload.data.data.audit.length > 20 && payload.data.data.totalpages;
  let i;
  let j;
  let temparray;
  const chunk = 20;
  const audit = {};
  let count = 2;
  for (i = 0, j = arr_data.length; i < j; i += chunk) {
    temparray = arr_data.slice(i, i + chunk);
    audit[`page_${count}`] = temparray;
    count += 1;
  }
  return audit;
};
