import {
    GET_ALL_LANGUAGE_BEGIN,
    GET_ALL_LANGUAGE_SUCCESS,
    GET_ALL_LANGUAGE_FAIL

} from '../action/languageAction'

const initialState = {
    languages:[],
    language_loading:false
}


export default (state = initialState, action) => {
    switch(action.type){
        case GET_ALL_LANGUAGE_BEGIN:
            return {
                ...state,
                language_loading:true
            }
        
        case GET_ALL_LANGUAGE_SUCCESS:
            return {
                ...state,
                language_loading:false,
                languages: action.payload.data.data.languages
            }
        
        case GET_ALL_LANGUAGE_FAIL:
            return {
                ...state,
                language_loading:false
            }

        default:
            return state
    }
}