import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { removeAlert } from "../../appRedux/action/alert";

const Alert = ({ alerts }) => {
  const dispatch = useDispatch();
  let classNames = "alert__a";
  classNames += "nimation";

  // OutsideClick Hide Alert
  // Detect the outisde click
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(removeAlert());
        }
      }

      // Bind the event listender
      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [ref]);
  }

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef);

  const hideAlert = async () => {
    dispatch(removeAlert());
  };

  return alerts.length > 0
    ? alerts.map((alert) => (
        <div
          ref={wrapperRef}
          key={alert.id}
          className={`text-${alert.alertType} alert__message bg-white ${classNames}`}
        >
          <table>
            <tbody>
              <tr>
                <td className="info__icon">
                  <i className="fas fa-info-circle  arrow" />
                </td>
                {/* <i className="fas fa-info-circle searchenginemidbox " /> */}

                <td className="message">{alert.msg}</td>

                {/* <i
                    aria-hidden="true"
                    className="fas fa-times-circle searchenginemidbox "
                    style={{
                      color: "red",
                      fontSize: "16px",
                      cursor: "pointer",
                      padding: "0px 5px",
                    }}
                    onClick={hideAlert}
                  /> */}
                <td className="arrow">
                  <i
                    aria-hidden="true"
                    className="fas fa-times-circle "
                    style={{
                      color: "red",
                      fontSize: "16px",
                      cursor: "pointer",
                      padding: "0px 5px",
                    }}
                    onClick={hideAlert}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))
    : null;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
