import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import RequiredLabel from "../RequiredLabel";

const WebsiteUrl = ({
  values,
  handlelabelPlaceChange,
  wform_url,
  wform_keyword,
  setValues,
  setErrors,
  can_advance_audit,
  errors,
}) => {
  const useYellowStyles = makeStyles(() => ({
    root: {
      background: "#fffde5",
      border: "1px solid #ffcf00",
      padding: "5px 5px",
      lineHeight: "20px",
      fontSize: "15px",
      borderRadius: "5px",
      marginBottom: "10px",
      "& a":{
        color: "#007bff",
        textDecoration: "underline",
      },
      "& strong": {
        lineHeight: "initial",
        fontSize: "15px",
        display: "inline-block",
        fontWeight: "700",
      },
    },
  }));
  const classesYellow = useYellowStyles();

  const { t } = useTranslation();
  console.log(wform_url.advance_audit);
  return (
    <Box className="divider ">
      <InputLabel
        label={t("createWidget.formField.7")}
        title={t("createWidget.formField.8")}
      />
      <div className="check ">
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="radio"
            className="custom-control-input d-none"
            name="advance_audit"
            id="advance_audit"
            onChange={() => {
              setValues({
                ...values,
                wform_url: {
                  ...values.wform_url,
                  advance_audit: wform_url.advance_audit === 1 ? 0 : 1,
                },
              });
            }}
            disabled= {can_advance_audit !== 1? true:false}
            checked={wform_url.advance_audit === 0}
          />
          <label className="custom-control-label" htmlFor="advance_audit">
            <Typography component="span" variant="body1">
              {t("createWidget.checkBox.labels.advance_audit")}
            </Typography>
          </label>
        </div>
        <div className="custom-control custom-checkbox custom-selector2 ">
          <input
            type="radio"
            className="custom-control-input d-none"
            name="advance_audit1"
            id="advance_audit1"
            onChange={() => {
              setValues({
                ...values,
                wform_url: {
                  ...values.wform_url,
                  advance_audit: wform_url.advance_audit === 1 ? 0 : 1,
                },
              });
            }}
            disabled= {can_advance_audit !== 1? true:false}
            checked={wform_url.advance_audit !== 0}
          />
          <label className="custom-control-label" htmlFor="advance_audit1">
            <Typography component="span" variant="body1">
              {t("createWidget.checkBox.labels.advance_audit1")}
            </Typography>
          </label>
        </div>
        {(can_advance_audit === 0)?(
          <div className={classesYellow.root}>
              {t("createWidget.checkBox.labels.advance_audit_cant1")}, <a href='/pricing' target="_blank">{t("createWidget.checkBox.labels.advance_audit_cant2")}</a>
          </div>
        
        ): (
          <div className={classesYellow.root}>
          {t("createWidget.checkBox.labels.advance_audit_info")}
          </div>
        )}
      {(values.show_field_text_as === 1 || values.show_field_text_as === 3) && (
        <Box className="input_wrapper custom-selector2  ">
          <RequiredLabel label={t("common.label")} required />
          <input
            placeholder={t("createWidget.formField.9")}
            name="label"
            value={wform_url.label}
            type="text"
            onChange={(e) => {
              handlelabelPlaceChange(e, "wform_url");
              if (e.target.value === "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  url_label_error: <span>{t("common.required")}</span>,
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  url_label_error: "",
                }));
              }
            }}
          />
          <div className="form__error">{errors.url_label_error}</div>
        </Box>
      )}
      {(values.show_field_text_as === 2 || values.show_field_text_as === 3) && (
        <Box className="input_wrapper ">
          <RequiredLabel label={t("common.placeholder")} required />
          <input
            placeholder={t("createWidget.formField.10")}
            name="placeholder"
            value={wform_url.placeholder}
            type="text"
            onChange={(e) => {
              handlelabelPlaceChange(e, "wform_url");
              if (e.target.value === "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  url_placeholder_error: <span>{t("common.required")}</span>,
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  url_placeholder_error: "",
                }));
              }
            }}
          />
          <div className="form__error">{errors.url_placeholder_error}</div>
        </Box>
      )}

        {values.widget_type === "5" || values.widget_type === 5 ?
            <div className="custom-control custom-checkbox custom-selector2 ">
              <input
                type="checkbox"
                className="custom-control-input"
                name="show_in_first_step"
                value={wform_url.show_in_first_step}
                id="wform_url_show_in_first_step"
                onChange={() => {
                  setValues({
                    ...values,
                    wform_url: {
                      ...values.wform_url,
                      show_in_first_step: wform_url.show_in_first_step > 0 ? 0 : 1,
                    },
                  });
                }}
                checked={wform_url.show_in_first_step !== 0}
              />
              <label className="custom-control-label" htmlFor="wform_url_show_in_first_step">
                <Typography component="span" variant="body1">
                  {t("createWidget.formField.35")}
                </Typography>
              </label>
            </div>
            :""}
      </div>
    </Box>
  );
};

export default WebsiteUrl;
