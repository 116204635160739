import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    minWidth: "200px",
    position: "relative",
    zIndex: "2",
    padding: "2px 0",
    "& div > div": {
      minHeight: "auto",
      borderColor: "#ddd !important",
      "&:first-child": {
        "&:hover": {
          boxShadow: "none",
        },
      },
      "& div:nth-last-of-type(1)": {
        "& div": {
          padding: "4px",
        },
      },
      "& div:nth-of-type(1)": {
        "& div": {
          padding: "0px",
          margin: "0px",
        },
      },
    },
  },
}));
