import {
  GET_AVG_RANK_DATA_BEGIN,
  GET_AVG_RANK_DATA_FAIL,
  GET_AVG_RANK_DATA_SUCCESS,
  GET_ENGINE_RANK_DATA_BEGIN,
  GET_ENGINE_RANK_DATA_FAIL,
  GET_ENGINE_RANK_DATA_SUCCESS,
  GET_PROJECT_RANK_DATA_BEGIN,
  GET_PROJECT_RANK_DATA_FAIL,
  GET_PROJECT_RANK_DATA_SUCCESS
} from "../../action/RankTopDataAction";

const initialState = {
  rankData: {
    projectData: {},
    searchEngineData: {},
  },
  loading: true,
  error: null,
};
const rankTopDataReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_PROJECT_RANK_DATA_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_PROJECT_RANK_DATA_SUCCESS:
      return {
        ...state,
        rankData: {
          projectData: payload,
        },
        loading: true,
      };
    case GET_PROJECT_RANK_DATA_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_ENGINE_RANK_DATA_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ENGINE_RANK_DATA_SUCCESS:
      // console.log(payload);
      return {
        ...state,
        rankData: {
          searchEngineData: payload,
        },
        loading: true,
      };
    case GET_ENGINE_RANK_DATA_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    // ---------start keyword group case ---------
    case GET_AVG_RANK_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_AVG_RANK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        rankData: {
          groups: payload.groups,
        },
      };

    case GET_AVG_RANK_DATA_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default rankTopDataReducer;
