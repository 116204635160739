import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mainRoot: {
    "& h5.title": {
      fontWeight: "500",
      fontSize: "1.1rem",
      marginBottom: ".2rem"
    },
    "& h6.title": {
      fontWeight: "500",
      fontSize: "1rem",
      marginBottom: ".2rem"
    },
    "label.required small": {
      color: theme.palette.common.red
    }
  },

  commonLabel: {
    display: "flex",
    alignItems: "center",
    "& label": {
      display: "flex",
      alignItems: "center",
      marginTop: "0 !important",
      fontWeight: "500 !important",
      fontSize: "1rem !important",
      "& small": {
        marginTop: "0 !important",
        color: theme.palette.common.red,
      },
      "& span": {
        lineHeight: "initial",
      }
    }
  },

  requiredLabel: {
    display: "flex",
    alignItems: "center",
    "& label": {
      display: "flex",
      alignItems: "center",
      marginTop: "6px !important",
      fontWeight: "400 !important",
      "& small": {
        marginTop: "0 !important",
        color: theme.palette.common.red,
      },
      "& span": {
        lineHeight: "initial",
      }
    }
  },

  labelInfo: {
    display: "inline-block",
    marginLeft: ".2rem",
    lineHeight: "initial",
    "& svg": {
      fontSize: "1.1rem",
      color: "#9d9d9d",
      cursor: "pointer",
    }
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "&:not(:nth-last-of-type(1))": {
      borderBottom: "1px solid #ddd",
      marginBottom: "1.2rem"
    },
    "&:nth-last-of-type(1)": {
      "& > div": {
        marginBottom: "0",
        "&:first-child": {
          marginBottom: "-.1rem",
        }
      }
    },
    "& > div": {
      "&:first-child": {
        fontSize: "1.1rem",
        fontWeight: "500",
        marginBottom: ".5rem",
        minWidth: "100px"
      }
    }
  },

  switch: {
    "& .MuiFormControlLabel-label": {
      fontSize: ".87rem",
      textTransform: "uppercase",
      fontWeight: "500",
    },
    "& input": {
      margin: "0 !important",
      padding: "0 !important",
      width: "300% !important",
      display: "initial !important",
      maxWidth: "initial !important",
    },
    "& .MuiSwitch-switchBase": {
      padding: "8px",
      top: "7px",
      left: "6px",
    },
    "& .MuiSwitch-root": {
      width: "65px",
      height: "45px",
    },
    "& .MuiSwitch-track": {
      borderRadius: "20px",
      opacity: "1",
      backgroundColor: "#dcdcdc",
    },
    "& .MuiSwitch-thumb": {
      color: "#fd552f",
      width: "15px",
      height: "15px",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        color: "#fff",
      },
    },
    "& .Mui-checked+.MuiSwitch-track": {
      opacity: "1 !important",
      backgroundColor: "#0dc236 !important",
    },
    "& .Mui-disabled+.MuiSwitch-track": {
      opacity: "1 !important",
    },
  },

  formStyle: {
    marginTop: "0rem",
    "& .MuiBox-root": {
      marginRight: "2rem",
      marginBottom: "1.6rem",
      "& > div": {
        display: "flex",
        alignItems: "center",
        "& input": {
          marginRight: "5px"
        }
      },
      "& input": {
        margin: "0",
        maxWidth: "75px",
        height: "35px",
        padding: "5px",
        "&[type=color]": {
          padding: "3px",
          maxWidth: "45px",
          boxShadow: "1px 0 8px 1px rgba(0, 0, 0, .123)",
          cursor: "pointer",
        }
      },
      "& label": {
        fontSize: ".75rem",
        fontWeight: "500",
        whiteSpace: "nowrap",
        marginTop: "0",
      }
    },

  },

  dividers: {
    "& .divider": {
      borderBottom: "2px solid #c7c7c7",
      paddingBottom: "1.5rem",
      marginBottom: "1.5rem",

      "& h4": {
        fontSize: "1rem",
      },

      "& .input__wrapper": {
        "& .MuiBox-root": {
          marginLeft: "1rem",
          marginTop: ".5rem",
          "& small": {
            fontSize: ".85rem",
            fontWeight: "400",
          },
          "& input": {
            marginTop: "0"
          }
        }
      }

    }
  },

  color: {
    border: "1px solid #d7d7d7",
    width: "75px",
    height: "35px",
    borderRadius: "4px",
  },

  swatch: {
    borderRadius: "1px",
    display: "inline-block",
    cursor: "pointer",
  },

  popover: {
    position: "absolute",
    zIndex: "999",
    "& > div": {
      boxShadow: "1px 5px 10px 1px rgba(183, 183, 183, .4)"
    }
  },

  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    zIndex: "-1"
  },

  button: {
    position: "relative",
    boxShadow: "none",
    padding: "0",
    border: "none",
  },

  accordion: {
    "& .MuiPaper-root": {
      "&.Mui-expanded": {
        marginBottom: "4rem"
      }
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      "&.Mui-expanded": {
        minHeight: "auto",
        marginBottom: "2rem",
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
        "& h4": {
          fontSize: "1.25rem",
          textTransform: "initial",
          fontWeight: "500",
        },
        "&.Mui-expanded": {
          margin: "0",
        }
      }
    }
  },

  themeRadio: {
    "& label": {
      display: "block",
      width: "120px",
      margin: "5px 10px",
      "&:nth-of-type(1)": {
        marginLeft: "0",
      },
      "&:nth-last-of-type(1)": {
        marginRight: "0",
      },
      "& span.theme__name": {
        display: "block",
        textAlign: "center",
        fontWeight: "400",
        lineHeight: "1.1rem"
      }
    },
    "& .image": {
      opacity: "1",
      width: " 120px",
      height: " 90px",
      backgroundPosition: " center center",
      backgroundColor: "#ddd",
      display: "inline-block",
      borderRadius: "5px",
      backgroundSize: "cover",
      transition: ".2s all ease-in-out",
      "&:hover": {
        opacity: "1",
      }
    },

    "& .radio-img": {
      "& > input": {
          display: "none",
      },
      "& > .image": {
        cursor: "pointer",
        border: "1px solid #959595",
      },
      "& > input:checked + .image": {
        border: "3px solid orange",
        transform: "scale(1.07)"
      }
    }
  }

}));
