import axios from "axios";
import Auth from "../../../modules/Auth";
import serverCall from "../../../modules/serverCall";
import SetServerUrl from "../../../modules/URL";
import { setAlert } from "../alert";
import i18n from "../../../i18n";

export const GET_MY_AUDIT_BEGIN = "GET_MY_AUDIT_BEGIN";
export const GET_MY_AUDIT_SUCCESS = "GET_MY_AUDIT_SUCCESS";
export const GET_MY_AUDIT_FAIL = "GET_MY_AUDIT_FAIL";

export const DELETE_AUDIT = "DELETE_AUDIT";
export const DELETE_AUDIT_FAIL = "DELETE_AUDIT_FAIL";

export const DOWNLOAD_AUDIT = "DOWNLOAD_AUDIT";
export const DOWNLOAD_AUDIT_FAIL = "DOWNLOAD_AUDIT_FAIL";

export const GET_DELETED_AUDIT_BEGIN = "GET_DELETED_AUDIT_BEGIN";
export const GET_DELETED_AUDIT_SUCCESS = "GET_DELETED_AUDIT_SUCCESS";
export const GET_DELETED_AUDIT_FAIL = "GET_DELETED_AUDIT_FAIL";
export const SET_CURRENT_PAGE_NUM = "SET_CURRENT_PAGE_NUM";
export const REMOVE_AUDIT = "REMOVE_AUDIT";
export const GET_ALL_AUDIT = "GET_ALL_AUDIT";
export const SET_CURRENT_PAGE_NUM_DELETE = "SET_CURRENT_PAGE_NUM_DELETE";
export const GET_ALL_DELETE_AUDIT = "GET_ALL_DELETE_AUDIT";

export const fetchAudit = (pageNumber) => (dispatch) => {
  // console.log("fetching");

  dispatch({
    type: GET_MY_AUDIT_BEGIN
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/list/?type=audit&page_item=20&page=${pageNumber}`
  })
    .then((res) => {
      dispatch({
        type: GET_MY_AUDIT_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_MY_AUDIT_FAIL,
        payload: { error }
      });
      return error;
    });
};

// Delete Audit
export const deleteAudit = (formData) => async (dispatch) => serverCall({
    method: "DELETE",
    url: "/api/v1/audit/delete/",
    data: formData
  })
    .then((res) => {
      if(res.status >=200 && res.status<300){
        dispatch({
          type: DELETE_AUDIT,
          payload: res
        });
        dispatch(setAlert(res.data.message, "success"));

      }else{
        dispatch(setAlert(res.data.message, "danger"));
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: DELETE_AUDIT_FAIL,
        payload: { error }
      });
      dispatch(setAlert(i18n.t("alert_messages.went_wrong"), "danger"));
      return error;
    });

// DOWNLOAD Audit
var langParam = window.sessionStorage.getItem("activeLanguage");
langParam = langParam && langParam !=""? langParam:"en";
export const downloadAudit = (audit_id) => async (dispatch) => {
  const react_token = Auth.getToken();
  
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.get(
      `${SetServerUrl}/download_pdf/?lang=${langParam}&id=${audit_id}&type=audit&react_token=${react_token}`,
      config
    );

    dispatch({
      type: DOWNLOAD_AUDIT,
      payload: res
    });
    dispatch(setAlert(i18n.t("alert_messages.download_success"), "success"));
  } catch (error) {
    dispatch(setAlert(i18n.t("alert_messages.download_failed"), "danger"));


    dispatch({
      type: DOWNLOAD_AUDIT_FAIL,
      payload: { error }
    });
    return error;
  }
};

export const fetchDeletedAudit = (pageNumber) => (dispatch) => {
  dispatch({
    type: GET_DELETED_AUDIT_BEGIN
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/list/?type=audit&status=deleted&page_item=20&page=${pageNumber}`
  })
    .then((res) => {
      dispatch({
        type: GET_DELETED_AUDIT_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_DELETED_AUDIT_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const setCurrentPageNumber = (data) => (dispatch) => {
  // console.log(data)
  dispatch({
    type: GET_MY_AUDIT_BEGIN
  });
  dispatch({
    type: SET_CURRENT_PAGE_NUM,
    payload: data
  });
  dispatch({
    type: GET_MY_AUDIT_FAIL,
    payload: { error: "There is an error" }
  });
};

export const setCurrentPageNumberDelete = (data) => (dispatch) => {
  // console.log(data)
  dispatch({
    type: GET_MY_AUDIT_BEGIN
  });
  dispatch({
    type: SET_CURRENT_PAGE_NUM_DELETE,
    payload: data
  });
  dispatch({
    type: GET_MY_AUDIT_FAIL,
    payload: { error: "There is an error" }
  });
};

export const remove_my_audit = () => (dispatch) => {
  // console.log(data)
  dispatch({
    type: REMOVE_AUDIT
  });
};

export const fetchAllAudit = (pageItem) => (dispatch) => {
  // console.log("fetching");

  dispatch({
    type: GET_MY_AUDIT_BEGIN
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/list/?type=audit&page_item=${pageItem}`
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_AUDIT,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_MY_AUDIT_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const fetchAllDeleteAudit = (pageItem) => (dispatch) => {
  // console.log("fetching");

  dispatch({
    type: GET_MY_AUDIT_BEGIN
  });
  return serverCall({
    method: "GET",
    url: `/api/v1/page/audit/list/?type=audit&status=deleted&page_item=${pageItem}`
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_DELETE_AUDIT,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_MY_AUDIT_FAIL,
        payload: { error }
      });
      return error;
    });
};
