import serverCall from "../../../modules/serverCall";

export const GET_NOTIFICATION_BEGIN = "GET_NOTIFICATION_BEGIN";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const getNotificationData = () => (dispatch) => {
  dispatch({ type: GET_NOTIFICATION_BEGIN });

  return serverCall({
    method: "GET",
    url: "/api/v1/notifications/",
  })
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_NOTIFICATION_FAIL,
        payload: { error },
      });
      return error;
    });
};
