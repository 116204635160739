import { ButtonLoader, KeywordTooltip, ToolTip } from "@components";
import InfoTooltip from "@components/InfoTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { getEngineRankData } from "appRedux/action/RankTopDataAction";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as adminSiteListActions from "../../../appRedux/action/adminSiteListActions";
import { deleteEngine } from "../../../appRedux/action/engineAction";
import "../../../assets/sass/FlagButton.scss";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { project_id, se_id } = props;

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  async function deleteSearchengine() {
    setLoading(true);

    await dispatch(deleteEngine(parseInt(project_id), se_id));

    await dispatch(adminSiteListActions.getAdminSiteListData());

    setLoading(false);

    handleClose();
  }

  return (
    <span>
      <ToolTip title={`${t("common.deleteSearchEngine")}`}>
        <i
          aria-hidden="true"
          onClick={handleShow}
          className="fas fa-trash-alt"
        />
      </ToolTip>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>{t("modalMessage.one")}</h5>
        </Modal.Header>
        <Modal.Body>
          <small>{t("modalMessage.two")}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button className="seo_btn danger" onClick={deleteSearchengine}>
            {t("modalMessage.btnOne")} {loading && <ButtonLoader />}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

function SearchEngineGroupedsite({
  searchEngine,
  site_id,
  props,
  selectProject,
  dataRoot,
}) {
  const { t } = useTranslation();
  const getTopRankData = useSelector(
    (topRankData) => topRankData.rankData.rankData
  );

  const dispatch = useDispatch();

  const validateEngineData = {
    topFiveData: (getTopRankData.searchEngineData || {}).topFiveData || [],
    topTenData: (getTopRankData.searchEngineData || {}).topTenData || [],
    topThirteenData:
      (getTopRankData.searchEngineData || {}).topThirTeenData || [],
  };

  const [seValuLoading, setSeValueLoading] = useState({
    load: false,
  });

  async function hoverEngineData(id, topValue) {
    await setSeValueLoading({
      load: true,
    });
    if (topValue === "5") {
      await dispatch(getEngineRankData(id, topValue));
    }

    if (topValue === "10") {
      await dispatch(getEngineRankData(id, topValue));
    }

    if (topValue === "30") {
      await dispatch(getEngineRankData(id, topValue));
    }
    setSeValueLoading({
      load: false,
    });
  }

  return (
    <>
      <div className="sub-body searchrankingTable">
        <div className="sub-data searchrankingTable">
          <div
            aria-hidden="true"
            className="engine"
            onClick={(e) => {
              e.preventDefault();
              selectProject(site_id, searchEngine.id);
            }}
          >
            <span>
              <i className="fab fa-google" />
            </span>
            <strong>{searchEngine.title}</strong>

            {searchEngine?.location && (
              <InfoTooltip title={searchEngine?.location}>
                <InfoIcon />
              </InfoTooltip>
            )}
          </div>
        </div>
        <div className="sub-data searchrankingTable ">
          <div className="rank searchrankingTable">
            <ToolTip
              title={
                <KeywordTooltip
                  loading={seValuLoading}
                  data={validateEngineData.topFiveData}
                />
              }
            >
              <span
                onMouseEnter={() => {
                  hoverEngineData(searchEngine.id, "5");
                }}
              >
                {searchEngine.top[0]}
              </span>
            </ToolTip>
            <ToolTip
              title={
                <KeywordTooltip
                  loading={seValuLoading}
                  data={validateEngineData.topTenData}
                />
              }
            >
              <span
                onMouseEnter={() => {
                  hoverEngineData(searchEngine.id, "10");
                }}
              >
                {searchEngine.top[1]}
              </span>
            </ToolTip>
            <ToolTip
              title={
                <KeywordTooltip
                  loading={seValuLoading}
                  data={validateEngineData.topThirteenData}
                />
              }
            >
              <span
                onMouseEnter={() => {
                  hoverEngineData(searchEngine.id, "30");
                }}
              >
                {searchEngine.top[2]}
              </span>
            </ToolTip>
          </div>
        </div>
        <div className="sub-data searchrankingTable ">
          <span>{searchEngine.keyword_count}</span>
        </div>
        <div className="sub-data searchrankingTable">
          {searchEngine.avg_position.map((avg, index) => (
            <span key={index}>
              {index ? "" : " "} {avg}
            </span>
          ))}
        </div>
        <div className="sub-data actions searchrankingTable">
          <div className="t-option">
            <ToolTip title={t("common.viewRanking")}>
              <button
                type="button"
                className={`${searchEngine.title.toLowerCase()} flag__button green`}
                aria-hidden="true"
                onClick={(e) => {
                  e.preventDefault();
                  selectProject(site_id, searchEngine.id);
                }}
              >
                <i />
              </button>
            </ToolTip>
            <span className="green">
              <ToolTip title={t("common.editSearchEngine")}>
                <Link
                  to={{
                    pathname: `/edit-engines/${site_id}`,
                    seId: searchEngine.id,
                  }}
                >
                  <i className="fas fa-cog" />
                </Link>
              </ToolTip>
            </span>
            <span className="red">
              <DeleteModal project_id={site_id} se_id={searchEngine.id} />
            </span>
          </div>
        </div>
      </div>
      <div className="sub-body dashboard_table_mob  ">
        <div className="sub-data">
          <div
            aria-hidden="true"
            className="engine"
            onClick={(e) => {
              e.preventDefault();
              selectProject(site_id, searchEngine.id);
            }}
          >
            <span>
              <i className="fab fa-google" />
            </span>
            <strong>{searchEngine.title}</strong>

            {searchEngine?.location && (
              <InfoTooltip title={searchEngine?.location}>
                <InfoIcon />
              </InfoTooltip>
            )}
          </div>
        </div>
        <div className="">
          <div className="">
            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> Top 5 :</span>

                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topFiveData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "5");
                      }}
                    >
                      {searchEngine.top[0]}
                    </span>
                  </ToolTip>
                  {/* {site.top[0]} */}
                </div>
              </td>
            </tr>

            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> Top 10 :</span>

                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topTenData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "10");
                      }}
                    >
                      {searchEngine.top[1]}
                    </span>
                  </ToolTip>
                  {/* {site.top[0]} */}
                </div>
              </td>
            </tr>

            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> Top 30 :</span>

                  <ToolTip
                    title={
                      <KeywordTooltip
                        loading={seValuLoading}
                        data={validateEngineData.topTenData}
                      />
                    }
                  >
                    <span
                      onMouseEnter={() => {
                        hoverEngineData(searchEngine.id, "30");
                      }}
                    >
                      {searchEngine.top[2]}
                    </span>
                  </ToolTip>
                </div>
              </td>
            </tr>

            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> AVG.POSITION:</span>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span">
                    {" "}
                    {dataRoot && dataRoot.dataRoot.avg_position_date[0]}:
                  </span>

                  <span>{searchEngine.avg_position[0]}</span>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span">
                    {" "}
                    {dataRoot && dataRoot.dataRoot.avg_position_date[1]}:
                  </span>
                  <span>{searchEngine.avg_position[1]}</span>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> Keywords:</span>

                  <span>{searchEngine.keyword_count}</span>
                </div>
              </td>
            </tr>

            <tr className="">
              <td className="lead-info lead-margin">
                <div
                  className="text table_data_condition"
                  style={{ fontWeigth: "Bold" }}
                >
                  <span className="table_data_span"> ACTION </span>
                  <span className="green  mr-4">
                    <ToolTip title={t("common.viewRanking")}>
                      <button
                        type="button"
                        className={`${searchEngine.title.toLowerCase()} flag__button green`}
                        aria-hidden="true"
                        onClick={(e) => {
                          e.preventDefault();
                          selectProject(site_id, searchEngine.id);
                        }}
                      >
                        <i />
                      </button>
                    </ToolTip>
                  </span>
                  <span className="green green_duplicate">
                    <ToolTip title={t("common.editSearchEngine")}>
                      <Link
                        to={{
                          pathname: `/edit-engines/${site_id}`,
                          seId: searchEngine.id,
                        }}
                      >
                        <i className="fas fa-cog fas-class fas-class-additional " />
                      </Link>
                    </ToolTip>
                  </span>
                  <span className="red red_additional">
                    <DeleteModal project_id={site_id} se_id={searchEngine.id} />
                  </span>
                </div>
              </td>
            </tr>
          </div>
        </div>
      </div>
    </>
  );
}
export default SearchEngineGroupedsite;
